import "../../schemes.page.styles.css";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import React from "react";

function SchemeEligibility() {
  return (
    <div>
      <div className="auto-container mt-5 pt-5 pb-5">
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div>
                <div className="schemes_heading athority-section text-dark ">
                  <b>SANCTIONING AUTHORITY</b>
                </div>
                <div className="athority-section-list">
                  <ul className="mt-3">
                    <li className="mt-5 mb-2">
                      <div>
                        <div className="d-flex mt-2">
                          <div className="" style={{ height: "100%" }}>
                            <KiedIcons
                              icon="list-btn"
                              className="authority-icon"
                            />
                          </div>
                          <div className="authority-txt">
                            <div>
                              For Startup Support - General Manager, District
                              Industries centre
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="mt-2 mb-2">
                      <div>
                        <div className="d-flex mt-2">
                          <div className="" style={{ height: "100%" }}>
                            <KiedIcons
                              icon="list-btn"
                              className="authority-icon"
                            />
                          </div>
                          <div className="authority-txt">
                            <div>
                              For Investment Support-District Level Committee
                              consisting of the following Members;.
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="authority-address">
                    <ul>
                      <li>
                        <div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            District Collector (Chairman)
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            Lead District Manager
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            Representative of Finance Department in Goverment
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            District Manager, KFC
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            Representative of KSSIA District Committee
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            General Manager, District Industries Centre (Member
                            Secretary)
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div>
                <div className="schemes_heading athority-section text-dark ">
                  <b>APPELLATE AUTHORITY</b>
                </div>
                <div className="athority-section-list">
                  <ul className="mt-3">
                    <li className="mt-5 mb-2">
                      <div></div>
                    </li>
                    <li className="mt-2 mb-2">
                      <div>
                        <div className="d-flex mt-2">
                          <div className="" style={{ height: "100%" }}>
                            <KiedIcons
                              icon="list-btn"
                              className="authority-icon"
                            />
                          </div>
                          <div className="authority-txt">
                            <div>
                              If the decision of the District level Committee is
                              not satisfactory, an applicant can approach the
                              State level Committee, consisting of the following
                              Members,
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="authority-address">
                    <ul>
                      <li>
                        <div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            District Collector (Chairman)
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            Lead District Manager
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            Representative of Finance Department in Goverment
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            Managing Director, KSIDC
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            Managing Director,KFC
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            Director-MSME(DI)
                          </div>
                          <div className="authority-details pl-4 ml-2 mb-2">
                            Representative of KISSA State Committee
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ;
    </div>
  );
}

export default SchemeEligibility;
