// import React from "react";

// function EDCDescription() {
//   return (
//     <div className="auto-container inovation_text about_kied">
//       <p className="">
//         Department of Industries & Commerce, Government of Kerala formulated a
//         scheme to set up Entrepreneurship Development Clubs in schools and
//         colleges of the State to inculcate Entrepreneurial Culture amongst youth
//         and equip them with the skills, techniques and confidence to act as
//         torch-bearers of Enterprise for the new generation. There are around 600
//         plus ED Clubs registered across Kerala of which around 400 are actively
//         engaged in entrepreneurship activities .Department of Industries &
//         Commerce, Government of Kerala formulated a scheme to set up
//         Entrepreneurship Development Clubs in schools and colleges of the State
//         to inculcate Entrepreneurial Culture amongst youth and equip them with
//         the skills, techniques and confidence to act as torch-bearers of
//         Enterprise for the new generation. There are around 600 plus ED Clubs
//         registered across Kerala of which around 400 are actively engaged in
//         entrepreneurship activities .
//       </p>
//     </div>
//   );
// }

// export default EDCDescription;

import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function EDCDescription() {
  const width = useScreenBreakPoint();
  return (
    <div
      className={`row auto-container d-flex ${
        width === "xs" || width === "sm"
          ? "flex-column align-items-center mt-4"
          : " align-items-center my-5 py-5 pr-3 pl-4 "
      }`}
    >
      <div className="col-lg-6 col-md-6 col-sm-12 p-0">
        <KiedImages className="w-100 hero-img" image="edc-new" />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 p-0">
        <div className=" inovation_text ">
          <p className="pt-5 pb-2">
            The Government of Kerala, through its Department of Industries &
            Commerce, has initiated a program to establish Entrepreneurship
            Development Clubs in schools and colleges throughout the state. The
            aim of this initiative is to foster an entrepreneurial culture among
            young people and provide them with the skills, knowledge, and
            confidence to become future leaders in entrepreneurship. Currently,
            there are over 600 ED Clubs registered in Kerala, with about 400 of
            them actively involved in entrepreneurship activities.
          </p>
          <p>
            The Schemes, Programmes and Events showcased on this page are
            tailored specifically to cater to the needs of both Research
            Institutions, Universities, Colleges and Schools. For online
            registration of ED Clubs, the user can follow these simple steps:
          </p>
          <ul className="ml-3 mt-2">
            <li>
              <b style={{ color: "#9a3232" }}>
                Step 1: Click on the <a href="/user-management">Login</a> link
                and enter your credentials to log in to the website as Research
                Institution/University/College/School.{" "}
              </b>
            </li>
            <li>
              <b style={{ color: "#9a3232" }}>
                Step 2: Once logged in, navigate to online registration of{" "}
                <a href="/department/EDC/home">ED Club</a>
              </b>
            </li>
            <li>
              <b style={{ color: "#9a3232" }}>
                Step 3: After registering, navigate to the specific
                program/event/scheme you are interested in and apply for it by
                following the instructions provided.
              </b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default EDCDescription;
