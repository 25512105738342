import React from "react";
import EventCard2 from "../../../../components/event.card2/EventCard2";
import EventCard from "../../../../components/event.cards/event.card";
import KiedImages from "../../../../assets/images/kied.images.component";
import { IndAcEventsData } from "../../industry-academy.data";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";

function IndAcEvents() {
  let width = useScreenBreakPoint();
  return (
    <div className="auto-container  pt-5 pb-5">
      <div
        id="InovationEventssHeading"
        className="d-flex justify-content-center align-items-center"
      >
        <div className="">
          <h2 className="inovation_headings m-1">Events</h2>
        </div>
      </div>
      <div className="ml-2 mr-2 ">
        <div
          id="inovation_Events_contents"
          className={`row d-flex icn-inner${
            width === "xs" || width === "sm" ? "flex-sm-column pb-4" : ""
          }`}
        >
          {IndAcEventsData?.length>2?<>
            {IndAcEventsData.map((card, i) => {
              return <EventCard2 data={card} key={i} index={i} />;
            })}
          </>:
          <>
            {IndAcEventsData.map((card, i) => {
              return <EventCard data={card} key={i} index={i} />;
            })}
          </>}
        </div>
      </div>
    </div>
  );
}

export default IndAcEvents;
