import React from "react";
import SmallBanner from "../../../../../components/small.banner/small.banner";
import "./InnovationDetails.css";
import InnovationEventDescription from "./sections/InnovationEventDescription";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { inovationEventData } from "../../inovationData";
import { getNews } from "../../../../../apis/publicApis";
// import { getEventById } from "../../../../../apis/publicApis";

function InnovationStatEventDetails() {
  const [isActive, setIsActive] = useState(true);
  const param = useParams();
  const [data, setData] = useState([]);
  const [newsData, setNewsData] = useState([])

  useEffect(() => {
    getNews()
      .then((res) => {
        console.log(res)
        setNewsData(res.data.filter((item, i) => item.id == param.id));
      })
      .catch(() => {});
  }, []);

  // useEffect(() => {
  //   // let selected = inovationEventData.filter((item) => {
  //   //   console.log("item", typeof item.id);
  //   //   console.log(typeof param.id);
  //   //   return item.id == param.id;
  //   // });
  //   // setData(selected[0]);
  // //   getEventById(param.id).then((res)=>{
  // //     let item=res?.data
  // //     let evItem = {
  // //       startTime: item?.date,
  // //       listingImage: item?.img1,
  // //       title: item?.name,
  // //       id:item?.id,
  // //       link:"/events/",
  // //       location: item?.mode,
  // //       mainDescription: item?.description,
  // //       images:[
  // //         {image:item?.img1},
  // //         {image:item?.img2},
  // //         {image:item?.img3},
  // //         {image:item?.img4}
  // //       ]
  // //     };
  // //     setData(evItem)
  // //   })
  // // }, [param.id]);
  // console.log(data);

  useEffect(() => {
    let selected = inovationEventData.filter((item) => {
      console.log("item", typeof item.id);
      console.log(typeof param.id);
      return item.id == param.id;
    });
    setData(selected[0]);
  }, [param.id]);
  console.log(data);
  return (
    <div>
      <SmallBanner />
      <InnovationEventDescription data={data} />
    </div>
  );
}

export default InnovationStatEventDetails;
