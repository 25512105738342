import React from "react";

function InternshipDetailBreadcrumb() {
  return (
    <div>
      <div className="faq_banner head-container d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/Home">IAC&nbsp;/</a>
        <a className="current" href="/internship-details">
          Internship Details
        </a>
      </div>
    </div>
  );
}

export default InternshipDetailBreadcrumb;
