export const registrationData = [
  {
    id: 1,
    label: "Aspiring Entrepreneur",
    label_ma: "പുതുസംരംഭകൻ",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: "aspiring",
    slug: "aspering_ent",
    link: "/user-management",
  },
  {
    id: 2,
    label: "Schools and Colleges",
    label_ma :"സ്കൂളുകളും കോളേജുകളും", 
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: "school",
    slug: "schools",
    link: "/user-management",
  },
  {
    id: 3,
    label: "Research Institutions and Universities",
    label_ma : " ഗവേഷണ സ്ഥാപനങ്ങളും സർവ്വകലാശാലകളും",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: "research",
    slug: "research",
    link: "/user-management",
  },
  {
    id: 4,
    label: "Existing Entrepreneurs",
    label_ma: "സംരംഭകൻ",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: "existing",
    slug: "existing_ent",
    link: "/user-management",
  },
  {
    id: 5,
    label: "Industry Associations / Enablers",
    label_ma : "വ്യവസായ അസോസിയേഷനുകൾ",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: "industry",
    link: "/user-management",
    slug: "industry_asso",
  },
  {
    id: 6,
    label: "Experts / Mentor",
    label_ma : "വിദഗ്ധരും ഉപദേശകരും",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: "expert",
    link: "/user-management",
    slug: "mentors",
  },
  {
    id: 7,
    label: "ED Club",
    label_ma : "ഇ ഡി ക്ലബ്",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: "club",
    link: "/user-management",
    slug: "edclub",
  },
  {
    id: 8,
    label: "Students",
    label_ma : "വിദ്യാർത്ഥികൾ",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: "students",
    link: "/user-management",
    slug: "students",
  },
  {
    id: 9,
    label: "Guest",
    label_ma : "അതിഥികൾ",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: "students",
    link: "/user-management",
    slug: "guest",
  },
];
export const registrationRole = [
  {
    id: 1,
    title: " Entrepreneur",
  },
  {
    id: 2,
    title: "Students",
  },
  {
    id: 3,
    title: "Research / Academic Institutions",
  },
  {
    id: 4,
    title: "Business Owners",
  },
  {
    id: 5,
    title: "Industry Associations / Enablers",
  },
  {
    id: 6,
    title: "Experts / Mentors",
  },
  {
    id: 7,
    title: "ED Club",
  },
  {
    id: 8,
    title: "Students",
  },
  {
    id: 9,
    title: "Guest",
  },
];
