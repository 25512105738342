import apis from "./api.actions";

export const getEvents = () => apis.getDatas(`v1/event`).then((res) => res);
export const getEventById = (id, data) =>
  apis.getDatas(`v1/event/${id}`).then((res) => res);
export const getPayment = (data) =>
  apis.createData(`v1/payment/initiate`, data).then((res) => res);
export const getEventPayment = (data) =>
  apis.createData(`v1/eventbookings`, data).then((res) => res);
export const getPaymentStatus = (data) =>
  apis.createData(`v1/payment/status`, data).then((res) => res);
export const getSchemes = () => apis.getDatas(`v1/scheme`).then((res) => res);
export const getSchemeById = (id, data) =>
  apis.getDatas(`v1/scheme/${id}`).then((res) => res);
export const getEventBydeptId = (id) =>
  apis.getDatas(`v1/event?department_id=${id}`).then((res) => res);
export const getProgramBydeptId = (id) =>
  apis.getDatas(`v1/program?department_id=${id}`).then((res) => res);
export const getSchemeBydeptId = (id) =>
  apis.getDatas(`v1/scheme?department_id=${id}`).then((res) => res);
export const getPrograms = () => apis.getDatas(`v1/program`).then((res) => res);
export const getMenu = () => apis.getDatas(`v1/departments`).then((res) => res);
export const getTenders = () => apis.getDatas(`v1/tender`).then((res) => res);
export const getCareers = () => apis.getDatas(`v1/career`).then((res) => res);
export const getVideo = () => apis.getDatas(`v1/video`).then((res) => res);
export const getMaterial = () => apis.getDatas(`v1/material`).then((res) => res);
export const getMember = () => apis.getDatas(`v1/member`).then((res) => res);
export const getNews = () => apis.getDatas(`v1/latestnews`).then((res) => res);
export const getProgramById = (id, data) =>
  apis.getDatas(`v1/program/${id}`).then((res) => res);
export const contact = ( data) =>
apis.createData(`v1/contact`, data).then((res) => res);


