import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import { Link } from "react-router-dom";

function SchemeEligibility() {
  return (
    <div className="auto-container mt-3 ">
      {/* <div className="schemes_heading mb-5">Appellate Authority</div>
      <div className=" Schemes_heading_description mb-2">
        If the decision of the District Level Committee is not satisfactory, an
        applicant can approach the State Level Committee, consisting of the
        following Members,
      </div>
      <ul className="ml-4 Schemes_heading_description pt-3 mb-5">
        <li>Director of Industries & Commerce (Chairman)</li>
        <li> Representative of Finance Department in Government</li>
        <li>Managing Director, KSIDC</li>
        <li>Managing Director, KFC</li>
        <li>Director –MSME (DI)</li>
        <li>Representative of KSSIA State Committee</li>
      </ul> */}
      <div className="d-flex justify-content-center  m-5">
        {/* <Link> */}
        <a
          href="https://industry.kerala.gov.in/index.php/pages/capacity-building/ed-club"
          // href="https://industry.kerala.gov.in/index.php/schemes-mainmenu/entrepreneur-support-scheme-schemes"
          target="_blank"
          rel="noreferrer"
        >
          <KiedButton
            content={
              <>
                <div className="d-flex p-1">
                  <div className="d-flex align-items-center">Apply</div>
                  <div className="d-flex align-items-center">
                    <KiedIcons
                      icon="arrow-right"
                      className="hero-button-icon arrow_icon"
                    />
                  </div>
                </div>
              </>
            }
            secondary
            rounded
            big
          />
        </a>
        {/* </Link> */}
      </div>
    </div>
  );
}

export default SchemeEligibility;
