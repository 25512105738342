import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
export const useLoginState = create((set) => ({
  loginCount: 0,
  nextPath: null,
  nextuserType: null,
  lang: "en",
  setNextPath: (data) => set({ nextPath: data }),
  setNextUserType: (data) => set({ nextuserType: data }),

  onLoginClick: () => set((state) => ({ loginCount: state.loginCount + 1 })),
  resetLogin: () => set({ loginCount: 0 }),
  resetNextPath: () => set({ nextPath: null }),
  resetUserType: () => set({ nextuserType: null }),
  languageSetter: () => set((value) => ({ loginCount: value })),
}));

export const useLangStore = create(
  persist((set) => ({
    language: "label",
    setLanguage: (value) => set(() => ({ language: value })),
  }),
  { name: "la__ng_s_tr" ,
storage : createJSONStorage(() => sessionStorage)
})
);
export const useLoginStore = create(
  persist((set) => ({
    isLogin: false,
    setIsLogin: (value) => set(() => ({ isLogin: value })),
    clearLogin: () => set(() => ({ isLogin: false })),
  }),
  { name: "1_5_l_09_1n" ,
storage : createJSONStorage(() => localStorage)
})
);
