import React from "react";
import ProgramsCard from "../../../../components/programs.cards/programs.card";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import KiedImages from "../../../../assets/images/kied.images.component";
import { EDCProgramData } from "../../edc.page.data";
import { useNavigate } from "react-router-dom";

function EDCPrograms() {
  const history = useNavigate();
  const handleregister = () => {
    sessionStorage.setItem(
      "next_path",
      "/department/inovation/edcregistration"
    );
    history("/department/inovation/edcregistration");
  };
  return (
    <div className="auto-container pb-5 pt-5">
      <div
        id="programs"
        className="d-flex justify-content-center align-items-center"
      >
        <div className="">
          <h2 className="inovation_headings m-1">Programs</h2>
        </div>
      </div>
      <div>
        <div className="">
          <div className="row ">
            {EDCProgramData.map((card, i) => {
              return <ProgramsCard data={card} key={i} index={i} />;
            })}
          </div>
        </div>
      </div>
      {/* <div className="d-flex justify-content-center m-3">
        <KiedButton
          onClick={() => handleregister()}
          content={
            <>
              <div className="d-flex p-1">
                <div className="d-flex align-items-center">Apply</div>
                <div className="d-flex align-items-center">
                  <KiedIcons
                    icon="arrow-right"
                    className="hero-button-icon m-1"
                  />
                </div>
              </div>
            </>
          }
          secondary
          rounded
          big
        />
      </div> */}
    </div>
  );
}

export default EDCPrograms;
