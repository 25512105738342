// import React from 'react'

// function AssistanceHero() {
//   return (
//     <div>

//     </div>
//   )
// }

// export default AssistanceHero

import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function AssistanceHero(props) {
  let width = useScreenBreakPoint();
  return (
    // <div
    //   className={`d-flex justify-content-center ${
    //     width === "xs" || width === "sm"
    //       ? props.index % 2 === 0
    //         ? "flex-column"
    //         : " flex-column"
    //       : props.index % 2 === 0
    //       ? "flex-row"
    //       : "flex-row-reverse "
    //   }`}
    // >
    <div
      className={`d-flex justify-content-center auto-container ${
        width === "xs" || width === "sm" ? "sml p-0 mt-2" : "lr mt-5 pt-5"
      }`}
    >
      <div className="row d-flex justify-content-between w-100">
        <div className="col-lg-4 col-md-4  col-sm-12 d-flex justify-content-start">
          <KiedImages
            className="w-100"
            style={{ height: "100%", objectFit: "cover", borderRadius: "15px" }}
            image="ess"
          />
        </div>
        <div
          className={`col-lg-8 col-md-8 col-sm-12 d-flex flex-column  ${
            width === "xs" || width === "sm" ? "sml  mt-3" : "lr p-2 pl-5"
          } `}
        >
          <div className="schemes_heading">
            Assistance for Export Promotion of Existing MSMEs
          </div>
          <div
            className={`Schemes_heading_description Schemes_new_discription  ${
              width === "xs" || width === "sm" ? "sml p-3 mt-3 " : "lr  mt-5 "
            } `}
          >
            The scheme aims at providing incentives and hand holding to increase
            volumes of MSMEs which export then goods and also to attract MSMEs
            to start exporting.
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default AssistanceHero;
