export const roleData = [
  {
    id: 1,
    value: 2,
    name: "Aspiring Entrepreneur",
  },
  {
    id: 2,
    value: 3,
    name: "Schools and Colleges",
  },
  {
    id: 3,
    value: 4,
    name: "Existing Entrepreneurs",
  },
  {
    id: 4,
    value: 5,
    name: "Research Institutions and Universities",
  },
  {
    id: 5,
    value: 6,
    name: "Industry Associations / Enablers",
  },
  {
    id: 6,
    value: 7,
    name: "Experts/ Mentor",
  },
  {
    id: 7,
    value: 8,
    name: "ED Club",
  },
  {
    id: 8,
    value: 9,
    name: "Students",
  },
  {
    id: 9,
    value: 10,
    name: "Guest",
  },
];
