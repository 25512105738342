import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import SubmitInternshipBreadcrumb from "./sections/breadcrumbs/submit.internship.breadcrumb";
import SubmitInternshipDetail from "./sections/details/submit.internship.details";

function SubmitIntershipPage() {
  return (
    <div>
      <SmallBanner />
      <SubmitInternshipBreadcrumb />
      <SubmitInternshipDetail />
    </div>
  );
}

export default SubmitIntershipPage;
