import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import ContactUsDetails from "../../pages/contact_us/sections/details/ContactUs.details";
function ContactUs() {
  return (
    <div>
      <SmallBanner title={"Contact Us"} />
      <ContactUsDetails />
    </div>
  );
}

export default ContactUs;
