import React from "react";
import KiedImages from "../../../assets/images/kied.images.component";

function BlogDetailDescription() {
  return (
    <div>
      <div className="faq_banner pl-5 d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/blogs"> Blogs&nbsp;/</a>
        <a className="current" href="/blogdetails">
          Blog-Details
        </a>
      </div>
      {/* ///////////// */}
      <div>
        <div className="d-flex justify-content-center mt-5">
          <h1 className="dedicated-blog-heading">
            The Dark Side Of Overnight Success
          </h1>
        </div>
        <div className="auto-container mt-3 mb-5 pb-5">
          <div className="row d-flex justify-content-center">
            <div className="col-md-4 col-sm-12">
              <KiedImages className="w-100" image="scheme" />
            </div>
            <div className="col-md-7 col-s-12 d-flex flex-column align-items-start justify-content-center">
              <div className="Schemes_heading_description">
                Entrepreneurial culture amongst youth and equip them with the
                skills,techniqus and confidence to act as torch-bearers of
                enterprise for the new generation.There are around 600 plus ED
                Clubs registered across Kerala of which around 400 are actively
                engaged in entrepreneurship activities culture amongst Youth
              </div>
            </div>
          </div>
        </div>
        <div className="auto-container mt-5 mb-5">
          <div className=" Schemes_heading_description">
            Entrepreneurial Culture amongst youth and equip them with the
            skills, techniques and confidence to act as torch-bearers of
            Enterprise for the new generation. There are around 600 plus ED
            Clubs registered across Kerala of which around 400 are actively
            engaged in entrepreneurship activities Entrepreneurial Culture
            amongst youth.Entrepreneurial Culture amongst youth and equip them
            with the skills, techniques and confidence to act as torch-bearers
            of Enterprise for the new generation. There are around 600 plus ED
            Clubs registered across Kerala of which around 400 are actively
            engaged in entrepreneurship activities Entrepreneurial Culture
            amongst youth.Entrepreneurial Culture amongst youth and equip them
            with the skills, techniques and confidence to act as torch-bearers
            of Enterprise for the new generation. There are around 600 plus ED
            Clubs registered across Kerala of which around 400 are actively
            engaged in entrepreneurship activities Entrepreneurial Culture
            amongst youth.
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetailDescription;
