// import React from 'react'

// function InternshipEventDetails() {
//   return (
//     <div>InternshipEventDetails</div>
//   )
// }

// export default InternshipEventDetails
import React from "react";
import SmallBanner from "../../../../../components/small.banner/small.banner";
// import "./EdcEventDetails.css";
import InternshipEventDescription from "./sections/InternshipEventDescription";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IndAcEventsData } from "../../../industry-academy.data";

function InternshipEventDetails() {
  const param = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    let selected = IndAcEventsData.filter((item) => {
      console.log("item", typeof item.id);
      console.log(typeof param.id);
      return item.id == param.id;
    });
    setData(selected[0]);
  }, [param.id]);
  console.log(data);
  return (
    <div>
      <SmallBanner />
      <InternshipEventDescription data={data} />
    </div>
  );
}

export default  InternshipEventDetails;