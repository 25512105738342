import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
function AboutKIED() {
  return (
    <div>
      <div className="padding-top d-flex auto-container  align-items-center">
        <div className=" col-lg-6 col-md-6 col-sm-12 ">
          <div className="about_kied_Text padding-top">
            <span className="icon flaticon-light-bulb about_icon" />
            <h2 className="text_about">About KIED EDC</h2>
            <p className="text_about">
              "EDC, a unique initiative of KIED, the Department of Industries
              and Commerce, Government of Kerala, recognizes that each business
              is distinct and requires tailored support. The center provides
              individualized assistance to entrepreneurs for planning, starting,
              sustaining, reviving, and growing their innovative ventures. With
              a focus on building unique and innovative next-generation MSMEs
              and small businesses, EDC also provides support to conventional
              businesses seeking technology adoption, high-value opportunities,
              and the application of knowledge systems in select sectors."
            </p>
            <p>
              <b>Vision</b>: "Our vision is to become a hub of excellence in
              executing the development of smart and sustainable enterprises
              throughout the state."
            </p>
            <p>
              <b>Mission</b>: "Our mission is to cultivate a dynamic local
              environment for businesses by utilizing global knowledge through
              collaboration to drive local growth and development."
            </p>
            <p>
              KIED EDC comprises of three departments, namely: Centre for
              Innovation, Centre for Industry Academia Collaboration, and
              Entrepreneurship Development Clubs
            </p>
          </div>
        </div>
        <div className="about_kied_image d-flex flex-column align-items-center justify-content-start d-none d-md-block">
          <KiedImages className="w-100 d-none d-md-block" image="about-kied" />
        </div>
      </div>
    </div>
  );
}

export default AboutKIED;
