import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import EventsListingBreadcrumbs from "./sections/breadcrumbs/events.listing.breadcrumbs";
import EventsListingDetails from "./sections/details/events.listing.details";
import { getPrograms } from "../../apis/publicApis";
import { useState, useEffect } from "react";

function ProgramsListingPage() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getPrograms()
      .then((res) => {
        let prdata = [];
        res?.data?.forEach((item) => {
          let prItem = {
            status: item?.status,
            listingImage: item?.thumb,
            title: item?.name,
            id: item?.id,
            link: "/program/",
            location: item?.location,
            status: item?.status,
            cardDescription: item?.description,
          };
          prdata.push(prItem);
        });
        setData(prdata);
      })
      .catch(() => {});
  }, []);
  return (
    <div>
      <SmallBanner title="PROGRAMS" />
      {/* <EventsListingBreadcrumbs /> */}
      <br />
      <EventsListingDetails data={data} />
      <br />
    </div>
  );
}

export default ProgramsListingPage;
