import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import ResourcesEventsTable from "./sections/event.table";

function ResourcesEventsPage() {
  return (
    <div>
      <SmallBanner title="Events" />
      <ResourcesEventsTable />
    </div>
  );
}

export default ResourcesEventsPage;
