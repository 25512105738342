import React from "react";

function SelectBox({
  options = [],
  value = "",
  selectionMessage,
  handleChange = () => {},
  ...props
}) {
  return (
    <>
      {props.label && (
        <label className="formstyle">
          <span>
            {props.label}{" "}
            {props.required && <span style={{ color: "red" }}>*</span>}
          </span>
        </label>
      )}
      <label className="formstyle">
        <select
          className="inputbox"
          onChange={(e) => handleChange(e)}
          value={value}
          name={props.name}
          disabled = {props.disabled}
        >
          <option value="">{selectionMessage ?? "select a value"}</option>
          {options.map((place, i) => {
            return (
              <option
                // selected={place.name === district}
                value={place.name}
                key={i}
              >
                {place.name}
              </option>
            );
          })}
        </select>
      </label>
    </>
  );
}

export default SelectBox;
