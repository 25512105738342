import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import "./banner.styles.css";

function Banner({ title, image, subtitle }) {
  return (
    <div
      id="Banner_outer "
      className="banner_image banner_big d-flex justify-content-center"
    >
      <div
        id="Banner_inner"
        className="d-flex  container bannerContainer  head-container justify-content-between align-items-center"
      >
        <div
          id="Banner_text"
          className="d-flex flex-column justify-content-end"
          // data-aos="fade-right"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <h2 className="text_banner more_link">{title}</h2>
          <div className="more_link">
            <div className="">
              <span className="prog-banner">{subtitle}</span>
            </div>
          </div>
        </div>
        <div
          id="Banner_image"
          className="aboutpage_images  d-none d-md-block"
          // data-aos="fade-left"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <KiedImages
            className="program-banner-image d-none d-md-block"
            image={image}
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;
