export const ResourceEvents = [
  {
    id: 1,
    name: "KIED Community MeetUp",
    status: "COMPLETED",
  },
  {
    id: 2,
    name: "Yuva Boot Camp - Trial Pitching",
    status: "COMPLETED",
  },
  {
    id: 3,
    name: "Yuva Boot Camp – State Level Conclave",
    status: "COMPLETED",
  },
];
