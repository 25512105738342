// import React from 'react'

// function EdcNewProgramGallery() {
//   return (
//     <div>EdcNewProgramGallery</div>
//   )
// }

// export default EdcNewProgramGallery

import React from "react";
import Masonry from "react-responsive-masonry";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function EdcNewProgramGallery(props) {
  let width = useScreenBreakPoint();
  return (
    <div className="auto-container   mb-3 pb-5 mb-5">
      <div
        id="InovationEventssHeading"
        className="d-flex justify-content-center align-items-center"
      >
        <div
          className={` oep-program-gallery  ${
            width === "xs" || width === "sm" ? "sml py-2 " : "py-5"
          }`}
        >
          <h2 className="inovation_headings m-1">Gallery</h2>
        </div>
      </div>
      <Masonry columnsCount={3} gutter="10px">
        {props?.data?.gallery?.map((image, i) => {
          console.log(image);
          return (
            <KiedImages
              image={image.image}
              style={{ width: "100%", height: "100%", display: "block" }}
            />
          );
        })}
      </Masonry>
    </div>
  );
}

export default EdcNewProgramGallery;
