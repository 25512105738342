import React, { forwardRef, useImperativeHandle } from "react";
import Swal from "sweetalert2";
import RadioButtonDash from "../../../../components/radiobutton-dash/radiobutton";
import SelectBoxDash from "../../../../components/selectbox-dash/SelectBox.component";
import TextFieldDash from "../../../../components/textfield-dash/TextField.component";
import {
  KiedCarriers,
  castCategory,
  gender,
  radiooption,
  businessStage,
  businessduration,
  mode,
} from "../../../edc.registration/registrationform.data";
import {
  districts,
  qualification,
} from "../../../registration/registrationform.data";
import KiedButton from "../../../../components/buttons/button/button.component";
import { toast } from "react-toastify";
import { createYearlyData } from "../../../../apis/auth.apis";

const ExpertRegistrationForm = (
  {
    handleChange,
    handleTextFieldChange,
    formdata,
    error,
    setError,
    fielderror,
    setSubmitted,
    handleSubmit,
    setCompleted,
    setFieldError,
    userData,
    userid,
    accountname,
    accountno,
    whatsapp_no,
    userrole,
    setProfiledit,
    fetchprofile,
    createProfile,
    validateField,
    submitted,
    FileUploadDash,
    errorSize,
    fileUpload,
    handleregister,
    expertise,
    edcView,
    handleEdcNext,
    Accordion,
  },
  ref
) => {
  const requiredFields = [
    "is_member",
    ...(formdata.is_member === 1 ? ["supporting_document"] : []),
    "institution_type",
    "district_institution",
    "address_institution",
    "approval_letter",
    "account_no",
    "account_name",
    "bank_name",
    "ifsc",
    // "name_faculty_coordinator",
    // "whatsapp_faculty_coordinator",
    // "email_faculty_coordinator",
    // "name_student_coordinator",
    // "whatsapp_student_coordinator",
    // "count_students",
  ];
  const requiredFieldsYear = [
    "year",
    "faculty_name",
    "faculty_number",
    "faculty_email",
    "faculty_bio",
    "student_name",
    "student_no",
    "student_email",
    "total_students",
    "boys",
    "girls",
    "student_details",
    // "name_faculty_coordinator",
    // "whatsapp_faculty_coordinator",
    // "email_faculty_coordinator",
    // "name_student_coordinator",
    // "whatsapp_student_coordinator",
    // "count_students",
  ];
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      dataSetter();
    },
  }));

  const validate = () => {
    let errorField = {};
    requiredFields.forEach((item) => {
      if (
        formdata[item] === null ||
        formdata[item] === undefined ||
        formdata[item] === ""
      ) {
        errorField[item] = "Please fill in the details";
        setSubmitted(false);
      }
    });
    return errorField;
  };
  const validateYear = () => {
    let errorField = {};
    requiredFieldsYear.forEach((item) => {
      if (
        formdata[item] === null ||
        formdata[item] === undefined ||
        formdata[item] === ""
      ) {
        errorField[item] = "Please fill in the details";
        setSubmitted(false);
      }
    });
    return errorField;
  };
  const dataSetter = () => {
    setSubmitted(true);

    let payload = new FormData();

    // navigate("/success");
    if (formdata?.is_member !== "" && formdata?.is_member) {
      payload.append("is_member", formdata?.is_member);
    }

    if (formdata?.is_member == 1) {
      payload.append("supporting_document", formdata?.supporting_document);
    } else {
      payload.append("is_member", 0);
      payload.append("supporting_document", null);
    }
    if (formdata?.institution_type !== "" && formdata?.institution_type) {
      payload.append("institution_type", formdata?.institution_type);
    }
    if (
      formdata?.district_institution !== "" &&
      formdata?.district_institution
    ) {
      payload.append("district_institution", formdata?.district_institution);
    }

    if (formdata?.address_institution !== "" && formdata?.address_institution) {
      payload.append("address_institution", formdata?.address_institution);
    }
    if (formdata?.approval_letter !== "" && formdata?.approval_letter) {
      payload.append("approval_letter", formdata?.approval_letter);
    }
    if (formdata?.account_no !== "" && formdata?.account_no) {
      payload.append("account_no", formdata?.account_no);
    }
    if (formdata?.account_name !== "" && formdata?.account_name) {
      payload.append("account_name", formdata?.account_name);
    }
    if (formdata?.bank_name !== "" && formdata?.bank_name) {
      payload.append("bank_name", formdata?.bank_name);
    }
    if (formdata?.ifsc !== "" && formdata?.ifsc) {
      payload.append("ifsc", formdata?.ifsc);
    }
    if (formdata?.gst_details !== "" && formdata?.gst_details) {
      payload.append("gst_details", formdata?.gst_details);
    }

    const errorField = validate();
    if (Object.keys(errorField).length === 0) {
      handleSubmit(payload);
    } else {
      setFieldError(errorField);
      setSubmitted(false);
      setCompleted(true);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required fields (Mandatory fields are marked in red)",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok",
      });
    }
  };
  console.log(formdata);
  const handleYearRegister = () => {
    let payload = new FormData();

    // navigate("/success");
    if (formdata?.year !== "" && formdata?.year) {
      payload.append("year", formdata?.year);
    }
    if (formdata?.faculty_name !== "" && formdata?.faculty_name) {
      payload.append("faculty_name", formdata?.faculty_name);
    }
    if (formdata?.faculty_number !== "" && formdata?.faculty_number) {
      payload.append("faculty_number", formdata?.faculty_number);
    }
    if (formdata?.faculty_email !== "" && formdata?.faculty_email) {
      payload.append("faculty_email", formdata?.faculty_email);
    }
    if (formdata?.faculty_bio !== "" && formdata?.faculty_bio) {
      payload.append("faculty_bio", formdata?.faculty_bio);
    }
    if (formdata?.student_name !== "" && formdata?.student_name) {
      payload.append("student_name", formdata?.student_name);
    }
    if (formdata?.student_no !== "" && formdata?.student_no) {
      payload.append("student_no", formdata?.student_no);
    }
    if (formdata?.student_email !== "" && formdata?.student_email) {
      payload.append("student_email", formdata?.student_email);
    }
    if (formdata?.total_students !== "" && formdata?.total_students) {
      payload.append("total_students", formdata?.total_students);
    }
    if (formdata?.boys !== "" && formdata?.boys) {
      payload.append("boys", formdata?.boys);
    }
    if (formdata?.girls !== "" && formdata?.girls) {
      payload.append("girls", formdata?.girls);
    }
    if (formdata?.student_details !== "" && formdata?.student_details) {
      payload.append("student_details", formdata?.student_details);
    }
    console.log(payload);
    const errorField = validateYear();
    if (Object.keys(errorField).length === 0) {
      createYearlyData(payload)
        .then((res) => {
          console.log(res);
          setCompleted(false);
        setProfiledit(false);
        setSubmitted(false);
        toast.success(res?.message);
        })
        .catch((err) => {
          console.log(err);
          setSubmitted(false);
        setCompleted(true);
        toast.error(err?.response?.data?.message||"something went wrong");
        setCompleted(true);
        });
    } else {
      setFieldError(errorField);
      setSubmitted(false);
      setCompleted(true);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required fields (Mandatory fields are marked in red)",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok",
      });
    }
  };
  return (
    <div>
      {edcView === 0 ? (
        <>
          <div>
            <div className="">
              <form>
                <RadioButtonDash
                  options={radiooption}
                  label="Do you have a Registered ED Club"
                  required
                  name="is_member"
                  error={fielderror.is_member}
                  value={formdata.is_member ?? ""}
                  checked={formdata.is_member}
                  onChange={handleChange}
                />
                <br />
                {formdata.is_member == 1 && (
                  <>
                    <FileUploadDash
                      label="Upload Supporting Document (eg. Registration Details, .pdf, .docx, .jpg format files are allowed)"
                      error={fielderror.supporting_document}
                      errorSize={errorSize?.supporting_document}
                      accept=".pdf, .docx, .jpg"
                      name="supporting_document"
                      type="file"
                      // filename={formdata.gst_file_name}
                      // value={formdata.gst_file ?? null}
                      onChange={fileUpload}
                    />
                    <br />
                  </>
                )}
                {error.supporting_document && (
                  <p id="proof" className="ml-3 error">
                    Unsupported file type! Please select a .pdf, .docx or jpg
                    file
                  </p>
                )}
                <br />
                <TextFieldDash
                  label="Institution Type (School / College)"
                  required
                  name="institution_type"
                  error={fielderror.institution_type}
                  value={formdata.institution_type ?? ""}
                  onChange={handleTextFieldChange}
                />
                <br />

                <SelectBoxDash
                  label="Select the District where your School/ College/ Institution is Located"
                  required
                  options={districts}
                  error={fielderror.district_institution}
                  value={formdata.district_institution}
                  selectionMessage="Select your district"
                  name="district_institution"
                  handleChange={handleChange}
                />
                {/* <br />
                        <TextFieldDash
                          label="Name of School/ College/ Institution"
                          required
                          name="institution_name"
                          error={fielderror.institution_name}
                          value={formdata.institution_name ?? ""}
                          onChange={handleTextFieldChange}
                        /> */}
                <br />
                <TextFieldDash
                  label="Address of the School/ College/ Institution"
                  required
                  type="textarea"
                  name="address_institution"
                  error={fielderror.address_institution}
                  value={formdata.address_institution ?? ""}
                  onChange={handleTextFieldChange}
                />
                <br />
                <TextFieldDash
                  label="Bank Account Number"
                  required
                  type="text"
                  name="account_no"
                  error={fielderror.account_no}
                  value={formdata.account_no ?? ""}
                  onChange={handleTextFieldChange}
                />
                <br />
                <TextFieldDash
                  label="Account Holder Name"
                  required
                  type="text"
                  name="account_name"
                  error={fielderror.account_name}
                  value={formdata.account_name ?? ""}
                  onChange={handleTextFieldChange}
                />
                <br />
                <TextFieldDash
                  label="Name of the Bank"
                  required
                  type="text"
                  name="bank_name"
                  error={fielderror.bank_name}
                  value={formdata.bank_name ?? ""}
                  onChange={handleTextFieldChange}
                />
                <br />
                <TextFieldDash
                  label="IFSC Code"
                  required
                  type="text"
                  name="ifsc"
                  error={fielderror.ifsc}
                  value={formdata.ifsc ?? ""}
                  onChange={handleTextFieldChange}
                />
                <br />
                <FileUploadDash
                  label="Approval Letter (Authorization letter from head of the institution, .pdf, .docx, .jpg format files are allowed)"
                  required
                  error={fielderror.approval_letter}
                  errorSize={errorSize?.approval_letter}
                  accept=".pdf, .docx, .jpg"
                  name="approval_letter"
                  type="file"
                  // filename={formdata.gst_file_name}
                  // value={formdata.gst_file ?? null}
                  onChange={fileUpload}
                />
                <br />
                {error.approval_letter && (
                  <p id="proof" className="ml-3 error">
                    Unsupported file type! Please select a .pdf, .docx or jpg
                    file
                  </p>
                )}
                <br />
                <FileUploadDash
                  label="GST Details (Authorization letter from head of the institution, .pdf, .docx, .jpg format files are allowed)"
                  required
                  error={fielderror.gst_details}
                  errorSize={errorSize?.gst_details}
                  accept=".pdf, .docx, .jpg"
                  name="gst_details"
                  type="file"
                  // filename={formdata.gst_file_name}
                  // value={formdata.gst_file ?? null}
                  onChange={fileUpload}
                />
                <br />
                {error.gst_details && (
                  <p id="proof" className="ml-3 error">
                    Unsupported file type! Please select a .pdf, .docx or jpg
                    file
                  </p>
                )}
                <br />
              </form>
              <div className="d-flex justify-content-center pt-2 pb-2">
                <KiedButton
                  disabled={submitted === true}
                  onClick={() => {
                    dataSetter();
                  }}
                  primary
                  rounded
                  type="submit"
                  content={
                    <div className="registerbtn d-flex justify-content-center">
                      Submit
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        edcView === 3 && (
          <>
            <div>
              <div className="">
                <form>
                  <TextFieldDash
                    label="Year"
                    required
                    name="year"
                    error={fielderror.year}
                    value={formdata.year ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />
                  <h2 className="mb-4">Faculty Coordinator Details</h2>

                  <TextFieldDash
                    label="Name of ED Club Faculity Coordinator"
                    required
                    name="faculty_name"
                    error={fielderror.faculty_name}
                    value={formdata.faculty_name ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />

                  <TextFieldDash
                    label="Whatsapp No of Faculity Coordinator"
                    required
                    type="phone"
                    maxLength={10}
                    minLength={10}
                    name="faculty_number"
                    error={fielderror.faculty_number}
                    value={formdata.faculty_number ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />
                  <TextFieldDash
                    label="Email ID of Faculity Coordinator"
                    required
                    type="email"
                    name="faculty_email"
                    error={fielderror.faculty_email}
                    value={formdata.faculty_email ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />
                  <FileUploadDash
                    label="Biodata of Faculity Coordinator, .pdf, .docx, .jpg format files are allowed)"
                    required
                    error={fielderror.faculty_bio}
                    errorSize={errorSize?.faculty_bio}
                    accept=".pdf, .docx, .jpg"
                    name="faculty_bio"
                    type="file"
                    // filename={formdata.gst_file_name}
                    // value={formdata.gst_file ?? null}
                    onChange={fileUpload}
                  />
                  <br />
                  {error.faculty_bio && (
                    <p id="proof" className="ml-3 error">
                      Unsupported file type! Please select a .pdf, .docx or jpg
                      file
                    </p>
                  )}
                  <br />
                  <hr></hr>

                  <h2 className="mb-4">Student Coordinator Details</h2>

                  <TextFieldDash
                    label="Name of ED Club Student Coordinator"
                    required
                    name="student_name"
                    error={fielderror.student_name}
                    value={formdata.student_name ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />

                  <TextFieldDash
                    label="Whatsapp No of Student Coordinator"
                    required
                    type="phone"
                    maxLength={10}
                    minLength={10}
                    name="student_no"
                    error={fielderror.student_no}
                    value={formdata.student_no ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />
                  <TextFieldDash
                    label="Email ID of Student Coordinator"
                    required
                    type="email"
                    name="student_email"
                    error={fielderror.student_email}
                    value={formdata.student_email ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />
                  <hr></hr>

                  <h2 className="mb-4">Student Details</h2>

                  <TextFieldDash
                    label="Total Student Count"
                    required
                    type="number"
                    name="total_students"
                    error={fielderror.total_students}
                    value={formdata.total_students ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />

                  <TextFieldDash
                    label="Number of Boys"
                    required
                    name="boys"
                    type="number"
                    error={fielderror.boys}
                    value={formdata.boys ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />
                  <TextFieldDash
                    label="Number of Girls"
                    required
                    type="number"
                    name="girls"
                    error={fielderror.girls}
                    value={formdata.girls ?? ""}
                    onChange={handleTextFieldChange}
                  />
                  <br />
                  <FileUploadDash
                    label="Excel document of Student list, Need a template to be attached along with that it may include details like (Sl No, Name of Student, Male/Female, Standard/Branch with year, Stream if any, Admission Year and Passing out Year)"
                    required
                    error={fielderror.student_details}
                    errorSize={errorSize?.student_details}
                    accept=".xls, .xlsx"
                    name="student_details"
                    type="file"
                    // filename={formdata.gst_file_name}
                    // value={formdata.gst_file ?? null}
                    onChange={fileUpload}
                  />
                  <br />
                  {error.student_details && (
                    <p id="proof" className="ml-3 error">
                      Unsupported file type! Please select an Excel file (.xlsx
                      or .xls).
                    </p>
                  )}
                  <hr></hr>
                </form>
                <div className="d-flex justify-content-center pt-5">
                  <KiedButton
                    onClick={() => handleYearRegister()}
                    primary
                    rounded
                    type="submit"
                    content={
                      <div className="registerbtn d-flex justify-content-center">
                        Save and Continue
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default forwardRef(ExpertRegistrationForm);
