export const faqData = [
  {
    id: 1,
    question: "What is the Business Growth Program of KIED?",
    answer:
      "Kerala Institute of Entrepreneurship Development center is launching a Business Growth program to support MSME entrepreneurs in the state to improve their operational efficiency, growth in sales, profitability and sustainably of the MSME unit. This 6 months program is designed with a combination mentorship and training sessions to support MSME units in the state.  ",
  },
  {
    id: 2,
    question: "What are the key offerings for MSME units in this program?",
    answer:
      "Key offering for MSME units are, Training Programs, Mentorship Sessions, Fund Raising Support, Service Network Support.",
  },
  {
    id: 3,
    question:
      "Does this program offers funding to MSME units enrolled in this program? ",
    answer:
      "This program doesn’t offer any funding to MSME units enrolled in this program. But will provide the opportunity to connect with funding agencies.",
  },
  {
    id: 4,
    question: "Who are the ideal entrepreneurs/MSME units for this program? ",
    answer:
      "MSME unit that has established a manufacturing unit in Kerala or provide specialized services to MSME. Has been in operation for less than 5 years (Early Stage) Having an annual turnover of more than Rs.50 lakhs and less than Rs. 10 Cr ",
  },
  {
    id: 5,
    question: "Why should an MSME unit/Entrepreneur join in this program?",
    answer:
      "This program is designed to support the MSME entrepreneurs to improve the overall operational efficiency, growth in sales, profitability of the of the MSME unit.",
  },
  {
    id: 6,
    question:
      "Is this program applicable for entrepreneurs/MSME units belonging to any sector/Industry?",
    answer:
      "Yes this program is applicable for entrepreneurs/MSME units across sectors",
  },
  {
    id: 7,
    question:
      "How many entrepreneurs from an MSME unit participate in this program ",
    answer:
      "Only one entrepreneur from an MSME unit is allowed to participate in this program ",
  },
  {
    id: 8,
    question: "Can an MSME unit from IT/ITES sector apply for this program?",
    answer:
      "Yes Companies from IT/ITES sector providing provide specialized services to MSME units can also apply for this program, however as the program objective is to support entrepreneurs/units in the manufacturing sector more preference may  be given to such units.",
  },
  {
    id: 9,
    question:
      "Is it mandatory for the unit to have some kind of innovation to be considered for this program?",
    answer:
      "No. It is not mandatory for the applicants to work on the innovative products/solutions.",
  },
  {
    id: 10,
    question: "Is it a virtual or Physical Program?",
    answer:
      "This program is structured as a hybrid program. Entrepreneurs will have to visit KIED campus for couple of training and mentorship sessions. ",
  },
  {
    id: 11,
    question: "What is the duration of incubation program at KIED?",
    answer: "The duration of the incubation program at KIED is 6 Months ",
  },
  {
    id: 12,
    question:
      "Should the MSME unit/Entrepreneur sign a separate agreement with KIED?",
    answer:
      "Yes. All entrepreneurs/ MSME units part of this program have to sign a an agreement with KIED",
  },
  {
    id: 13,
    question: " Does this program guarantees any funding for the participant?",
    answer:
      "This program doesn’t guarantee any funding for the participants. However towards the end of the program, high performing entrepreneurs/MSME units will be connected to the funding agencies",
  },
  {
    id: 14,
    question:
      "What are the broad selection criteria of participants for this program?",
    answer:
      "Market Opportunity, Product Differentiation, Investment by the promoter, External Funding, Team and the promoter",
  },
  {
    id: 15,
    question: "What is the batch size of the program?",
    answer: "This program will support 20 MSME entrepreneurs in the state",
  },
  {
    id: 16,
    question: "Can I submit multiple applications for this program?",
    answer:
      "No. Multiple applications are not allowed for this program. Your first application will be Considered as the final application for evaluation",
  },
  {
    id: 17,
    question: "How often the applications are invited?",
    answer:
      "Applications for this Program are invited half yearly. You may apply for the program directly through KIED website and the notification will be released on website and social media handles.",
  },
  {
    id: 18,
    question:
      "Our MSME unit is already funded by the bank? Can we still apply for this program? ",
    answer:
      "Yes. Even if the MSME unit has already raised funds, it can apply for this program.",
  },
  {
    id: 19,
    question: "Can an entrepreneur apply for this program? ",
    answer:
      "An entrepreneur can apply along with the name of the firm/company. ",
  },
  {
    id: 20,
    question:
      "My company was part of another KIED program? Should I again apply for this program?",
    answer:
      "Yes. You may have to apply for this program separately and will be part of the selection process.",
  },
  {
    id: 21,
    question:
      "Does applying for this program guarantee selection to this program?",
    answer:
      "No. All applicants will be evaluated by a panel and only shortlisted applicants will be part of the program.",
  },
  {
    id: 22,
    question: " Is there any application fee?",
    answer:
      "KIED takes effort to find, support and grow early-stage entrepreneurs who can build high-impact sustainable organizations and doesn’t charge a fee for this program.",
  },
  {
    id: 23,
    question: "How many rounds of evaluations are planned in this program?",
    answer:
      "There will be two rounds of evaluation. After the initial shortlisting, entrepreneur/MSME unit will be invited to make a short pitch in front of the final selection committee.",
  },
  {
    id: 24,
    question: "How and where will the pitching round be held?",
    answer:
      "Pitching process will be held physically at KIED center at Ernakulum.",
  },
  {
    id: 25,
    question: "How will I know about the result of each round?",
    answer:
      "KIED would intimate you with the status of the application via email to your registered email id.",
  },
  {
    id: 26,
    question:
      "My MSME unit is incubated/supported by another Entrepreneurship Development center. Can I still apply for this program? ",
    answer:
      "Yes. You can apply for this program even if you are part of other incubators, accelerators or any other entrepreneurship development centers.",
  },
  {
    id: 27,
    question:
      "My MSME unit has already received multiple grants and funding support from other government agencies/Incubators/entrepreneurship Development Agencies. Can I still apply for this program? ",
    answer: "Yes. The MSME unit can still apply for this program ",
  },
];
