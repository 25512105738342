import React from "react";
import FooterDesktopComponent from "./components/footer.desktop.component";
import FooterDesktopSubComponent from "./components/footer.desktop.subcomponent";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
import FooterMobileComponent from "./components/footer.mobile.component";

function FooterComponent() {
  let width = useScreenBreakPoint();
  return (
    <footer className="main-footer">
      {width === "xs" || width === "sm" ? (
        <>
          <FooterMobileComponent />
        </>
      ) : (
        <>
          <FooterDesktopComponent />
        </>
      )}

      <FooterDesktopSubComponent />
    </footer>
  );
}

export default FooterComponent;
