import React, { useState } from 'react'
import KiedButton from '../../../../components/buttons/button/button.component'
import DashInfo from '../../../../components/dashinfo/dashInfo'
import { Accordion } from 'react-bootstrap'
import { useEffect } from 'react'
import { getYearlyData } from '../../../../apis/auth.apis'
import { toast } from 'react-toastify'

function EDClubDataForm({handleEdit, handleEdcPreview, handlepopupEdit, ...props}) {

  const [data, setData] = useState([])
const yearData = () => {
  getYearlyData()
  .then((res) => {
    console.log(res)
    setData(res.data)
  })
  .catch((err) => {
    console.log(err)
    toast.error(err?.response?.data?.message||"something went wrong");
  })
}
  useEffect(() => {
yearData()
  },[])
  return (
    <>
    <div>
      <div className="">
        <DashInfo
          title="Do you have a Registered ED Club"
          value={props.formdata.is_member === 1 ? "Yes" :"No"}
        />
        {props.formdata.is_member === 1 && (
          <DashInfo
          title="Supporting Document"
          value={props.formdata.supporting_document ?? ""}
          file
        />
        )}
        <DashInfo
          title="Institution Type"
          value={props.formdata.institution_type ?? ""}
        />
       
        <DashInfo
          title="Select the District where your School/ College/ Institution is Located"
          value={props.formdata.district_institution}
        />
       
        <DashInfo
          title="Address of the School/ College/ Institution"
          value={props.formdata.address_institution ?? ""}
        />
        <DashInfo
          title="Account Number"
          value={props.formdata.account_no ?? ""}
        />
        <DashInfo
          title="Account Name"
          value={props.formdata.account_name ?? ""}
        />
        <DashInfo
          title="Bank Name"
          value={props.formdata.bank_name ?? ""}
        />
        <DashInfo
          title="IFSC Code"
          value={props.formdata.ifsc ?? ""}
        />
          <DashInfo
          title="Approval Letter"
          value={props.formdata.approval_letter ?? ""}
          file
        />
          <DashInfo
          title="GST Details"
          value={props.formdata.gst_details ?? ""}
          file
        />
        <div className="d-flex justify-content-center pt-5 pb-5">
          
          <KiedButton
            onClick={() => handleEdcPreview(0)}
            primary
            rounded
            className="mx-3"
            content={
              <div className="registerbtn d-flex justify-content-center">
                Edit
              </div>
            }
          />
         
        </div>
        <hr />

        {data.length !== 0 ? (
            <Accordion defaultActiveKey="0">
              {data.map((item, key) => (
                <Accordion.Item eventKey={`${key}`}>
                  <Accordion.Header style={{width:"100%",border:'0px',borderRadius:'20px'}}>
                    <button type="button" aria-expanded="false" class="accordion-button collapsed py-2 mx-auto" style={{width:"350px", backgroundColor:'rgb(161, 81, 59)', border:'0px',color:'white',stroke:'ActiveBorder'}}>{item.year}</button>
                  </Accordion.Header>
                  <Accordion.Body>
                    <>
                    
                    <div class>
                    <h2 className="my-4">Faculty Coordinator Details</h2>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                            Name of ED Club Faculity Coordinator
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <span class="formstyle p-0">{item.faculty_name}</span>
                        </div>
                      </div>
                      <br></br>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                            Whatsapp No of Faculity Coordinator
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <span class="formstyle p-0">{item.faculty_number}</span>
                        </div>
                      </div>
                      <br></br>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                            Email ID of Faculity Coordinator
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <span class="formstyle p-0">{item.faculty_email}</span>
                        </div>
                      </div>
                      <br></br>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                            Biodata of Faculity Coordinator
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <button
                            class="btn  text-white"
                            style={{ backgroundColor: "rgb(161, 81, 59)" }}
                            disabled = {item.student_details ? false : true}
                            onClick={() => window.open( item.student_details,"_blank")}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                      <br></br>
                    </div>
                    <hr></hr>
                    <div class>
                    <h2 className="my-4">Student Coordinator Details</h2>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                            Name of ED Club Student Coordinator
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <span class="formstyle p-0">{item.student_name}</span>
                        </div>
                      </div>
                      <br></br>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                            Whatsapp No of Student Coordinator
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <span class="formstyle p-0">{item.student_no}</span>
                        </div>
                      </div>
                      <br></br>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                            Email ID of Student Coordinator
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <span class="formstyle p-0">{item.student_email}</span>
                        </div>
                      </div>
                      <br></br>
                     
                      <br></br>
                    </div>
                    <hr></hr>
                    <div class>
                    <h2 className="my-4">Student Details</h2>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                          Total Student Count
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <span class="formstyle p-0">{item.total_students}</span>
                        </div>
                      </div>
                      <br></br>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                          Number of Boys
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <span class="formstyle p-0">{item.boys}</span>
                        </div>
                      </div>
                      <br></br>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                          Number of Girls
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <span class="formstyle p-0">{item.girls}</span>
                        </div>
                      </div>
                      <br></br>
                      <div class="d-flex">
                        <div class="d-flex justify-content-between col-6 pr-1">
                          <span class="formstyle p-0">
                          Excel document of Student list
                          </span>
                          <span class="formstyle p-0">:</span>
                        </div>
                        <div class="col-6">
                          <button
                            class="btn  text-white"
                            style={{ backgroundColor: "rgb(161, 81, 59)" }}
                            disabled = {item.student_details ? false : true}
                            onClick={() => window.open( item.student_details,"_blank")}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                      <br></br>
                      
                    </div>
                    </>
                  </Accordion.Body>
                </Accordion.Item>
                
              ))}
            </Accordion>
          ) : (
            // <div>Hello</div>
            <div className="d-flex justify-content-center pt-5 pb-5">
              No Yearly Data to display
            </div>
          )}
        
        
        

        {/* {error.proof && (
          <p id="proof" className="ml-3 error">
            Unsupported file type! Please select a .pdf or .docx
            file
          </p>
        )} */}
        <br />

        <div className="d-flex justify-content-center pt-5 pb-5">
          
          <KiedButton
            onClick={() => handleEdcPreview(3)}
            primary
            rounded
            className="mx-3"
            content={
              <div className="registerbtn d-flex justify-content-center">
                Add
              </div>
            }
          />
          <KiedButton
            onClick={() => handlepopupEdit()}
            primary
            className="mx-3"
            rounded
            content={
              <div className="registerbtn d-flex justify-content-center">
                Home
              </div>
            }
          />
        </div>
      </div>
    </div>
  </>
  )
}

export default EDClubDataForm