import React from "react";
import SmallBanner from "../../../../components/small.banner/small.banner";
import KiedButton from "../../../../components/buttons/button/button.component";
import FileUpload from "../../../../components/file.upload/fileupload.component";
import SelectBox from "../../../../components/selectbox/SelectBox.component";
import TextField from "../../../../components/textfield/TextField.component";
import { useState } from "react";
import RadioButton from "../../../../components/radiobutton/radiobutton";
import {
  gender,
  castCategory,
  radiooptions,
  businessStage,
  productType,
  mode,
} from "../../../edc.registration/registrationform.data";
import {
  districts,
  investmentLevel,
  qualification,
} from "../../../registration/registrationform.data";
import { useNavigate } from "react-router-dom";

function AriseRegistration(props) {
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const handleTextFieldChange = (value, name) => {
    setFormdata({ ...formdata, [name]: value });
  };

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
    console.log(e.target);
  };

  const handleregister = () => {
    navigate("/success");
  };

  const fileUpload = (e) => {
    console.log(formdata);
    console.log(e.target.files);
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    // accept = ".pdf, .png, .jpeg";
    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);

    if (regVal.test(filename)) {
      setFormdata({
        ...formdata,
        [name]: value,
        [name + "_name"]: filename,
      });
    } else {
      setFormdata({
        ...formdata,
        [name]: undefined,
        [name + "_name"]: undefined,
      });
      setError({ [name]: true });
    }
  };
  return (
    <>
      <SmallBanner title="Registration" />
      <div>
        <div className="d-flex align-items-center justify-content-between head-container">
          <div className="faq_banner  d-flex">
            <a href="/">Home&nbsp;/</a>
            <a href="/department/inovation/home"> Innovation&nbsp;/</a>
            <a href="/newprograms/2"> ARISE&nbsp;/</a>
            <a
              className="current"
              href="/department/inovation/ARISERegistration"
            >
              Registration
            </a>
          </div>
          <div className=" d-flex justify-content-end mt-3">
            <p className="">
              <span>
                <span style={{ color: "red" }}>* </span> Mandatory Fields
              </span>
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <h4 className="formtitle">ARISE REGISTRATION</h4>
        </div>
        <div className="auto-container form">
          <form>
            <TextField
              label="Name"
              required
              {...props}
              name="name"
              value={formdata.name ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <RadioButton
              options={gender}
              label="Gender"
              required
              {...props}
              name="gender"
              value={formdata.gender ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Date of Birth "
              required
              type="date"
              {...props}
              name="dob"
              value={formdata.dob ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Age"
              required
              maxLength={2}
              {...props}
              type="number"
              name="age"
              value={formdata.age ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Address"
              required
              type="textarea"
              {...props}
              name="address"
              value={formdata.address ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <SelectBox
              label="District"
              required
              options={districts}
              value={formdata.district}
              selectionMessage="Select your district"
              name="district"
              handleChange={handleChange}
            />
            <br />
            <RadioButton
              options={castCategory}
              label="Cast"
              required
              {...props}
              name="cast"
              value={formdata.cast ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Contact No"
              required
              {...props}
              type="number"
              name="phone"
              value={formdata.phone ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="E-mail Address"
              required
              {...props}
              type="email"
              name="email"
              value={formdata.email ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Aadhaar No"
              required
              {...props}
              type="number"
              name="aadhaar"
              value={formdata.aadhaar ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <SelectBox
              label="Highest Qualification"
              required
              options={qualification}
              value={formdata.qualification}
              selectionMessage="Select your Qualification"
              name="qualification"
              handleChange={handleChange}
            />
            <br />
            <TextField
              label="Have you took part in ARISE -Inspiration or Immersion. If yes, type the name of the program."
              required
              {...props}
              type="text"
              name="program"
              value={formdata.program ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <RadioButton
              options={radiooptions}
              label="Are you currently a business owner"
              required
              {...props}
              name="owner"
              value={formdata.owner ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <SelectBox
              label="Stage of your business"
              required
              options={businessStage}
              value={formdata.businessStage}
              selectionMessage="Select the stage of your business"
              name="businessStage"
              handleChange={handleChange}
            />
            <br />
            <SelectBox
              label="Type of the product"
              required
              options={productType}
              value={formdata.producttype}
              selectionMessage="Select the type of your product"
              name="producttype"
              handleChange={handleChange}
            />
            <br />
            {formdata.producttype === "Other" && (
              <>
                <TextField
                  label="Specify the product type"
                  required
                  {...props}
                  type="text"
                  name="typeSpecification"
                  value={formdata.typeSpecification ?? ""}
                  onChange={handleTextFieldChange}
                />
                <br />
              </>
            )}
            <SelectBox
              label="Level of Investment"
              required
              options={investmentLevel}
              value={formdata.investmentlevel}
              selectionMessage="Select the investment level"
              name="investmentlevel"
              handleChange={handleChange}
            />
            <br />
            <SelectBox
              label="Mode of business"
              required
              options={mode}
              value={formdata.mode}
              selectionMessage="Select the mode of your business"
              name="mode"
              handleChange={handleChange}
            />
            <br />
            {formdata.mode === "Other" && (
              <>
                <TextField
                  label="Please specify the mode of business"
                  required
                  {...props}
                  type="text"
                  name="modeSpecify"
                  value={formdata.modeSpecify}
                  onChange={handleTextFieldChange}
                />
                <br />
              </>
            )}
            <TextField
              label="Brief description of your business plan/idea"
              required
              {...props}
              type="textarea"
              name="plan"
              value={formdata.plan}
              onChange={handleTextFieldChange}
            />
            <br />
            <FileUpload
              label="Proof of remittance of fees (.pdf, .docx, .jpg format files are allowed)"
              required
              {...props}
              accept=".pdf, .docx, .jpg"
              name="proof"
              type="file"
              // filename={formdata.gst_file_name}
              // value={formdata.gst_file ?? null}
              onChange={fileUpload}
            />
            {error.proof && (
              <p id="proof" className="ml-3 error">
                Unsupported file type! Please select a .pdf or .docx file
              </p>
            )}
            <br />
          </form>
          <div className="d-flex justify-content-center pt-5 pb-5">
            <KiedButton
              onClick={() => handleregister()}
              primary
              rounded
              type="submit"
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Submit
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AriseRegistration;
