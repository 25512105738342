// import React from 'react'

// function BuildingDescription() {
//   return (
//     <div>

//     </div>
//   )
// }

// export default BuildingDescription

import React from "react";
// import KiedIcons from "../../../../assets/icons/kied.icons.component";
// import "../../schemes.page.styles.css";
// import ProgDoc from "./innovation-program.pdf";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function BuildingDescription() {
  let width = useScreenBreakPoint();
  return (
    <div className="auto-container ">
      <div
        className={`schemes_heading  ${
          width === "xs" || width === "sm" ? "sml mt-4 " : "lr  mt-5 pt-5  "
        }`}
      >
        Procedure of Implementation
      </div>
      <ul className="ml-1 Schemes_heading_description pt-3  px-2 pb-5 mb-5">
        <li>
          <p>
            The issues facing by Industries/Enterprises to be addrssed by the
            Technical University shall be collected from all District Industries
            Centres and shall be scrutinized at the Directorate of Industries
            and Commerce with the support of expert persons if needed.
          </p>
        </li>
        <li>
          <p>
            {" "}
            After scrutinizing the issues, it shall be handed over to the APJ
            Abdul Kalam Technological University to undertake research for
            providing technology solutions to problems.
          </p>
        </li>
        <li>
          <p>
            {" "}
            Once the University accepts any issue for research of the students,
            an acceptance letter is to be submitted to the Director of
            Industries and Commerce with a brief proposal about the project
            mentioning the team of students, time line of completion etc.
          </p>
        </li>
        <li>
          <p>
            On approval of proposal by the Director of Industries and Commerce
            an agreement is to be executed by the University with the Director
            of Industries and Commerce.
          </p>
        </li>
        <li>
          <p>
            {" "}
            The disbursement of the sanctioned assistance to the students who
            are engaged in the research shall be sent to the bank account of the
            University based on the request of the University.
          </p>
        </li>
        <li>
          <p>
            {" "}
            The University shall be asked to release the monthly grant to each
            student who are engaged in the research.
          </p>
        </li>
        <li>
          <p>
            {" "}
            The University shall have to submit the Research report on
            completion of the project by the students based on the time line
            scheduled.
          </p>
        </li>
        <li>
          <p>
            {" "}
            Director of Industries & Commerce or his authorized reresentative
            shall have full powers to monitor progress of the project / monitor
            utilization of funds at any stage and as often as deemed necessary.
          </p>
        </li>
        <li>
          <p>
            {" "}
            The Director of Industries & Commerce shall have full powers for
            sanctioning the assistance. All claims shall be disposed of within
            30 days from the date of receipt of proposal from the University.
          </p>
        </li>
      </ul>

      {/* <div className="schemes_heading mb-5 mt-5">
        Enterprises under Priority Sector
      </div>
      <div className=" Schemes_heading_description mb-5">
        Rubber-based industries, Agro based and Food processing industries,
        Readymade Garments, Industries manufacturing equipment and machinery for
        non conventional energy generation, Biotechnology based industries, 100%
        export oriented units, Biodegradable plastic industries, Plastic waste
        recycling industries, Bio fertilizer industries, Pharmaceutical
        industries and healthcare products manufacturing industries.
      </div>
      <hr />
      <div className="schemes_heading mb-5 mt-5">
        Enterprises under Negative List
      </div>
      <div className=" Schemes_heading_description mb-5">
        Service Enterprises, Photo Studios and Colour Processing Centres,
        Tailoring other than manufacturing of readymade garments, Breweries and
        Distilleries of all types, Saw mills, Soap Grade Sodium Silicate,
        Asbestos processing, Metal Crushers including Granite Manufacturing
        units, All types of Steel Re rolling Mills, Units manufacturing iron
        ingots, Calcium Carbide, Cement manufacturing except units manufacturing
        cement from fly ash, Potassium Chlorate, Power intensive units.
      </div>
      <hr />
      <div className="schemes_heading mb-5 mt-5">
        Stages in Entrepreneur Support Scheme
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">1. Startup Support</div>
      <div className=" Schemes_heading_description mb-5">
        The Startup support is provided for those enterprises which have availed
        at least term loan from a financial institution and wish to avail a part
        of the total eligible support prior to the commencement of commercial
        production. The assistance is limited to 50% of the total eligible
        support limited to Rs 3 lakh on sanctioning the term loan from the bank.
        The unit can apply for the balance of the eligible support once it start
        the commercial production. A unit which do not avail Startup Support,
        can directly apply for the investment support, after the commencement of
        commercial production.
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">2. Investment Support</div>
      <div className=" Schemes_heading_description mb-5">
        Investment Support is provided after the commencement of commercial
        production. To apply for Investment Support, loan from a financial
        institution is not mandatory. The entrepreneurs shall apply within one
        year from the date commencement of production. Enterprises undertaking
        Expansion, Diversification or Modernization are also eligible for invest
        support for the additional investment made.
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">3. Technology Support</div>
      <div className=" Schemes_heading_description mb-5">
        Technology Support is provided after commencement of production, on
        acquiring new technology from authorized institutions. The unit shall
        apply for technology support within 6 months from the date of
        commencement after installing the new technology form authorized
        agencies. Assistance will be provided for the new technology and for the
        plant and machinery installed in connection with the new technology.
      </div>
      <hr />
      <div className="schemes_heading mb-5 mt-5">Application Fee</div>

      <div className=" Schemes_heading_description mb-5">
        An application fee of Rs 1105/- has to be remitted per unit.
      </div> */}

      {/* <div className="d-flex align-items-center mt-5 mb-5">
        <div className="download_description">
          <h6>For more information</h6>
        </div>
        <div className="schemes_download ml-3">
          <a
            href={ProgDoc}
            targt="_blank"
            className="d-flex align-items-center download-btn"
          >
            <span className="ml-1 ">Download</span>
            <span className="d-flex ">
              <KiedIcons icon="Download-btn" className="ml-1" />
            </span>
          </a>
        </div>
      </div> */}
    </div>
  );
}

export default BuildingDescription;
