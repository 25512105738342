import React from 'react'

function InternshipApplicationBreadcrumbs() {
  return (
    <div>
          <div className="faq_banner head-container pl-5 d-flex">
      <a href="/">Home&nbsp;/</a>
      <a href="/internship-details"> Internship-Services&nbsp;/</a>
      <a className="current" href="#">
        Internship Summary List
      </a>
    </div>
    </div>
  )
}

export default InternshipApplicationBreadcrumbs