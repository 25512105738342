import React, { useEffect, useState } from "react";

import SchemeBreadcrumbs from "./sections/breadcrumb/schemes.breadcrumb";
import SchemeHerosection from "./sections/hero/schemeHero";
import SchemeDescription from "./sections/description/scheme.description";
import SchemeCanapply from "./sections/canapply/scheme.canapply";
import SchemeEligibility from "./sections/eligibility/scheme.eligibility";
import SupportScheme from "./sections/supportscheme/SupportScheme";
import SmallBanner from "../../components/small.banner/small.banner";
import "./schemes.page.styles.css";
import { getSchemeById } from "../../apis/publicApis";
import { useParams } from "react-router-dom";
function SchemeDetailPage() {
  const param = useParams();
  const [data,setData]=useState({})
  useEffect(()=>{
    getSchemeById(param.id).then((res)=>{
      let item=res?.data   
    
      setData(item)
    })
  }, [param.id]);
  return (
    <div>
      <SmallBanner title={"Schemes"} />
      <SchemeBreadcrumbs />
      <SchemeHerosection data={data}/>
      <SchemeDescription data={data}/>
    </div>
  );
}

export default SchemeDetailPage;
