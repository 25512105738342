import React from "react";
import WebinarCard from "../../../../components/webinar.card/WebinarCrad";
import Pagination from "../../../../components/pagination/pagination";
import { useRef, useState, useMemo } from "react";

import { webinarDetails } from "../../OurWebinarData";

let PageSize = 9;

function OurWebinarDetails() {
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const myRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  const mainData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return webinarDetails.slice(firstPageIndex, lastPageIndex);
  }, [webinarDetails, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    myRef.current.scrollIntoView();
  };

  return (
    <div className="auto-container pb-5 pt-5 " ref={myRef}>
      <div className="row mt-5 pt-5 ">
        {mainData.map((card, i) => {
          return (
            <div className="col-4">
              <WebinarCard data={card} key={i} index={i} />
            </div>
          );
        })}
      </div>
      <Pagination
        className="pagination-bar d-flex justify-content-center"
        currentPage={currentPage}
        totalCount={webinarDetails.length}
        pageSize={PageSize}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
}

export default OurWebinarDetails;
