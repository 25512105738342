import React from "react";

function SubmitInternshipBreadcrumb() {
  return (
    <div>
      <div className="faq_banner head-container d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/home">IAC&nbsp;/</a>
        <a href="/internship-details"> Internship-Details&nbsp;/</a>
        <a className="current" href="#">
          Submit Internship
        </a>
      </div>
    </div>
  );
}

export default SubmitInternshipBreadcrumb;
