// import React from 'react'

// function BuildingBreadcrumb() {
//   return (
//     <div>BuildingBreadcrumb</div>
//   )
// }

// export default BuildingBreadcrumb

import React from "react";

function BuildingBreadcrumb() {
  return (
    <div>
      <div className="faq_banner auto-container d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/academiaSchemes">
          {" "}
          Academia&nbsp;/
        </a>
        <a className="current" href="/BuildingProgramSchemes">
          Academia Scheme Details
        </a>
      </div>
    </div>
  );
}

export default BuildingBreadcrumb;
