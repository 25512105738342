import React from "react";
import Masonry from "react-responsive-masonry";
import KiedImages from "../../../../assets/images/kied.images.component";

function NewProgrmGallery(props) {
  return (
    <div className="greyBackground">
      <div className="auto-container  pb-5 mb-3 ">
        <div
          id="InovationEventssHeading"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="p-5">
            <h2 className="inovation_headings m-1">Gallery</h2>
          </div>
        </div>
        <Masonry columnsCount={3} gutter="10px">
          {props?.data?.gallery?.map((image, i) => (
            <KiedImages
              image={image.image}
              style={{ width: "100%", height: "100%", display: "block" }}
            />
          ))}
        </Masonry>
      </div>
    </div>
  );
}

export default NewProgrmGallery;
