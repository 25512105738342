import React from "react";
import WebinarTable from "./sections/webinar.tables";
import SmallBAnner from "../../components/small.banner/small.banner";

function WebinarPage() {
  return (
    <div>
      <SmallBAnner title="Webinar Events" />
      <WebinarTable />
    </div>
  );
}

export default WebinarPage;
