import React from "react";

import { companyDetails } from "../../internship.summary.data";
import SelectBox from "../../../../components/selectbox/SelectBox.component";

function InternshipSummarySelector() {
  return (
    <div className="col-3 pr-5">
      <SelectBox selectionMessage="Select a company" options={companyDetails} />
    </div>
  );
}

export default InternshipSummarySelector;
