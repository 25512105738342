import React from "react";
import { faqData } from "../faqData";
import FaqCard from "../../../../components/faq/faq.card";
import KiedImages from "../../../../assets/images/kied.images.component";

function FaqQA() {
  return (
    <div>
      <div className="auto-container  ">
        <div
          id="InovationSchemesHeading"
          className="d-flex justify-content-center align-items-center mb-3"
        >
          {/* <div className="">
            <KiedImages
              className="inovation_headings_logo m-1"
              image="inovation-heading-icon"
            />
          </div> */}
          {/* <div className="">
            <h2 className="inovation_headings m-1">
              Frequently Asked Questions
            </h2>
          </div> */}
          {/* <hr /> */}
        </div>
        {/* <hr /> */}
        <div className="mt-5 ">
          {faqData.map((card, i) => {
            return <FaqCard data={card} key={i} index={i} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default FaqQA;
