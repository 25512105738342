import React from "react";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import "../../schemes.page.styles.css";
import ProgDoc from "./innovation-program.pdf";
import KiedButton from "../../../../components/buttons/button/button.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SchemeDescription() {
  let width = useScreenBreakPoint();
  return (
    <div className="auto-container ">
      {/* <div className=" Schemes_heading_description mb-5">
        Kerala State Industrial Development Corporation Ltd. (KSIDC), an
        industrial and investment promotion agency of the Government of Kerala
        has been involved in various Startup initiatives like Business
        Incubation Centres, mentoring sessions for young entrepreneurs, seed
        funding for innovative start-ups etc. The Innovative finance scheme was
        launched as part of YES (Young Entrepreneur Summit 2014) initiative.
        Under the scheme, innovative ventures are given seed fund by way of soft
        loan upto 90% of the project cost or Rs.25 lakhs whichever is lower. The
        seed fund assistance will be sanctioned as Soft Loan for a period of
        three year at the prevailing RBI bank rate as on the date of sanction of
        financial assistance. The company can either repay the loan or convert
        it to equity limited to19% on mutually agreed terms within 3 years from
        the date of disbursement.
        <br /> Apart from this, for the innovative start-ups which have started
        commercial operation has the option to avail scale up loan assistance
        @7% interest rate/equity assistance up to 80% of the scale up project
        cost or Rs.50lakhs whichever is lower. This loan shall be repaid in 3
        years.
        <br /> Under these schemes, the Project Evaluation Memorandum and
        specific Term Sheet shall be placed before the expert Committee
        constituted for evaluation of the start-up proposals.
      </div> */}
      <div>
        <div
          className={`auto-container    ${
            width === "xs" || width === "sm"
              ? "sml px-3 mt-5"
              : "lr  mt-5 pb-5  "
          }`}
        >
          <div>
            <div className="schemes_heading">Contact Details</div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 p-2">
                <div>
                  <div className="athority-section-list">
                    <ul className="mt-3">
                      <li
                        className={` ${
                          width === "xs" || width === "sm"
                            ? "sml "
                            : "lr  mt-5 mb-2  "
                        }`}
                      >
                        <div>
                          <div className="d-flex mt-2">
                            <div className="" style={{ height: "100%" }}>
                              <KiedIcons
                                icon="list-btn"
                                className="authority-icon"
                              />
                            </div>
                            <div className="authority-txt">
                              <div>
                                Mr. Nithesh B Manager(Project Finance)
                                KSIDC.Ltd,
                                <br /> 2nd Floor.Choice Towers, <br />
                                <div className="mt-3">
                                  Manorama Junction, Kochi-682016 <br /> Email
                                  Id:nithesh@ksidcmail.org
                                  <br />
                                  Ph: 0484-2323010/2323101
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div>
                  <div className="athority-section-list">
                    <ul className="mt-3">
                      <li className="mt-5 mb-2">
                        <div></div>
                      </li>
                      <li className="mt-2 mb-2">
                        <div>
                          <div className="d-flex mt-2">
                            <div className="" style={{ height: "100%" }}>
                              <KiedIcons
                                icon="list-btn"
                                className="authority-icon"
                              />
                            </div>
                            <div className="authority-txt">
                              <div>
                                Mr Vinu K.R. Project Officer(IF &WE
                                Mission)KSIDC.Ltd,
                                <br /> 2nd Floor.Choice Towers, <br />
                                <div className="mt-3">
                                  Manorama Junction, Kochi-682016 <br /> Email
                                  Id:vinu@ksidcmail.org
                                  <br />
                                  Ph: 0484-2323010/2323101
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5 ">
            {/* <Link> */}
            <a
              // href="https://industry.kerala.gov.in/index.php/pages/capacity-building/ed-club"
              href="https://www.ksidc.org/services/entrepreunaurship-development-start-ups/"
              // href="https://industry.kerala.gov.in/index.php/schemes-mainmenu/entrepreneur-support-scheme-schemes"
              target="_blank"
              rel="noreferrer"
            >
              <KiedButton
                content={
                  <>
                    <div className="d-flex p-1">
                      <div className="d-flex align-items-center">Apply</div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon arrow_icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                rounded
                big
              />
            </a>
            {/* </Link> */}
          </div>
        </div>
        ;
      </div>
      {/* 
      <div className="schemes_heading mb-5 mt-5">Contact Details </div>
      <div className=" Schemes_heading_description mb-5">
        Mr. Nithesh B Manager(Project Finance) KSIDC.Ltd,
        <br /> 2nd Floor.Choice Towers, <br />
        Manorama Junction, Kochi-682016 <br /> Email Id:nithesh@ksidcmail.org
        <br />
        Ph: 0484-2323010/2323101
      </div>
      <br />
      <div className=" Schemes_heading_description mb-5">
        Mr Vinu K.R. Project Officer(IF &WE Mission)KSIDC.Ltd,
        <br /> 2nd Floor.Choice Towers, <br />
        Manorama Junction, Kochi-682016 <br /> Email Id:vinu@ksidcmail.org
        <br />
        Ph: 0484-2323010/2323101
      </div> */}
      {/* <div className="schemes_heading mb-5 mt-5">
        Stages in Entrepreneur Support Scheme
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">1. Startup Support</div>
      <div className=" Schemes_heading_description mb-5">
        The Startup support is provided for those enterprises which have availed
        at least term loan from a financial institution and wish to avail a part
        of the total eligible support prior to the commencement of commercial
        production. The assistance is limited to 50% of the total eligible
        support limited to Rs 3 lakh on sanctioning the term loan from the bank.
        The unit can apply for the balance of the eligible support once it start
        the commercial production. A unit which do not avail Startup Support,
        can directly apply for the investment support, after the commencement of
        commercial production.
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">2. Investment Support</div>
      <div className=" Schemes_heading_description mb-5">
        Investment Support is provided after the commencement of commercial
        production. To apply for Investment Support, loan from a financial
        institution is not mandatory. The entrepreneurs shall apply within one
        year from the date commencement of production. Enterprises undertaking
        Expansion, Diversification or Modernization are also eligible for invest
        support for the additional investment made.
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">3. Technology Support</div>
      <div className=" Schemes_heading_description mb-5">
        Technology Support is provided after commencement of production, on
        acquiring new technology from authorized institutions. The unit shall
        apply for technology support within 6 months from the date of
        commencement after installing the new technology form authorized
        agencies. Assistance will be provided for the new technology and for the
        plant and machinery installed in connection with the new technology.
      </div>
      <hr />
      <div className="schemes_heading mb-5 mt-5">Application Fee</div>

      <div className=" Schemes_heading_description mb-5">
        An application fee of Rs 1105/- has to be remitted per unit.
      </div> */}

      {/* <div className="d-flex align-items-center mt-5 mb-5">
        <div className="download_description">
          <h6>For more information</h6>
        </div>
        <div className="schemes_download ml-3">
          <a
            href={ProgDoc}
            targt="_blank"
            className="d-flex align-items-center download-btn"
          >
            <span className="ml-1 ">Download</span>
            <span className="d-flex ">
              <KiedIcons icon="Download-btn" className="ml-1" />
            </span>
          </a>
        </div>
      </div> */}
      {/* <hr /> */}
    </div>
  );
}

export default SchemeDescription;
