import React from "react";
import "./under.construction.css"

function UnderConstruction() {
  return (
    <div className="d-flex align-items-center justify-content-center construction">
      <b>
        <i>Page Under Construction.</i>
      </b>
    </div>
  );
}

export default UnderConstruction;
