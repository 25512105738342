import React from "react";
import { WebinarData } from "../webinar.data";
import Pagination from "../../../components/pagination/pagination";
import { useRef, useState, useMemo } from "react";
import { useEffect } from "react";
let PageSize = 20;

function WebinarTable() {
  const [data, setData] = useState([]);
  const myRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  //   let TenderData = TenderDatas.reverse();

  const mainData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    myRef.current.scrollIntoView();
  };
  useEffect(() => {
    setData(WebinarData.reverse());
  }, []);
  return (
    <div className="auto-container pb-5 pt-5" ref={myRef}>
      <div className="d-flex justify-content-center">
        <div>
          <table className="">
            <thead>
              {mainData.length > 0 ? (
                <tr className="">
                  <th className="table-head">Sl No</th>
                  <th className="table-head">PROGRAMME</th>
                  <th className="table-head ">STATUS</th>
                </tr>
              ) : (
                "No Programs Available"
              )}
            </thead>
            <tbody>
              {mainData.map((card, i) => {
                return (
                  <tr>
                    <td className="m-2">
                      {(currentPage - 1) * PageSize + i + 1}
                    </td>
                    <td className="m-2">{card.name}</td>
                    <td className="m-2">{card.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            className="pagination-bar "
            currentPage={currentPage}
            totalCount={data.length}
            pageSize={PageSize}
            onPageChange={(page) => handlePageChange(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default WebinarTable;
