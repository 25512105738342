import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";

import RegistrationForms from "./sections/registration.forms/registrations.forms";
import "./sections/registration.page.styles.css";

function RegistrationPage() {
  return (
    <div>
      <SmallBanner title="Registration"/>
      <RegistrationForms />
    </div>
  );
}

export default RegistrationPage;
