import React from "react";
import { footercontent } from "../footer.config";
import useScreenBreakPoint from "../../../hooks/useScreenBreakPoint";
import { useLangStore } from "../../../utils/zustand";
function FooterDesktopComponent() {
  let width = useScreenBreakPoint();
  const { language, setLanguage } = useLangStore();
  const langSet = (val) => {
    setLanguage(val);
  };
  return (
    <div className="auto-container ">
      <div className="widgets-section ml-2">
        <div className="clearfixf">
          {footercontent.map((footersection, i) => {
            return (
              <React.Fragment key={i}>
                {footersection.type === "address" && (
                  <div
                    className={
                      width === "xs" ? "footer-column w-100 " : "footer-column"
                    }
                  >
                    <div className="footer-widget links-widget">
                      <ul className="footer-list d-flex flex-column align-items-start">
                        <li>{footersection.data.line1}</li>{" "}
                        <li>{footersection.data.line2}</li>{" "}
                        <li>{footersection.data.phone}</li>
                      </ul>
                    </div>
                  </div>
                )}
                {footersection.type === "link" && (
                  <div
                    className={
                      width === "xs" ? "footer-column w-100" : "footer-column"
                    }
                  >
                    <div className="footer-widget links-widget">
                      <ul
                        className={`footer-list ${
                          i === 3
                            ? "d-flex flex-column align-items-end"
                            : "d-flex flex-column align-items-start"
                        }`}
                      >
                        {footersection.data.map((footerlinks, n) => {
                          return (
                            <li key={n}>
                              {footerlinks.label === "Privacy Policy" ? (
                                <a
                                  href={`${process.env.PUBLIC_URL}/files/privacypolicy.pdf`}
                                  target="_blank"
                                >
                                  {language !== "ma" ? footerlinks[language] ?? footerlinks.label  : footerlinks.label}

                                  {/* {footerlinks.label} */}
                                </a>
                              ) : footerlinks.label === "Terms & Conditions" ? (
                                <a
                                  href={`${process.env.PUBLIC_URL}/files/terms.pdf`}
                                  target="_blank"
                                >
                                   {language !== "ma" ? footerlinks[language] ?? footerlinks.label  : footerlinks.label}
                                  {/* {footerlinks.label} */}
                                </a>
                              ) : footerlinks.label === "Disclaimers" ? (
                                <a
                                  href={`${process.env.PUBLIC_URL}/files/disclaimer.pdf`}
                                  target="_blank"
                                >
                                   {language !== "ma" ? footerlinks[language] ?? footerlinks.label  : footerlinks.label}
                                  {/* {footerlinks.label} */}
                                </a>
                              ) : footerlinks.label ===
                                "Right to Information" ? (
                                <a
                                  href={`${process.env.PUBLIC_URL}/files/rti.pdf`}
                                  target="_blank"
                                >
                                  {/* {footerlinks.label} */}
                                  {language !== "ma" ? footerlinks[language] ?? footerlinks.label  : footerlinks.label}
                                </a>
                              ) : (
                                <a href={footerlinks.link}>
                                  {/* {footerlinks.label} */}
                                  {language !== "ma" ? footerlinks[language] ?? footerlinks.label  : footerlinks.label}
                                </a>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>{" "}
        <div
          className=""
          style={{ zIndex: 999, color: "red" }}
          onClick={(e) => {
            e.stopPropagation();
            langSet(language === "label" ? "label_ma" : "label");
          }}
        >
        <span style={{ zIndex: 999, color: "white" }}>Switch Language :</span>  <span style={{cursor : "pointer"}}> {language !== "label" ? "ma" : "en"}</span> 
        </div>
        
      </div>
    </div>
  );
}

export default FooterDesktopComponent;
