import api from "./apis";

const apis = {
	auth: async (url, reqData) => await api.post(url, reqData).then((res) => res),
	getDatas: async (url, queryParams, type) =>
		await api
			.get(url, queryParams, type)
			.then((res) => {
				return res;
			})
			.catch((error) => {
				return Promise.reject(error);
			}),
	createData: async (url, queryParams) =>
		await api
			.post(url, queryParams)
			.then((res) => {
				return res;
			})
			.catch((error) => {
				return Promise.reject(error);
			}),
	updateData: async (url, queryParams) =>
		await api
			.put(url, queryParams)
			.then((res) => {
				return res;
			})
			.catch((error) => {
				return Promise.reject(error);
			}),
	deleteData: async (url, queryParams) =>
		await api
			.delete(url, queryParams)
			.then((res) => {
				return res;
			})
			.catch((error) => {
				return Promise.reject(error);
			}),
};
export default apis;
