import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
function ProgramsBanner(props) {
  let width = useScreenBreakPoint();
  const user = JSON.parse(localStorage.getItem("user"))
  const userRole = user?.role
  console.log(userRole)
  const history = useNavigate();
  const param = useParams()
  const handleregister = (id, role) => {
    // console.log(role, userRole)
    // if(role.includes(userRole)){
    history(`/department/inovation/registration/${id}`);
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: "This program is not meant for your user type",
    //     confirmButtonColor: "#ffa93c",
    //     confirmButtonText: "Ok",
    //   });
    // }
  }
  return (
    <div
      id="Banner_outer"
      className="banner_image banner_big d-flex justify-content-center"
    >
      <div
        id="Banner_inner"
        className="d-flex  container bannerContainer  head-container justify-content-between"
      >
        <div
          id="Banner_text"
          className="d-flex flex-column justify-content-end"
          // data-aos="fade-right"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <h2 className="text_banner more_link">Enterprise Development Center</h2>

          <div className="more_link">
            <div className="">
              {/* <span className="prog-banner" style={{ color: "#fff" }}>
                (Agro Incubation for Sustainable Entrepreneurship)
              </span> */}
            </div>
          </div>

          <div className="more_link">
            {/* <div className="">
              <span className="prog-banner">{props.data.participants}</span>
            </div> */}
          </div>
          {/* <Link to="/department/inovation/registration"> */}

          {props?.data?.status === "Upcoming" && props?.data?.fees &&  (
            <div className="more_link">
              <KiedButton
                onClick={() => handleregister(param.id, props?.data?.role_id)}
                content={
                  <>
                    <div className="d-flex">
                      <div className="d-flex align-items-center m-2">
                        
                          Click Here To Apply
                        
                      </div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                rounded
                big
              />

              {/* </Link> */}
            </div>
          )}
        </div>
        <div
          id="Banner_image"
          className="aboutpage_images  d-none d-md-block"
          // data-aos="fade-left"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <KiedImages
            className="program-banner-image d-none d-md-block"
            image="program-banner"
          />
        </div>
      </div>
    </div>
  );
}

export default ProgramsBanner;
