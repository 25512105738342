import React from "react";
import ResourceProgramTable from "./sections/tables/program.tables";
import SmallBanner from "../../components/small.banner/small.banner";
import "./resource.programs.styles.css"

function ResourceProgramPage() {
  return (
    <div>
      <SmallBanner title="Programs" />
      <ResourceProgramTable />
    </div>
  );
}

export default ResourceProgramPage;
