import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import AssistanceBreadcrumb from "./sections/breadcrumb/AssistanceBreadcrumb";
import AssistanceHero from "./sections/hero/AssistanceHero";
import AssistanceSupportingSchemes from "./sections/supportingschemes/AssistanceSupportingSchemes";
import AssistanceCanapply from "./sections/canapply/AssistanceCanapply";
function AssistancePromotion() {
  return (
    <div>
      <SmallBanner title={"Export Promotion for Existing MSMEs"} />
      <AssistanceBreadcrumb />
      <AssistanceHero />
      <AssistanceSupportingSchemes />
      <AssistanceCanapply />
    </div>
  );
}

export default AssistancePromotion;
