import React from "react";
import EventCard from "../../../../components/event.cards/event.card";
import { inovationEventData } from "../inovationData";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import { useNavigate } from "react-router-dom";

function InovationEvents(props) {
  const navigate = useNavigate();
  let width = useScreenBreakPoint();
  let sortedEventData = inovationEventData.sort(
    (a, b) =>
      new Date(...a.startDate.split("/").reverse()) -
      new Date(...b.startDate.split("/").reverse())
  );
  let mainData = sortedEventData.reverse();
  console.log(props.data)
  return (
    <div className="head-container mb-5 pb-5 pt-5 pl-4 pl-3">
      <div
        id="InovationEventssHeading"
        className="d-flex justify-content-center align-items-center"
      >
        <div className="">
          <h2 className="inovation_headings m-1">Events</h2>
        </div>
      </div>

      <div className="">
        <div
          id="inovation_Events_contents"
          className={`row mx-0 d-flex ${
            width === "xs" || width === "sm" ? "flex-sm-column pb-4" : ""
          }, ${props.data.length === 1 && "justify-content-center"}`}
        >
          {props?.data?.map((card, i) => {
            return <EventCard data={card} key={i} index={i} />;
          })}
        </div>
      </div>
      {/* <div className="w-100 d-flex justify-content-center mt-5 mb-5">
        <button
          className="all-eventbtn d-flex align-items-center justify-content-between"
          onClick={() => navigate("/department/inovation/events")}
        >
          All Events{" "}
          <span>
            <KiedIcons
              icon="arrow-right"
              className="hero-button-icon arrow_icon d-flex align-items-center"
            />{" "}
          </span>
        </button>
      </div> */}
    </div>
  );
}

export default InovationEvents;
