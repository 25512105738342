// import React from "react";
// import EdcNewProgramBanner from "../edc.new.program/sections/banner/EdcNewProgramBanner";
// function EdcNewProgram() {
//   return (
//     <div>
//       <EdcNewProgramBanner />
//     </div>
//   );
// }

// export default EdcNewProgram;
import React from "react";
import YuvaTeamCard from "../../components/yuva-team/YuvaTeamCard";
import EdcNewProgramDescription from "../edc.new.program/sections/description/EdcNewProgramDescription";
import EdcNewProgramBanner from "../edc.new.program/sections/banner/EdcNewProgramBanner";
import EdcNewProgramHero from "../edc.new.program/sections/hero/EdcNewProgramHero";
import EdcNewProgramGallery from "../edc.new.program/sections/gallery/EdcNewProgramGallery";
import ProgramDetails from "./sections/program.details/ProgramDetails";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { EDCProgramData } from "../Entrepreneurship_Development_Club/edc.page.data";
import EdcNewProgramDone from "../edc.new.program/sections/overDetails/EdcNewProgramDone";
function EdcNewProgram() {
  const param = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    let selected = EDCProgramData.filter((item) => {
      console.log("item", typeof item.id);
      console.log(typeof param.id);
      return item.id == param.id;
    });
    setData(selected[0]);
  }, [param.id]);
  console.log(data);

  console.log(data);
  return (
    <div>
      <EdcNewProgramBanner data={data} />
      <EdcNewProgramHero data={data} />
      {data?.title === "YUVA BOOT CAMP" ? (
        <EdcNewProgramDescription data={data} />
      ) : (
        <ProgramDetails data={data} />
      )}

      {data?.title === "YUVA BOOT CAMP" && <EdcNewProgramDone data={data} />}

      <EdcNewProgramGallery data={data} />

      {/* <NewProgramOutcome data={data} /> */}
      {/* 
      <NewProgramDetails data={data} />
      {data?.status === "Upcoming" ? (
        <>
          <NewProgramMentors data={data} />
          <NewProgramSupportingPartners data={data} />
          <NewProgramFaq />
        </>
      ) : data?.title === "ARISE" ? (
        <>
          <AriseReport data={data} />
        </>
      ) : (
        <>
          <NewProgramsReport data={data} />
          <NewProgrmGallery data={data} />
        </>
      )} */}
    </div>
  );
}

export default EdcNewProgram;
