import React from "react";

function SchemeBreadcrumbs() {
  return (
    <div>
      <div className="faq_banner auto-container d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/inovation/home"> Innovation&nbsp;/</a>
        <a className="current" href="/schemes">
          Scheme Details
        </a>
      </div>
    </div>
  );
}

export default SchemeBreadcrumbs;
