import React from "react";
import ProgramsCard from "../../../../components/programs.cards/programs.card";
import KiedImages from "../../../../assets/images/kied.images.component";
import { IndAcProgramData } from "../../industry-academy.data";

function IndAcProgramms() {
  return (
    <div className="auto-container pb-5 pt-5">
      <div
        id="InovationSchemesHeading"
        className="d-flex justify-content-center align-items-center "
      >
        <div className="">
          <h2 className="inovation_headings m-1">Programs</h2>
        </div>
      </div>
      <div>
        <div className=" ">
          <div className="row ">
            {IndAcProgramData.map((card, i) => {
              return <ProgramsCard data={card} key={i} index={i} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndAcProgramms;
