import React, { useState } from "react";
import "./payment.css";
import KiedButton from "../../components/buttons/button/button.component";

const PaymentDetails = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   alert(`Card Number: ${cardNumber}\nExpiry Date: ${expiryDate}\nCVV: ${cvv}`);
  // };

  return (
    <div className="d-flex justify-content-center align-items-center py-5 payment-card-container">
      <div className="payment-card p-3">
        <div className="d-flex flex-column items-start">
          <div className="d-flex col-12 form-style">
            <label className="col-4 payment-label">Card Number</label>
            <input
            className="col-8 payment-input"
              type="text"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </div>
          <div className="d-flex col-12 form-style">
            <label className="col-4 payment-label">Card Holder's Name</label>
            <input
            className="col-8 payment-input"
              type="text"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </div>
          <div className="d-flex col-12">
          <div className="d-flex col-7 form-style">
            <label className="col-4 payment-label">Expiration</label>
            <input
            className="col-8 payment-input"
              type="text"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </div>
          <div className="d-flex col-5 form-style">
            <label className="col-4 payment-label">cvv</label>
            <input
            className="col-8 payment-input"
              type="text"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </div>
          </div>
          
          <KiedButton
              
              secondary
              
              
              className="mt-2"
              type="submit"
              content={
                <div className=" d-flex justify-content-center">
                  Pay
                </div>
              }
            />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
