export const inovationData = [
  {
    id: 1,
    title: "Entrepreneur Support Scheme",
    status: "Ongoing",
    description:
      "It's center supporting startups and small business entities to launch and grow initiative and financial assistance.",
    cardDescription:
      "It's center supporting startups and small business entities to launch and grow initiative and financial assistance.",
    link: "/schemes",
    type: "DIC Scheme",
    listingImage: "ess",
  },
  {
    id: 2,
    title: "KSIDC Entrepreneur Scheme",
    status: "Ongoing",
    description:
      "Startup initiatives like Business Incubation Centres, mentoring sessions for young entrepreneurs, seed funding for innovative start-ups etc.",
    cardDescription:
      "Startup initiatives like Business Incubation Centres, mentoring sessions for young entrepreneurs, seed funding for innovative start-ups etc.",
    link: "/ksidc-schemes",
    type: "KSIDC ",
    listingImage: "ess",
  },
  // {
  //   id: 2,
  //   title: "Financial Assistance",
  //   description:
  //     "Lorem ipsum is a placeholder text commonly used to demonstrate.",
  //   link: "/schemes",
  // },
  // {
  //   id: 3,
  //   title: "Financial Assistance",
  //   description:
  //     "Lorem ipsum is a placeholder text commonly used to demonstrate.",
  //   link: "/schemes",
  // },
  // {
  //   id: 4,
  //   title: "Financial Assistance",
  //   description:
  //     "Lorem ipsum is a placeholder text commonly used to demonstrate.",
  //   link: "/schemes",
  // },
];

export const inovationProgramData = [
  {
    id: 1,
    title: "BUSINESS GROWTH PROGRAM",
    titleLink: "/department/inovation/registration",
    location: "Kochi",
    startDate: "21st March 2023",
    status: "Upcoming",
    endDate: "20th May 2023",
    // startTime: "10.00 AM",
    participants: "MSME / Small Business Entrepreneurs",
    description:
      "The Business Growth program aims to support MSME entrepreneurs in the state by improving their  operational efficiency, sales growth, profitability, and sustainability through a six-month program  that combines mentorship and training sessions.",
    cardDescription:
      "The Business Growth program aims to support MSME entrepreneurs in the state by improving their  operational efficiency, sales growth, profitability, and sustainability through a six-month program  that combines mentorship and training sessions.",
    image: "growthprgm",
    listingImage: "growthprgm",
    mainDescription:
      "The Micro, Small and Medium Enterprises (MSME) sector has emerged as a highly vibrant and dynamic sector of the Indian economy over the last five decades. It contributes significantly in the economic and social development of the country by fostering entrepreneurship and generating large employment opportunities at comparatively lower capital cost, next only to agriculture. MSMEs are complementary to large industries as ancillary units and it contributes significantly in the inclusive industrial development of the country. The 6 crore MSMEs are widening their domain across sectors of the economy, producing diverse range of products and services to meet demands of the domestic as well as the global markets Globally, technology and its rapid expansion and adoption is becoming a critical factor in impacting almost all sectors.The Business Growth program aims to support MSME entrepreneurs in the state by improving their operational efficiency, sales growth, profitability, and sustainability through a six-month program that combines mentorship and training sessions.",
    offerings: [
      {
        id: 1,
        // image: "offering-seed",
        name: "Raise Finance",
        description:
          "Supporting on expansion, financial stability and innovation",
      },
      {
        id: 2,
        // image: "offering-growth",
        name: "Improve business KPI’s",
        description:
          "Overall growth in business KPI’s and employment generation",
      },
      {
        id: 3,
        // image: "offering-mentor",
        name: "Efficiency & Excellence ",
        description: "Make MSME units competitive and growth oriented",
      },
      {
        id: 4,
        // image: "offering-cloud",
        name: "Business Growth & Stability ",
        description:
          "Accelerate the growth of MSME Units/Entrepreneurs in Kerala",
      },
      // {
      //   id: 5,
      //   // image: "offering-training",
      //   name: "Business Knowledge ",
      //   description : ""
      // },
    ],
    objectives: [
      {
        id: 1,
        objective:
          "Accelerate the growth of MSME Units/Entrepreneurs in Kerala.",
      },
      {
        id: 2,
        objective:
          "Supporting on expansion, financial stability and innovation.",
      },
      {
        id: 3,
        objective: "Make MSME units competitive and growth oriented.",
      },
      {
        id: 4,
        objective:
          "Overall growth in business KPI’s and employment generation.",
      },
      {
        id: 5,
        objective:
          "Provide right business inputs through mentorship sessions and training programs.",
      },
    ],
    eligibilities: {
      listing: [
        {
          id: 1,
          eligibility:
            "MSME unit that has established a business venture in Kerala preferably in the manufacturing or providing specialized services to MSME units.",
        },
        {
          id: 2,
          eligibility: "Has been in operation for less than 10 years.",
        },
        {
          id: 3,
          eligibility:
            "Having an annual turnover of more than Rs.35 lakhs and less than Rs. 50 Cr.",
        },
      ],
      sectorFocus: "MSME units with unique ideas from all sectors can apply.",
      duration: "6 Months",
      batchSize: "20 ",
    },
    structures: [
      // {
      //   id: 1,
      //   structure: "Two-step selection process for Business Growth Program.",
      // },
      {
        id: 2,
        structure:
          "Initial Screening: MSMEs may sign up on this website as Existing Entrepreneur, verify and upload a growth plan.",
      },
      {
        id: 3,
        structure:
          "Based in the submission, KIED will evaluate the applications and shortlisted MSMEs for the final round of selection.",
      },
      {
        id: 4,
        structure:
          "Final Selection: Shortlisted MSMEs will be invited for a presentation before a selection committee.",
      },
      {
        id: 5,
        structure: "The MSMEs selected by the committee will be inducted into the program.",
      },
      // {
      //   id: 6,
      //   structure:
      //     "Entrepreneurs selected for the 6-month Business Growth Program can participate in a flexible mode without disrupting their regular business operations.",
      // },
    ],
    fees: [
      {
        id: 1,
        fee: "Fees for this program will be Rs.10000+GST per participant.",
      },
      // {
      //   id: 2,
      //   fee: "Participants should make payment on or before the program day.",
      // },
      {
        id: 3,
        fee: "Participants who opt for co-working space can avail a discount of 50%, paying only Rs 2500/- and GST per cubicle per month.",
      },
      // {
      //   id: 4,
      //   fee: "For non incubate entrepreneurs should pay the normal co-working charges of Rs.5000+GST per month.",
      // },
    ],
    mentors: [
      {
        id: 1,
        name: "Mr. Padmakumar",
        designation:
          "C Padmakumar was the Chairman & Managing Director of Terumo Penpol Private Limited. He is a qualified manager with a Bachelor's in Commerce and an MBA in Marketing. Currently serving as Directors of Kerala Life Sciences Industries Limited, Padosan Trading Private Limited, and Kerala Medical Technology Consortium as Special Officers. Recipient of the 2017 Asia Pacific Enterprise Award (APEA) given by the non-governmental organisation Enterprise Asia for the healthcare sector. Currently serving as the Trivandrum Management Association's president and the Rajiv Gandhi Center for Biotechnology's PG program's industry specialist.",
        image: "mentor1",
        link: "https://in.linkedin.com/in/c-padmakumar-7a521a14",
      },
      {
        id: 2,
        name: "Mr. Ajayan K Anat",
        designation:
          "Senior Mentor of Kerala Startup Mission and Kerala Development and Innovation Strategic Council, Asst. General Manager (Ret.) of Punjab National Bank (K-DISC). Member of the MSME Committee for the All India Management Association (AIMA), with experience in loan syndication, project financing, and corporate credit.",
        image: "mentor2",
        link: "https://www.linkedin.com/in/ajayan-kavungal-anat-14163923/?originalSubdomain=in",
      },
      {
        id: 3,
        name: "Mr. P k Gopalakrishnan",
        designation:
          "Director of Malabar Innovation Zone, Kannur, a business established to support Kerala's entrepreneurial ecosystem. He also contributed to the creation of Malabar Angel Network, a renowned platform for angel investing in early-stage start-ups. He served in executive capacities at ITC Paper, Wipro, and Mindtree before leaving the corporate world to become the CEO and MD of Wep Solutions Ltd. He also holds a Business Excellence Assessor certification from the CII Institute of Quality. He has experience in all corporate activities, giving him a comprehensive understanding of business management.",
        image: "mentor4",
        link: "https://www.linkedin.com/in/p-k-gopalakrishnan-pkg-10a11a15/?originalSubdomain=in",
      },
      {
        id: 4,
        name: "Mr. Vivishnavel Vajravel",
        designation:
          "He has a passion for insightful marketing and strategy on cultures, people, categories, brands, and corporations. More than 20 years of expertise working with market research companies on projects involving consumer rights, neuromarketing, behavioural economics, social intelligence, and tech analysis.",
        image: "mentor5",
        link: "https://www.linkedin.com/in/vivishnavel-vajravel-5944a72/?originalSubdomain=in",
      },
      {
        id: 5,
        name: "Mr. Vedanarayanan Vedantham",
        designation:
          "In the fields of fintech, health tech, ed tech, and online travel, Vedanarayanan assisted in the development and expansion of numerous digital products & companies. possess a diverse range of experiences from around the world in areas such as business development, brand consulting, and digital marketing. He provides branding, digital marketing, and product-market fitting advice to early-stage entrepreneurs directly or through incubators, including multiple TiE Chapters, the NEXEA Accelerator in Southeast Asia, and the Atal Innovation Mission with the Government of India. Additionally, he conducts seminars on digital marketing, mobile marketing, influencer marketing, services marketing, and product marketing as a visiting faculty member at numerous top business schools.",
        image: "mentor8",
        link: "https://www.linkedin.com/in/vedanarayananvedantham/?originalSubdomain=in",
      },
      {
        id: 6,
        name: "Mr. Alok Agarwal",
        designation:
          "Alok enables firms uncover untapped potential and experience exponential growth in his capacity as an innovation and business consultant. He has led various Innovation challenges and hackathons and has assisted numerous organisations and startups with their business innovation. Alok has experience in the media, having served as CEO of Zee Media and Group COO of Network 18. He has experience in advertising, having served as COO of WPP Network and Cheil Worldwide (A Samsung Group Company). Alok, a graduate of IIT Kanpur, IIM Bangalore, and Wharton, has steered major businesses, start-ups, mergers, and acquisitions, as well as numerous fruitful brand launches. In the areas of company strategy, innovation, digital transformation, startup alliances, media, marketing, and go-to-market planning, he provides consultation and implementation services.",
        image: "mentor3",
        link: "https://www.linkedin.com/in/alokagrawal17/?originalSubdomain=in",
      },
      {
        id: 7,
        name: "Mr. Deepak Balachandran",
        designation:
          "Deepak is an entrepreneur who specialises in business strategy, strategic messaging, and interaction design for startups and small businesses. He guides founders through the implementation process and assists them in developing their strategy and mission statements. Deepak now serves as co-principal of Syntex Natural Resource Group, a group of senior professionals working to launch a significant decarbonization initiative located in the United States, where he directs the marketing, IT, and technological innovation initiatives. He serves as a mentor for K-Innovation DISC's by Youth with Disabilities programme and is a member of the NIT Calicut Innovation Council.",
        image: "mentor7",
        link: "https://www.linkedin.com/in/deepakbalachandran/?originalSubdomain=in",
      },
      {
        id: 8,
        name: "Dr. C Venugopal",
        designation:
          "Author of Books UNCHAINED and BYOB - Be Your Own Boss. With 4.5 decades of experience working in leadership positions on various change management projects in the corporate sector, including Business Process Reengineering, ERP deployment, TQM, etc. Krysalis Consultancy Service Private Ltd. is a boutique management consulting firm that was cofounded by him. He has served as MD & CEO of Krysalis for the past 18 years while advising with hundreds of big and small businesses on a variety of strategic and operational projects.",
        image: "mentor6",
        link: "https://www.linkedin.com/in/cvenugopal/?originalSubdomain=in",
      },
      {
        id: 9,
        name: "Mr. Bimal Nair",
        designation:
          "As a management and marketing expert, Bimal analyses customer, market, and other organisational data to assist firms in enhancing their performance. He has experience with both old and new economy brands and can combine the best of both worlds in the solution he develops. His experience spans a variety of industries, including healthcare, media, FMCG, retail, telecom, technology, banking, travel, and food and beverage. He has a track record of success as a media strategist and has achieved numerous firsts. Bimal works with iCreate in Ahmedabad and CIE (IIM, Ahmedabad) as a major mentor for start-ups. By imparting his expertise and experience to the marketers of the future, he is dedicated to fostering a brighter tomorrow.",
        image: "mentor9",
        link: "https://in.linkedin.com/in/bimalnair",
      },
    ],
    partners: [
      {
        id: 1,
        image: "imk",
        title: "",
      },
      {
        id: 2,
        image: "tie",
        title: "",
      },
      // {
      //   id: 3,
      //   image: "ksidc",
      //   title: "",
      // },
      {
        id: 4,
        image: "kase",
        title: "",
      },
    ],
  },

  {
    id: 2,
    title: "ARISE",
    titleLink: "https://kied.info/arise-3/",
    externalLink: true,
    location: "Kochi",
    startDate: "06-September-2022",
    status: "Ongoing",
    // endDate: "September-08-2022",
    // startTime: "10.00 AM",
    participants: "MSME / Small Business Entrepreneurs (Agriculture)",
    description:
      "Department of Industries and Commerce envisages to promote entrepreneurship in Agro food processing industry and Agro business through an innovative Industry Incubation Program –ARISE (Agro Incubation for Sustainable Entrepreneurship).",
    cardDescription:
      "Department of Industries and Commerce envisages to promote entrepreneurship in Agro food processing industry and Agro business through an innovative Industry Incubation Program –ARISE (Agro Incubation for Sustainable Entrepreneurship).",
    image: "arise",
    listingImage: "arise",
    dateDetails: "Completed",
    mainDescription:
      "Subhiksha Keralam is an initiative of Government of Kerala for achieving self-sufficiency in food production across the state.  As part of that, Department of Industries and Commerce envisages to promote entrepreneurship in Agro food processing industry and Agro business through an innovative Industry Incubation Program –ARISE (Agro Incubation for Sustainable Entrepreneurship)",
    offerings: [
      {
        id: 2,
        // image: "offering-seed",
        description: " Technology Infusion",
      },
      {
        id: 3,
        image: "offering-growth",
        description: "New Product / Market Development ",
      },
      {
        id: 4,
        image: "offering-mentor",
        description: " Improved value addition",
      },
      {
        id: 5,
        image: "offering-cloud",
        description: "Mentoring and Handholding",
      },
      {
        id: 6,
        image: "offering-training",
        description: " Linking with AGRO incubator",
      },
      {
        id: 7,
        image: "offering-training",
        description: " Business growth and stability",
      },
    ],
    objectives: [
      {
        id: 1,
        objective:
          "Encourage new enterprise creation and scaling up existing businesses.",
      },
      {
        id: 2,
        objective:
          "Introduce new and diversified technologies and value addition methods",
      },
      {
        id: 3,
        objective:
          "Create an ecosystem for entrepreneurs,by putting them in touch with subject experts ",
      },
    ],
    eligibilities: {
      listing: [
        {
          id: 1,
          eligibility: "Individuals or entity from the state of Kerala",
        },
        {
          id: 2,
          eligibility: " Business/enterprise in Agriculture sector",
        },
      ],
      sectorFocus: "Business based on Agriculture",
    },

    structures: [
      {
        id: 1,
        structure: "Inspiration",
      },
      {
        id: 2,
        structure: "Immersion",
      },
      {
        id: 3,
        structure: "Assessment",
      },
      {
        id: 4,
        structure: "Establishing",
      },
    ],
    fees: [
      {
        id: 1,
        fee: "Nominal Fee",
      },
    ],
    mentors: [
      {
        id: 1,
        name: "Name",
        designation: "Designation",
        image: "news-demo-image",
      },
      {
        id: 2,
        name: "Name",
        designation: "Designation",
        image: "news-demo-image",
      },
      {
        id: 3,
        name: "Name",
        designation: "Designation",
        image: "news-demo-image",
      },
      {
        id: 4,
        name: "Name",
        designation: "Designation",
        image: "news-demo-image",
      },
    ],
    partners: [
      {
        id: 1,
        image: "imk",
        title: "",
      },
      {
        id: 2,
        image: "tie",
        title: "",
      },
      {
        id: 3,
        image: "ksidc",
        title: "",
      },
    ],
    reportHeading: "ARISE Program Stages",
    fileLink: "arise.pdf",
    gallery: [
      {
        id: 1,
        image: "arise1",
      },
      {
        id: 2,
        image: "arise2",
      },
      {
        id: 3,
        image: "arise3",
      },
      {
        id: 4,
        image: "arise4",
      },
      {
        id: 5,
        image: "arise5",
      },
      {
        id: 6,
        image: "arise6",
      },
    ],
    subHeading1: {
      heading: "Inspiration Stage",
      path: "",
      status: "Completed",
      description: [
        {
          id: 1,
          point:
            "Invite call for prospective entrepreneur who are interested in Agri Business Sector",
        },
        {
          id: 2,
          point:
            "Will provide online training in various Agri business sector possibilities, technologies, value added services, case studies, sucess stories etc.",
        },
      ],
      gallery: [
        {
          id: 1,
          image: "arise1",
        },
        {
          id: 2,
          image: "arise5",
        },
        {
          id: 3,
          image: "arise3",
        },
        {
          id: 4,
          image: "arise4",
        },
      ],
      mainData: [
        {
          id: 1,
          district: "Ernakulam",
          date: "23.02.2021",
          status: "Completed",
          participantNo: "62",
          mode: "In Campus",
        },
        {
          id: 2,
          district: "Alapuzha",
          date: "18.03.2021",
          status: "Completed",
          participantNo: "69",
          mode: "In Campus",
        },
        {
          id: 3,
          district: "Kollam",
          date: "06.03.2021",
          status: "Completed",
          participantNo: "78",
          mode: "In Campus",
        },
        {
          id: 4,
          district: "Kozhikode",
          date: "23.03.2021",
          status: "Completed",
          participantNo: "62",
          mode: "In Campus",
        },
        {
          id: 5,
          district: "Thiruvananthapuram",
          date: "19.06.2021 ",
          status: "Completed",
          participantNo: "100 ",
          mode: "Online",
        },
        {
          id: 6,
          district: "Pathanamthitta",
          date: "23.06.2021 ",
          status: "Completed",
          participantNo: "100",
          mode: "Online",
        },
        {
          id: 7,
          district: "Malappuram",
          date: "26.06.2021 ",
          status: "Completed",
          participantNo: "98",
          mode: "Online",
        },
        {
          id: 8,
          district: "Wayanad",
          date: "01.07.2021 ",
          status: "Completed",
          participantNo: "79",
          mode: "Online",
        },
        {
          id: 9,
          district: "Thrissur",
          date: "06.07.2021 ",
          status: "Completed",
          participantNo: "89",
          mode: "Online",
        },
        {
          id: 10,
          district: "Kannur",
          date: "08.07.2021 ",
          status: "Completed",
          participantNo: "133",
          mode: "Online",
        },
        {
          id: 11,
          district: "Palakkad",
          date: "12.07.2021 ",
          status: "Completed",
          participantNo: "84",
          mode: "Online",
        },
        {
          id: 12,
          district: "Kottayam",
          date: "16.07.2021 ",
          status: "Completed",
          participantNo: "101",
          mode: "Online",
        },
        {
          id: 13,
          district: "Idukki",
          date: "22.07.2021 ",
          status: "Completed",
          participantNo: "155",
          mode: "Online",
        },
        {
          id: 14,
          district: "Kasargode",
          date: "27.07.2021",
          status: "Completed",
          participantNo: "232",
          mode: "Online",
        },
      ],
    },
    subHeading2: {
      heading: "Immersion Stage",
      status: "Completed",
      description: [
        {
          id: 1,
          point:
            "Application will invite from participants who completed Inspiration stage.",
        },
        {
          id: 2,
          point: "Short list applicants with their Ideas and Business plan.",
        },
        {
          id: 3,
          point:
            "Will give practical exposure to the technical and commercial aspects of the business through partner research organization CFC (Common facility centre)",
        },
        {
          id: 4,
          point:
            "Training on technology, machineries, production, marketing etc.",
        },
      ],
      gallery: [
        { id: 1, image: "imm1" },
        { id: 2, image: "imm2" },
        { id: 3, image: "imm3" },
        { id: 4, image: "imm4" },
      ],
      mainData: [
        {
          id: 1,
          district: "Fish",
          date: "30.06.2021 ",
          status: "Completed",
          participantNo: "100 ",
          mode: "Online",
        },
        {
          id: 2,
          district: "Fruits & Vegetables ",
          date: "14.07.2021 ",
          status: "Completed",
          participantNo: "121",
          mode: "Online",
        },
        {
          id: 3,
          district: "Milk",
          date: "31.07.2021 ",
          status: "Completed",
          participantNo: "276",
          mode: "Online",
        },
        {
          id: 4,
          district: "Meat",
          date: "11.08.2021 ",
          status: "Completed",
          participantNo: "119",
          mode: "Online",
        },
        {
          id: 5,
          district: "Mushroom",
          date: "27.08.2021  ",
          status: "Completed",
          participantNo: "133 ",
          mode: "Online",
        },
        {
          id: 6,
          district: "Spices",
          date: "08.09.2021  ",
          status: "Completed",
          participantNo: "204",
          mode: "Online",
        },
        {
          id: 7,
          district: "Egg",
          date: "28.09.2021  ",
          status: "Completed",
          participantNo: "131",
          mode: "Online",
        },
        {
          id: 8,
          district: "Tuber Crops ",
          date: "08.10.2021 ",
          status: "Completed",
          participantNo: "190",
          mode: "Online",
        },
        {
          id: 9,
          district: "Coconut",
          date: "27.10.2021 ",
          status: "Completed",
          participantNo: "190",
          mode: "Online",
        },
        {
          id: 10,
          district: "Immersion Offline Training ",
          date: "30.10.2021 ",
          status: "Completed",
          participantNo: "43",
          mode: "In Campus",
        },
      ],
    },
    subHeading3: {
      heading: "Assessment Stage",
      status: "Ongoing",
      description: [
        {
          id: 1,
          point:
            "Opportunity to entrepreneur to conduct small scale test production using the common facility centres (CFC)",
        },
        {
          id: 2,
          point: "Test of various business models, products, market etc.",
        },
        {
          id: 3,
          point: "Incubation and infrastructure support",
        },
        {
          id: 4,
          point: "Training, Mentoring and Support services ",
        },
      ],
      gallery: [
        { id: 1, image: "stag1" },
        { id: 2, image: "stag2" },
        { id: 3, image: "stag3" },
        { id: 4, image: "stag4" },
      ],
      mainData: [
        {
          id: 1,
          title:
            "Entrepreneurship Practice Workshop in Food, Fruits & Vegetables,  Grains  at Agri Business Incubator, Thrissur  ",
          participants: "Aspiring and Existing Entrepreneurs ",
          batchSize: "15",
          duration: "5",
          from: "15.11.22 ",
          to: "20.11.22 ",
          amount: "1,60,000 ",
          venue: "Thrissur",
          mode: "Non-Residential   ",
          fees: "1000 + Tax ",
          status: "Completed",
        },
        {
          id: 2,
          title:
            "Entrepreneurship Practice Workshop in Meet Products KVASU, Thrissur  ",
          participants: "Aspiring and Existing Entrepreneurs ",
          batchSize: "20",
          duration: "7",
          from: "14.12.22  ",
          to: "21.12.22 ",
          amount: "2,99,000 ",
          venue: "Thrissur",
          mode: "Non-Residential   ",
          fees: "1000 + Tax ",
          status: "Completed",
        },
        {
          id: 3,
          title:
            "Entrepreneurship Practice Workshop in Tuber Crops at CTCRI Trivandrum  ",
          participants: "Aspiring and Existing Entrepreneurs ",
          batchSize: "20",
          duration: "7 ",
          from: "03.01.23 ",
          to: "11.01.23 ",
          amount: "3,50,000 ",
          venue: "Trivandrum",
          mode: "Residential   ",
          fees: "1500 + Tax ",
          status: "Completed",
        },
        {
          id: 4,
          title:
            "Entrepreneurship Practice Workshop in Food Processing at NIIST, Trivandrum ",
          participants: "Aspiring and Existing Entrepreneurs ",
          batchSize: "20",
          duration: "8",
          from: "18.01.23",
          to: "25.01.23",
          amount: "7,80,000 ",
          venue: "Trivandrum",
          mode: "Residential   ",
          fees: "1500 + Tax ",
          status: "Completed",
        },
      ],
    },
    subHeading4: {
      heading: "Establishing Stage",
      status: "Ongoing",
      description: [
        {
          id: 1,
          point: "Establishment or full fledged enterprise",
        },
        {
          id: 2,
          point: "Incubation and infrastructure support",
        },
        {
          id: 3,
          point:
            "Link with Govt programs and schemes for support and handholding",
        },
        {
          id: 4,
          point:
            "Review, Mentoring, Legal and procedureal advises for establishing business by industry expert panel - Technical  advisory group (TAG)",
        },
        {
          id: 5,
          point:
            "Connect the Entreprenur with District Industries Centre (DIC) to expand business",
        },
        {
          id: 6,
          point: "Training, Advisory services, Incubation",
        },
      ],
      gallery: [
        { id: 1, image: "" },
        { id: 2, image: "" },
      ],
      mainData: [
        {
          id: 1,
          district: "Ernakulam",
          date: "23.02.2021",
          status: "Completed",
          participantNo: "62",
          mode: "In Campus",
        },
        {
          id: 2,
          district: "Alapuzha",
          date: "18.03.2021",
          status: "Completed",
          participantNo: "69",
          mode: "In Campus",
        },
        {
          id: 3,
          district: "Kollam",
          date: "06.03.2021",
          status: "Completed",
          participantNo: "78",
          mode: "In Campus",
        },
        {
          id: 4,
          district: "Kozhikode",
          date: "23.03.2021",
          status: "Completed",
          participantNo: "62",
          mode: "In Campus",
        },
      ],
    },
  },
  {
    id: 3,
    title: "BUSINESS ACCELERATION PROGRAM",
    titleLink: "",
    externalLink: true,
    location: "Kochi",
    startDate: "19-March-2021",
    status: "Completed",
    endDate: "To - 02-May-2021",
    // startTime: "10.00 AM",
    participants: "MSME Entrepreneurs (Turnover above 50 Lakhs)",
    description:
      "Business Acceleration Program is being launched to impart the necessary leadership skills and management training for micro, small and medium entrepreneurs (MSMEs)",
    cardDescription:
      "Business Acceleration Program is being launched to impart the necessary leadership skills and management training for micro, small and medium entrepreneurs (MSMEs)",
    dateDetails: "Ongoing",
    mainDescription:
      "Business Acceleration Program is being launched to impart the necessary leadership skills and management training for micro, small and medium entrepreneurs (MSMEs)  with a minimum turnover of 50 lakhs and are striving to grow. KIED is rolling out this program in online mode in collaboration with IIMK LIVE, Indian Institute of Management, Kozhikode the leading management institute in Kerala.",
    image: "bap",
    listingImage: "bap",
    offerings: [
      {
        id: 2,
        // image: "offering-seed",
        description: "Raise Finance",
      },
      {
        id: 3,
        // image: "offering-growth",
        description: "Improve business KPI’s",
      },
      {
        id: 4,
        // image: "offering-mentor",
        description: "Efficiency & Excellence ",
      },
      {
        id: 5,
        // image: "offering-cloud",
        description: "Business Growth & Stability ",
      },
      {
        id: 6,
        // image: "offering-training",
        description: "Business Knowledge ",
      },
    ],
    objectives: [
      {
        id: 1,
        objective:
          "To equip entrepreneurs to accelerate their business to next level.",
      },
      {
        id: 2,
        objective: "To give an exposure to sales and marketing strategies",
      },
      {
        id: 3,
        objective: "Effective financial planning and cash flow management",
      },
      {
        id: 4,
        objective:
          "Enhance the sales negotiation, lead generation and sale closure skills.",
      },
      {
        id: 5,
        objective:
          "To manage the team effectively and to build leadership skills",
      },
    ],
    eligibilities: {
      listing: [
        {
          id: 1,
          eligibility:
            "MSME Enterpreneur in kerala with turnover above 50 lakhs in the last financial year",
        },
        // {
        //   id: 2,
        //   eligibility: "Turnover above 50 lakhs in the last financial year",
        // },
      ],
      sectorFocus: "All sectors can apply",
      duration: " 40 Hrs",
      batchSize: " 60",
    },

    structures: [
      {
        id: 1,
        structure: "Business Acceleration and scale Up",
      },
      {
        id: 2,
        structure: "Crafting the Marketing strategy",
      },
      {
        id: 3,
        structure: "Branding and marketing communication",
      },
      {
        id: 4,
        structure: "Sales and channel management",
      },
      {
        id: 5,
        structure: "Entrepreneurial finance",
      },
      {
        id: 6,
        structure: "Leading Self and the Team",
      },
      {
        id: 7,
        structure: "Blue Ocean Strategy",
      },
      {
        id: 8,
        structure: "System and ERP’s for SME’S",
      },
      {
        id: 9,
        structure: "Business Simulation",
      },
    ],
    fees: [
      {
        id: 1,
        fee: "Free of cost",
      },
    ],
    mentors: [
      {
        id: 1,
        name: "Name",
        designation: "Designation",
        image: "news-demo-image",
      },
      {
        id: 2,
        name: "Name",
        designation: "Designation",
        image: "news-demo-image",
      },
      {
        id: 3,
        name: "Name",
        designation: "Designation",
        image: "news-demo-image",
      },
      {
        id: 4,
        name: "Name",
        designation: "Designation",
        image: "news-demo-image",
      },
    ],
    partners: [
      {
        id: 1,
        image: "imk",
        title: "",
      },
      {
        id: 2,
        image: "tie",
        title: "",
      },
      {
        id: 3,
        image: "ksidc",
        title: "",
      },
    ],
    reportHeading: "Program Details",
    reportHeading2: "Mentors",

    fileLink: "bap.pdf",
    gallery: [
      {
        id: 1,
        image: "bap1",
      },
      {
        id: 2,
        image: "bap2",
      },
      {
        id: 3,
        image: "bap3",
      },
    ],
    subHeading1: {
      // heading: "Inspiration Stage",
      subheading:
        "The program was conducted in association with Indian Institute of Management, Kozhikode from 19.05.2021 to 06.05.2021 using Cisco WebEx and LMS Platform. The following subjects were covered in the programme. ",
      mainData: [
        {
          id: 1,
          date: "19th March",
          days: "Friday",
          hrs: "2",
          timing: "7PM to 9PM",
          name: "Business Acceleration and Scale Up",
        },
        {
          id: 2,
          date: "20th March",
          days: "Saturday",
          hrs: "2",
          timing: "7PM to 9PM",
          name: "Business Acceleration and Scale Up",
        },
        {
          id: 3,
          date: "21st March",
          days: "Sunday",
          hrs: "2",
          timing: "7PM to 9PM",
          name: "Business Acceleration and Scale Up",
        },
        {
          id: 4,
          date: "26th March",
          days: "Friday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Crafting the Marketing Strategy",
        },
        {
          id: 5,
          date: "27th March",
          days: "Saturday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Branding and Marketing Communication",
        },
        {
          id: 6,
          date: "28th March",
          days: "Sunday",
          hrs: "2",
          timing: "7PM to 9PM",
          name: "Sales and Channel Management",
        },
        {
          id: 7,
          date: "3rd April",
          days: "Saturday",
          hrs: "2",
          timing: "6PM to 9PM",
          name: "Sales and Channel Management",
        },
        {
          id: 8,
          date: "4th April",
          days: "Sunday",
          hrs: "2",
          timing: "7PM to 9PM",
          name: "Sales and Channel Management",
        },
        {
          id: 9,
          date: "9th April",
          days: "Friday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Sales and Channel Management",
        },
        {
          id: 10,
          date: "10th April",
          days: "Saturday",
          hrs: "2",
          timing: "7PM to 9PM",
          name: "Entrepreneurial Finance",
        },
        {
          id: 11,
          date: "11th April",
          days: "Sunday",
          hrs: "2",
          timing: "7PM to 9PM",
          name: "Entrepreneurial Finance",
        },
        {
          id: 12,
          date: "16th April",
          days: "Friday",
          hrs: "2",
          timing: "7PM to 9PM",
          name: "Entrepreneurial Finance",
        },
        {
          id: 13,
          date: "17th April",
          days: "Saturday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Entrepreneurial Finance",
        },
        {
          id: 14,
          date: "18th April",
          days: "Sunday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Operations Management",
        },
        {
          id: 15,
          date: "23rd April",
          days: "Friday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Leading Self and the Team",
        },
        {
          id: 16,
          date: "24th April",
          days: "Saturday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Leading Self and the Team",
        },
        {
          id: 17,
          date: "25th April",
          days: "Sunday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Blue Ocean Strategy",
        },
        {
          id: 18,
          date: "30th April",
          days: "Friday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Systems and ERP for SME's",
        },
        {
          id: 19,
          date: "1st MAy",
          days: "Saturday",
          hrs: "3",
          timing: "6PM to 9PM",
          name: "Business Simulation",
        },
        {
          id: 20,
          date: "Reserved Day",
          days: "",
          hrs: "",
          timing: "",
          name: "",
        },
      ],
    },
    subHeading2: {
      // heading: "Inspiration Stage",
      subheading:
        "The program was conducted in association with Indian Institute of Management, Kozhikode from 19.05.2021 to 06.05.2021 using Cisco WebEx and LMS Platform. The following subjects were covered in the programme. ",
      mainData: [
        {
          id: 1,
          name: "Prof. Sankalp Pratap",
          designation:
            "Faculty (Innovation & Entrepreneurship) :IIT Jodhpur, IIM-Trichy, Alumnus: IIM-c",
        },
        {
          id: 2,
          name: "Prof Keyoor Purani",
          designation:
            "Professor - IIM, Alumnus: Mudra Institute of Communication and Advertising (MICA)",
        },
        {
          id: 3,
          name: "Mr Bimal Nair",
          designation: "CEC - Ossicles Consulting",
        },
        {
          id: 4,
          name: "Prof G. Sreedhar",
          designation: "Professor - IIMK, Alumnus : IRMA",
        },
        {
          id: 5,
          name: "Prof. Aishwarya Ramasundaram",
          designation: "Asst: Professor - IIMK, Alumna : IIM-B",
        },
        {
          id: 6,
          name: "Mr. Amit Agarwal",
          designation:
            "Author: The Ultimate Sales Accelerator : Alumnus- IIM-A, IIT-V",
        },
        {
          id: 7,
          name: "Prof. Rajesh S Upadhyayula",
          designation: "Professor - IIMK : Alumnus IIM-A",
        },
        {
          id: 8,
          name: "Prof Kapil Verma",
          designation:
            "Asst Professor -IIMK, Alumnus: IIM-C, Nanyang Business School",
        },
        {
          id: 9,
          name: "Prof KK Ramesh",
          designation: "Visiting Professor - IIMK, Chartered Accountant",
        },
        {
          id: 10,
          name: "Prof. Abhilash S Nair",
          designation: "Associate Professor - IIMK, Alumnus IIT-B",
        },
        {
          id: 11,
          name: "Mr. C Venugopal",
          designation: "MD CEO: Krysalis Consulting, Alumnus: IIT-Kharagpur",
        },
        {
          id: 12,
          name: "Prof Shahid Abdulla",
          designation:
            "Associate Professor: IIMK, Alumnus: Indian Institute of Science (IIS)",
        },
        {
          id: 13,
          name: "Mr Ajay Kelkar",
          designation: "Co-Founder Hansa Cequity and former CMO of HDFC Bank",
        },
      ],
    },
  },
  // {
  //   id: 2,
  //   title: "ACCELERATION",
  //   titleLink: "#",
  //   location: "EDC Ground",
  //   startDate: "September-06-2022",
  //   endDate: "September-08-2022",
  //   startTime: "10.00 AM",
  //   participants: "College Students",
  //   description:
  //     "Entrepreneurial Culture amongst youth and equip them with thec skills, technique and confidence to act as tourch-bearers of enterprise for the new generation.",
  //   link: "#",
  // },
  // {
  //   id: 3,
  //   title: "BUSINESS GROWTH PROGRAM",
  //   titleLink: "#",
  //   location: "EDC Ground",
  //   startDate: "September-06-2022",
  //   endDate: "September-08-2022",
  //   startTime: "10.00 AM",
  //   participants: "College Students",
  //   description:
  //     "Entrepreneurial Culture amongst youth and equip them with thec skills, technique and confidence to act as tourch-bearers of enterprise for the new generation.",
  //   link: "#",
  // },
];

export const inovationEventData = [
  {
    id: 1,
    title: "Design Thinking",
    location: "Online",
    startDate: "23/03/2022",
    endDate: "31/03/2022",
    startTime: "10.00 AM",
    status: "Completed",
    endTime: "6.00pm",
    mainDescription:
      "Seven days Workshop for Aspiring Entrepreneurs who wish to start a new business was conducted  in online mode using ZOOM platform from 23/03/2022. Objective of the programme is to  formulate business ideas through the Human Centered Approach.",
    cardDescription:
      "Seven days Workshop for Aspiring Entrepreneurs who wish to start a new business was conducted  in online mode using ZOOM platform from 23/03/2022.",
    duration: "7 days",
    CategoryOfParticipants:
      "Aspiring Entrepreneurs who wish to start a new business",
    NoOfParticipants: "25",
    listheading: "",
    listitem: [],
    link: "/events/",
    mainLink: "/inevents/",
    listingImage: "designthinking2",
    images: [
      {
        id: 1,
        image: "designthinking1",
      },
      {
        id: 2,
        image: "designthinking2",
      },
      {
        id: 3,
        image: "designthinking3",
      },
      {
        id: 4,
        image: "designthinking4",
      },
    ],
  },
  {
    id: 2,
    title: "Webinar on Branding",
    location: "Online",
    startDate: "23/04/2022",
    startTime: "10.00 AM",
    endTime: "6.00 PM",
    link: "/events/",
    mainLink: "/inevents/",
    status: "Completed",
    mainDescription:
      "A Webinar on Branding was conducted in online mode using ZOOM platform on 23/04/2022.Around 108 participants have joined our one day program. ",
    cardDescription:
      "A Webinar on Branding was conducted in online mode using ZOOM platform on 23/04/2022.Around 108 participants have joined our one day program. ",
    duration: "1 day",
    CategoryOfParticipants: "Aspiring and Existing Entrepreneurs",
    NoOfParticipants: "108",
    listheading: "",
    listitem: [],
    listingImage: "webinarbranding",
    images: [
      {
        id: 1,
        image: "webinarbranding",
      },
      {
        id: 3,
        image: "webinarbranding3",
      },
      {
        id: 4,
        image: "webinarbranding2",
      },
    ],
  },

  {
    id: 3,
    title: "Intellectual Property Rights",
    location: "Online",
    startDate: "07/04/2022",
    startTime: "10.00 AM",
    status: "Completed",
    link: "/events/",
    mainLink: "/inevents/",
    mainDescription:
      "A Webinar on Branding was conducted in online mode using ZOOM platform on 23/04/2022. Around 94 participants have joined our one day program.",
    cardDescription:
      "A Webinar on Branding was conducted in online mode using ZOOM platform on 23/04/2022. Around 94 participants have joined our one day program.",
    duration: "1 day",
    CategoryOfParticipants: "Aspiring and Existing Entrepreneurs",
    NoOfParticipants: "94",
    listheading: "",
    listitem: [],
    listingImage: "intellectualproperty",
    images: [
      {
        id: 1,
        image: "intellectualproperty",
      },
      {
        id: 2,
        image: "intellectualproperty2",
      },
      {
        id: 3,
        image: "intellectualproperty4",
      },
      {
        id: 4,
        image: "intellectualproperty4",
      },
    ],
  },
  {
    id: 4,
    title: "E-Commerce Opportunities",
    location: "Online",
    startDate: "01/10/2022",
    startTime: "10.00 AM",
    status: "Completed",
    link: "/events/",
    mainLink: "/inevents/",
    mainDescription:
      "A Webinar on E-Commerce Opportunities was conducted for Entrepreneurs on 01/10/2022 through online mode (Zoom). The session was handled by Shri. Abhilash Pillai, Senior Trainer Flipkart. 221 participants from different districts of Kerala participated in the session.",
    cardDescription:
      "A Webinar on E-Commerce Opportunities was conducted for Entrepreneurs on 01/10/2022 through online mode (Zoom). The session was handled by Shri. Abhilash Pillai, Senior Trainer Flipkart.",
    duration: "1 day",
    CategoryOfParticipants: "Aspiring and Existing Entrepreneurs",
    NoOfParticipants: "221",
    listheading: "",
    listitem: [],
    listingImage: "ecommerce",
    images: [
      {
        id: 1,
        image: "ecommerce",
      },

      {
        id: 2,
        image: "ecommerce2",
      },
      {
        id: 3,
        image: "ecommerce3",
      },
      {
        id: 4,
        image: "ecommerce0",
      },
    ],
  },
  {
    id: 5,
    title: "Kied Community Meetup",
    location: "KIED Campus Kochi",
    startDate: "25/06/2022",
    startTime: "10.00 AM",
    link: "/events/",
    mainLink: "/inevents/",
    status: "Completed",
    mainDescription:
      "Community Meetup 2022 was inaugurated by Shri. P.Rajeeve, Hon'ble Minister for Industries, Law & Coir on 25th June 2022. Principal Secretary, Industries Department Shri. Suman Billa gave the presidential address. As part of the Community Meetup 2022 a Product Exhibition was  organized and it was inaugurated by Smt. Seema Kannan, Hon’ble Kalamassery Municipal Chair Person. 40 MSME units selected from different districts of the state participated in this exhibition. Food processing, textiles, health & wellness, various types of services, cricket bat, leather bag, innovative building products, electronic hardware etc. were exhibited in the fair. Along with the product exhibition, sessions were organized on various areas such as market potential, branding, packaging, logistics management and e-commerce. More than 200 trainees who participated in various trainings of KIED participated in the community meetup.",
    cardDescription:
      "Community Meetup 2022 was inaugurated by Shri. P.Rajeeve, Hon'ble Minister for Industries, Law & Coir on 25th June 2022. Principal Secretary, Industries Department Shri. Suman Billa gave the presidential address.",
    duration: "1 day",
    CategoryOfParticipants: "Aspiring and Existing Entrepreneurs",
    NoOfParticipants: "200",
    listheading: "",
    listitem: [],
    listingImage: "kiedmeetup1",
    images: [
      {
        id: 1,
        image: "kiedmeetup1",
      },
      {
        id: 2,
        image: "kiedmeetup2",
      },
      {
        id: 3,
        image: "kiedmeetup3",
      },
      {
        id: 4,
        image: "kiedmeetup4",
      },
      {
        id: 5,
        image: "kiedmeetup5",
      },
      {
        id: 6,
        image: "kiedmeetup6",
      },
    ],
  },
  {
    id: 6,
    title: " Women Entrepreneurs",
    location: "KIED Campus Kochi",
    startDate: "23/06/2022",
    startTime: "10.00 AM",
    status: "Completed",
    link: "/events/",
    mainLink: "/inevents/",
    mainDescription:
      "One day training for Women Entrepreneurs was conducted by KIED in association with TiE Kerala, Kochi at KIED Campus on 23.06.2022",
    cardDescription:
      "One day training for Women Entrepreneurs was conducted by KIED in association with TiE Kerala, Kochi at KIED Campus on 23.06.2022",
    duration: "1 day",
    CategoryOfParticipants: "Aspiring and Existing Entrepreneurs",
    NoOfParticipants: "",
    listheading: "Topics Covered",
    listitem: [
      "How to create a business model canvas to simplify and structure the business.",
      "Understanding customer demand.",
      "To deliver an effective elevator/sales pitch.",
    ],
    listingImage: "womenenterpreners",
    images: [
      {
        id: 1,
        image: "womenenterpreners",
      },
      {
        id: 2,
        image: "womenenterpreners2",
      },
      {
        id: 3,
        image: "womenenterpreners3",
      },
      {
        id: 4,
        image: "womenenterpreners0",
      },
    ],
  },
  {
    id: 7,
    title: " Inauguration Of EDC",
    location: "",
    startDate: "21/03/2023",
    startTime: "10.00 AM",
    status: "Completed",
    link: "/events/",
    mainLink: "/inevents/",
    mainDescription:
      "On 21st March 2023, Sri. P. Rajeeve, Hon’ble Minister for Law, Industries and Coir inaugurated the KIED - Enterprise Development Centre (EDC) at Inkel Tower, Angamaly,Ernakulam. This centre has been established to provide entrepreneurs with personalized assistance for planning, starting, sustaining, reviving, and growing their innovative ventures. With a particular emphasis on developing distinct and innovative next-generation MSMEs and small businesses, the centre will support technology adoption, provide high-value opportunities, and apply knowledge systems in specific sectors. The initiative focuses on each business individually and tailors support to its unique needs. The Minister inaugurated the Incubation and Co Working Space, EDC Website and Business Growth Programme too, which are designed to enhance the growth of MSMEs in the state. The Business Growth Program&#39;s goals include supporting MSMEs in expanding, achieving financial stability, and fostering innovation to make them competitive and growth-oriented. Other objectives include improving business KPIs, generating employment opportunities, and providing the necessary business guidance through mentorship sessions and training programs. This program is open to MSME units across all industries and is sector-agnostic.",
    cardDescription:
      "On 21st March 2023, Sri. P. Rajeeve, Hon’ble Minister for Law, Industries and Coir inaugurated the KIED - Enterprise Development Centre (EDC) at Inkel Tower,",
    duration: "1 day",
    // CategoryOfParticipants: "",
    NoOfParticipants: "",
    listheading: "Topics Covered",
    listitem: ["", "To deliver an effective elevator/sales pitch."],
    listingImage: "womenenterpreners",
    images: [
      {
        id: 1,
        image: "womenenterpreners",
      },
    ],
  },
];
