import React from "react";

import NewProgramHero from "./sections/hero/newPrograms.hero";
import NewProgramOutcome from "./sections/ExpectedOutcome/newProgram.outcome";
import NewProgramDetails from "./sections/details/newprogram.details";
import NewProgramMentors from "./sections/mentors/newProgram.mentors";
import NewProgramSupportingPartners from "./sections/supportingPartners/newProgram.supporting";
import NewProgramFaq from "./sections/faq/newProgram.faq";
import NewProgramsReport from "./sections/reports/newprograms.reports";
import "./new.programs.styles.css";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { inovationProgramData } from "../inovation/sections/inovationData";
import ProgramsBanner from "./sections/banner/programs.banner";
import NewProgrmGallery from "./sections/gallery/newProgram.gallery";
import AriseReport from "./sections/ariseReport/arise.report";

function NewProgramsPage() {
  let props = useOutletContext()
  const param = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    let selected = inovationProgramData.filter((item) => {
      console.log("item", typeof item.id);
      console.log(typeof param.id);
      return item.id == param.id;
    });
    setData(selected[0]);
  }, [param.id]);
  console.log(data);
  console.log(data.status);
  return (
    <div>
      <ProgramsBanner data={data} {...props}/>
      <NewProgramHero data={data} />
      <NewProgramOutcome data={data} />

      <NewProgramDetails data={data} />
      {data?.status === "Upcoming" ? (
        <>
          <NewProgramMentors data={data} />
          <NewProgramSupportingPartners data={data} />
          <NewProgramFaq />
        </>
      ) : data?.title === "ARISE" ? (
        <>
          <AriseReport data={data} />
        </>
      ) : (
        <>
          <NewProgramsReport data={data} />
          <NewProgrmGallery data={data} />
        </>
      )}
    </div>
  );
}

export default NewProgramsPage;
