export const offerings = [
  // {
  //   id: 1,
  //   image: "offering-investor",
  //   name: "Investor Connects",
  // },
  {
    id: 2,
    image: "offering-seed",
    name: "Raise Finance",
  },
  {
    id: 3,
    image: "offering-growth",
    name: "Improve business KPI’s",
  },
  {
    id: 4,
    image: "offering-mentor",
    name: "Efficiency & Excellence ",
  },
  {
    id: 5,
    image: "offering-cloud",
    name: "Business Growth & Stability ",
  },
  {
    id: 6,
    image: "offering-training",
    name: "Business Knowledge ",
  },
];
export const mentors = [
  {
    id: 1,
    name: "Name",
    designation: "Designation",
    image: "news-demo-image",
  },
  {
    id: 2,
    name: "Name",
    designation: "Designation",
    image: "news-demo-image",
  },
  {
    id: 3,
    name: "Name",
    designation: "Designation",
    image: "news-demo-image",
  },
  {
    id: 4,
    name: "Name",
    designation: "Designation",
    image: "news-demo-image",
  },
  {
    id: 5,
    name: "Name",
    designation: "Designation",
    image: "news-demo-image",
  },
  {
    id: 6,
    name: "Name",
    designation: "Designation",
    image: "news-demo-image",
  },
];
export const partners = [
  {
    id: 1,
    image: "imk",
    title: "",
  },
  {
    id: 2,
    image: "tie",
    title: "",
  },
  {
    id: 3,
    image: "ksidc",
    title: "",
  },
];
export const structure = [
  {
    id: 1,
    point: "Induction program",
  },
  {
    id: 2,
    point: "Enterpreneurship Development Program",
  },
  {
    id: 3,
    point: "Coustomer Validation Excercise",
  },
  {
    id: 4,
    point: "Induction program",
  },
  {
    id: 5,
    point: "Enterpreneurship Development Program",
  },
  {
    id: 6,
    point: "Coustomer Validation Excercise",
  },
];
