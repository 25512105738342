import React from "react";
import EdcRegistrationPage from "./sections/EdcRegistration.page";
import SmallBanner from "../../components/small.banner/small.banner";
import EDCRegistration from "./sections/edc-registration";
import { getEDC } from "../../actions/api.functions";

function EdcRegistration(props) {

  return (
    <div>
      <SmallBanner className="m-0" title=" Online Registration " />
      {/* <EdcRegistrationPage /> */}
      <EDCRegistration />
    </div>
  );
}

export default EdcRegistration;
