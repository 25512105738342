import React from 'react'
import DashInfo from '../../../../components/dashinfo/dashInfo'
import KiedButton from '../../../../components/buttons/button/button.component'

function ExpertDataForm({handleEdit, handlepopupEdit, ...props}) {
  return (
    <>
                  <div>
                    <div className="">
                      <DashInfo title="Gender" value={props.formdata.gender ?? ""} />
                      <DashInfo
                        title="Highest Qualification"
                        value={props.formdata.highest_educational_qualification}
                      />
                      <DashInfo
                        title="Website/Online Profile of the Applicant e.g., LinkedIn"
                        value={props.formdata.online_profile ?? ""}
                      />
                      <DashInfo
                        title="Area of Professional Expertise"
                        value={props.formdata.area_expertise}
                      />
                      {props.formdata.area_expertise === "Others" && (
                        <DashInfo
                          title="Specify the Area of Your Expertise"
                          value={props.formdata.expspecify ?? ""}
                        />
                      )}
                      <DashInfo
                        title="Credentials to prove Qualification (.pdf, .docx, .jpg format files are allowed)"
                        value={props.formdata.qualification_proof ?? ""}
                        file
                      />
                      <DashInfo
                        title="Credentials to prove Experience (.pdf, .docx, .jpg format files are allowed)"
                        value={props.formdata.experience_proof ?? ""}
                        file
                      />
                      <DashInfo
                        title="Details of other Empanelment at other organizations (.pdf, .docx, .jpg format files are allowed)"
                        value={props.formdata.other_empanelment ?? ""}
                        file
                      />

                      {/* {error.proof && (
                        <p id="proof" className="ml-3 error">
                          Unsupported file type! Please select a .pdf or .docx
                          file
                        </p>
                      )} */}
                      <br />

                      <div className="d-flex justify-content-center pt-5 pb-5">
                        <KiedButton
                          onClick={() => handleEdit()}
                          primary
                          rounded
                          className="mx-3"
                          type="submit"
                          content={
                            <div className="registerbtn d-flex justify-content-center">
                              Edit
                            </div>
                          }
                        />
                        <KiedButton
                          onClick={() => handlepopupEdit()}
                          primary
                          className="mx-3"
                          rounded
                          content={
                            <div className="registerbtn d-flex justify-content-center">
                              Home
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
  )
}

export default ExpertDataForm