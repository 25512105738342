import React from "react";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";

function RadioButtonDash({ options = [], onChange = () => {}, ...props }) {
  const checkchange = (check) => {
    if (check == props.checked) {
      return true;
    }
    return false;
  };
  let width = useScreenBreakPoint();
  return (
    <>
      <div
        className={` w-100 align-items-center ${
          width === "sm" || width === "xs" ? "" : "d-flex"
        }`}
      >
        <span
          className={` d-flex align-items-center justify-content-between  ${
            width === "sm" || width === "xs" ? "col-12" : "col-5 p-0 mr-1"
          }`}
        >
          <span
            className={
              width === "sm" || width === "xs"
                ? `p-0 formstyle d-flex flex-row justify-content-start mb-2 ${
                    props.error ? "text-danger" : ""
                  }`
                : `mr-1 formstyle d-flex flex-row justify-content-start ${
                    props.error ? "text-danger" : ""
                  }`
            }
          >
            {props.label}
            {/* {props.required && <span style={{ color: "red" }}>*</span>} */}
          </span>
          {width === "xs" || width === "sm" ? (
            ""
          ) : (
            <span className="formstyle pr-1">:</span>
          )}
        </span>
        <div className="d-flex col-7 w-100 ml-1 ">
          {options.map((opt) => (
            <div className="pr-3 d-flex align-items-center">
              <input
                onChange={onChange}
                name={props.name ?? "name"}
                className={`mr-2 ${props.required && "required"}`}
                type="radio"
                value={opt.value}
                checked={checkchange(opt.value)}
                defaultChecked={checkchange(opt.value)}
              />
              <label className="formstyle p-0">{opt.label}</label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default RadioButtonDash;
