import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import ProgramPartner from "../../../../components/program.partners/program.partners";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function NewProgramSupportingPartners(props) {
  const history = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"))
  const userRole = user?.role
  console.log(userRole)
  // const handleregister = () => {
  //   localStorage.setItem("next_path", "/department/inovation/registration");
  //   history(`/department/inovation/registration/${props.data.id}`);
  // };
  const param = useParams()
  const handleregister = (id, role) => {
    console.log(role, userRole)
    if(role.includes(userRole)){
    history(`/department/inovation/registration/${id}`);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "This program is not meant for your user type",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok",
      });
    }
  }
  console.log(props)
  return (
    <div className="">
      <div className="auto-container pb-5 pt-5 ">
        <div className="d-flex justify-content-center align-items-center ">
          <div className="">
            <h2 className="inovation_headings m-1 ">Supporting Partners</h2>
          </div>
        </div>
        <div className="">
          <div className="  d-flex justify-content-center align-items-center align-items-stretch">
            <div className="   d-flex justify-content-center align-items-center flex-wrap p-0 w-100">
              {props?.data?.partner?.map((part, i) => {
                return <ProgramPartner data={part} key={i} index={i} />;
              })}
            </div>
          </div>
        </div>
        
        {/* <div className="d-flex justify-content-center mt-5 mb-3">
          <Link to={`/department/inovation/registration/${props.data.id}`}>
            <KiedButton
              content={
                <>
                  <div className="d-flex p-1">
                    <div className="d-flex align-items-center">
                      Click Here To Apply
                    </div>
                    <div className="d-flex align-items-center">
                      <KiedIcons
                        icon="arrow-right"
                        className="hero-button-icon"
                      />
                    </div>
                  </div>
                </>
              }
              secondary
              rounded
              big
            />
          </Link>
        </div> */}
        {props?.data?.status === "Upcoming" && props?.data?.fees  &&
        <div>
          <div className="d-flex justify-content-center mt-5 mb-3">
            <KiedButton
              onClick={() => handleregister(param.id, props?.data?.role_id)}
              content={
                <>
                  <div className="d-flex p-1">
                    <div className="d-flex align-items-center">
                    
                        Click Here To Apply
                     
                    </div>
                    <div className="d-flex align-items-center">
                      <KiedIcons
                        icon="arrow-right"
                        className="hero-button-icon"
                      />
                    </div>
                  </div>
                </>
              }
              secondary
              rounded
              big
            />
          </div>
        </div>}
      </div>
    </div>
  );
}

export default NewProgramSupportingPartners;
