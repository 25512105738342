import React, { forwardRef, useImperativeHandle } from "react";
import Swal from "sweetalert2";
import RadioButtonDash from '../../../../components/radiobutton-dash/radiobutton';
import SelectBoxDash from '../../../../components/selectbox-dash/SelectBox.component';
import TextFieldDash from '../../../../components/textfield-dash/TextField.component';
import { KiedCarriers, castCategory, gender, radiooption, businessStage, businessduration, mode } from '../../../edc.registration/registrationform.data';
import { districts, qualification } from '../../../registration/registrationform.data';
import KiedButton from "../../../../components/buttons/button/button.component";
import { toast } from "react-toastify";

const ExistingRegistrationForm = ({
  handleChange,
  handleTextFieldChange,
  formdata,
  error, setError,
  fielderror,
  setSubmitted,
  handleSubmit,
  setCompleted,
  setFieldError,
  userData,
  userid,
  accountname,
  accountno,
  whatsapp_no,
  userrole,
  setProfiledit,
  fetchprofile,
  createProfile,
  validateField,
  submitted,
}, ref) => {

  const requiredFields = [
    "gender",
    "dob",

    "address",
    "district",
    "highest_educational_qualification",
    "product_type",
    "stage_of_business",
    "how_long_business",
    "mode_of_business",
    ...(formdata?.mode_of_business === "Other" ? ["other_mode_business"] : []),
  ];
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      dataSetter();
    },
  }));

  const validate = () => {
    let errorField = {};
    requiredFields.forEach((item) => {
      if (
        formdata[item] === null ||
        formdata[item] === undefined ||
        formdata[item] === ""
      ) {
        errorField[item] = "Please fill in the details";
        setSubmitted(false);
      }
    });
    return errorField;
  };
  const dataSetter = () => {
    setSubmitted(true);


    let payload = new FormData();

    // navigate("/success");
    if (formdata?.name && formdata?.name !== "") {
      payload.append("name", formdata?.name);
    } else {
      payload.append("name", accountname);
    }

    if (formdata?.mobile && formdata?.mobile !== "") {
      payload.append("mobile", formdata?.mobile);
    } else {
      payload.append("mobile", accountno);
    }
    if (formdata?.whatsapp_no && formdata?.whatsapp_no !== "") {
      payload.append("whatsapp_no", formdata?.whatsapp_no);
    } else {
      payload.append("whatsapp_no", whatsapp_no);
    }

    // payload.append("mobile", formdata?.mobile);
    // payload.append("whatsapp_no", formdata?.whatsapp_no);
    payload.append("user_id", userid);
    payload.append("role", userrole);


      if (formdata?.gender !== "" && formdata?.gender) {
        payload.append("gender", formdata?.gender);
      }
      if (formdata?.dob !== "" && formdata?.dob) {
        payload.append("dob", formdata?.dob);
      }
      if (formdata?.address !== "" && formdata?.address) {
        payload.append("address", formdata?.address);
      }
      if (formdata?.district !== "" && formdata?.district) {
        payload.append("district", formdata?.district);
      }
      if (
        formdata?.highest_educational_qualification !== "" &&
        formdata?.highest_educational_qualification
      ) {
        payload.append(
          "highest_educational_qualification",
          formdata?.highest_educational_qualification
        );
      }
      // if (formdata?.do_you_own_business !== "" && formdata?.do_you_own_business) {
      //   payload.append("do_you_own_business", 1);
      // }
      if (formdata?.company_name !== "" && formdata?.company_name) {
        payload.append("company_name", formdata?.company_name);
      }
      if (formdata?.stage_of_business !== "" && formdata?.stage_of_business) {
        payload.append("stage_of_business", formdata?.stage_of_business);
      }
      if (formdata?.how_long_business !== "" && formdata?.how_long_business) {
        payload.append("how_long_business", formdata?.how_long_business);
      }
      payload.append("do_you_own_business", 1);

      if (formdata?.product_type !== "" && formdata?.product_type) {
        payload.append("product_type", formdata?.product_type);
      }

      if (formdata?.mode_of_business === "Other") {
        payload.append("mode_of_business", formdata?.other_mode_business ?? "");
        // payload.append("others", null);
      } else {
        if (formdata?.mode_of_business !== "" && formdata?.mode_of_business) {
          payload.append("mode_of_business", formdata?.mode_of_business);
          // payload.append("others", formdata?.others);
        }
      }
    const errorField = validate();
    if (Object.keys(errorField).length === 0) {
      handleSubmit(payload);
    } else {
      setFieldError(errorField);
      setSubmitted(false);
      setCompleted(true);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required fields (Mandatory fields are marked in red)",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok",
      });
    }
  };
  console.log(formdata)
  return (
    <div>
    <div>
    <div className="">
      <RadioButtonDash
        options={gender}
        label="Gender"
        required
        error={fielderror.gender}
        name="gender"
        checked={formdata?.gender}
        value={formdata?.gender ?? ""}
        onChange={handleChange}
      />
      <br />
      <TextFieldDash
        label="Date of Birth "
        required
        error={fielderror.dob}
        type="date"
        name="dob"
        value={formdata?.dob ?? ""}
        onChange={handleTextFieldChange}
      />
      <br />
      {/* <TextFieldDash
        label="Age"
        required
        error={fielderror.age}
        maxLength={2}
        type="number"
        name="age"
        value={formdata?.age ?? ""}
        onChange={handleTextFieldChange}
      />
      <br /> */}
      <TextFieldDash
        label="Address"
        required
        error={fielderror.address}
        type="textarea"
        name="address"
        value={formdata?.address ?? ""}
        onChange={handleTextFieldChange}
      />
      <br />
      <SelectBoxDash
        label="Select your District "
        required
        error={fielderror.district}
        options={districts}
        value={formdata?.district}
        selectionMessage="Select your district"
        name="district"
        handleChange={handleChange}
      />
      <br />
      <SelectBoxDash
        label="Highest Qualification"
        required
        error={fielderror.highest_educational_qualification}
        options={qualification}
        value={formdata?.highest_educational_qualification}
        selectionMessage="Select your Qualification"
        name="highest_educational_qualification"
        handleChange={handleChange}
      />
      <br />
      {/* <RadioButtonDash
        options={radiooption}
        label="Do you own a business"
        required
        error={fielderror.do_you_own_business}
        name="do_you_own_business"
        checked={formdata?.do_you_own_business}
        value={formdata?.do_you_own_business ?? ""}
        onChange={handleChange}
      />
      <br /> */}
      <TextFieldDash
        label="Name of your Company/ Business"
        required
        error={fielderror.company_name}
        type="text"
        name="company_name"
        value={formdata?.company_name ?? ""}
        onChange={handleTextFieldChange}
      />
      <br />

      <SelectBoxDash
        label="Stage of your business"
        required
        error={fielderror.stage_of_business}
        options={businessStage}
        value={formdata?.stage_of_business}
        selectionMessage=""
        name="stage_of_business"
        handleChange={handleChange}
      />
      <br />
      <SelectBoxDash
        label="How long have you been in this business"
        required
        error={fielderror.how_long_business}
        options={businessduration}
        value={formdata?.how_long_business}
        selectionMessage=""
        name="how_long_business"
        handleChange={handleChange}
      />
      <br />

      <TextFieldDash
        label="Specify the Product/ Service"                     
        required
        error={fielderror.product_type}
        type="text"
        name="product_type"
        value={formdata?.product_type ?? ""}
        onChange={handleTextFieldChange}
      />
      <br />

      <SelectBoxDash
        label="Mode of business"
        required
        error={fielderror.mode_of_business}
        options={mode}
        value={formdata?.mode_of_business}
        selectionMessage="Select the mode of your business"
        name="mode_of_business"
        handleChange={handleChange}
      />
      <br />
      {formdata?.mode_of_business === "Other" && (
        <>
          <TextFieldDash
            label="Please specify the mode of business"
            required
            error={fielderror.other_mode_business}
            type="text"
            name="other_mode_business"
            value={formdata?.other_mode_business}
            onChange={handleTextFieldChange}
          />
          <br />
        </>
      )}

      <br />

      {error.proof && (
        <p id="proof" className="ml-3 error">
          Unsupported file type! Please select a .pdf or .docx
          file
        </p>
      )}
      <br />

    </div>
  </div>

    </div>
  );
};

export default forwardRef(ExistingRegistrationForm);
