import React from "react";

function ReportProblemBread() {
  return (
    <div>
      <div className="faq_banner head-container d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/home">IAC&nbsp;/</a>
        <a href="/department/academy-collaboration/program">
          {" "}
          Program Details&nbsp;/
        </a>
        <a
          className="current"
          href="/department/academy-collaboration/program/report-problem"
        >
          Report your Problems
        </a>
      </div>
    </div>
  );
}

export default ReportProblemBread;
