import React from "react";
import OurWebinarDetails from "../../pages/our_webinars/sections/details/OurWebinarDetails";
import Banner from "../../components/banner/banner";
function OurWebinar() {
  return (
    <div>
      <Banner title="Our Webinars" image="academia-banner" />
      <OurWebinarDetails />
    </div>
  );
}

export default OurWebinar;
