import React from "react";
// import { TenderData } from "../../tenders.data";
import Pagination from "../../../../components/pagination/pagination";
import { useRef, useState, useMemo } from "react";
import TenderTableComponent from "../../../../components/tender-table/tender.table";

let PageSize = 10;

function TenderTable(props) {
  // const [isActive, setIsActive] = useState(false);

  // const [data, setData] = useState(props?.data);
  const myRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  //   let TenderData = TenderDatas.reverse();

  const mainData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return props?.data.slice(firstPageIndex, lastPageIndex);
  }, [props?.data, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    myRef.current.scrollIntoView();
  };

  return (
    <div className="auto-container pb-5 pt-5" ref={myRef}>
      <div className="d-flex justify-content-center">
        <table className="">
          {mainData.length > 0 ? (
            <tr className="">
              <th className="table-head">Tenders</th>
              <th className="table-head ">Download</th>
            </tr>
          ) : (
            "No Tenders Available"
          )}

          {mainData.map((card, i) => {
            return <TenderTableComponent data={card} key={i} index={i} />;
          })}

          <Pagination
            className="pagination-bar "
            currentPage={currentPage}
            totalCount={props?.data.length}
            pageSize={PageSize}
            onPageChange={(page) => handlePageChange(page)}
          />
        </table>
      </div>
    </div>
  );
}

export default TenderTable;
