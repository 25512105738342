import axios from "axios";

export const BaseUrl = () => {
  //demo
  return "";
};

export const refreshToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }
};
