import React from "react";
import FaqBanner from "./sections/banner/faq.banner";
import FaqQA from "./sections/q&a/faq.q_a";
import "./sections/faq.styles.css";
import FaqBreadcrumb from "./sections/breadcrumb/breadcrumb";

function FaqPage() {
  return (
    <div className="">
      <FaqBanner />
      <FaqBreadcrumb />
      <FaqQA />
    </div>
    //
  );
}

export default FaqPage;
