import React from "react";
import { inovationProgramData } from "../inovationData";
import ProgramsCard from "../../../../components/programs.cards/programs.card";
import KiedImages from "../../../../assets/images/kied.images.component";
// import KiedButton from "../../../../components/buttons/button/button.component";
// import KiedIcons from "../../../../assets/icons/kied.icons.component";
// import { Link } from "react-router-dom";

function InovationProgrammes(props) {
  return (
    <div id="inovPrograms" className="head-container pb-5 pt-5 pl-4 pr-3">
      <div
        id="InovationSchemesHeading"
        className="d-flex justify-content-center align-items-center"
      >
        <div className="">
          <h2 className="inovation_headings m-1">Programs</h2>
        </div>
      </div>
      <div>
        <div className="">
          <div className="row mx-0">
            {props.data.map((card, i) => {
              return <ProgramsCard data={card} key={i} index={i} />;
            })}
          </div>
        </div>
      </div>
      {/* <div className="d-flex justify-content-center m-3">
        <Link to="#">
          <KiedButton
            content={
              <>
                <div className="d-flex p-1">
                  <div className="d-flex align-items-center">
                    Click Here To Register
                  </div>
                  <div className="d-flex align-items-center">
                    <KiedIcons
                      icon="arrow-right"
                      className="hero-button-icon m-1"
                    />
                  </div>
                </div>
              </>
            }
            secondary
            rounded
            big
          />
        </Link>
      </div> */}
    </div>
  );
}

export default InovationProgrammes;
