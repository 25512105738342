import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import { offerings } from "../programsData";
import OfferingCard from "../../../../components/offering.card/offering.card";

function ProgramsOfferings() {
  return (
    <div className="offerings pt-5 pb-5">
      <div className="auto-container pb-5 pt-5">
        <div className="d-flex justify-content-center align-items-center pb-4">
          <div className="">
            <h2 className="inovation_headings m-1 ">
              Overall Expected Outcome{" "}
            </h2>
          </div>
        </div>
        <div>
          <div
            id="inovation_Events_contents"
            className="row justify-content-center"
          >
            {offerings.map((card, i) => {
              return <OfferingCard data={card} key={i} index={i} />;
            })}
          </div>
        </div>
        <div>
          <div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramsOfferings;
