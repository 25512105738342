import React from 'react'
import SmallBanner from '../../components/small.banner/small.banner'
import IndAccProgramBreadcrumb from './sections/breadcrumbs/programBreadcrumbs'
import IndAccProgramDetails from './sections/details/program_details'
import IndAccProgramDescription from './sections/description/program_description'
import IndAccProgramCards from './sections/problem_cards/problemCard'
import "./industry-academy_programs.styles.css"

function IndAccProgramPage() {
  return (
    <div className=''>
        <SmallBanner />
        <IndAccProgramBreadcrumb />
        <IndAccProgramDetails />
        <IndAccProgramDescription />
        {/* <IndAccProgramCards />         */}
    </div>
  )
}

export default IndAccProgramPage