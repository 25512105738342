import React from "react";
////////////////////Logos/////////////////////////
import KiedLogo from "./resourses/kied.png";
import KGovLogo from "./resourses/kgov.png";
import CeedLogo from "./resourses/ceed.jpg";
//////////////////////////////////////////////////
/////////////////Service Icons////////////////////
import ServiceFinace from "./resourses/finance.png";
import serviceEDC from "./resourses/edc.png";
import ServiceGuidance from "./resourses/guidance.png";
import ServiceInnovation from "./resourses/innovation.png";
import ServiceStudent from "./resourses/student.png";
import ServiceTechnology from "./resourses/technology.png";
//////////////////////////////////////////////////
////////////////////Images////////////////////////
import NoImage from "./resourses/noimage.png";
import ActivityImage from "./resourses/activity-image.png";
import RegEntrepreneur from "./resourses/reg-box .png";
import RegInstitution from "./resourses/new-ins.png";
import RegIndustry from "./resourses/new-instr.png";
import NewsImage from "./resourses/news.jpg";
import NoRegImage from "./resourses/empty-reg-box.png";
import Auth1 from "./resourses/author-19.jpg";
import Auth2 from "./resourses/author-20.jpg";
import Auth3 from "./resourses/author-21.jpg";
import Auth4 from "./resourses/author-22.jpg";
import Auth5 from "./resourses/author-23.jpg";
import AboutEDCImage from "./resourses/about_edc.png";
import BannerImage from "./resourses/banner.png";
import AboutKied from "./resourses/about_kied.png";
import InnovationBanner from "./resourses/innovation-banner.png";
import InovationGroup from "./resourses/inovation_group.png";
import LocationIcon from "./resourses/location.png";
import CalenderIcon from "./resourses/calender.png";
import QuestionIcon from "./resourses/question.png";
import SchemeImage from "./resourses/scheme.jpg";
import ChainStudent from "./resourses/chain-students.png";
import LocationBicon from "./resourses/location_brown.png";
import OfferingCloud from "./resourses/offeringCloud.png";
import OfferingGrowth from "./resourses/offeringGrowth.png";
import OfferingInvestor from "./resourses/offeringInvestor.png";
import OfferingMentor from "./resourses/offeringMentor.png";
import OfferingSeed from "./resourses/offeringSeed.png";
import OfferingTraining from "./resourses/offeringTraining.png";
import ProgPartner from "./resourses/progpartner.png";
import ProgBanner from "./resourses/program-banner.png";
import ProgStructure from "./resourses/Programstructure.png";
import OurTeamBanner from "./resourses/our-team-banner.png";
import TeamImage from "./resourses/team-img.jpg";
import TeamFull from "./resourses/team-full.jpg";
import AcaBanner from "./resourses/academia-banner.png";
import Seminar from "./resourses/seminar.png";
import ReportImg from "./resourses/report-img.jpg";
import Graph from "./resourses/graph.png";
import Schemesded from "./resourses/schemesded.jpg";
import Eventsded from "./resourses/events.jpg";
import QuestionSquare from "./resourses/questionSquare.png";
import DownArrow from "./resourses/dow-arrow.png";
import EDCGallery1 from "./resourses/edcgallery1.png";
import EDCGallery2 from "./resourses/edcgallery2.png";
import EDCGallery3 from "./resourses/edcgallery3.png";
import EDCGallery4 from "./resourses/edcgallery4.png";
import EDCGallery5 from "./resourses/edcgallery5.png";
import EDCGallery6 from "./resourses/edcgallery6.png";
import NewsAuthor from "./resourses/news-author.jpg";
import NewsImg from "./resourses/news-img.jpg";
import ErrorIcon from "./resourses/error-icon.png";
import SuccessIcon from "./resourses/success-icon.png";
import TieKerala from "./resourses/Tie-Kerala.png";
import IMK from "./resourses/imk.png";
import KSIDC from "./resourses/ksidc.png";
import Bakery1 from "./resourses/5day.png";
import Bakery from "./resourses/bakery.png";
import Dummy from "./resourses/dummyresources.png";
import Inaugral from "./resourses/inaugral.png";
import YuvaMain from "./resourses/yuvamain.png";
import Inaugral2 from "./resourses/inaugral2.png";
import Yuva1 from "./resourses/yuva1.png";
import Yuva2 from "./resourses/yuva2.png";
import Yuva3 from "./resourses/yuva3.png";
import Yuva4 from "./resourses/yuva5.png";
import Yuva5 from "./resourses/yuva6.png";
import Yuva6 from "./resourses/yuva7.png";
import Yuva7 from "./resourses/yuva8.png";
import Yuva8 from "./resourses/yuva9.png";
import Suman from "./resourses/suman.jpg";
import Sarath from "./resourses/Sarath.jpg";
import Snehil from "./resourses/snehil.png";
import Hari from "./resourses/harikishore.jpg";
import Prakash from "./resourses/prakash.jpg";
import Anisha from "./resourses/anisha.jpg";
import Campus from "./resourses/campus.jpg";
import Designthinking1 from "./resourses/designthinking1.jpg";
import Designthinking2 from "./resourses/designthinking2.jpg";
import Designthinking3 from "./resourses/designthinking3.jpg";
import Designthinking4 from "./resourses/designthinking4.jpg";
import Webinarbranding from "./resourses/webinarbranding.png";
import Webinarbranding2 from "./resourses/webinarbranding2.png";
import Webinarbranding3 from "./resourses/webinarbranding3.png";
import Intellectualproperty5 from "./resourses/intellectualproperty5.png";
import Intellectualproperty from "./resourses/intellectualproperty.png";
import Intellectualproperty2 from "./resourses/intellectualproperty2.png";
import Intellectualproperty4 from "./resourses/intellectualproperty4.png";
import Ecommerce0 from "./resourses/ecommerce0.jpeg";
import Ecommerce from "./resourses/ecommerce.png";
import Ecommerce2 from "./resourses/ecommerce2.png";
import Ecommerce3 from "./resourses/ecommerce3.png";
import Kiedmeetup1 from "./resourses/kiedmeetup1.png";
import Kiedmeetup2 from "./resourses/kiedmeetup2.png";
import Kiedmeetup3 from "./resourses/kiedmeetup3.png";
import Kiedmeetup4 from "./resourses/kiedmeetup4.png";
import Kiedmeetup5 from "./resourses/kiedmeetup5.png";
import Kiedmeetup6 from "./resourses/kiedmeetup6.jpeg";
import Womenenterpreners0 from "./resourses/womenenterpreners0.jpg";
import Womenenterpreners from "./resourses/womenenterpreners.png";
import Womenenterpreners2 from "./resourses/womenenterpreners2.png";
import Womenenterpreners3 from "./resourses/womenenterpreners3.png";
import Innovationimg from "./resourses/innovationimg.jpg";
import Georgin from "./resourses/georgin.jpg";
import NewsYuva1 from "./resourses/newsyuva.jpg";
import NewsYuva2 from "./resourses/newsYuva2.jpg";
import NewsYuva3 from "./resourses/newsyuva3.jpg";
import Chinchu from "./resourses/chinchu.jpg";
import Programimg from "./resourses/program-img.jpg";
import Regcard7 from "./resourses/regcard7.jpeg";
import Regcard8 from "./resourses/regcard8.jpeg";
import Regcard3 from "./resourses/regcard3.jpeg";
import Regcard9 from "./resourses/regcard9.jpeg";
import Regcard5 from "./resourses/regcard5.jpeg";
import Regcard6 from "./resourses/regcard6.jpeg";
import RightArrow from "./resourses/arrow-right.png";
import RightWhite from "./resourses/arrow-right-white.png";
import Ravi from "./resourses/raviraj.jpg";

import Arise1 from "./resourses/arise1.jpeg";
import Arise2 from "./resourses/arise2.jpeg";
import Arise3 from "./resourses/arise3.jpeg";
import Arise4 from "./resourses/arise4.jpeg";
import Arise5 from "./resourses/arise5.jpeg";
import Arise6 from "./resourses/arise6.jpeg";
import Bap1 from "./resourses/bap1.png";
import Bap2 from "./resourses/bap2.png";
import Bap3 from "./resourses/bap3.png";
import GrowthPrgm from "./resourses/growthprgm.png";
import News2 from "./resourses/news-2.png";
import News3 from "./resourses/news-3.png";
import News4 from "./resourses/news-4.png";
import News7 from "./resourses/news-7.png";
import News5 from "./resourses/news-5.png";
import NewsMentors from "./resourses/newsmentors.png";

import SchemesInner from "./resourses/schemes-inner.jpg";
import Arise from "./resourses/arise.png";
import Imm1 from "./resourses/imm1.jpeg";
import Imm2 from "./resourses/imm2.jpeg";
import Imm3 from "./resourses/imm3.jpeg";
import Imm4 from "./resourses/imm4.jpeg";
import Immersion from "./resourses/Immersion.jpg";
import Stage1 from "./resourses/stage33.jpeg";
import Stage2 from "./resourses/stage34.jpeg";
import Stage3 from "./resourses/stage35.jpg";
import Stage4 from "./resourses/stage36.jpg";

import EdcEventCardImgOne from "./resourses/edcevent.1.1.png";
import EdcEventCardImgTwo from "./resourses/edcevent.1.2.png";
import EdcEventCardImgThree from "./resourses/edcevent.1.3.png";
import EdcEventCardImgFour from "./resourses/edcevent.1.4.png";
import EdcEventCardImgFive from "./resourses/edcevent.1.5.png";
import EdcEventCardImgSix from "./resourses/edcevent.1.6.png";
import EdcEventCardImgSeven from "./resourses/edcevent.1.7.png";
import EdcEventCardImgEight from "./resourses/edcevent.1.8.png";
import EdcEventCardImgNine from "./resourses/edcevent.1.9.png";

import EdcEventCardTwoImgOne from "./resourses/edcevent.2.1.png";
import EdcEventCardTwoImgTwo from "./resourses/edcevent.2.2.png";
import EdcEventCardTwoImgThree from "./resourses/edcevent.2.3.png";
import EdcEventCardTwoImgFour from "./resourses/edcevent.2.4.png";

import EdcEventCardThreeImgOne from "./resourses/edcevent.3.1.png";
import EdcEventCardThreeImgTwo from "./resourses/edcevent.3.2.png";
import EdcEventCardThreeImgThree from "./resourses/edcevent.3.3.png";
import EdcEventCardThreeImgFour from "./resourses/edcevent.3.4.png";

import EdcEventCardFourImgOne from "./resourses/edcevent.4.1.png";
import EdcEventCardFourImgTwo from "./resourses/edcevent.4.2.png";
import EdcEventCardFourImgThree from "./resourses/edcevent.4.3.png";

import EdcEventCardFiveImgOne from "./resourses/edcevent.5.1.png";
import EdcEventCardFiveImgTwo from "./resourses/edcevent.5.2.png";
import EdcEventCardFiveImgThree from "./resourses/edcevent.5.3.png";
import EdcEventCardFiveImgFour from "./resourses/edcevent.5.4.png";
import EdcEventCardFiveImgFive from "./resourses/edcevent.5.5.png";
import EdcEventCardFiveImgSix from "./resourses/edcevent.5.6.png";

import EdcEventCardSixImgOne from "./resourses/edcevent.6.1.png";
import EdcEventCardSixImgTwo from "./resourses/edcevent.6.2.png";
import EdcEventCardSixImgThree from "./resourses/edcevent.6.3.png";

import OepImg1 from "./resourses/oepimg1.png";
import OepImg2 from "./resourses/oepimg2.png";
import OepImg3 from "./resourses/oepimg3.png";
import OepImg4 from "./resourses/oepimg4.png";
import OepImg5 from "./resourses/oepimg5.png";
import OepImg6 from "./resourses/oepimg6.png";
import OepImg7 from "./resourses/oepimg7.png";
import OepImg8 from "./resourses/oepimg8.png";
import OepImg9 from "./resourses/oepimg9.png";
import Ess from "./resourses/ess.jpg";
import Bap from "./resourses/BAP.jpg";
import Anil from "./resourses/anil.png";
import Ashraf from "./resourses/ashraf.png";
import Jain from "./resourses/jain.png";
import val1 from "./resourses/val1.png";
import val2 from "./resourses/val2.png";
import val3 from "./resourses/val3.png";
import val4 from "./resourses/val4.png";
import val5 from "./resourses/val5.png";
import val6 from "./resourses/val6.png";
import val7 from "./resourses/val7.png";
import val8 from "./resourses/val8.png";
import Pro1 from "./resourses/pro1.png";
import Pro2 from "./resourses/pro2.png";
import Pro3 from "./resourses/pro3.png";
import Pro4 from "./resourses/pro4.png";
import Pro5 from "./resourses/pro5.png";
import Pro6 from "./resourses/pro6.png";
import Pro7 from "./resourses/pro7.png";
import Pro8 from "./resourses/pro8.png";
import Pro9 from "./resourses/pro9.png";
import Pro10 from "./resourses/pro10.png";

import yuvaOriented1 from "./resourses/yuva-oriented1.png";
import yuvaOriented2 from "./resourses/yuva-oriented2.png";
import yuvaOriented3 from "./resourses/yuva-oriented3.png";
import yuvaOriented4 from "./resourses/yuva-oriented4.png";
import yuvaOriented5 from "./resourses/yuva-oriented5.png";
import yuvaOriented6 from "./resourses/yuva-oriented6.png";

import yuvaCamp1 from "./resourses/yuva-camp1.png";
import yuvaCamp2 from "./resourses/yuva-camp2.png";
import yuvaCamp3 from "./resourses/yuva-camp3.png";
import yuvaCamp4 from "./resourses/yuva-camp4.png";

import EdcYuva1 from "./resourses/edc-yuva1.png";
import EdcYuva2 from "./resourses/edc-yuva2.png";
import EdcYuva3 from "./resourses/edc-yuva3.png";
import EdcYuva4 from "./resourses/edc-yuva4.png";
import EdcYuva5 from "./resourses/edc-yuva5.png";
import EdcYuva6 from "./resourses/edc-yuva6.png";
import EdcYuva7 from "./resourses/edc-yuva7.png";
import EdcYuva8 from "./resourses/edc-yuva8.png";
import Schemesub from "./resourses/schemesub.jpg";

import yuvaTeam1 from "./resourses/yuvateam1.png";
import yuvaTeam2 from "./resourses/yuvateam2.png";
import yuvaTeam3 from "./resourses/yuvateam3.png";
import yuvaTeam4 from "./resourses/yuvateam4.png";
import yuvaTeam5 from "./resourses/yuvateam5.png";
import yuvaTeam6 from "./resourses/yuvateam6.png";
import yuvaTeam7 from "./resourses/yuvateam7.png";
import yuvaTeam8 from "./resourses/yuvateam8.png";
import yuvaTeam9 from "./resourses/yuvateam9.png";
import yuvaTeam10 from "./resourses/yuvateam10.png";
import yuvaTeam11 from "./resourses/yuvateam11.png";

import Pannel1 from "./resourses/pannel1.png";
import Pannel2 from "./resourses/pannel2.png";
import Pannel3 from "./resourses/pannel3.png";
import Pannel4 from "./resourses/pannel4.png";
import Pannel5 from "./resourses/pannel5.png";
import Coi from "./resourses/coi.png";
import EdcListing from "./resourses/edcListing.png";
import CoiListing from "./resourses/coiListing.png";
import Yuva from "./resourses/yuva.png";
import EdcP from "./resourses/edcp.png";

import AngamalyCampus from "./resourses/angamalycampus.png";
import CochinCampus from "./resourses/cochincampus.png";

import Schemesdec1 from "./resourses/schemes-dec1.png";

import Edcschemenew from "./resourses/edcschemenew.png";

import Matchmaking from "./resourses/matchmaking.jpg";
import Internship from "./resourses/internship.jpg";

import Internshipevent1 from "./resourses/internshipevent1.png";
import Internshipevent2 from "./resourses/internshipevent2.png";
import Internshipevent3 from "./resourses/internshipevent3.png";
import Internshipevent4 from "./resourses/internshipevent4.png";
import Internshipevent5 from "./resourses/internshipevent5.png";

import InnovationNew from "./resourses/innovation-new.jpg";
import EdcNew from "./resourses/edc-new.jpg";
import OverallTick from "./resourses/overallTick.png";

import Problem from "./resourses/problem.jpg";
import Solutions from "./resourses/solutions.jpg";
import Summary from "./resourses/summary.jpg";

import Mentor1 from "./resourses/mentor1.png";
import Mentor2 from "./resourses/mentor2.jpg";
import Mentor3 from "./resourses/mentor3.JPG";
import Mentor4 from "./resourses/mentor4.JPG";
import Mentor5 from "./resourses/mentor5.jpg";
import Mentor6 from "./resourses/mentor6.jpg";
import Mentor7 from "./resourses/mentor7.jpg";
import Mentor8 from "./resourses/mentor8.jpg";
import Mentor9 from "./resourses/mentor9.jpg";
import Kase from "./resourses/kase.png";
import Sanjeev from "./resourses/sanjeev.png";
import Aspiring from "./resourses/aspiring.png";
import Club from "./resourses/club.png";
import Existing from "./resourses/existing.png";
import Expert from "./resourses/expert.png";
import Industry from "./resourses/industry.png";
import Research from "./resourses/research.png";
import School from "./resourses/school.png";
import Students from "./resourses/students.png";

//////////////////////////////////////////////////

function KiedImages(props) {
  const fetchImage = () => {
    switch (props.image) {
      // "switch" check the "props.image" mathches with any any of the following "cases"

      ////////////////////Logos/////////////////////////
      case "kied-logo":
        // if the  "props.image === kied-logo" then return "KiedLogo"
        return KiedLogo;
      case "kgov-logo":
        return KGovLogo;
      case "ceed-logo":
        return CeedLogo;
      ////////////////////Icons/////////////////////////

      case "icon-center-for-inovation":
        return ServiceInnovation;
      case "icon-center-for-industry-academia-collaboration":
        return ServiceStudent;
      case "icon-entrepreneurship-development-club":
        return serviceEDC;
      case "center-for-business-finance":
        return ServiceFinace;
      case "center-for-guidance-and-facilitation":
        return ServiceGuidance;
      case "center-for-technology-transfer-assistance":
        return ServiceTechnology;
      case "inovation-heading-icon":
        return InovationGroup;
      case "location-icon":
        return LocationIcon;
      case "location-bicon":
        return LocationBicon;
      case "calender-icon":
        return CalenderIcon;
      case "question-icon":
        return QuestionIcon;
      case "chain-student":
        return ChainStudent;
      case "question-square":
        return QuestionSquare;
      case "error-icon":
        return ErrorIcon;
      case "success-icon":
        return SuccessIcon;
      case "arrow-right":
        return RightArrow;
      case "arrow-white":
        return RightWhite;
      ////////////////////Images/////////////////////////
      case "kied-activity-image":
        return ActivityImage;

      case "banner-image":
        return BannerImage;

      case "about-kied":
        return AboutKied;

      case "about-edc":
        return AboutEDCImage;

      case "inovation-banner":
        return InnovationBanner;

      case "reg-new-entrepreneur":
        return RegEntrepreneur;

      case "reg-research-or-academic-institutions":
        return RegInstitution;

      case "reg-industry-associations":
        return RegIndustry;
      case "news-demo-image":
        return NewsImage;
      case "auth-1":
        return Auth1;
      case "auth-2":
        return Auth2;
      case "auth-3":
        return Auth3;
      case "auth-4":
        return Auth4;
      case "auth-5":
        return Auth5;
      case "scheme-image":
        return SchemeImage;
      case "offering-cloud":
        return OfferingCloud;
      case "offering-growth":
        return OfferingGrowth;
      case "offering-mentor":
        return OfferingMentor;
      case "offering-investor":
        return OfferingInvestor;
      case "offering-seed":
        return OfferingSeed;
      case "offering-training":
        return OfferingTraining;
      case "program-partner":
        return ProgPartner;
      case "program-banner":
        return ProgBanner;
      case "program-structure":
        return ProgStructure;
      case "team-banner":
        return OurTeamBanner;
      case "team-img":
        return TeamImage;
      case "team-full":
        return TeamFull;
      case "academia-banner":
        return AcaBanner;
      case "seminar":
        return Seminar;
      case "report":
        return ReportImg;
      case "graph":
        return Graph;
      case "scheme":
        return Schemesded;
      case "events":
        return Eventsded;
      case "down-arrow":
        return DownArrow;
      case "edcgallery1":
        return EDCGallery1;
      case "edcgallery2":
        return EDCGallery2;
      case "edcgallery3":
        return EDCGallery3;
      case "edcgallery4":
        return EDCGallery4;
      case "edcgallery5":
        return EDCGallery5;
      case "edcgallery6":
        return EDCGallery6;
      case "newscardimage":
        return NewsImg;
      case "newsauthor":
        return NewsAuthor;
      case "tie":
        return TieKerala;
      case "imk":
        return IMK;
      case "ksidc":
        return KSIDC;
      case "bakery1":
        return Bakery1;
      case "bakery":
        return Bakery;
      case "dummy":
        return Dummy;
      case "inaugral":
        return Inaugral;
      case "inaugral2":
        return Inaugral2;
      case "yuvamain":
        return YuvaMain;
      case "yuva1":
        return Yuva1;
      case "yuva2":
        return Yuva2;
      case "yuva3":
        return Yuva3;
      case "yuva4":
        return Yuva4;
      case "yuva5":
        return Yuva5;
      case "yuva6":
        return Yuva6;
      case "yuva7":
        return Yuva7;
      case "yuva8":
        return Yuva8;
      case "Anisha":
        return Anisha;
      case "Prakash":
        return Prakash;
      case "Hari":
        return Hari;
      case "snehil":
        return Snehil;
      case "Sarath":
        return Sarath;
      case "Suman":
        return Suman;
      case "campus":
        return Campus;
      case "designthinking1":
        return Designthinking1;
      case "designthinking2":
        return Designthinking2;
      case "designthinking3":
        return Designthinking3;
      case "designthinking4":
        return Designthinking4;
      case "webinarbranding":
        return Webinarbranding;
      case "webinarbranding2":
        return Webinarbranding2;
      case "webinarbranding3":
        return Webinarbranding3;
      case "intellectualproperty5":
        return Intellectualproperty5;
      case "intellectualproperty":
        return Intellectualproperty;
      case "intellectualproperty2":
        return Intellectualproperty2;
      case "intellectualproperty4":
        return Intellectualproperty4;
      case "ecommerce0":
        return Ecommerce0;
      case "ecommerce":
        return Ecommerce;
      case "ecommerce2":
        return Ecommerce2;
      case "ecommerce3":
        return Ecommerce3;
      case "kiedmeetup1":
        return Kiedmeetup1;
      case "kiedmeetup2":
        return Kiedmeetup2;
      case "kiedmeetup3":
        return Kiedmeetup3;
      case "kiedmeetup4":
        return Kiedmeetup4;
      case "kiedmeetup5":
        return Kiedmeetup5;
      case "kiedmeetup6":
        return Kiedmeetup6;
      case "womenenterpreners0":
        return Womenenterpreners0;
      case "womenenterpreners":
        return Womenenterpreners;
      case "womenenterpreners2":
        return Womenenterpreners2;
      case "womenenterpreners3":
        return Womenenterpreners3;
      case "innovationimg":
        return Innovationimg;
      case "testi1":
        return Georgin;
      case "homenews1":
        return NewsYuva1;
      case "homenews2":
        return NewsYuva2;
      case "homenews3":
        return NewsYuva3;
      case "chinchu":
        return Chinchu;
      case "program-img":
        return Programimg;
      case "ravi":
        return Ravi;

      case "regcard7":
        return Regcard7;
      case "regcard8":
        return Regcard8;
      case "regcard3":
        return Regcard3;
      case "regcard9":
        return Regcard9;
      case "regcard5":
        return Regcard5;
      case "regcard6":
        return Regcard6;
      case "growthprgm":
        return GrowthPrgm;
      case "news-2":
        return News2;
      case "news-3":
        return News3;
      case "news-4":
        return News4;
      case "news-7":
        return News7;
      case "news-5":
        return News5;
      case "newsmentors":
        return NewsMentors;
      case "arise1":
        return Arise1;
      case "arise2":
        return Arise2;
      case "arise3":
        return Arise3;
      case "arise4":
        return Arise4;
      case "arise5":
        return Arise5;
      case "arise6":
        return Arise6;
      case "bap1":
        return Bap1;
      case "bap2":
        return Bap2;
      case "bap3":
        return Bap3;
      case "growthprgm":
        return GrowthPrgm;
      case "news-2":
        return News2;
      case "news-3":
        return News3;
      case "news-4":
        return News4;
      case "news-7":
        return News7;
      case "news-5":
        return News5;
      case "newsmentors":
        return NewsMentors;

      case "schemes-inner":
        return SchemesInner;
      case "arise":
        return Arise;
      case "imm1":
        return Imm1;
      case "imm2":
        return Imm2;
      case "imm3":
        return Imm3;
      case "imm4":
        return Imm4;
      case "immersion":
        return Immersion;
      case "stag1":
        return Stage1;
      case "stag2":
        return Stage2;
      case "stag3":
        return Stage3;
      case "stag4":
        return Stage4;

      case "edcevent.1.1":
        return EdcEventCardImgOne;
      case "edcevent.1.2":
        return EdcEventCardImgTwo;
      case "edcevent.1.3":
        return EdcEventCardImgThree;
      case "edcevent.1.4":
        return EdcEventCardImgFour;
      case "edcevent.1.5":
        return EdcEventCardImgFive;
      case "edcevent.1.6":
        return EdcEventCardImgSix;
      case "edcevent.1.7":
        return EdcEventCardImgSeven;
      case "edcevent.1.8":
        return EdcEventCardImgEight;
      case "edcevent.1.9":
        return EdcEventCardImgNine;

      case "edcevent.2.1":
        return EdcEventCardTwoImgOne;
      case "edcevent.2.2":
        return EdcEventCardTwoImgTwo;
      case "edcevent.2.3":
        return EdcEventCardTwoImgThree;
      case "edcevent.2.4":
        return EdcEventCardTwoImgFour;

      case "edcevent.3.1":
        return EdcEventCardThreeImgOne;
      case "edcevent.3.2":
        return EdcEventCardThreeImgTwo;
      case "edcevent.3.3":
        return EdcEventCardThreeImgThree;
      case "edcevent.3.4":
        return EdcEventCardThreeImgFour;

      case "edcevent.4.1":
        return EdcEventCardFourImgOne;
      case "edcevent.4.2":
        return EdcEventCardFourImgTwo;
      case "edcevent.4.3":
        return EdcEventCardFourImgThree;

      case "edcevent.5.1":
        return EdcEventCardFiveImgOne;
      case "edcevent.5.2":
        return EdcEventCardFiveImgTwo;
      case "edcevent.5.3":
        return EdcEventCardFiveImgThree;
      case "edcevent.5.4":
        return EdcEventCardFiveImgFour;
      case "edcevent.5.5":
        return EdcEventCardFiveImgFive;
      case "edcevent.5.6":
        return EdcEventCardFiveImgSix;

      case "edcevent.6.1":
        return EdcEventCardSixImgOne;
      case "edcevent.6.2":
        return EdcEventCardSixImgTwo;
      case "edcevent.6.3":
        return EdcEventCardSixImgThree;
      case "arise":
        return Arise;
      case "imm1":
        return Imm1;
      case "imm2":
        return Imm2;
      case "imm3":
        return Imm3;
      case "imm4":
        return Imm4;
      case "immersion":
        return Immersion;
      case "stag1":
        return Stage1;
      case "stag2":
        return Stage2;
      case "stag3":
        return Stage3;
      case "stag4":
        return Stage4;

      case "edcevent.1.1":
        return EdcEventCardImgOne;
      case "edcevent.1.2":
        return EdcEventCardImgTwo;
      case "edcevent.1.3":
        return EdcEventCardImgThree;
      case "edcevent.1.4":
        return EdcEventCardImgFour;
      case "edcevent.1.5":
        return EdcEventCardImgFive;
      case "edcevent.1.6":
        return EdcEventCardImgSix;
      case "edcevent.1.7":
        return EdcEventCardImgSeven;
      case "edcevent.1.8":
        return EdcEventCardImgEight;
      case "edcevent.1.9":
        return EdcEventCardImgNine;

      case "edcevent.2.1":
        return EdcEventCardTwoImgOne;
      case "edcevent.2.2":
        return EdcEventCardTwoImgTwo;
      case "edcevent.2.3":
        return EdcEventCardTwoImgThree;
      case "edcevent.2.4":
        return EdcEventCardTwoImgFour;

      case "edcevent.3.1":
        return EdcEventCardThreeImgOne;
      case "edcevent.3.2":
        return EdcEventCardThreeImgTwo;
      case "edcevent.3.3":
        return EdcEventCardThreeImgThree;
      case "edcevent.3.4":
        return EdcEventCardThreeImgFour;

      case "edcevent.4.1":
        return EdcEventCardFourImgOne;
      case "edcevent.4.2":
        return EdcEventCardFourImgTwo;
      case "edcevent.4.3":
        return EdcEventCardFourImgThree;

      case "edcevent.5.1":
        return EdcEventCardFiveImgOne;
      case "edcevent.5.2":
        return EdcEventCardFiveImgTwo;
      case "edcevent.5.3":
        return EdcEventCardFiveImgThree;
      case "edcevent.5.4":
        return EdcEventCardFiveImgFour;
      case "edcevent.5.5":
        return EdcEventCardFiveImgFive;
      case "edcevent.5.6":
        return EdcEventCardFiveImgSix;

      case "edcevent.6.1":
        return EdcEventCardSixImgOne;
      case "edcevent.6.2":
        return EdcEventCardSixImgTwo;
      case "edcevent.6.3":
        return EdcEventCardSixImgThree;
      case "ess":
        return Ess;
      case "bap":
        return Bap;
      case "jain":
        return Jain;
      case "anil":
        return Anil;
      case "ashraf":
        return Ashraf;

      case "oepimg1":
        return OepImg1;
      case "oepimg2":
        return OepImg2;
      case "oepimg3":
        return OepImg3;
      case "oepimg4":
        return OepImg4;
      case "oepimg5":
        return OepImg5;
      case "oepimg6":
        return OepImg6;
      case "oepimg7":
        return OepImg7;
      case "oepimg8":
        return OepImg8;
      case "oepimg9":
        return OepImg9;
      case "val1":
        return val1;
      case "val2":
        return val2;
      case "val3":
        return val3;
      case "val4":
        return val4;
      case "val5":
        return val5;
      case "val6":
        return val6;
      case "val7":
        return val7;
      case "val8":
        return val8;
      case "pro1":
        return Pro1;
      case "pro2":
        return Pro2;
      case "pro3":
        return Pro3;
      case "pro4":
        return Pro4;
      case "pro5":
        return Pro5;
      case "pro6":
        return Pro6;
      case "pro7":
        return Pro7;
      case "pro8":
        return Pro8;
      case "pro9":
        return Pro9;
      case "pro10":
        return Pro10;

      case "yuva-oriented1":
        return yuvaOriented1;
      case "yuva-oriented2":
        return yuvaOriented2;
      case "yuva-oriented3":
        return yuvaOriented3;
      case "yuva-oriented4":
        return yuvaOriented4;
      case "yuva-oriented5":
        return yuvaOriented5;
      case "yuva-oriented6":
        return yuvaOriented6;

      case "yuva-camp1":
        return yuvaCamp1;
      case "yuva-camp2":
        return yuvaCamp2;
      case "yuva-camp3":
        return yuvaCamp3;
      case "yuva-camp4":
        return yuvaCamp4;

      case "edc-yuva1":
        return EdcYuva1;
      case "edc-yuva2":
        return EdcYuva2;
      case "edc-yuva3":
        return EdcYuva3;
      case "edc-yuva4":
        return EdcYuva4;
      case "edc-yuva5":
        return EdcYuva5;
      case "edc-yuva6":
        return EdcYuva6;
      case "edc-yuva7":
        return EdcYuva7;
      case "edc-yuva8":
        return EdcYuva8;
      case "schemesub":
        return Schemesub;

      case "yuvateam1":
        return yuvaTeam1;
      case "yuvateam2":
        return yuvaTeam2;
      case "yuvateam3":
        return yuvaTeam3;
      case "yuvateam4":
        return yuvaTeam4;
      case "yuvateam5":
        return yuvaTeam5;
      case "yuvateam6":
        return yuvaTeam6;
      case "yuvateam7":
        return yuvaTeam7;
      case "yuvateam8":
        return yuvaTeam8;
      case "yuvateam9":
        return yuvaTeam9;
      case "yuvateam10":
        return yuvaTeam10;
      case "yuvateam11":
        return yuvaTeam11;
      case "coi":
        return Coi;
      case "edcl":
        return EdcListing;
      case "coil":
        return CoiListing;
      case "yuva":
        return Yuva;
      case "edcp":
        return EdcP;

      case "angamalycampus":
        return AngamalyCampus;
      case "cochincampus":
        return CochinCampus;

      case "schemes-dec1":
        return Schemesdec1;

      case "edcschemenew":
        return Edcschemenew;

      case "matchmaking":
        return Matchmaking;
      case "internship":
        return Internship;

      case "internshipevent1":
        return Internshipevent1;
      case "internshipevent2":
        return Internshipevent2;
      case "internshipevent3":
        return Internshipevent3;
      case "internshipevent4":
        return Internshipevent4;
      case "internshipevent5":
        return Internshipevent5;

      case "innovation-new":
        return InnovationNew;

      case "edc-new":
        return EdcNew;

      case "pannel1":
        return Pannel1;
      case "pannel2":
        return Pannel2;
      case "pannel3":
        return Pannel3;
      case "pannel4":
        return Pannel4;
      case "pannel5":
        return Pannel5;
      case "overallTick":
        return OverallTick;

      case "problem":
        return Problem;
      case "summary":
        return Summary;
      case "solutions":
        return Solutions;

      case "mentor1":
        return Mentor1;
      case "mentor2":
        return Mentor2;
      case "mentor3":
        return Mentor3;
      case "mentor4":
        return Mentor4;
      case "mentor5":
        return Mentor5;
      case "mentor6":
        return Mentor6;
      case "mentor7":
        return Mentor7;
      case "mentor8":
        return Mentor8;
      case "mentor9":
        return Mentor9;
      case "kase":
        return Kase;
      case "sanjeev":
        return Sanjeev;

        case "aspiring":
        return Aspiring;
        case "existing":
        return Existing;
        case "expert":
        return Expert;
        case "club":
        return Club;
        case "industry":
        return Industry;
        case "research":
        return Research;
        case "school":
        return School;
        case "students":
        return Students;



      case "no-reg-image":
        return NoRegImage;
      //////////////////////////////////////////////////
      default:
        // If any of the above not match with "props.image" then return "NoImage"
        return NoImage;
    }
  };
  return (
    <img
      src={props.link ?? fetchImage()}
      alt={props.alt ?? "KIED"}
      {...props}
    />
  );
}

export default KiedImages;
