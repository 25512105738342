import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import LoaderComponent from "../components/loader/loader.component";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  // const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    // setIsActive(true);
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Optional if you want to skip the scrolling animation
    });
    setTimeout(() => {
      // setIsActive(false);
    }, 2000);
  }, [pathname]);

  // return <LoaderComponent open={isActive} />;
  return null;
}
