// import React from 'react'

// function AssistanceBreadcrumb() {
//   return (
//     <div>AssistanceBreadcrumb</div>
//   )
// }

// export default AssistanceBreadcrumb

import React from "react";

function AssistanceBreadcrumb() {
  return (
    <div>
      <div className="faq_banner auto-container d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/academiaSchemes">
          {" "}
          Academia&nbsp;/
        </a>
        <a className="current" href="/BuildingProgramSchemes">
          Academia Scheme Details
        </a>
      </div>
    </div>
  );
}

export default AssistanceBreadcrumb;
