import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { VerifyEmailAddress } from "../../../actions/api.functions";
import KiedButton from "../../../components/buttons/button/button.component";

const VerifyEmail = (props) => {
  const param = useParams();
  const history = useNavigate();

  const [message, setMessage] = useState("Verifying Email ...");
  const [status, setStatus] = useState("loading");
  useEffect(() => {
    VerifyEmailAddress(param.id)
      .then((res) => {
        setMessage("Email Verified Successfully");
        setStatus("success");
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setStatus("error");
      });
  }, [param.id]);
  return (
    <div
      className={`py-5 d-flex flex-column align-items-center justify-content-center ${
        status === "success"
          ? "text-success"
          : status === "error"
          ? "text-danger"
          : ""
      }`}
      style={{ height: "calc(100vh - 80px)" }}
    >
      <div>
        <h1>{message}</h1>
      </div>
      <div className="py-5">
        <KiedButton
          content={"Home"}
          onClick={() => {
            history("/");
          }}
        />
      </div>
    </div>
  );
};

export default VerifyEmail;
