import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SchemeHerosection() {
  let width = useScreenBreakPoint();
  return (
    // <div className="auto-container mt-3  mb-5">
    //   <div className="row d-flex justify-content-center">
    //     <div className="col-md-4 col-sm-12">
    //       <KiedImages className="w-100" image="scheme" />
    //     </div>
    //     <div className="col-md-7 col-s-12 d-flex flex-column align-items-start justify-content-center">
    //       <div className="schemes_heading mb-4">
    //         KSIDC Entrepreneurship Learn to pronounce development / StartUp
    //       </div>
    //       <div className="Schemes_heading_description">
    //         Kerala State Industrial Development Corporation Ltd. (KSIDC), an
    //         industrial and investment promotion agency of the Government of
    //         Kerala has been involved in various Startup initiatives like
    //         Business Incubation Centres, mentoring sessions for young
    //         entrepreneurs, seed funding for innovative start-ups etc.
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div>
      <div
        className={`auto-container  ${
          width === "xs" || width === "sm"
            ? "sml mt-5 d-flex justify-content-center"
            : "lr mt-5 pt-2  mb-5 "
        }`}
      >
        <div className="row d-flex justify-content-between w-100">
          <div className="col-lg-4 col-md-4  col-sm-12 d-flex justify-content-start">
            <KiedImages
              className="w-100"
              style={{
                height: "100%",
                objectFit: "cover",
                borderRadius: "15px",
              }}
              image="ess"
            />
          </div>
          <div
            className={`col-lg-8 col-md-8 col-sm-12 d-flex flex-column align-items-start  ${
              width === "xs" || width === "sm"
                ? "sml  d-flex justify-content-center"
                : "pl-5 "
            }`}
          >
            <div className="schemes_heading mb-4">
              <span className="p-1">
                {/* Who we are{" "} */}
                {/* <KiedImages
                className="pb-1"
                style={{ width: "20px" }}
                image="question-icon"
              /> */}
              </span>
            </div>
            <div
              className={`schemes_heading  ${
                width === "xs" || width === "sm" ? "sml mb-3" : "mb-5"
              }`}
            >
              KSIDC Entrepreneurship Learn to pronounce development / StartUp
            </div>
            <div className={`Schemes_heading_description p-1 `}>
              Kerala State Industrial Development Corporation Ltd. (KSIDC), an
              industrial and investment promotion agency of the Government of
              Kerala has been involved in various Startup initiatives like
              Business Incubation Centres, mentoring sessions for young
              entrepreneurs, seed funding for innovative start-ups etc.
            </div>
            <div
              className={`Schemes_heading_description Schemes_new_discription  ${
                width === "xs" || width === "sm"
                  ? "sml  mt-3 p-3 pb-3"
                  : "mt-3 "
              } `}
            >
              <div className=" Schemes_heading_description mb-5">
                The Innovative finance scheme was launched as part of YES (Young
                Entrepreneur Summit 2014) initiative. Under the scheme,
                innovative ventures are given seed fund by way of soft loan upto
                90% of the project cost or Rs.25 lakhs whichever is lower. The
                seed fund assistance will be sanctioned as Soft Loan for a
                period of three year at the prevailing RBI bank rate as on the
                date of sanction of financial assistance. The company can either
                repay the loan or convert it to equity limited to19% on mutually
                agreed terms within 3 years from the date of disbursement.
                <br /> Apart from this, for the innovative start-ups which have
                started commercial operation has the option to avail scale up
                loan assistance @7% interest rate/equity assistance up to 80% of
                the scale up project cost or Rs.50lakhs whichever is lower. This
                loan shall be repaid in 3 years.
                <br /> Under these schemes, the Project Evaluation Memorandum
                and specific Term Sheet shall be placed before the expert
                Committee constituted for evaluation of the start-up proposals.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchemeHerosection;
