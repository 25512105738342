import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import KiedButton from "../buttons/button/button.component";
import "./events.listing.card.styles.css";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
function EventsListingCard(props) {
  let width = useScreenBreakPoint();
  const navigate = useNavigate()
  console.log(props.data.link)
  const Navtodetails =(id) => {
    
    if(props.type === "EventList"){
      navigate(`/events/${id}`)
    } else if (props.type === "ProgramList"){
      navigate(`/program/${id}`)
    } else {
      navigate(`/schemes/${id}`)
    }
  }
  return (
    <div className=" mb-2 w-100 ml-2 " style={{backgroundColor:"#fff"}}>
      {/* <div className="d-flex  h-100 w-100">
        <div className="   ribbonevent ">
          <span className="">{props.data.status}</span>
        </div>
      </div> */}

      <div>
        <div className="auto-container">
          <div className="d-flex align-items-center justify-content-between">
            {props.data.scheme_owner ? (
              <div className="text_black d-flex flex-column pl-5 col-2">
                <b
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#F8463F",
                    color: "#fff",
                    borderRadius: "5px",
                  }}
                  className="text-center"
                >
                  {props.data.scheme_owner}
                </b>
                {/* <b>{props.data.endTime}</b> */}
              </div>
            ) : (
              <div className="text_black d-flex flex-column pr-3">
                <b>{props.data.startTime}</b>
                {/* <b>{props.data.endTime}</b> */}
              </div>
            )}
            {props.data.status && (
              <div
                className="text_black d-flex text-center align-items-center justify-content-center "
                style={{
                  width: "95px",
                  // padding: "5px 10px",

                  backgroundColor: "#F8463F",
                  color: "#fff",
                  borderRadius: "5px",
                }}
              >
                <div>
                  <strong>{props.data.status}</strong>
                </div>
                {/* <b>{props.data.endTime}</b> */}
              </div>
            )}
            <div className="d-flex align-items-center lsitingImage ">
              <KiedImages
                className="mb-1 mr-1 w-100"
                link={props.data.listingImage}
              />
            </div>
            <div className="d-flex flex-column col-6">
              <div className="d-flex pl-3">
                <span className="listingTitle ">{props.data.title}</span>
                {props.data.location && (
                  <span className="px-3 listingTitle">
                    | @{props.data.location}
                  </span>
                )}
              </div>
              <div
                className="listingText pl-3 "
                style={{ lineHeight: "1em !important" }}
                // style={{
                //   textOverflow: "ellipsis",
                //   width: "370px",
                //   whiteSpace: "nowrap",
                //   overflow: "hidden",
                // }}
              >
                {props.data.cardDescription
                  ? parse(props.data.cardDescription)
                  : "--"}
              </div>
            </div>
            <div className="listingreadmore_btn h-100">
              <KiedButton
                className="w-100 readmore_btn "
                primary
                onClick = {()=> Navtodetails(props?.data?.id)}
                rounded
                content={
                  
                    "Read More"
                  
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsListingCard;
