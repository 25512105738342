export const AdvisoryCommittee = [
    {
      id: 1,
      Image: "team-full",
      Name: "Mark Liuvw",
      Designation: "General Manager",
    },
    {
      id: 2,
      Image: "team-full",
      Name: "Mark Liuvw",
      Designation: "General Manager",
    },
    {
      id: 3,
      Image: "team-full",
      Name: "Mark Liuvw",
      Designation: "General Manager",
    },
    {
      id: 4,
      Image: "team-full",
      Name: "Mark Liuvw",
      Designation: "General Manager",
    },
    {
      id: 5,
      Image: "team-full",
      Name: "Mark Liuvw",
      Designation: "General Manager",
    },
    {
      id: 6,
      Image: "team-full",
      Name: "Mark Liuvw",
      Designation: "General Manager",
    },
    {
      id: 7,
      Image: "team-full",
      Name: "Mark Liuvw",
      Designation: "General Manager",
    },
    {
      id: 8,
      Image: "team-full",
      Name: "Mark Liuvw",
      Designation: "General Manager",
    },
  ];