import React from "react";
// import SmallBanner from "../../components/small.banner/small.banner";
import TextField from "../../components/textfield/TextField.component";
import RadioButton from "../../components/radiobutton/radiobutton";
import SelectBox from "../../components/selectbox/SelectBox.component";
import KiedButton from "../../components/buttons/button/button.component";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FileUpload from "../../components/file.upload/fileupload.component";
import { registeredclub } from "../../actions/api.functions";
import "./edcform.css";

import {
  KiedCarriers,
  gender,
  castCategory,
  radiooptions,
} from "../edc.registration/registrationform.data";
import {
  districts,
  qualification,
} from "../registration/registrationform.data";
import { developmentStage } from "./store";

function EDCFirstForm(props) {
  const [formdata, setFormdata] = useState({
    founder_details: [
      {
        name: "",
        qualification: "",
        experience: null,
        financial_details: "",
        pan_number: "",
        aadhar_number: "",
        address: "",
        email: "",
        contact_number: "",
      },
    ],
  });
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);
  //   const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();

  const handleTextFieldChange = (value, name) => {
    console.log(value, name);
    setFormdata({ ...formdata, [name]: value });
  };

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
    console.log(e.target.value, typeof e.target.value);
  };

  const handleregister = () => {
    // navigate("/success");
    props.setActiveStep(2);
  };

  const fileUpload = (e) => {
    console.log(formdata);
    console.log(e.target.files);
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    // accept = ".pdf, .png, .jpeg";
    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);

    if (regVal.test(filename)) {
      setFormdata({
        ...formdata,
        [name]: value,
        [name + "_name"]: filename,
      });
    } else {
      setFormdata({
        ...formdata,
        [name]: undefined,
        [name + "_name"]: undefined,
      });
      setError({ [name]: true });
    }
  };
  const fileFounderUpload = (e, i) => {
    // console.log(formdata);
    // console.log(e.target.files);
    let data = { ...formdata };
    let a = true;
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    // accept = ".pdf, .png, .jpeg";
    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);
    const setFounderData = (a) => {
      let upData = data?.founder_details?.forEach((item, j) => {
        if (i === j) {
          if (a) {
            item[name] = value;
          } else {
            item[name] = undefined;
          }
        }
      });
      return upData;
    };

    if (regVal.test(filename)) {
      setFormdata({
        ...formdata,
        founder_details: setFounderData(a),
      });
    } else {
      setFormdata({
        ...formdata,
        founder_details: setFounderData(!a),
      });
      setError({ [name]: true });
    }
  };
  const handleAdd = () => {
    let data = { ...formdata };
    data?.founder_details.push({
      name: "",
      qualification: "",
      experience: null,
      financial_details: "",
      pan_number: "",
      aadhar_number: "",
      address: "",
      email: "",
      contact_number: "",
    });
    setFormdata(data);
  };

  const handleRemove = (i) => {
    let data = { ...formdata };
    data?.founder_details.splice(i, 1);
    setFormdata(data);
  };

  const handleFounderChange = (value, name, i) => {
    let data = { ...formdata };
    console.log(name, value);
    // data?.founder_details[i] = {
    //   ...data?.founder_details[i] ,
    //   [e.target.name] : e.target.value
    // }

    const setFounderData = () => {
      let upData = data?.founder_details?.forEach((item, j) => {
        if (i === j) {
          item[name] = value;
        }
      });
      return upData;
    };

    setFormdata({ ...data, founder_details: setFounderData() });

    setFormdata(data);
  };
  console.log(formdata?.founder_details.length);
  return (
    <>
      <div className="auto-container form">
        <form>
          <TextField
            label="Title of the Startup/Entreprise "
            required
            name="title"
            value={formdata.title ?? ""}
            onChange={(val, name) => handleTextFieldChange(val, name)}
          />
          <br />
          <TextField
            label="Date of Incorporation"
            required
            type="date"
            name="incorporation_date"
            value={formdata.incorporation_date ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          {/* <FileUpload
            label="Attach Company Incorporation Documents (.pdf format files are allowed)"
            required
            accept=".pdf"
            name="incorporation_documents"
            type="file"
            // filename={formdata.gst_file_name}
            // value={formdata.gst_file ?? null}
            onChange={fileUpload}
          />
          <br /> */}
          <FileUpload
            label="Attach the Logo of Startup/Enterprise (.pdf format files are allowed)"
            required
            accept=".pdf"
            name="logo"
            type="file"
            // filename={formdata.gst_file_name}
            // value={formdata.gst_file ?? null}
            onChange={fileUpload}
          />
          <br />
          <TextField
            label="Website Link of the Startup/Enterprise"
            type="text"
            name="website_link"
            value={formdata.website_link ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="Social Media Links of the Startup/Enterprise"
            type="text"
            name="social_link"
            value={formdata.social_link ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />

          <TextField
            label="Registered Address of Startup/Enterprise"
            type="text"
            name="address"
            value={formdata.address ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="Startup/Enterprise sector or Technology Area"
            type="text"
            name="technology_area"
            value={formdata.technology_area ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <div>
            <div className="edc-common-label">
              Startup Founder(s) and Co Founder(s) Details
            </div>
            <br />
            {formdata?.founder_details.map((item, i) => (
              <>
                <div>
                  <TextField
                    label="Name"
                    type="text"
                    name="name"
                    value={item.name ?? ""}
                    onChange={(val, name) => handleFounderChange(val, name, i)}
                  />
                  <br />
                  <TextField
                    label="Educational Qualification"
                    type="text"
                    name="qualification"
                    value={item.qualification ?? ""}
                    onChange={(val, name) => handleFounderChange(val, name, i)}
                  />
                  <br />
                  <FileUpload
                    label="Professional Experience (Attach CV) (.pdf format files are allowed)"
                    required
                    accept=".pdf"
                    name="experience"
                    type="file"
                    // filename={formdata.gst_file_name}
                    // value={formdata.gst_file ?? null}
                    onChange={(val, name) => fileFounderUpload(val, name, i)}
                  />
                  <br />
                  <TextField
                    label="Financial Details"
                    type="text"
                    name="financial_details"
                    value={item.financial_details ?? ""}
                    onChange={(val, name) => handleFounderChange(val, name, i)}
                  />
                  <br />
                  <TextField
                    label="PAN Card"
                    type="text"
                    name="pan_number"
                    value={item.pan_number ?? ""}
                    onChange={(val, name) => handleFounderChange(val, name, i)}
                  />
                  <br />
                  <TextField
                    label="Aadhaar Number"
                    type="text"
                    name="aadhar_number"
                    value={item.aadhar_number ?? ""}
                    onChange={(val, name) => handleFounderChange(val, name, i)}
                  />
                  <br />
                  <TextField
                    label="Address"
                    type="text"
                    name="address"
                    value={item.address ?? ""}
                    onChange={(val, name) => handleFounderChange(val, name, i)}
                  />
                  <br />
                  <TextField
                    label="Contact Email"
                    type="text"
                    name="email"
                    value={item.email ?? ""}
                    onChange={(val, name) => handleFounderChange(val, name, i)}
                  />
                  <br />
                  <TextField
                    label="Contact Number"
                    type="number"
                    maxLength={10}
                    minLength={10}
                    name="contact_number"
                    value={item.contact_number ?? ""}
                    onChange={(val, name) => handleFounderChange(val, name, i)}
                  />
                  <br />
                </div>
                <div className="d-flex justify-content-end">
                  {formdata?.founder_details.length === i + 1 && (
                    <div className="m-2">
                      <KiedButton
                        onClick={() => handleAdd()}
                        primary
                        rounded
                        type="button"
                        content={
                          <div className="small d-flex justify-content-center">
                            Add
                          </div>
                        }
                      />
                    </div>
                  )}
                  {formdata?.founder_details.length > 1 && (
                    <div className="m-2">
                      <KiedButton
                        onClick={() => handleRemove(i)}
                        primary
                        rounded
                        type="button"
                        content={
                          <div className="small d-flex justify-content-center">
                            Remove
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
          <hr />
          <br />

          <TextField
            label="Mentor Details"
            name="mentor_details"
            value={formdata.mentor_details ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <TextField
            label="Brief Description of the Technology/ Product: 50 words"
            type="textarea"
            required
            name="technology_description"
            value={formdata.technology_description ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <br />
          <TextField
            label="Novelty/ USP/ Innovation of the Technology/ Product"
            required
            name="product_type"
            value={formdata.product_type ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          <SelectBox
            label="Stage of development"
            required
            options={developmentStage}
            value={formdata.development_stage}
            selectionMessage="Select Development Stage"
            name="development_stage"
            handleChange={handleChange}
          />
          <br />
          <TextField
            label="Scope of Scalability"
            required
            type="textarea"
            name="scalability_scope"
            value={formdata.scalability_scope ?? ""}
            onChange={handleTextFieldChange}
          />
          <br />
          {/* <TextField
            label="Email ID of the Faculty Coordinator"
            required
            name="faculty_email"
            value={formdata.faculty_email ?? ""}
            onChange={handleTextFieldChange}
          /> */}
          <RadioButton
            options={radiooptions}
            label="Have you applied for incubation/ funding from any other agency in India or abroad?"
            required
            name="other_application"
            checked={formdata.other_application ?? ""}
            value={formdata.other_application ?? ""}
            onChange={handleChange}
          />
          <br />
          {formdata?.other_application === "Yes" && (
            <>
              <TextField
                label="Any Other Relevant Information"
                type="textarea"
                name="relevant_information"
                value={formdata.relevant_information ?? ""}
                onChange={handleTextFieldChange}
              />

              <br />
            </>
          )}
        </form>
        <div className="d-flex justify-content-center pt-5 pb-5">
          <KiedButton
            onClick={() => handleregister()}
            primary
            rounded
            type="submit"
            content={
              <div className="registerbtn d-flex justify-content-center">
                Submit
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}

export default EDCFirstForm;
