// import React from 'react'

// function BuildingSupportingSchemes() {
//   return (
//     <div>BuildingSupportingSchemes</div>
//   )
// }

// export default BuildingSupportingSchemes

import React from "react";
// import "../../schemes.page.styles.css";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function BuildingSupportingSchemes() {
  let width = useScreenBreakPoint();
  return (
    <div>
      <div className="auto-container">
        <div
          className={`schemes_heading  ${
            width === "xs" || width === "sm" ? "sml mt-5" : "lr  mb-5 mt-5"
          }`}
        >
          Stages in Entrepreneur Support Scheme
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-3 mt-5 mb-5"
          } `}
        >
          1.Objectives
        </div>
        <div className=" Schemes_heading_description mb-5">
          To create and foster strong and enduring linkages between enterprises
          within the state and institdiion having established research
          facilities and testing laboratories.To extend financial assistance to
          the Technical University, for fostering innovation, providing
          technology solutions and developing and demonstrating new products,
          designs and processes.For conducting studies/research on specific
          issues relating to various industry- related sectors in the State
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-3 mt-5"
          } `}
        >
          2.Key features
        </div>
        <div className=" Schemes_heading_description mb-5">
          To undertake research for providing technology solutions to problems
          faced by industry particularly in the areas of improving quality and
          productivity, enhancing mechanization, adoption of Non-conventional
          energy sources, mitigating pollution etc.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-3 mt-5"
          } `}
        >
          3.Pattern of Assistance
        </div>
        <div className=" Schemes_heading_description mb-5">
          Each student engaged in the Research Programme shall be, assisted with
          a grant of Rs 10,000 per month for a maximum period of 6 months during
          the financial year 2022-23.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-3 mt-5"
          } `}
        >
          4.Eligibility for applicants
        </div>
        <div className=" Schemes_heading_description mb-5">
          Students under the APJ Abdul Kalam Technological University shall be
          eligible for assistance under the Scheme.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr mb-3  mt-5"
          } `}
        >
          5.Entitlement of a successful applicant
        </div>
        <div className=" Schemes_heading_description mb-5">
          For research that aims at providing technology solutions o problems
          faced by industry/Enterprises, entitlement under the scheme shall be
          lmited to an amount of Rs. 10,000 per month for each student and the
          assistance is limited for a peripd of 6 months.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr mb-3  mt-5"
          } `}
        >
          6.Powers and Services offered by the sanctioning authority
        </div>
        <div className=" Schemes_heading_description mb-5">
          The power to sanction assistances under the scheme shll be vested with
          the Director of Industries & Commerce
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr mb-3  mt-5"
          } `}
        >
          7. Funds
        </div>
        <div className=" Schemes_heading_description mb-5">
          The cost of the Programmes is estimated to be Rs.12,00,000 (Rupees
          Twelve lakh only). The expenditure for this proposal can be met from
          the Head of Account 281 -00-001-93 provided in the annual plan
          2022-23.The Director of Industries &Commerce have the full powers to
          prepare apj1ications forms, form of agreement and any other formats
          required for the proper implementation of the scheme, and to make,
          sub-rules, check list if any, reqiired; without altering the
          guidelines.
        </div>
      </div>
    </div>
  );
}

export default BuildingSupportingSchemes;
