import React from "react";

function FaqBanner() {
  return (
    <div>
      <div className="banner_image mb-0">
        <div className="faq_banner d-flex justify-content-center banner_title">
          <h3>Frequently asked questions</h3>
        </div>
      </div>
    </div>
  );
}

export default FaqBanner;
