import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import "./mentors.card.styles.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import KiedButton from "../buttons/button/button.component";

function MentorsCard(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handlePopup = () => {
    setShow(true);
  };
  const handleNav = () => {
    window.open(props.data.link, "_blank");
  };
  return (
    <>
      <div className="col-md-4 col-sm-12 mt-5 d-flex justify-content-around ">
        <div className="col-12 mb-4 mentor_card_full">
          <div
            className="mentor_card  d-flex flex-column align-items-center card_mentor "
            style={{ display: "flex", justifycontent: "center" }}
          >
            <div className="d-flex justify-content-center mentor-picture">
              {/* <KiedImages className="mentor_image" image={props.data.photo} /> */}
              <img className="mentor_image" src={props.data.photo} />
            </div>
            <div className="mentor_name d-flex flex-column justify-content-between align-items-center">
              <div>{props.data.mentor_name}</div>
              <div className="mentor_designation pt-3">
                <KiedButton
                  secondary
                  square
                  onClick={() => {
                    handlePopup();
                  }}
                  type="submit"
                  className="squre-button2"
                  content={
                    <div className=" d-flex justify-content-center">
                      View Profile
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {show === true && (
        <div className="w-100">
          <Modal
            className=" w-100"
            style={{ marginTop: "150px" }}
            show={show}
            onHide={handleClose}
          >
            <Modal.Header>
              <Modal.Title className="forgot_title w-100 d-flex justify-content-center">
                Profile
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="d-flex flex-column align-items-center">
                <KiedImages
                  image={props.data.image}
                  className="my-3"
                  style={{ borderRadius: "10px" }}
                />
                <div className="d-flex justify-content-start">
                  <span className="companyName my-3">{props.data.name}</span>
                </div>
                <div style={{ padding: "5px" }}>{props.data.description}</div>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button
                className="forgot_button_secondary"
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </Button>
              <Button
                className="forgot_button_primary"
                onClick={() => {
                  handleNav();
                }}
              >
                Visit Profile
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}

export default MentorsCard;
