import React from "react";

function DirectorySearch() {
  return (
    <div className="auto-container">
      <div className="mt-5 pt-2 mb-5 ">
        <form>
          <input
            className=" directory_search_box"
            type="text"
            placeholder="Search.."
            name="Search"
          />
          <button className=" directory_search_button" type="submit">
            {" "}
            Search
          </button>
        </form>
      </div>
    </div>
  );
}

export default DirectorySearch;
