import React from "react";
// import KiedIcons from "../../../../assets/icons/kied.icons.component";

// import KiedButton from "../../../../components/buttons/button/button.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";

function HeroSection() {
  let display_mode = useScreenBreakPoint();
  return (
    <>
    <div id="kied-hero-section">
      <div className="h-100 ">
        <video
          height={"100%"}
          width={"100%"}
          controls={true}
          muted={false}
          autoPlay={true}
          loop={false}
        >
          <source
            // src={`${process.env.PUBLIC_URL}/videos/resourses/hero_new.mp4`}
            src={`https://mastec-s3-video.s3.ap-south-1.amazonaws.com/kied+edc.mp4`}
            type="video/mp4"
          />
          Your browser does not support video.
        </video>
      </div>
      {/* <div className="hero-overlay h-100  ">
        <div
          className={` d-flex  align-items-end h-100	w-100 ${
            display_mode === "sm" || display_mode === "xs" ? "" : ""
          }`}
        >
          <div className="  d-flex flex-column w-100 ">
            <div
              className={` hero-title  w-100	${
                display_mode === "sm" || display_mode === "xs"
                  ? "header-font-mob"
                  : "d-flex flex-column justify-content-left "
              }`}
              style={{ paddingLeft: "22px" }}
            >
              <h1 className="hero-overlay-text-grad">Enterprise Development Center (EDC)</h1>
              <h4>
                A hub for entrepreneurs to realize and advance their unique
                business
              </h4>
            </div>
            
          </div>
          ///////////////////////////////////////////////////
          <div>
            <div
              className={`hero-text ${
                display_mode === "sm" || display_mode === "xs"
                  ? "w-100"
                  : "w-50 mx-4"
              } `}
            >
              Enterprise Development Center (EDC) is a place which empowers
              innovative entrepreneurs to Plan, Start, Sustain, Revive and Grow
              their business. Services offered include incubation, acceleration,
              industry academic collaboration, facilitation & guidance,
              financial linkages and student entrepreneur development
              activities.
            </div>
          </div>
          <div className="hero-button mx-4">
            <KiedButton
              content={
                <>
                  <div className="d-flex p-2">
                    <div className="d-flex align-items-center">Explore Now</div>
                    <div className="d-flex align-items-center">
                      <KiedIcons
                        icon="arrow-right"
                        className="ml-2 hero-button-icon"
                      />
                    </div>
                  </div>
                </>
              }
              secondary
              rounded
              big
            />
          </div>
          //////////////////////////////////////////
        </div>
      </div> */}
      <div className="banner-stats-container d-flex justify-content-around w-100 ">
              <div className="d-flex flex-column banner-stats align-items-center justify-content-center  w-100">
                <span className="stats-value">120+</span>
                <span className="stats-heading">Programs</span>
              </div>
              <div className="d-flex flex-column banner-stats align-items-center justify-content-center  w-100">
                <span className="stats-value">26000+</span>
                <span className="stats-heading">Man Days</span>
              </div>
              <div className="d-flex flex-column banner-stats align-items-center justify-content-center  w-100">
                <span className="stats-value">9000+</span>
                <span className="stats-heading">Participants</span>
              </div>
              <div className="d-flex flex-column banner-stats-left align-items-center justify-content-center  w-100">
                <span className="stats-value">6+</span>
                <span className="stats-heading">Initiatives</span>
              </div>
            </div>
    </div>
    </>
  );
}

export default HeroSection;
