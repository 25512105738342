import React from "react";

function SubmitSolutionsBreadcrumb() {
  return (
    <div>
      <div className="faq_banner pl-5 d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/home">IAC&nbsp;/</a>
        <a href="/department/academy-collaboration/program">
          {" "}
          Program Details&nbsp;/
        </a>
        <a
          className="current"
          href="/department/academy-collaboration/programs/submit-solution"
        >
          Submit your Solutions
        </a>
      </div>
    </div>
  );
}

export default SubmitSolutionsBreadcrumb;
