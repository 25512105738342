import React from "react";
import "./offering.card.styles.css";
import KiedImages from "../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";

function OfferingCard(props) {
  let width = useScreenBreakPoint();

  return (
    <>
      {/* <div className="col-md-2 col-sm-12 d-flex flex-column align-items-center mt-5">
      <div className="">
        <KiedImages className="offering_card_image" image={props.data.image} />
      </div>

      <div className="offering_card_title">{props.data.name}</div>
    </div> */}
      <div className="d-flex col-md-6 col-sm-12 ">
        <div
          className={`d-flex  ${
            width === "xs" || width === "sm"
              ? "overall-responsive-container"
              : "overall-container"
          }`}
        >
          <div className="">
            <KiedImages className="overall-image" image="overallTick" />
          </div>
          <div>
            <div className="overall-name">{props.data.title}</div>
            <div className="overall-description">{props.data.description}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfferingCard;
