import React from "react";
import "./dashInfo.styles.css";

function DashInfo({ title, value, file }) {
  console.log(value);
  return (
    <>
      <div className="d-flex">
        <div className="d-flex justify-content-between col-6 pr-1">
          <span className="formstyle p-0">{title}</span>
          <span className="formstyle p-0">:</span>
        </div>
        <div className="col-6">
          {file ? (
            <a href={value} target="_blank">
              <button
                className="btn  text-white"
                style={{ backgroundColor: "#a1513b" }}
              >
                {" "}
                Download
              </button>
            </a>
          ) : (
            <span className="formstyle p-0">{value}</span>
          )}
        </div>
      </div>
      <br />
    </>
  );
}

export default DashInfo;
