import React, { forwardRef } from "react";
import RegistrationCard from "../../../../components/registration.cards/registration.card";
import { registrationData } from "./registrationData";
const RegistrationSection = forwardRef((props, ref) => {
  console.log(props);
  return (
    <div
      ref={ref}
      className="common-sections head-container px-2 pt-5 mt-5 mb-5 pb-5 px-sm-0 "
    >
      <div className="sec-title text-center">
        <h2 className="sec-heading" style={{ marginBottom: "48px" }}>
          {props.heading}
        </h2>
      </div>
      <div className="row mx-0">
        {registrationData.map((card, i) => (
          <RegistrationCard {...props} data={card} key={i} index={i} chess nextPath={props.nextPath} />
        ))}
      </div>
    </div>
  );
});

export default RegistrationSection;
