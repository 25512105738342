// import { ServInstance } from "../config";
import axios from "axios";
// import serverApi from "./core";
import { Logout } from "../AppRouter";
import { useLoginStore } from "../utils/zustand";

const servInstance = process.env.REACT_APP_BASE_URL;

axios.defaults.headers.common = {
  "Access-Control-Allow-Origin": "*",
  Accept: "application/json",
};



export const refreshheaderToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }
};

const isLogged = useLoginStore.getState().isLogin
const api = {
  get: (URL, queryParams, type = {}) =>
    axios
      .get(servInstance + URL, queryParams, type)
      .then((res) => 
      {
        if(res.status == 401) {
          Logout()
          useLoginStore.setState().clearLogin()
          return null
        }
        return res.data
      }
      ),
      
		// serverApi.get(servInstance + URL),
  post: (URL, reqData = {}) =>
    axios.post(servInstance + URL, reqData).then((res) => {
      if(res.status == 401) {
        Logout()
        useLoginStore.setState().clearLogin()
        return null
      }
      return res.data
    })
		,
		// serverApi.post(servInstance + URL),

  put: (URL, reqData = {}) =>
    axios.put(servInstance + URL, reqData).then((res) => {
      if(res.status == 401) {
        Logout()
        useLoginStore.setState().clearLogin()
        return null
      }
      return res.data
    }),
		// serverApi.put(servInstance + URL),

  delete: (URL, reqData = {}) =>
	axios.delete(servInstance + URL, reqData).then((res) => {
    if(res.status == 401) {
      Logout()
      useLoginStore.setState().clearLogin()
      return null
    }
    return res.data
  }),
    // serverApi.delete( servInstance+ URL)
};
export default api;
