import React from "react";
import MentorsCard from "../../../../components/mentors.card/mentors.card";

function NewProgramMentors(props) {
  console.log(props)
  return (
    <div className="greyBackground">
      <div className="auto-container pb-5">
        <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <h2 className="inovation_headings mt-5">Mentors</h2>
          </div>
        </div>

        <div className="">
          <div className="row justify-content-center">
            {props?.data?.mentor?.map((card, i) => {
              return <MentorsCard data={card} key={i} index={i} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewProgramMentors;
