import React from "react";
import AboutBanner from "./sections/banner/about.banner";
import AboutEDC from "./sections/edc/about.edc";
import AboutKIED from "./sections/kied/about.kied";

function AboutPage() {
  return (
    <div className="">
      <AboutBanner />
      <AboutKIED />
      <AboutEDC />
    </div>
  );
}

export default AboutPage;
