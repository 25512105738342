import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import { Accordion } from "react-bootstrap";
import KiedImages from "../../../../assets/images/kied.images.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";

function DirectoryProblems() {
  return (
    <div className="auto-container mt-5 mb-5 pt-5 pb-5">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className=" banner_image directory_acordion">
            <div className="">
              <div className="d-flex justify-content-between">
                <span>
                  This is the heading of a particular problem contain Demo Text
                </span>
                <span className="problems-round">
                  <KiedImages className="problem-image" image="down-arrow" />
                </span>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className=" directory_content">
            <span>
              {" "}
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatibus soluta repellendus in quos est esse dolorum autem,
              odio enim facere ullam excepturi cum nemo necessitatibus iste ipsa
              quisquam, perferendis commodi. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Debitis, consequuntur. Eaque ex
              consequuntur sit quaerat nulla tenetur ducimus, quibusdam labore
              sunt voluptates earum excepturi aperiam non architecto numquam
              explicabo cupiditate.
            </span>
            <div className="mt-5 mb-5 d-flex justify-content-center">
              <KiedButton
                className=" problem-button"
                content={
                  <>
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        Submit a Soultion
                      </div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                square
                big
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="mt-3">
          <Accordion.Header className=" banner_image directory_acordion">
            <div className="">
              <div className="d-flex justify-content-between">
                <span>
                  This is the heading of a particular problem contain Demo Text
                </span>
                <span className="problems-round">
                  <KiedImages className="problem-image" image="down-arrow" />
                </span>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className=" directory_content">
            <span>
              {" "}
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatibus soluta repellendus in quos est esse dolorum autem,
              odio enim facere ullam excepturi cum nemo necessitatibus iste ipsa
              quisquam, perferendis commodi. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Debitis, consequuntur. Eaque ex
              consequuntur sit quaerat nulla tenetur ducimus, quibusdam labore
              sunt voluptates earum excepturi aperiam non architecto numquam
              explicabo cupiditate.
            </span>
            <div className="mt-5 mb-5 d-flex justify-content-center">
              <KiedButton
                className=" problem-button"
                content={
                  <>
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        Submit a Soultion
                      </div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                square
                big
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="mt-3">
          <Accordion.Header className=" banner_image directory_acordion">
            <div className="">
              <div className="d-flex justify-content-between">
                <span>
                  This is the heading of a particular problem contain Demo Text
                </span>
                <span className="problems-round">
                  <KiedImages className="problem-image" image="down-arrow" />
                </span>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className=" directory_content">
            <span>
              {" "}
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatibus soluta repellendus in quos est esse dolorum autem,
              odio enim facere ullam excepturi cum nemo necessitatibus iste ipsa
              quisquam, perferendis commodi. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Debitis, consequuntur. Eaque ex
              consequuntur sit quaerat nulla tenetur ducimus, quibusdam labore
              sunt voluptates earum excepturi aperiam non architecto numquam
              explicabo cupiditate.
            </span>
            <div className="mt-5 mb-5 d-flex justify-content-center">
              <KiedButton
                className=" problem-button"
                content={
                  <>
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        Submit a Soultion
                      </div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                square
                big
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default DirectoryProblems;
