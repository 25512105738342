import React from "react";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SchemeCanapply(props) {
  let width = useScreenBreakPoint();
  return (
    // <div className="auto-container pt-5 pb-3">
    //   <div className="d-flex flex-column align-items-start justify-content-center">
    //     <div className="schemes_heading mb-5">How to apply</div>
    //     <div className="Schemes_heading_description ">
    //       The application can be submitted online along with the documents as
    //       per the checklist using the link given at the bottom. The application
    //       will be processed by the Sanctioning Authority and the assistance will
    //       be sanctioned based on merit.
    //     </div>
    //   </div>
    //   <hr />
    //   <div className="schemes_heading mb-3 mt-5">Sanctioning Authority</div>
    //   <ul className="ml-4 Schemes_heading_description pt-3 mb-5">
    //     <li>
    //       For Startup Support - General Manager, District Industries Centre
    //     </li>
    //     <li>
    //       {" "}
    //       For Investment Support - District Level Committee consisting of the
    //       following Members;.
    //       <ul className="sub_list">
    //         <li>District Collector (Chairman)</li>
    //         <li>Lead District Manager</li>
    //         <li>Representative of Finance Department in Government</li>
    //         <li>District Manager, KFC</li>
    //         <li>Representative of KSSIA District Committee</li>
    //         <li>
    //           General Manager, District Industries Centre (Member Secretary)
    //         </li>
    //       </ul>
    //     </li>
    //   </ul>
    //
    //   <hr />
    // </div>

    <div style={{ backgroundColor: "#f2f3f4 " }} className="pb-5">
      <div
        className={` row head-container  d-flex justify-content-between ${
          width === "xs" || width === "sm" ? "sml pt-5 " : "lr mt-5 pt-5"
        } `}
      >
        <div className="col-lg-6 col-md-6 col-sm-12  ">
          <div
            className={`schemes-listing-card  ${
              width === "xs" || width === "sm" ? "sml p-3" : "lr"
            }`}
          >
            <div
              className={`schemes_heading text-dark  ${
                width === "xs" || width === "sm"
                  ? "sml pt-2 "
                  : "lr mb-3 mt-5 pl-3"
              } `}
            >
              {" "}
              ENTERPRISES UNDER PRIORITY SECTOR
            </div>
            <div className="px-3">
              <ul
                className={`Schemes_heading_description  ${
                  width === "xs" || width === "sm"
                    ? "sml pt-2 p-0"
                    : "pt-3 mb-5 ml-4"
                } `}
              >
                <li>
                  <p>Rubber based industries</p>
                </li>
                <li>
                  <p>Readymade garments</p>
                </li>
                <li>
                  <p>
                    Industries manufacturing equipments and machiney <br /> for
                    non conventional energy generation{" "}
                  </p>
                </li>
                <li>
                  <p>Biotechnology based industries</p>{" "}
                </li>
                <li>
                  <p>100% export oriented units</p>
                </li>
                <li>
                  <p>Biodegradable plastic industries</p>
                </li>
                <li>
                  <p>Plastic waste recycling industries</p>
                </li>
                <li>
                  <p>Bio fertilizer industries</p>
                </li>
                <li>
                  <p>Pharmaceutical industries</p>
                </li>
                <li>
                  <p>Healthcare products manufacturing industries</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12  ">
          <div
            className={`schemes-listing-card  ${
              width === "xs" || width === "sm" ? "sml p-3 mt-3" : "lr"
            }`}
          >
            <div
              className={`schemes_heading text-dark  ${
                width === "xs" || width === "sm"
                  ? "sml pt-2 "
                  : "lr mb-3 mt-5 pl-3"
              } `}
            >
              {" "}
              ENTERPRISES UNDER NEGATIVE LIST
            </div>
            <div className="px-3">
              <ul
                className={`Schemes_heading_description schemes-list  ${
                  width === "xs" || width === "sm"
                    ? "sml pt-2 p-0 text-left "
                    : "pt-3 mb-5 ml-4"
                } `}
              >
                <li>
                  <p>Service Enterprises</p>
                </li>
                <li>
                  <p>Photo studios and Color processing centers</p>
                </li>
                <li>
                  <p>Tailors other than manufacturing of readymade garments</p>
                </li>
                <li>
                  <p>Breweries and Distilleries of all types</p>{" "}
                </li>
                <li>
                  <p>
                    Saw mills, Soap Grade Soldium Salicate, Asbestos processing
                  </p>
                </li>
                <li>
                  <p>Metal Crushes including granite manufacturing units</p>
                </li>
                <li>
                  <p>All types of steel re-rolling Mills </p>
                </li>
                <li>
                  <p>Units manufacturing iron ingots, calcium carbide</p>
                </li>
                <li>
                  <p>Bio fertilizer industries</p>
                </li>
                <li>
                  <p>
                    Cement manufacturing except units manufacturing cement from
                    fly ash, Potassium Chlorate,Power insentive units{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchemeCanapply;
