import React from "react";
import "./success.card.styles.css";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import { Link, useParams } from "react-router-dom";

function PaymentSuccessCard(props) {
  const orderNo = sessionStorage.getItem("order_id")
  const {orderid} = useParams()
  console.log(props)
  console.log(props)
  return (
    <div className="auto-container d-flex justify-content-center success ">
      <div className="success_card ">
        <div className="success_title pt-4 pb-5 d-flex justify-content-center">
          <h4>Payment Completed Successfully</h4>
        </div>
        <div className="  d-flex justify-content-center pb-5">
          <KiedIcons className="success_icon" icon="Success-tick" />
        </div>
        
        <div className="success_title pt-4 pb-1 d-flex justify-content-center">
          <h5>Amount : <span className="text-primary">{props?.message?.order_amt} ₹</span></h5>
          </div>
        <div className="success_title pt-1 pb-5 d-flex justify-content-center">
         
          <h5 className="">Order Number : <span className="text-primary">{props?.order}</span></h5>
        </div>
        <div className="d-flex justify-content-center pb-5">
          <Link to="/">
            <KiedButton
              secondary
              rounded
              inverted
              content={
                <div className="d-flex justify-content-center">Done</div>
              }
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccessCard;
