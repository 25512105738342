// export default SchemeCanapply;
import React from "react";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SchemeCanapply(props) {
  let width = useScreenBreakPoint();
  return (
    // <div className="auto-container pt-5 pb-3">
    //   <div className="d-flex flex-column align-items-start justify-content-center">
    //     <div className="schemes_heading mb-5">How to apply</div>
    //     <div className="Schemes_heading_description ">
    //       The application can be submitted online along with the documents as
    //       per the checklist using the link given at the bottom. The application
    //       will be processed by the Sanctioning Authority and the assistance will
    //       be sanctioned based on merit.
    //     </div>
    //   </div>
    //   <hr />
    //   <div className="schemes_heading mb-3 mt-5">Sanctioning Authority</div>
    //   <ul className="ml-4 Schemes_heading_description pt-3 mb-5">
    //     <li>
    //       For Startup Support - General Manager, District Industries Centre
    //     </li>
    //     <li>
    //       {" "}
    //       For Investment Support - District Level Committee consisting of the
    //       following Members;.
    //       <ul className="sub_list">
    //         <li>District Collector (Chairman)</li>
    //         <li>Lead District Manager</li>
    //         <li>Representative of Finance Department in Government</li>
    //         <li>District Manager, KFC</li>
    //         <li>Representative of KSSIA District Committee</li>
    //         <li>
    //           General Manager, District Industries Centre (Member Secretary)
    //         </li>
    //       </ul>
    //     </li>
    //   </ul>
    //
    //   <hr />
    // </div>

    <div style={{ backgroundColor: "#f2f3f4 " }} className="pb-5">
      <div
        className={` row head-container  d-flex justify-content-between ${
          width === "xs" || width === "sm" ? "sml pt-5 " : "lr mt-5 pt-5"
        } `}
      >
        <div className="col-lg-6 col-md-6 col-sm-12  ">
          <div
            className={`schemes-listing-card  ${
              width === "xs" || width === "sm" ? "sml p-3" : "lr"
            }`}
          >
            <div
              className={`schemes_heading text-dark  ${
                width === "xs" || width === "sm"
                  ? "sml pt-2 "
                  : "lr mb-3 mt-5 pl-3"
              } `}
            >
              {" "}
              FUNCTIONS OF THE CLUB
            </div>
            <div className="px-3">
              <ul
                className={`Schemes_heading_description  ${
                  width === "xs" || width === "sm"
                    ? "sml pt-2 p-0"
                    : "pt-3 mb-5 ml-4"
                } `}
              >
                <li>
                  <p>
                    Organise interactive sessions with successful entrepreneurs
                    from outside as well as within the State.
                  </p>
                </li>
                <li>
                  <p>Organise workshops/debates.</p>
                </li>
                <li>
                  <p>
                    Organise industrial visits to business enterprises within
                    /outside State.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    Interaction with promotional agencies / successful
                    entrepreneurs / other stake holders
                  </p>{" "}
                </li>
                <li>
                  <p>
                    Exposure to national and international events in various
                    parts of the country related to industrial developments.
                  </p>
                </li>
                <li>
                  <p>
                    Organize Entrepreneurship Clinics & Entrepreneur Awareness
                    Programme.
                  </p>
                </li>
                <li>
                  <p>
                    To introduce talented youths to industrial research
                    organizations.
                  </p>
                </li>
                <li>
                  <p>
                    To familiarize the youths to the latest developments through
                    media.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12  ">
          <div
            className={`schemes-listing-card  ${
              width === "xs" || width === "sm" ? "sml p-3 mt-3" : "lr"
            }`}
          >
            <div
              className={`schemes_heading text-dark  ${
                width === "xs" || width === "sm"
                  ? "sml pt-2 "
                  : "lr mb-3 mt-5 pl-3"
              } `}
            >
              {" "}
              FINANCIAL ASSISTANCE
            </div>
            <div className="px-3">
              <ul
                className={`Schemes_heading_description schemes-list  ${
                  width === "xs" || width === "sm"
                    ? "sml pt-2 p-0 text-left "
                    : "pt-3 mb-5 ml-4"
                } `}
              >
                <li>
                  <p>
                    A minimum of 10% is to be contributed by the institution.
                    Funds from other sources can also be pooled.
                  </p>
                </li>
                <li>
                  <p>
                    Rs.12,000 per annum will be given as grant to each club by
                    the Department of Industries & Commerce .
                  </p>
                </li>
                <li>
                  <p>
                    Additional assistance will be considered on merit. The fund
                    can be utilized preferably for the following purposes
                  </p>
                </li>
                <li>
                  <p>Entrepreneurship motivation of students</p>{" "}
                </li>
                <li>
                  <p>
                    Interaction of students with successful entrepreneurs /
                    investors / stake holders etc.
                  </p>
                </li>
                <li>
                  <p>Essential exposure visits</p>
                </li>
                <li>
                  <p>
                    Conducting meaningful surveys related to entrepreneurial
                    development.{" "}
                  </p>
                </li>
                <li>
                  <p>Camp on Entrepreneurial Adventures for Youth</p>
                </li>
                <li>
                  <p>Entrepreneurship Awareness Camps</p>
                </li>
                <li>
                  <p>
                    Training Programme on Project Appraisal and Entrepreneur
                    Assessment{" "}
                  </p>
                </li>
                <li>
                  <p>
                    Training Programme on Industrial Project Preparation and
                    Appraisal{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchemeCanapply;
