import React from "react";
import "./reportProblem.page.styles.css";
import SmallBanner from "../../components/small.banner/small.banner";
import ReportProblemBread from "./sections/breadcrumbs/reportProblem.breadcrumb";
import ReportProblemContent from "./sections/contents/reportProblem.content";

function ReportProblemPage() {
  return (
    <div>
      <SmallBanner />
      <ReportProblemBread />
      <ReportProblemContent />
    </div>
  );
}

export default ReportProblemPage;
