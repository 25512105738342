// import React from 'react'

// function BuildingHero() {
//   return (
//     <div>BuildingHero</div>
//   )
// }

// export default BuildingHero

import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function BuildingHero(props) {
  let width = useScreenBreakPoint();
  return (
    // <div
    //   className={`d-flex justify-content-center ${
    //     width === "xs" || width === "sm"
    //       ? props.index % 2 === 0
    //         ? "flex-column"
    //         : " flex-column"
    //       : props.index % 2 === 0
    //       ? "flex-row"
    //       : "flex-row-reverse "
    //   }`}
    // >
    <div
      className={`d-flex justify-content-center auto-container ${
        width === "xs" || width === "sm" ? "sml p-0 mt-2" : "lr mt-5 pt-5"
      }`}
    >
      <div className="row d-flex justify-content-between w-100">
        <div className="col-lg-4 col-md-4  col-sm-12 d-flex justify-content-start">
          <KiedImages
            className="w-100"
            style={{ height: "100%", objectFit: "cover", borderRadius: "15px" }}
            image="ess"
          />
        </div>
        <div
          className={`col-lg-8 col-md-8 col-sm-12 d-flex flex-column   ${
            width === "xs" || width === "sm" ? "sml mt-3 " : "lr p-2 pl-5"
          } `}
        >
          <div className="schemes_heading">Capacity Building Programme</div>
          <div
            className={`Schemes_heading_description Schemes_new_discription  ${
              width === "xs" || width === "sm" ? "sml p-3 mt-3 " : "lr  mt-5 "
            } `}
          >
            Government has accorded high priority for promoting technology
            infusion in industry and start-ups. As universities become more
            involved in promoting economic development, there is a push on more
            relevant research and training. One way to address this call for
            relevance is by encouraging more linkages between higher
            education/research institutions and the business sector. Such
            linkages will be beneficial to all stakeholders - encouraging such
            institutions to foster research, providing design & technology
            solutions to industry as well as help end users of goods and
            services, access good quality products and advanced services.
            Extending financial assistance to Universities/Research institutions
            and technology providers will go a long extent in addressing the
            need of industry and business enterprises. By focusing research in
            specific areas where the state stands to gain in terms of technology
            inputs - such as value addition in food processing, design
            intervention in traditional sectors etc. - the needs of all
            stakeholders will be ni e t. The scheme envisages providing
            assistance by way of grant to students involved in developing and
            harnessing technological innovation to come up with solutions for
            problems besetting industrial activity as well as for. encouraging
            innovation in the manufacturing sector.
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default BuildingHero;
