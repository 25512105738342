import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";

function AboutBanner() {
  return (
    <div
      id="Banner_outer"
      className="banner_image banner_big d-flex justify-content-center align-items-center"
    >
      <div
        id="Banner_inner"
        className="d-flex  container bannerContainer  head-container justify-content-between"
      >
        <div
          id="Banner_text"
          className="d-flex flex-column justify-content-center"
          // data-aos="fade-right"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <h2 className="text_banner more_link">
            {" "}
            KIED&nbsp;EDC <br />
            {/* about&nbsp;KIED and&nbsp;EDC */}
          </h2>
        </div>
        <div
          id="Banner_image"
          className="aboutpage_images  d-none d-md-block "
          // data-aos="fade-left"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <KiedImages
            className="program-banner-image d-none d-md-block "
            image="banner-image"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutBanner;
