import React from "react";

function EventsBreadcrumbs() {
  return (
    <div>
      <div className="faq_banner pl-5 d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/EDC"> EDC&nbsp;/</a>
        <a className="current" href="/events">
          Event Details
        </a>
      </div>
    </div>
  );
}

export default EventsBreadcrumbs;
