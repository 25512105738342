import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import "./WebinarCard.css";
import { useNavigate, useParams } from "react-router-dom";
import { webinarDetails } from "../../pages/our_webinars/OurWebinarData";
import { useState, useEffect } from "react";

function WebinarCard(props) {
  const navigate = useNavigate();

  return (
    <div className=" news-block px-3 h-100 w-100  ">
      <div className="inner-box wow fadeInUp animated h-100 ">
        <div className="image">
          <a onClick={(e) => navigate(`/news/${props.data.id}`)}>
            <video
              height={"100%"}
              width={"100%"}
              controls={true}
              muted={false}
              autoPlay={false}
              loop={true}
            >
              <source
                src={`${process.env.PUBLIC_URL}/videos/resourses/webinar.mp4`}
                type="video/mp4"
              />
              Your browser does not support video.
            </video>
          </a>
        </div>

        <div className="lower-content">
          <div className="time">On {props.data.date}</div>
          <h3>
            <a onClick={(e) => navigate(`/news/${props.data.id}`)}>
              {props.data.heading}
            </a>
          </h3>
          <div className="text">{props.data.description}</div>
          <div className="clearfix d-flex justify-content-center">
            <div
              className="pull-right read-more mt-2 read_more_news "
              onClick={(e) => navigate(`/news/${props.data.id}`)}
            >
              Apply Now
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebinarCard;
