// import React from 'react'

// function InternshipEventDescription() {
//   return (
//     <div>

//     </div>
//   )
// }

// export default InternshipEventDescription
import React from "react";
import KiedImages from "../../../../../../assets/images/kied.images.component";
import Masonry from "react-responsive-masonry";
// import "../EdcEventDetails.css";
import useScreenBreakPoint from "../../../../../../hooks/useScreenBreakPoint";
function InternshipEventDescription(props) {
  let width = useScreenBreakPoint();
  console.log(props);
  return (
    <div>
      <div>
        <div className="faq_banner pl-2 d-flex head-container">
          <a href="/">Home&nbsp;/</a>
          <a href="/department/academy-collaboration/academiaEvents">
            {" "}
            Academia Events&nbsp;/
          </a>
          <a className="current" href="">
            Event-Details
          </a>
        </div>
      </div>
      {/* ///////////// */}
      <div className="head-container">
        <div className="row mx-0 event-row d-flex justify-content-between">
          <div className="col-lg-6 col-md-6 col-sm-12 p-0">
            <div
              className={`d-flex justify-content-start  ${
                width === "xs" || width === "sm" ? "sml mb-2 p-0" : " mb-4"
              }`}
            >
              <h1 className="event-detail-heading">{props.data.title}</h1>
            </div>
            <div className="">
              {props.data.mainDescription &&
                props.data.mainDescription !== "" && (
                  <div>
                    <div className="event-detail-para d-flex justify-content-center ">
                      {props.data.mainDescription}
                    </div>
                    <h1 className="edc-subtitle">{props.data.subTitle1}</h1>
                    <div className="event-detail-para d-flex justify-content-center ">
                      {props.data.cardDescription}
                    </div>
                    <h1 className="edc-subtitle">{props.data.subTitle2}</h1>
                    <div className="event-detail-para d-flex justify-content-center ">
                      {props.data.programDiscussion}
                    </div>
                    {/* <div className="Schemes_heading_description mb-3">
                    {props.data.maindescription}
                  </div>
                  <div className="description_heading_news">
                    {props.data.maindescription2heading}
                  </div>
                  <div className="Schemes_heading_description mb-3">
                    {props.data.maindescription2}
                  </div> */}
                  </div>
                )}
              <div className="event-page-details">
                {props.data.duration && props.data.duration !== "" && (
                  <div className=" mt-4 d-flex flex-column align-items-start justify-content-center mb-3">
                    <div className="Schemes_heading_description">
                      <div className="m-1">
                        <b className="sub-titles-event">
                          Duration :&nbsp;&nbsp;
                        </b>
                        {props.data.duration}
                      </div>
                    </div>
                  </div>
                )}
                {props.data.CategoryOfParticipants &&
                  props.data.CategoryOfParticipants !== "" && (
                    <div className=" mt-4 d-flex flex-column align-items-start justify-content-center mb-3">
                      <div className="Schemes_heading_description">
                        <div className="m-1">
                          <b className="sub-titles-event">
                            Category of Participants:&nbsp;&nbsp;
                          </b>
                          {props.data.CategoryOfParticipants}
                        </div>
                      </div>
                    </div>
                  )}
                {props.data.NoOfParticipants &&
                  props.data.NoOfParticipants !== "" && (
                    <div className=" mt-4 d-flex flex-column align-items-start justify-content-center mb-3">
                      <div className="Schemes_heading_description">
                        <div className="m-1">
                          <b className="sub-titles-event">
                            Number of Participants:&nbsp;&nbsp;
                          </b>
                          {props.data.NoOfParticipants}
                        </div>
                      </div>
                    </div>
                  )}
              </div>

              {props.data.listitem && props.data.listitem !== "" && (
                <div className="mt-5 mb-3">
                  <div className="description_heading_news">
                    {props.data.listheading}
                  </div>
                  <ul className="Schemes_heading_description mt-4 ">
                    {props.data.listitem.map((list, i) => (
                      <li className="ml-2 pr-5  text-dark">{list}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div
              className={`  ${
                width === "xs" || width === "sm"
                  ? "sml "
                  : "mt-3 mb-2 pb-2 py-4 "
              }`}
            >
              {props.data.images && props.data.images !== "" && (
                <div
                  className={` ${
                    width === "xs" || width === "sm" ? "sml mt-3" : "pt-3 mb-5"
                  }`}
                >
                  <Masonry columnsCount={2} gutter="10px">
                    {props.data.images.map((image, i) => (
                      <KiedImages
                        image={image.image}
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                          // height: "220px",
                          object: "cover",
                          border: "solid 1px grey ",
                          boxShadow: "1px 1px 5px 3px grey",
                        }}
                      />
                    ))}
                  </Masonry>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternshipEventDescription;
