import React from "react";

function IntershipSummaryBreadcrumbs() {
  return (
    
      <div className="faq_banner  d-flex ">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/home">IAC&nbsp;/</a>
        <a href="/internship-details"> Internship-Details&nbsp;/</a>
        <a className="current" href="#">
          Directory
        </a>
      </div>
    
  );
}

export default IntershipSummaryBreadcrumbs;
