import React from "react";
import Banner from "../../components/banner/banner";
import InternshipSummarySelector from "./sections/selector/internship_selector";
import InternshipSummaryDetails from "./sections/details/internship_details";
import IntershipSummaryBreadcrumbs from "./sections/breadcrumbs/internship_breadcrumbs";

function InternshipSummaryPage() {
  return (
    <div>
      <Banner title="Internship Summary List" image="academia-banner" />
      <div className=" head-container d-flex justify-content-between align-items-center">
        <IntershipSummaryBreadcrumbs />
        <InternshipSummarySelector />
      </div>

      <InternshipSummaryDetails />
    </div>
  );
}

export default InternshipSummaryPage;
