import React, { useEffect, useState } from "react";
import { getSchemes } from "../../apis/publicApis";

import SmallBanner from "../../components/small.banner/small.banner";
import EventsListingBreadcrumbs from "./sections/breadcrumbs/events.listing.breadcrumbs";
import EventsListingDetails from "./sections/details/events.listing.details";

function SchemesListingPage() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getSchemes()
      .then((res) => {
        let evdata = [];
        res?.data?.forEach((item) => {
          let evItem = {
            // startTime: item?.scheme_owner,
            scheme_owner: item?.scheme_owner,
            listingImage: item?.thumb,
            title: item?.name,
            id: item?.id,
            link: "/schemes/",
            location: item?.mode,
            cardDescription: item?.description,
          };
          evdata.push(evItem);
        });
        setData(evdata);
      })
      .catch(() => {});
  }, []);
  return (
    <div>
      <SmallBanner title="SCHEMES" />
      {/* <EventsListingBreadcrumbs /> */}
      <br />
      <EventsListingDetails data={data} />
      <br />
    </div>
  );
}

export default SchemesListingPage;
