import React from "react";

function IndAcDescription() {
  return (
    <div className="auto-container inovation_text about_kied">
      <p className="">
        KIED aims to foster collaboration between industry and academia as a
        means to spur innovation and drive technological progress. This
        partnership is beneficial for both parties, as it allows industry to tap
        into the specialized knowledge and practical training of academia, while
        universities can work on real-world problems and cutting-edge
        technologies.
        <br />
        To support this collaboration, KIED offers capacity building programs
        and awareness-raising initiatives, as well as events that bring industry
        and academia together to address specific challenges. The organization
        also provides opportunities for academia to gain hands-on experience
        through internships, problem-solving initiatives, and other similar
        programs
      </p>
      <p>
        The Schemes, Programmes and Events showcased on this page are tailored
        specifically to cater to the needs of both Industry Associations,
        Existing Entrepreneurs, Schools and Colleges, Research Institutions and
        Universities. To register for any of the programs offered on this
        website, the user can follow these simple steps:
      </p>
      <ul className="ml-3 mt-2">
        <li>
          <b style={{ color: "#9a3232" }}>
            Step 1: Click on the <a href="/user-management">Login</a> link and
            enter your credentials to log in to the website.{" "}
          </b>
        </li>
        <li>
          <b style={{ color: "#9a3232" }}>
            Step 2: Once logged in, navigate to the specific
            program/event/scheme you are interested in and apply for it by
            following the instructions provided.
          </b>
        </li>
      </ul>
    </div>
  );
}

export default IndAcDescription;
