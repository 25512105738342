import React from "react";

function TenderTableComponent(props) {
  return (
    <tr>
      <td className="m-2 text-left">{props.data.title}</td>
      <td className="m-2">
        <a href={`${props.data.file}`} target="_blank">
          Download File
        </a>
      </td>
    </tr>
  );
}

export default TenderTableComponent;
