// import React from 'react'

// function AssistanceSupportingSchemes() {
//   return (
//     <div>AssistanceSupportingSchemes</div>
//   )
// }

// export default AssistanceSupportingSchemes

import React from "react";
// import "../../schemes.page.styles.css";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function AssistanceSupportingSchemes() {
  let width = useScreenBreakPoint();
  return (
    <div>
      <div className="auto-container">
        {/* <div
          className={`schemes_heading  ${
            width === "xs" || width === "sm" ? "sml mt-5" : "lr  mb-5 mt-5"
          }`}
        >
          Stages in Entrepreneur Support Scheme
        </div> */}
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm"
              ? "sm my-4"
              : "lr  mb-3 mt-5 pt-5 mt-5"
          } `}
        >
          About the scheme
        </div>
        <div className=" Schemes_heading_description mb-5">
          The scheme aims at providing to entrepreneurs. Subsidy for the
          installation of export related infrastructure. Incentives to
          participate in national and international exhibitions Incentives for
          taking quality cenifications Capacity building for exports and
          potential exponers.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-3 mt-5"
          } `}
        >
          Objectives
        </div>
        <div className=" Schemes_heading_description mb-5">
          Promote export awareness Easing the documentation process involved in
          exponing products/commodities and thereby motivating existing
          entrepreneurs to export their products.basing the financial burden
          faced by new MSMEs entering the export market.Diversify exports in
          higher value-added segments and projecting “Kerala Brand”.Upgradation/
          scaling up of existing export units.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-3 mt-5"
          } `}
        >
          Eligibility
        </div>
        <div className=" Schemes_heading_description mb-5">
          All micro, small and medium enterprises engaged in
          manufacturing/service activities, set up in the State of Kerala and
          started exporting after 01/04/2022, which filed Udyam registration,
          shall be eligible for assistance under the scheme.Market
          identification: Extensive marketing campaigns need to be carried out
          to reach out to various markets abroad. For that the entrepreneur
          might need to attend various trade fairs abroad as well as to
          participate in international exhibitions to introduce his product.
          MSMEs also need to send numerous samples to familarize his product and
          get approvals.Development of export infrastructure: Existing MSMEs
          need to completely revamp their existing manufacturing process in
          order to be export ready. This begins from the stage of procurement of
          raw materials, testing for their quality, standardized preservation
          methods, mechanisms for pre-shipment treatment facilities such as
          irradiation, Vapour Heat Treatment (VHT), Hot Water Dip Treatment
          (HWDT) for compliance to Phyto-Sanitary requirements for importing
          countries, Post processing of the final products to ensure various
          quality standards.Procuring quality standards/certifications: To
          participate/engage in international trade, it is necessary to comply
          with certain quality requirements of different countries. Several
          importing countries demand adherence to stringent product standards
          like TSO 9000/BIS 14000/HACCP/Halal and other related certificates.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-3 mt-5"
          } `}
        >
          Entitlement
        </div>
        <div className=" Schemes_heading_description mb-5">
          MSMEs attending trade fairs and participating in exhibitions shall be
          eligible for assistance upto 75% of the admission/registration charges
          limited a sum of Rs.2 Lakhs and 50% for travelling expenses per
          organization subject to a ceiling of Rs.1 Lakh. MSMBs which intend to
          avail the assistance shall obtain prior sanction for partcipating in
          the event from the General Manager, DIC concerned. The assistance
          shall be reimbursed to the account of the beneficiary on submission of
          relevant documents to ascertain stall charges and relevant travel
          documents Air freight/courier charges incurred while sending trade
          samples limited to Rs.2 Lakh/product subject to Rs.S Lakh/MSME shall
          be reimbursed to the beneficiary upon submission of relevant documents
          to ascertain air freight/courier charges.The assistance for
          Infrastructure devclopment or upgradation, for export purpose will be
          limited to 40% of expenditure incurred subject to a ceiling of Rs.60
          Lakh. Additional support of 10% will be extended to units falling
          under Young/SC/ST/Women/NRK subject to a ceiling of Rs.10 Lakhs. The
          norms prescribed in the guidelines of Entrepreneurship Support Scheme
          shall be used to calculate eligible fixed capital investment.50% of
          the total cost incurred for the implementation and certification of
          'Quality and Food Safety Management' limited to a maximum of Rs.5 Lakh
          per certification shall be reimbursed to the beneficiary upon the
          submission of relevant proof of certification and costs incurred in
          the process. Assistance shall be available for units applying to
          renewal of ceritification also. Total assistance shall be limited to a
          maximum of Rs.25 Lakhs per unit.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr mb-3  mt-5"
          } `}
        >
          Training
        </div>
        <div className=" Schemes_heading_description mb-5">
          10% of the funds available for the scheme shall be set aside for
          conducting capacity building training, workshops, seminars and any
          other activity to improve the export potential and value of MSMEs in
          the state.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr mb-3  mt-5"
          } `}
        >
          Sanctioning/ Recommending Authority
        </div>
        <div className=" Schemes_heading_description mb-5">
          The power to sanction assistance under the scheme shall be vested with
          the General Manager, District Industries Centre.The recommending
          authority, Assistant District Industries Officer shall request and
          accept fiirther documentation or clarification required from the
          applicant, associated agencies or stake holders of other departments.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr mb-3  mt-5"
          } `}
        >
          Implementation procedure
        </div>
        <div className=" Schemes_heading_description mb-5">
          Application shall be submitted to the recommending authority concerned
          with all supporting documents.On verification of the submitted
          documents, applications will be forwarded to the sanctioning
          authority.The application will be processed by the Sanctioning
          Authority and the assistance will be sanctioned based on merit.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr mb-3  mt-5"
          } `}
        >
          Appeals
        </div>
        <div className=" Schemes_heading_description mb-5">
          Appeals shall be filed before the Director of Industries & Commerce
          within 30 days from the date of issuance of orders of the General
          Manager, District Industries Centre concerned. No appeal after this
          period will be entertained. Th appeal shall be disposed of as far as
          possible within 3 months from the date of receipt of appeal after
          giving the appellant an opportunity of being heard whenever necessary.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr mb-3  mt-5"
          } `}
        >
          Recoveries & Penalties
        </div>
        <div className=" Schemes_heading_description mb-5">
          Any assistance under the scheme to an industrial unit is liable to be
          refu nded by the unit with interest at the rate of 14% per annum from
          the date of receipt of the same, on issuance of registered demand
          notice toy the unit by the Recommending/Sanctioning AuthoriJ on ground
          of obtaining assistance by misrepresentation, forgery or deception or
          not found working continuously for 3 years from the date, of receipt
          of grant. All amounts due to Government under.this provision ‘shall,
          in case off default, be recoverable as if they are arrears of land
          reyenue kinder the provisions of the Kerala Revenue 'Reéovery Act,
          1968 or in such other manner as Government riiay deem fit. Sufficient
          opportunity to show cause in writing shall however be granted to the
          units before a demand rose against it.The Departmental.Working Group
          in its meeting held on 28/06/2022 has considered the proposal and
          recommended to issue administrative sanction for the scheme of
          Assistance for Export Promotion of Existing MSMEs for an amount of
          Rs.100 Lakhs (Rupees One Hundred Lakhs only) under the Head of Account
          2851-00-102-07(2)-34-3 from the current year's budget provision.In
          the circumstances, Government have examined the matter in detail and
          are pleased to approve the scheme namely Assistance for Export
          Promotion of Existing MSMEs as proposed by the Director of Industries
          and Commerce as per letter read above and as detailed abt›ve and to
          issue administrative sanction for this scheme at an amount of Rs.100
          Lakhs (Rupees One Hundred Lakhs only) under the Head of Account
          2851-00-102- 07(2)-34-3 from the current years budget provision.
        </div>
      </div>
    </div>
  );
}

export default AssistanceSupportingSchemes;
