// import React from 'react'

// function EdcNewProgramHero() {
//   return (
//     <div>

//     </div>
//   )
// }

// export default EdcNewProgramHero
import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function EdcNewProgramHero(props) {
  let width = useScreenBreakPoint();
  return (
    <div className="auto-container mb-5 mt-5 mb-5">
      <div className=" row ">
        <div
          className={`col-lg-7 col-md-7 col-sm-12  inovation_text align-items-center d-flex edc-program-hero ${
            width === "xs" || width === "sm" ? "sml px-3 " : "pr-5"
          }`}
        >
          <p>{props.data.mainDescription}</p>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12  mt-5 edc-program-img-new d-flex justify-content-center">
          <KiedImages
            className=" programs-hero-image"
            image={props?.data?.image}
          />
        </div>
      </div>
    </div>
  );
}

export default EdcNewProgramHero;
