import React from "react";
import parse from "html-react-parser";
import KiedButton from "../buttons/button/button.component";
import {  useNavigate } from "react-router-dom";
function CareerTableComponent(props) {
  const navigate = useNavigate()
  console.log(props);
  return (
    <tr>
      <td className="m-2 text-left align-top">
        <b>{props.data.title}</b>
      </td>
      <td className="m-2 text-left career-description">
        {props.data.description ? parse(props.data.description) : "--"}
      </td>
      <td className="m-2 align-top">
        {/* <div className="h-100"> */}
        <KiedButton
              onClick={(e) =>
                window.open(props.data.link, "_blank")
              }
              secondary
              small
              
              className="mt-2"
              type="submit"
              content={
                <div className=" d-flex justify-content-center">
                  Click here to apply
                </div>
              }
            />
        {/* <a
          href={`${props.data.link}`}
          className="table-button "
          target="_blank"
        >
          Click here to apply
        </a> */}
        {/* </div> */}
      </td>
    </tr>
  );
}

export default CareerTableComponent;
