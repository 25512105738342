export const webinarDetails = [
  {
    id: 1,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 2,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 3,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 4,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 5,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 6,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 7,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 8,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 9,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 10,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 11,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
  {
    id: 12,
    video: "webinar",
    date: "06 September 2023 / 9AM to 5:30 PM",
    heading: "The Dark side of Overnight Sucess",
  },
];
