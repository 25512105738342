import React from "react";
import VideoCard from "../../../components/video.card/VideoCard";
import Pagination from "../../../components/pagination/pagination";
import { useRef, useState, useMemo } from "react";

import { videoDetails } from "../videoData";

let PageSize = 9;

function KiedVideosDetails(props) {
  // const [isActive, setIsActive] = useState(false);
  // const [data, setData] = useState([]);
  const myRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  const mainData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return props?.data.slice(firstPageIndex, lastPageIndex);
  }, [props?.data, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    myRef.current.scrollIntoView();
  };

  return (
    <div className="head-container pb-5 pt-5 " ref={myRef}>
      <div className="row mt-4 pt-5  ">
        {mainData.map((card, i) => {
          return (
            <div className="col-lg-6 col-md-6 col-sm-12 pb-3">
              <VideoCard data={card} key={i} index={i} />
              
            </div>
          );
        })}
      </div>
      <Pagination
        className="pagination-bar d-flex justify-content-center"
        currentPage={currentPage}
        totalCount={props?.data.length}
        pageSize={PageSize}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
}

export default KiedVideosDetails;
