import React from "react";

function SelectBoxDash({
  options = [],
  value = "",
  selectionMessage,
  handleChange = () => {},
  ...props
}) {
  return (
    <>
      <div className="d-flex align-items-start">
        {props.label && (
          <label className="d-flex align-items-start justify-content-between col-5 p-0">
            <span className="formstyle p-0">
              {props.label}{" "}
              {/* {props.required && <span style={{ color: "red" }}>*</span>} */}
            </span>
            <span className="formstyle pr-1">:</span>
          </label>
        )}
        <label className="formstyle d-flex  px-2 ml-1 mt-1 col-7">
          <select
            className={`inputbox ${props.required && "required"}`}
            onChange={(e) => handleChange(e)}
            value={value}
            name={props.name}
          >
            <option value="">{selectionMessage ?? "select a value"}</option>
            {options.map((place, i) => {
              return (
                <option
                  // selected={place.name === district}
                  value={place.name}
                  key={i}
                >
                  {place.name}
                </option>
              );
            })}
          </select>
        </label>
      </div>
    </>
  );
}

export default SelectBoxDash;
