import React from "react";
import "./advisory.committee.styles.css";
import Banner from "../../components/banner/banner";
import AdvisoryDescription from "./sections/description/description";
import AdvisoryMembers from "./sections/committee_members/committee.members";

function AdvisoryCommittee() {
  return (
    <div>
      <Banner title="Our Advisory committee" image="team-banner" />
      <AdvisoryDescription />
      <AdvisoryMembers />
    </div>
  );
}

export default AdvisoryCommittee;
