import React from "react";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function KsidcSchemeBreadcrumbs() {
  let width = useScreenBreakPoint();
  return (
    <div>
      <div className="auto-container">
        <div className={`faq_banner  d-flex  ${
            width === "xs" || width === "sm" ? "sml mt-2 w-100 mb-0" : "lr  "
          }`}>
          <a href="/">Home&nbsp;/</a>
          <a href="/department/inovation/home"> Inovation&nbsp;/</a>
          <a className="current" href="/ksidc-schemes">
            Scheme Details
          </a>
        </div>
      </div>
    </div>
  );
}

export default KsidcSchemeBreadcrumbs;
