import React from "react";

function KsidcSchemeBreadcrumbs() {
  return (
    <div>
      <div className="auto-container">
      <div className="faq_banner  d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/EDC/edcSchemes"> EDC&nbsp;/</a>
        <a className="current" href="/edc-schemes">
          Scheme Details
        </a>
      </div>
      </div>
    </div>
  );
}

export default KsidcSchemeBreadcrumbs;
