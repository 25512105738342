import React from "react";

function KiedButton(props) {
  return (
    <button
      // {...props}
      disabled={props.disabled}
      onClick={props.onClick}
      type = {props?.type || undefined}
      className={`main-button ${
        props.primary
          ? props.inverted
            ? "button-primary-inverted"
            : "button-primary"
          : props.secondary
          ? props.inverted
            ? "button-secondary-inverted"
            : "button-secondary"
          : props.inverted
          ? "button-orange-inverted"
          : "button-orange"
      } 
            ${props.rounded ? "round-button" : "squre-button"} 
            ${props.big ? "button-big" : ""}  
            ${props.className ?? ""}`}
    >
      <div className="align-middle">{props.content}</div>
    </button>
  );
}

export default KiedButton;
