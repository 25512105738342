import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import "../../programs.page.styles.css";

function ProgramsEligibility() {
  return (
    <div className="auto-container pb-5  mt-5">
      <div className="">
        <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <h2 className="inovation_headings m-1">Objectives</h2>
          </div>
        </div>
        <hr />
        <div className="mt-5 pt-5 mb-5 criteria">
          <ol>
            <li>Accelerate the growth of MSME Units/Entrepreneurs in Kerala</li>
            <li>
              Supporting on expansion, financial stability and innovation{" "}
            </li>
            <li>Make MSME units competitive and growth oriented</li>
            <li>Overall growth in business KPI’s and employment generation</li>
            <li>
              Provide right business inputs through mentorship sessions and
              training programs.
            </li>
          </ol>
        </div>
        {/* ///////////////////////////////
        <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <KiedImages
              className="inovation_headings_logo m-1"
              image="inovation-heading-icon"
            />
          </div>
          <div className="">
            <h2 className="inovation_headings m-1">
              Targeted entrepreneurs for this program
            </h2>
          </div>
        </div>
        <hr />
        <div className="inovation_text about_kied  pb-5">
          <div className="">
            <p className="">
              Target segment for this incubation program is entrepreneurs who
              have set up small and medium enterprises in Kerala with turnover
              between Rs. 35 lakhs and 50 Cr. These enterprises should be less
              than 7 years old from the date of incorporation or registration
              and preferably in the manufacturing sector or providing
              specialized services to MSME units. As part of the incubation
              program, the focus will be more on the operational side of the
              venture, getting their operational plan right, scaling up, optimum
              utilization of the capacity added and the overall operational
              efficiency. The broad program structure of this incubation program
              includes, one to one and one to many mentorship sessions, training
              sessions, funding and service network connect to help the
              entrepreneurs to take these enterprises to the next stage of
              growth. The focus of the mentorship sessions and the training
              sessions are to increase the overall operational efficiency of
              their enterprises with special focus on strengthening and
              executing the Sales, Marketing and Financial plan.
            </p>
          </div>
        </div> */}
        {/* /////////////////////////////// */}
        {/* <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <KiedImages
              className="inovation_headings_logo m-1"
              image="inovation-heading-icon"
            />
          </div>
          <div className="">
            <h2 className="inovation_headings m-1">
              Selection Process and Selection committee
            </h2>
          </div>
        </div>
        <hr />
        <div className="inovation_text about_kied  pb-5">
          <div className="">
            <p className="">
              Selection of entrepreneurs/MSME units for this program will be
              through a two-step selection process. Application invitation for
              this program happens through KIED website which will have the
              complete details about the program, deadline, FAQ’s etc.
              Interested applicants will have to fill in the application form
              and upload the sample pitch deck or the growth plan in the
              website. Once the application deadline is over, initially KIED
              team will evaluate all the applications and will shortlist
              potential MSME Units/Entrepreneurs for the final/second round of
              selection by the selection committee In the second round of
              selection the selected startups will have to present to the final
              selection committee constituted by KIED as per the given below
              composition. After the final discussion the committee members will
              individually evaluate and will unanimously recommend the final
              list of selected startups for this program. Composition of the
              committee members will be as follows:
              <ul className="sub_list m-3">
                <li>Entrepreneur preferably from an MSME Sector</li>
                <li>Representative from Industry Bodies</li>
                <li>
                  Representative of other Incubators or Entrepreneurship
                  Development Centers
                </li>
                <li>Financial or Investment Expert</li>
                <li>
                  Subject Experts – In the area of Operation, Marketing etc
                </li>
                <li>
                  Representative from financial institutions in Kerala, KSIDC,
                  KFC or Banks
                </li>
                <li>MSME sectorial Experts/ Consultants</li>
                <li>Representatives from KIED/Department of Industries</li>
              </ul>
              The selection committee should have minimum 4 members and maximum
              of 7 Members from the above list/composition indicated. The
              committee has to unanimously recommend the entrepreneurs/MSME
              units selected for this program.
            </p>
          </div>
        </div> */}
        {/* ////////////////////////////////// */}
        {/* /////////////////////////////// */}
        {/* <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <KiedImages
              className="inovation_headings_logo m-1"
              image="inovation-heading-icon"
            />
          </div>
          <div className="">
            <h2 className="inovation_headings m-1">
              Program Approach and Broad Program Structure
            </h2>
          </div>
        </div>
        <hr />
        <div className="inovation_text about_kied  pb-5">
          <div className="">
            <p className="">
              Overall Success of the Business Growth Incubation program depends
              on how well the incubator uses the Push and the Pull strategy to
              help the entrepreneur’s part of the program.
            </p>
          </div>
        </div> */}
        {/* /////////////////////////////// */}
        {/* /////////////////////////////// */}
        {/* <div className="d-flex justify-content-left align-items-center">
          <div className="">
            <h2 className="inovation_sub_headings mt-2 ml-1">
              <b>Push Strategy</b>
            </h2>
          </div>
        </div>

        <div className="inovation_text mt-2 pb-3">
          <div className="">
            <p className="">
              In Push Strategy, the incubator first analysis the composition of
              the shortlisted entrepreneurs for the program and then decide the
              overall program structure. In this case, incubator will identify
              training sessions, contents, and mentorship pool based on the
              characteristics of the cohort. If the entrepreneurs are more into
              food sector then many sessions that can support entrepreneurs in
              food sector will be provided along with engagement of experts in
              this sector. Apart from this, common sessions on sales, finance,
              and operations can be planned as per the requirement of the
              cohort. With regard to mentors, a mix of sector specific and
              business focused mentors can be engaged to support entrepreneurs
              especially in one to many Mentorship sessions.
            </p>
          </div>
        </div> */}
        {/* /////////////////////////////// */}
        {/* /////////////////////////////// */}
        {/* <div className="d-flex justify-content-left align-items-center">
          <div className="">
            <h2 className="inovation_sub_headings mt-2 ml-1">
              <b>Pull Strategy</b>
            </h2>
          </div>
        </div>
        <div className="inovation_text mt-2 pb-3">
          <div className="">
            <p className="">
              While implementing the Pull Strategy, there can be many support
              requirements which are unique at the entrepreneur/firm level. When
              an incubator engages more in a push mode of incubation program
              management, entrepreneur level support requirements tend to get
              ignored. Such support requirements can be related to finance,
              operational issues, regulatory concerns, manpower management etc.
              which varies from one entrepreneur to another entrepreneur. Hence
              the basic approach in a pull support is to identify the individual
              requirements of the firm/entrepreneur and support them to get such
              requirements fulfilled. The responsibility of the incubator is to
              identify such requirements and then provide the necessary support
              mechanism through the internal support mechanism built over a
              period of time and through the external connect established by the
              center. An incubator may not be able to cater to all the support
              requirements internally due of lack of resources and due to
              institutional constrains. Therefore the incubator will have to
              connect with other institutions and entities that are well
              equipped to fulfil those needs. Hence the incubator should
              identify different institutions and entities with different core
              competencies so that it can directly approach and connect their
              entrepreneurs as part of the incubation program. Incubator can
              build the support mechanisms by roping in educational/technical
              institutions/ NGO’s/ Government agencies, Industry bodies and
              other institutions and entities that can support in fulfilling
              such requirements.
            </p>
          </div>
        </div> */}
        {/* /////////////////////////////// */}
        <div className="d-flex align-items-center justify-content-center">
          <div className="">
            <h2 className="inovation_headings m-1">Eligibility</h2>
          </div>
        </div>
        <hr />
        <div className=" pt-5 mb-3 criteria">
          <ol>
            <li>
              MSME unit that has established a business venture in Kerala
              preferably in the manufacturing or providing specialized services
              to MSME units.{" "}
            </li>
            <li>Has been in operation for less than 10 years</li>
            <li>
              Having an annual turnover of more than Rs.35 lakhs and less than
              Rs. 50 Cr{" "}
            </li>
          </ol>
        </div>

        <div className="d-flex justify-content-left align-items-center">
          <div className="">
            <h2 className="inovation_sub_headings mt-2 ml-1">
             Sector Focus
            </h2>
          </div>
        </div>
        <div className="inovation_text mt-2 pb-3">
          <div className="">
            <p className="ml-3">
              This will be a sector agnostic program, MSME units across
              industries can apply for this program
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-left align-items-center">
          <div className="">
            <h2 className="inovation_sub_headings mt-2 ml-1">
              <b>Duration of the program</b>
            </h2>
          </div>
        </div>
        <div className="inovation_text mt-2 pb-3">
          <div className="">
            <p className="ml-3">
              Duration of this incubation program is for 6 months
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-left align-items-center">
          <div className="">
            <h2 className="inovation_sub_headings mt-2 ml-1">
              Batch Size
            </h2>
          </div>
        </div>
        <div className="inovation_text mt-2 pb-3">
          <div className="">
            <p className="ml-3">
              Batch Size will be limited to 20 entrepreneurs from Kerala.
            </p>
          </div>
        </div>
        {/* <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <KiedImages
              className="inovation_headings_logo m-1"
              image="inovation-heading-icon"
            />
          </div>
          <div className="">
            <h2 className="inovation_headings m-1">Selection Criteria</h2>
          </div>
        </div> */}
        {/* <hr />
        <div className="pt-5 mb-5 criteria">
          <ol>
            <li>Market/Sectorial Opportunity – 25% Weightage</li>
            <li>Product Differentiation – 25% Weightage</li>
            <li>Growth Plan/Capability – 25% Weightage</li>
            <li>Team and Promoters- 25% Weightage</li>
          </ol>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <KiedImages
              className="inovation_headings_logo m-1"
              image="inovation-heading-icon"
            />
          </div>
          <div className="">
            <h2 className="inovation_headings m-1">Input Metrics </h2>
          </div>
        </div>
        <hr />
        <div className=" pt-5 mb-5 criteria">
          <ol>
            <li>Number of Training Sessions Conducted </li>
            <li>Number of One to Many Mentorship Sessions Conducted </li>
            <li>Number of One on One Mentorship Session Conducted </li>
            <li>
              Number of Entrepreneurs connected with the financing agencies{" "}
            </li>
            <li>
              Number of Entrepreneurs connect to the Internal Support Network{" "}
            </li>
            <li>
              Number of Entrepreneurs connected to External Support Network{" "}
            </li>
          </ol>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <KiedImages
              className="inovation_headings_logo m-1"
              image="inovation-heading-icon"
            />
          </div>
          <div className="">
            <h2 className="inovation_headings m-1">Output Metrics </h2>
          </div>
        </div>
        <hr />
        <div className=" pt-5 mb-5 criteria">
          <ol>
            <li>Number of enterprises with growth in revenue (Pre/Post) </li>
            <li>Year on Year top line revenue generated (Pre/Post)</li>
            <li>Number of employees working in the organization (Pre/Post)</li>
            <li>Capacity addition in the Manufacturing unit ( Pre/Post)</li>
            <li>Net profit trends (Pre/Post)</li>
            <li>Product Diversification(Pre/Post)</li>
            <li>Finance from Banks and from other financial institutions</li>
          </ol>
        </div> */}
      </div>
    </div>
  );
}

export default ProgramsEligibility;
