import React from "react";
import Banner from "../../components/banner/banner";


function Terms() {
  return (
    <div className="">
      <Banner />
      <div>
        Welcome to KIED! By accessing this website, you agree to comply with
        these terms and conditions. If you do not agree with these terms, do not
        use KIED.
        <p>
          Cookies: Our website uses cookies. By accessing KIED, you agree to our
          use of cookies in accordance with our Privacy Policy.
        </p>
        <p>
          License: All intellectual property rights on this website and its
          material belong to KIED and/or its licensors. You may only view,
          download, and print pages from the website for personal use, subject
          to the restrictions outlined below.
        </p>
        You must not:
        <ul>
          <li>Republish or sell website material</li>
          <li>Display website material in public</li>
          <li>Use website material for commercial purposes</li>
          <li>Edit or modify website material</li>
          <li>
            Redistribute website material, except for specifically designated
            content
          </li>
        </ul>
        <p>
          Acceptable use: Do not use this website in any way that may harm the
          website, impair accessibility, or conduct any unlawful, fraudulent or
          harmful activity. Do not use this website to distribute malicious
          computer software, or engage in systematic or automated data
          collection activities without our express written consent.
        </p>
        <p>
          Restrictions: We reserve the right to restrict access to certain areas
          of this website.
        </p>
      </div>
    </div>
  );
}

export default Terms;
