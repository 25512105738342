/////////////////////////////////////////////////
import AOS from "aos";
import React from "react";
import ReactDOM from "react-dom/client";
////////////////////Style Imports////////////////
// import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import "react-multi-carousel/lib/styles.css";
import "./styles/bootstrap.css";
import "./styles/buttons.style.css";
import "./styles/footer.style.css";
import "./styles/header.style.css";
import "./styles/icons.style.css";
import "./styles/main.style.css";

import "./styles/footer.style.css";

/////////////////////////////////////////////////
//////////// Routing Component //////////////////
import AppRouter from "./AppRouter";
/////////////////////////////////////////////////
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
/////////////////////////////////////////////////
AOS.init();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.Stri>ctMode>
	<BrowserRouter>
		<AppRouter />
	</BrowserRouter>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
