// import React from 'react'

// function InfrastructureDescription() {
//   return (
//     <div>

//     </div>
//   )
// }

// export default InfrastructureDescription
import React from "react";
// import KiedIcons from "../../../../assets/icons/kied.icons.component";
// import "../../schemes.page.styles.css";
// import ProgDoc from "./innovation-program.pdf";
import useScreenBreakPoint from "../../../hooks/useScreenBreakPoint";
function InfrastructureDescription() {
  let width = useScreenBreakPoint();
  return (
    <div className="auto-container ">
      <div
        className={`schemes_sub_heading  ${
          width === "xs" || width === "sm" ? "sml mt-4 " : "lr mb-2 mt-5 pt-5"
        }`}
      >
        8. Eligibility for Applicants
      </div>
      <ul className="ml-1 Schemes_heading_description pt-3 mb-5 px-2 ">
        <li>
          <p>
            Units engaged in manufacturing activity, with fixed capital
            investment in plant and machinery up to Rs 25 lakhs are eligible.
          </p>
        </li>
        <li>
          <p>
            {" "}
            The Date of commencement of production of the must be on or after
            1st April 2020.
          </p>
        </li>
        <li>
          <p>
            {" "}
            Order of selection of the applicant will be based on the seniority
            in date of commencement of production.
          </p>
        </li>
        <li>
          <p>
            Assistance/support shall be provided to units which are located
            within the district.
          </p>
        </li>
        <li>
          <p>
            {" "}
            Preference shall be given to young entrepreneurs (up to 45 years),
            handicraft units, products with GI indication.
          </p>
        </li>
        <li>
          <p>
            {" "}
            Priority for allocation of space, shall be given to units, which are
            working in the same local body, where the space is located.
          </p>
        </li>

        <li>
          <p>
            {" "}
            Space, which belongs to Government/PSUs, shall be provided to the
            applicants to display and market/sell their product. Cost of the
            Shelf/Rack shall be borne by a single unit or more than one unit.
            Actual upkeep maintenance charges subject to a ceiling of Rs 10,000
            per month will be provided by industries department. The period of
            display shall be limited to one year from the date of display,
            subject to the availability of space and pending applications.
            Upkeep maintenance charges include care taking charge, rent,
            electricity. This charge shall be reimbursed to the person
            nominated by the unit, with the approval of ADIO of the Taluk
            Industries Office concerned for selling their products at the space.
          </p>
        </li>
        <li>
          <p>
            {" "}
            Display cum sales spaces on rent/lease basis from private
            individuals/establishments/NGOs/Malls/Supermarkets etc. also is
            considered, subject to the availability. The rent/lease charges
            shall be met by the industries department, subject to a maximum
            of- 5000/10000/15000 Rs. per month in Block panchayat/
            Municipality/ Corporation respectively.
          </p>
        </li>
        <li>
          <p>
            50% of the Cost of Development of e-commerce platform/application
            for the selling of their product limited to Rs 25,000.
          </p>
        </li>
        <li>
          <p>
            {" "}
            Product information board with emblem of Govt. of Kerala will be
            displayed in the near premises/inside the shops. The cost will be
            borne by the department.
          </p>
        </li>
        <li>
          <p>
            Identified spaces shall be confirmed by a committee comprising
            General Manager of the DIC, Manager and Taluk ADIO concerned.
          </p>
        </li>
        <li>
          <p>
            Application for allotment of display spaces of products shall be
            scrutinized and finalized by the above committee.
          </p>
        </li>
        <li>
          <p>
            Units which received allocation of space shall execute an agreement
            with General Manager of the District Industries Centre concerned.
            Private Space owners shall execute a lease/rent agreement with
            General Manager, DIC.
          </p>
        </li>
      </ul>

      <div
        className={`schemes_sub_heading  ${
          width === "xs" || width === "sm" ? "sml mt-4 " : "lr mb-2 mt-5 pt-5"
        }`}
      >
        9. Obligation of an applicant
      </div>
      <ul className="ml-1 Schemes_heading_description pt-3 mb-5 px-2 pb-5 ">
        <li>
          <p>
            The applicant shall provide all required details and declare such
            information as to be true.
          </p>
        </li>
        <li>
          <p>
            The applicant shall provide clarifications of further details sought
            by the sanctioning authority.
          </p>
        </li>
        <li>
          <p>
            The applicant shall allow inspection or verification of any details
            mentioned in the application including plant and machinery and all
            other assets if so required by sanctioning authority.
          </p>
        </li>
        <li>
          <p>
            Applicants shall produce originals of any important documents if so
            required by the sanctioning authority for verification.
          </p>
        </li>
        <li>
          <p> The applicant shall execute the requisite legal documents.</p>
        </li>
        <li>
          <p>
            
            The applicant shall utilize the amount received only in the manner
            agreed upon.
          </p>
        </li>
        <li>
          <p>
            
            The applicant shall utilize the space as stipulated in the agreement
            in which the quantum of support received, failing which the
            assistance shall be resumed by restoring the provisions of Kerala
            Revenue Recovery Act.
          </p>
        </li>
      </ul>
    </div>
  );
}

export default InfrastructureDescription;
