import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
function AboutEDC() {
  return (
    <div>
      <div className=" d-flex auto-container justify-content-between  align-items-center common-padding ">
        <div className="image col-4 d-none d-md-block">
          <KiedImages className="" image="about-edc" />
        </div>

        <div className="Text padding-top col-md-6 col-sm-12 ">
          <span className="icon flaticon-big-anchor about_icon" />
          <h2 className="text_about">KIED</h2>
          <p className="text_about ">
            "Established as an autonomous body of the Department of Industries
            and Commerce, Government of Kerala in 1994, KIED was created with
            the goal of fostering the spirit and culture of entrepreneurship
            among the youth of Kerala. With its own campus established in
            Kalamassery, Kochi in 2005, KIED provides orientation in Human
            Resources Development, training, skill development, and leadership
            to its entrepreneurial-minded participants
          </p>
          <p>
            <b>Vision</b>: "To be a global centre of knowledge for the smart and
            sustainable entrepreneurship development of the state".
          </p>
          <p>
            <b>Mission</b>: "To develop and apply insights for providing
            inspiration for entrepreneur development, information for enterprise
            development and influence policy for industrial development".
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutEDC;
