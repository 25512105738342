import React from "react";
import { Outlet } from "react-router-dom";
import LayoutComponent from "../../layout/layout.component";

function PublicRoute(props) {
	console.log(props);
	return (
		<LayoutComponent {...props}>
			<Outlet context={props} />
		</LayoutComponent>
	);
}

export default PublicRoute;
