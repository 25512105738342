import React from "react";
import { inovationProgramData } from "../../../inovation/sections/inovationData";
import EventsListingCard from "../../../../components/eventsListingCard/events.listing.card";

function EventsListingDetails({ data }) {
  return (
    <div className="auto-container">
      <div className="">
        {data.map((card, i) => {
          return (
            <EventsListingCard
              data={card}
              key={i}
              index={i}
              type={"ProgramList"}
            />
          );
        })}
      </div>
    </div>
  );
}

export default EventsListingDetails;
