import React from "react";
import "./dedicatedNewsCard.styles.css";
import KiedImages from "../../assets/images/kied.images.component";

function DedicatedNewsCard(props) {
  return (
    <div className="col-md-4 col-sm-12 pt-4 mt-1 mb-1 d-flex justify-content-around">
      <div className="">
        <div className="mentor_card  d-flex flex-column align-items-center  ">
          <div className="">
            <KiedImages className="mentor_image" image={props.data.image} />
          </div>
          <div className=" news_tag_container d-flex justify-content-between align-items-center w-100">
            <div className="d-flex justify-content-left align-items-center w-100">
              <div className="m-1 news_tag">{props.data.tag1}</div>
              <div className="m-1 news_tag">{props.data.tag2}</div>
            </div>
            <div>
              <a href={props.data.viewmorelink}>VIEW&nbsp;MORE</a>
            </div>
          </div>
          <div className=" news_content_container d-flex flex-column justify-content-between align-items-center">
            <div className="news_content_title">{props.data.title}</div>
            <div className="news_content_description">
              {props.data.description}
            </div>
          </div>
          <div className="p-3 w-100 news_author_container">
            <div className="d-flex align-items-center w-100">
              <div className="d-flex align-items-center">
                <KiedImages image="newsauthor" className="news_author_image" />
                <div className="news_author_name m-1">
                  {props.data.authorname}
                </div>
              </div>
              <div className="news_author_date ml-3">{props.data.date}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DedicatedNewsCard;
