import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import "./activity.section.style.css";
import KiedImages from "../../../../assets/images/kied.images.component";
// import { Parallax } from "react-scroll-parallax";

function ActivitiesSection() {
  return (
    <div className="common-sections container">
      <div className="row">
        <div className="position-relative col-lg-6 col-md-6 col-md-12">
          <div className="activity-section-image-container">
            {/* <div className="w-100 position-relative"> */}
            {/* <Parallax
								translateX={[0, 100]}
								easing={"easeInOutQuad"}
								style={{ position: "absolute", width: "100%" }}
							> */}
            <div className="play-box">
              <div className="box-inner">
                <a className="play-btn" href="/">
                  <span className="icon flaticon-play-button-1"></span>
                </a>
                video
                <br />
                30.min
              </div>
            </div>
            {/* </Parallax> */}
            {/* </div> */}

            <div className="image">
              <KiedImages image="kied-activity-image" />
            </div>
          </div>
        </div>
        <div className="position-relative col-lg-6 col-md-6 col-md-12">
          <div className="activity-section-text-container ">
            <div>
              <div className="title">
                EDC conducts various <br /> Programs, Events & Trainings
              </div>
              <div className="text">
                Enterprise Development Center (EDC) is a place which empowers
                innovative entrepreneurs to Plan, Start, Sustain, Revive and
                Grow their business. Services offered include incubation,
                acceleration, Industry academic collaboration, facilitation &
                guidance, financial linkages and student entrepreneur
                development activities.
              </div>
              {/* <div className="button">
                <KiedButton
                  secondary
                  inverted
                  big
                  rounded
                  content={
                    <>
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          Explore Now
                        </div>
                        <div className="d-flex align-items-center">
                          <KiedIcons
                            icon="arrow-right"
                            className="hero-button-icon"
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivitiesSection;
