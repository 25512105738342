import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import TextField from "../../components/textfield/TextField.component";
import RadioButton from "../../components/radiobutton/radiobutton";
import SelectBox from "../../components/selectbox/SelectBox.component";
import KiedButton from "../../components/buttons/button/button.component";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/file.upload/fileupload.component";
import { useState } from "react";
import { institution } from "../edc.registration/registrationform.data";
import { districts, expertise } from "../registration/registrationform.data";
import { gender } from "../edc.registration/registrationform.data";
import { qualification } from "../registration/registrationform.data";

function ExpertForm() {
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const handleTextFieldChange = (value, name) => {
    setFormdata({ ...formdata, [name]: value });
  };

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
    console.log(e.target);
  };

  const handleregister = () => {
    navigate("/success");
  };

  const fileUpload = (e) => {
    console.log(formdata);
    console.log(e.target.files);
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    // accept = ".pdf, .png, .jpeg";
    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);

    if (regVal.test(filename)) {
      setFormdata({
        ...formdata,
        [name]: value,
        [name + "_name"]: filename,
      });
    } else {
      setFormdata({
        ...formdata,
        [name]: undefined,
        [name + "_name"]: undefined,
      });
      setError({ [name]: true });
    }
  };

  return (
    <>
      <SmallBanner title="Experts/Mentors Data Form" />
      <div>
        <div className="d-flex align-items-center justify-content-between head-container">
          <div className="faq_banner  d-flex">
            <a href="/">Home&nbsp;/</a>

            <a className="current">Registration</a>
          </div>
          <div className=" d-flex justify-content-end mt-3">
            <p className="">
              <span>
                <span style={{ color: "red" }}>* </span> Mandatory Fields
              </span>
            </p>
          </div>
        </div>

        <div className="auto-container form">
          <form>
            <RadioButton
              options={gender}
              label="Gender"
              required
              name="gender"
              value={formdata.gender ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <SelectBox
              label="Highest Qualification"
              required
              options={qualification}
              value={formdata.qualification}
              selectionMessage="Select your Qualification"
              name="qualification"
              handleChange={handleChange}
            />
            <br />
            <TextField
              label="Website/Online Profile of the Applicant e.g., LinkedIn"
              required
              name="address"
              value={formdata.address ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <SelectBox
              label="Area of Professional Expertise"
              required
              options={expertise}
              value={formdata.expertise}
              selectionMessage="Select your Area of Expertise"
              name="expertise"
              handleChange={handleChange}
            />
            <br />
            {formdata.expertise === "Others" && (
              <>
                <TextField
                  label="Specify the Area of Your Expertise"
                  required
                  name="expspecify"
                  value={formdata.expspecify ?? ""}
                  onChange={handleTextFieldChange}
                />
                <br />
              </>
            )}
            <FileUpload
              label="Credentials to prove Qualification (.pdf, .docx, .jpg format files are allowed)"
              required
              accept=".pdf, .docx, .jpg"
              name="proofq"
              type="file"
              // filename={formdata.gst_file_name}
              // value={formdata.gst_file ?? null}
              onChange={fileUpload}
            />
            <br />
            <FileUpload
              label="Credentials to prove Experience (.pdf, .docx, .jpg format files are allowed)"
              required
              accept=".pdf, .docx, .jpg"
              name="proofe"
              type="file"
              // filename={formdata.gst_file_name}
              // value={formdata.gst_file ?? null}
              onChange={fileUpload}
            />
            <br />
            <FileUpload
              label="Details of other Empanelment at other organizations (.pdf, .docx, .jpg format files are allowed)"
              accept=".pdf, .docx, .jpg"
              name="prooforg"
              type="file"
              // filename={formdata.gst_file_name}
              // value={formdata.gst_file ?? null}
              onChange={fileUpload}
            />
            <br />

            {error.proof && (
              <p id="proof" className="ml-3 error">
                Unsupported file type! Please select a .pdf or .docx file
              </p>
            )}
            <br />
          </form>
          <div className="d-flex justify-content-center pt-5 pb-5">
            <KiedButton
              onClick={() => handleregister()}
              primary
              rounded
              type="submit"
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Submit
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpertForm;
