import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import "./blog.detail.page.styles.css";
import BlogDetailDescription from "./sections/blog.detail.description";

function BlogDetailPage() {
  return (
    <div>
      <SmallBanner />
      <BlogDetailDescription />
    </div>
  );
}

export default BlogDetailPage;
