import React from "react";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
import HeaderMenuComponent from "./components/menu.desktop.component";
import MobileMenuCompoent from "./components/menu.mobile.component";
import PartnerLogoComponent from "./components/partner.logo.component";

function HeaderComponent({ isScrolled, ...props }) {
  let width = useScreenBreakPoint();

  return (
    <>
      {width === "xs" || width === "sm" ? (
        <div>
          <MobileMenuCompoent isScrolled={isScrolled} {...props} />
        </div>
      ) : (
        <div className="desktop-header w-100 pt-2" id="header">
          <PartnerLogoComponent {...props} />
          <HeaderMenuComponent isScrolled={isScrolled} {...props} />
        </div>
      )}
    </>
  );
}

export default HeaderComponent;
