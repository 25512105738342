import React from "react";
import SmallBanner from "../../../../components/small.banner/small.banner";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getNews } from "../../../../apis/publicApis";
import HomeNewsDetailPage from "./HomeNewsDetailPage";

function HomeNewsDetails() {
  const [isActive, setIsActive] = useState(true);
  const param = useParams();
  const [data, setData] = useState([]);
  const [newsData, setNewsData] = useState([])

  useEffect(() => {
    getNews()
      .then((res) => {
        console.log(res)
        setNewsData(res.data.filter((item, i) => item.id == param.id));
      })
      .catch(() => {});
  }, []);
  return (
    <>
      <SmallBanner />
      <HomeNewsDetailPage data={newsData[0] || []} />
    </>
  );
}

export default HomeNewsDetails;
