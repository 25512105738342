import React from "react";
import KsidcSchemeBreadcrumbs from "./sections/breadcrumb/schemes.breadcrumb";
import SchemeHerosection from "./sections/hero/schemeHero";
import SchemeDescription from "./sections/description/scheme.description";
import SchemeCanapply from "./sections/canapply/scheme.canapply";
import SchemeEligibility from "./sections/eligibility/scheme.eligibility";
import SmallBanner from "../../components/small.banner/small.banner";
import "./schemes.page.styles.css";

function KsidcSchemesPage() {
  return (
    <div>
      <SmallBanner title={"KSIDC Entrepreneur Scheme"} />
      <KsidcSchemeBreadcrumbs />
      <SchemeHerosection />
      <SchemeDescription />
      {/* <SchemeCanapply /> */}
      {/* <SchemeEligibility /> */}
    </div>
  );
}

export default KsidcSchemesPage;
