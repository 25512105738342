import React from "react";
import Banner from "../../components/banner/banner";
import BlogDetail from "./sections/blogs.details";

function BlogsPage() {
  return (
    <div>
      <Banner title="Blogs" image="academia-banner" />
      <BlogDetail />
    </div>
  );
}

export default BlogsPage;
