export const footercontent = [
  {
    id: 2,
    type: "link",
    data: [
      {
        label: "Tenders",
        label_ma : "ടെൻഡർ",
        link: "/tenders",
      },
      // {
      //   label: "Kied Edc",
      //   link: "/department/EDC/home",
      // },
      {
        label: "Right to Information",
        label_ma : "വിവരാവകാശം",
        link: "#",
      },
      // {
      // 	label: "Publications",
      // 	link: "#",
      // },
      // {
      //   label: "Blog",
      //   link: "#",
      // },
    ],
  },
  // {
  //   id: 3,
  //   type: "link",
  //   data: [

  //     // {
  //     // 	label: "Alumni",
  //     // 	link: "#",
  //     // },
  //   ],
  // },
  {
    id: 4,
    type: "link",

    data: [
      {
        label: "Careers",
        label_ma:"തൊഴിലവസരങ്ങൾ",

        link: "/carrers",
      },

      {
        label: "Terms & Conditions",
        label_ma:"വ്യവസ്ഥകള്‍",
        link: "/terms",
      },
    ],
  },
  {
    id: 5,
    type: "link",
    data: [
      // {
      // 	label: "Downloads",
      // 	link: "#",
      // },

      // {
      // 	label: "Feedback",
      // 	link: "#",
      // },
      {
        label: "Disclaimers",
        label_ma:"നിരാകരണങ്ങൾ",
        link: "#",
      },
      {
        label: "Privacy Policy",
        label_ma:"സ്വകാര്യതാ നയം",
        link: "",
      },
    ],
  },
  {
    id: 1,
    type: "address",
    data: {
      line1: "KIED EDC, Ernakulam",
      line2: "Kerala, India-682319",
      phone: "0484 2550322, 2532890.",
    },
  },
];
export const footerMcontent = [
  {
    id: 2,
    type: "link",
    data: [
      {
        label: "Tenders",
        label_ma : "ടെൻഡർ",
        link: "/tenders",
      },
      // {
      //   label: "Kied Edc",
      //   link: "/department/EDC/home",
      // },
      {
        label: "Right to Information",
        label_ma : "വിവരാവകാശം",
        link: "#",
      },
      // {
      // 	label: "Publications",
      // 	link: "#",
      // },
      // {
      //   label: "Blog",
      //   link: "#",
      // },
      {
        label: "Careers",
        label_ma:"തൊഴിലവസരങ്ങൾ",
        link: "/carrers",
      },
    ],
  },
  // {
  //   id: 3,
  //   type: "link",
  //   data: [

  //     // {
  //     // 	label: "Alumni",
  //     // 	link: "#",
  //     // },
  //   ],
  // },
  {
    id: 4,
    type: "link",

    data: [
      // {
      //   label: "Right to Information",
      //   link: "#",
      // },
      {
        label: "Terms & Conditions",
        label_ma:"വ്യവസ്ഥകള്‍",
        link: "/terms",
      },
      {
        label: "Disclaimers",
        label_ma:"നിരാകരണങ്ങൾ",
        link: "#",
      },
      {
        label: "Privacy Policy",
        label_ma:"സ്വകാര്യതാ നയം",
        link: "#",
      },
    ],
  },
  // {
  //   id: 5,
  //   type: "link",
  //   data: [
  //     // {
  //     // 	label: "Downloads",
  //     // 	link: "#",
  //     // },
  //     // {
  //     // 	label: "Feedback",
  //     // 	link: "#",
  //     // },
  //   ],
  // },
  {
    id: 1,
    type: "address",
    data: {
      line1: "KIED EDC, Ernakulam, Kerala",
      line2: "India-682319. Ph:0484 2550322, 2532890.",
      // phone: "",
    },
  },
];
export const footercontentsub = {
  id: 1,
  type: "link",
  label: "Copyright © 2022 KIED . All Rights Reserved.",
  data: [
    {
      icon: "facebook",
      link: "https://www.facebook.com/kiedkerala",
    },
    {
      icon: "linkedin",
      link: "https://www.linkedin.com/in/kiedkerala",
    },
    {
      icon: "youtube",
      link: "https://youtube.com/@kiedkerala",
    },
    {
      icon: "instagram",
      link: "https://instagram.com/kiedkerala",
    },
  ],
};
