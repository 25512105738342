import React from "react";

function FaqBreadcrumb() {
  return (
    // <div>
    //   <div className="head-container  d-flex">
    //     <a href="/">Home&nbsp;/</a>
    //     <a href="/department/inovation/home"> Innovation&nbsp;/</a>
    //     <a href="/department/inovation/programs"> Programms&nbsp;/</a>
    //     <a className="current" href="department/inovation/faq">
    //       Faq
    //     </a>
    //   </div>
    // </div>
    <div>
      <div className="auto-container">
        <div className="faq_banner  d-flex">
          <a href="/">Home&nbsp;/</a>
          <a href="/department/inovation/home"> Innovation&nbsp;/</a>
          <a href="/newprograms/1"> Programms&nbsp;/</a>
          <a className="current" href="/edc-schemes">
            Faq
          </a>
        </div>
      </div>
    </div>
  );
}

export default FaqBreadcrumb;
