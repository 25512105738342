import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import EDCProgramBread from "./sections/breascrumbs/edcProgram.breadcrumb";
import EDCProgramDetails from "./sections/details/EDCProgramDetails";
import EDCProgramDescription from "./sections/description/edcProgram.description";
import EDCProgramGallery from "./sections/gallery/edcProgram.gallery";

function EDCProgramPage() {
  return (
    <div>
      <SmallBanner />
      <EDCProgramBread />
      <EDCProgramDetails />
      <EDCProgramDescription />
      <EDCProgramGallery />
    </div>
  );
}

export default EDCProgramPage;
