// import React from 'react'

// function EventCard2() {
//   return (
//     <div>EventCard2</div>
//   )
// }

// export default EventCard2

import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
// import "./event.card.style.css";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
import KiedButton from "../buttons/button/button.component";
function EventCard2(props) {
  let width = useScreenBreakPoint();
  return (
    <div className=" d-flex justify-content-center w-100 icn-event">
      <div
        className={`col-md-4 col-sm-12 event_card ${
          width === "sm" || width === "xs"
            ? props.index > 2 //if index greater than 2
              ? (props.index + 1) % 2 === 0
                ? "event_center_card "
                : " "
              : (props.index + 1) % 2 === 0 //else index less than 2
              ? "event_center_card  "
              : ""
            : props.index > 2 //if index greater than 2
            ? props.index % 3 === 1
              ? "event_center_card mt-5 "
              : " mt-5 "
            : props.index % 3 === 1 //else index less than 2
            ? "event_center_card mt-5 mb-5 "
            : "mt-5  "
        }`}
      >
        <div className="d-flex flex-column align-items-center event_card_container">
          <div className="ribbon1">
            <span>Сompleted</span>
          </div>
          <div>
            <h3 className="event_title">{props.data.title}</h3>
          </div>
          <div className="d-flex mb-3 align-items-center">
            {/* <KiedImages
            className="event_icon"
            image={`${
              props.index > 2 //if index greater than 2
                ? props.index % 3 === 1
                  ? "location-bicon"
                  : "location-icon"
                : props.index % 3 === 1 //else index less than 2
                ? "location-bicon"
                : "location-icon"
            }`}
          /> */}
            <span className="event_location text_black ml-1">
              {props.data.location}
            </span>
          </div>
          <div className="d-flex align-items-center ">
            <KiedImages className="mb-1 mr-1" image="calender-icon" />
            <span className="text_black">
              {props.data.startDate} {props.data.startTime}
            </span>
          </div>
          <div className="mt-4 ml-3 mb-3">
            <KiedButton
              className="readmore_btn "
              primary
              rounded
              content={
                <a
                  className="event_readmore"
                  href={props.data.link + props.data.id}
                >
                  Learn More
                </a>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCard2;
