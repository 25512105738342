import { parseQueryParams } from "../utils/formValidate";
import apis from "../apis/api.actions";

export const registerIncubatte = (data) =>
  apis.createData(`v1/incubatee`, data).then((res) => res);
export const registeredclub = (data) =>
  apis.createData(`v1/ed-register`, data).then((res) => res);
export const createBooking = (data) =>
  apis.createData(`v1/bookings`, data).then((res) => res);
export const getProfile = (data) =>
  apis.getDatas(`v1/user-profile${parseQueryParams(data)}`).then((res) => res);

  export const getEDC = () => apis.getDatas(`v1/is-edc-register`).then((res) => res);
  export const getquestions = (id) => apis.getDatas(`v1/programs/${id}/questions`).then((res) => res);
  export const updateclub = (id,data) =>
  apis.createData(`v1/ed-register/${id}`, data).then((res) => res);
export const VerifyEmailAddress = (data) =>
  apis.getDatas(`v1/verify-email/${data}`).then((res) => res);
export const createProfile = (data) =>
  apis.createData(`v1/user-profile`, data).then((res) => res);
export const createEDProfile = (data) =>
  apis.createData(`v1/ed-register`, data).then((res) => res);
