export const districts = [
  {
    id: 1,
    name: "Trivandrum",
  },
  {
    id: 2,
    name: "kollam",
  },
  {
    id: 3,
    name: "Pathanamthitta",
  },
  {
    id: 4,
    name: "Alapuzha",
  },
  {
    id: 5,
    name: "Kottayam",
  },
];
export const institutions = [
  {
    id: 1,
    name: "School",
  },
  {
    id: 2,
    name: "Polytechnic",
  },
  {
    id: 3,
    name: "ITI",
  },
  {
    id: 4,
    name: "Professional College",
  },
  {
    id: 5,
    name: "Management College",
  },
  {
    id: 6,
    name: "Arts & Science College",
  },
  {
    id: 7,
    name: "Others",
  },
];
export const institution = [
  {
    id: 1,
    name: "School",
  },
  {
    id: 2,
    name: "College",
  },
  {
    id: 3,
    name: "Research Organization",
  },
  {
    id: 4,
    name: "NGO",
  },
  {
    id: 5,
    name: "Others",
  },
];
export const schoolCollege = [
  {
    id: 1,
    name: "School",
  },
  {
    id: 2,
    name: "College",
  },
];
export const radiooptions = [
  {
    label: "YES",
    value: "Yes",
  },
  {
    label: "NO",
    value: "No",
  },
];
export const radiooption = [
  {
    label: "YES",
    value: 1,
  },
  {
    label: "NO",
    value: 0,
  },
];
export const gender = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Other",
    value: " Other",
  },
];

export const castCategory = [
  { label: "SC/ST", value: "SC/ST" },
  { label: "OBC", value: "OBC" },
  { label: "General", value: "General" },
];
export const businessStage = [
  // {
  //   name: "No idea",
  //   id: 1,
  // },
  // {
  //   name: "I have an idea",
  //   id: 2,
  // },
  {
    name: "Just started a business",
    id: 3,
  },
  {
    name: "Already in business - Wish to expand",
    id: 4,
  },
  {
    name: "Already in business - Wish to diversify",
    id: 5,
  },
];
export const KiedCarriers = [
  {
    name: "Social Media",
    id: 1,
  },
  {
    name: "Friends",
    id: 2,
  },
  {
    name: "Print Media",
    id: 3,
  },
  {
    name: "Others",
    id: 4,
  },
];

export const productType = [
  {
    id: 1,
    name: "Raw Product",
  },
  {
    id: 2,
    name: "Ready to Cook",
  },
  {
    id: 3,
    name: "Ready to eat",
  },
  {
    id: 4,
    name: "By Product",
  },
  {
    id: 5,
    name: "Other",
  },
];
export const mode = [
  {
    id: 1,
    name: "Manufacturing / Production",
  },
  {
    id: 2,
    name: "Packing / Repacking",
  },
  {
    id: 3,
    name: "Trading / Marketing",
  },
  {
    id: 4,
    name: "Services",
  },
  {
    id: 5,
    name: "Other",
  },
];
export const problemtype = [
  {
    label: "Management",
    value: "Management",
  },
  {
    label: "Technical",
    value: "Technical",
  },
  {
    label: "Others",
    value: "Others",
  },
];
export const businessduration = [
  {
    name: "0 - 3 years",
    id: 1,
  },
  {
    name: "3 - 5 years",
    id: 2,
  },
  {
    name: "5 - 10 years",
    id: 3,
  },
  {
    name: "Above 10 years",
    id: 4,
  },
];
