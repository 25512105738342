import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import TextField from "../../../../components/textfield/TextField.component";
import SelectBox from "../../../../components/selectbox/SelectBox.component";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { nature, mode, payment } from "../../internship-details";
import { districts } from "../../../registration/registrationform.data";
import RadioButton from "../../../../components/radiobutton/radiobutton";
import RichTextEditor from "../../../../components/richtext_editor/richtexteditor";
import { logedUser } from "../../../../apis/auth.apis";
import { useEffect } from "react";
import Swal from "sweetalert2";

function SubmitInternshipDetail() {
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState({});
  const [fielderror, setFieldError] = useState(true);

  let navigate = useNavigate();

  const fetchprofile = () => {
    logedUser()
      .then((res) => {
        console.log(res);
        localStorage.setItem("user", JSON.stringify(res.user));
        let profileData = res.user?.profile[0] || {};
        setFormdata(profileData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchprofile();
  }, []);

  const validateField = () => {
    let errorField = {};

    return errorField;
  };

  let userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  let userrole = userData?.role;

  const handleTextFieldChange = (value, name) => {
    console.log(name, value);
    setFormdata({
      ...formdata,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value, e.target.name);
  };

  const handleregister = () => {
    if (userrole === "4" || userrole === "6") {
      navigate("/success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You are not authorized. Only Existing Entrepreneurs, Research Institutions and Universities have access to this section  ",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok"
      });
    }
  };

  // const fileUpload = (e) => {
  //   console.log(formdata);
  //   console.log(e.target.files);
  //   let name = e.target.name;
  //   let value = e.target.files[0];
  //   let filename = e.target.files[0].name;
  //   // accept = ".pdf, .png, .jpeg";
  //   let validationFormats = e.target.accept
  //     .split(",")
  //     .join("|")
  //     .split(".")
  //     .join("")
  //     .split(" ")
  //     .join("");

  //   var regVal = new RegExp(`^.*.(${validationFormats})$`);

  //   if (regVal.test(filename)) {
  //     setFormdata({
  //       ...formdata,
  //       [name]: value,
  //       [name + "_name"]: filename,
  //     });
  //   } else {
  //     setFormdata({
  //       ...formdata,
  //       [name]: undefined,
  //       [name + "_name"]: undefined,
  //     });
  //     setError({ [name]: true });
  //   }
  // };

  return (
    <div className="auto-container pb-5 pt-5">
      <div id="" className="d-flex justify-content-center align-items-center">
        <div className="">
          <h2 className="report_heading m-1">Report Internship Details</h2>
        </div>
      </div>
      <div className="">
        <div className="auto-container form mt-5">
          <div className=" mt-4">
            <TextField
              label="Internship Role"
              required
              type="text"
              name="internship_role"
              value={formdata.internship_role ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Name of Organization"
              required
              type="text"
              name="company_name"
              value={formdata.company_name ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Website"
              required
              type="text"
              name="website"
              value={formdata.website ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Contact No"
              required
              type="text"
              name="contact_no"
              value={formdata.contact_no ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Email Id"
              required
              type="text"
              name="email"
              value={formdata.email ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="About Organization"
              required
              type="text"
              name="about_organization"
              value={formdata.about_organization ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <SelectBox
              label="Internship Mode"
              required
              options={mode}
              // error={fielderror.religion}
              value={formdata.mode}
              selectionMessage="Select Internship Mode"
              name="mode"
              handleChange={handleChange}
            />
            <br />
            <SelectBox
              label="Nature of Internship"
              required
              options={nature}
              // error={fielderror.religion}
              value={formdata.nature}
              selectionMessage="Select Nature of Internship"
              name="nature"
              handleChange={handleChange}
            />
            <br />
            <SelectBox
              label="Location"
              required
              options={districts}
              // error={fielderror.religion}
              value={formdata.location}
              selectionMessage="Select Location"
              name="location"
              handleChange={handleChange}
            />
            <br />
            <TextField
              label="Duration"
              required
              type="text"
              name="duration"
              value={formdata.duration ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <RadioButton
              options={payment}
              name="payment"
              // error={fielderror.gender}
              value={formdata.payment ?? ""}
              checked={formdata.payment}
              onChange={handleChange}
            />
            <br />
            <TextField
              label="If Paid, the Stipend Amount"
              required
              type="text"
              name="stipend"
              value={formdata.stipend ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Additional Perks"
              required
              type="textarea"
              name="preks"
              value={formdata.preks ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Qualification"
              required
              type="textarea"
              name="qualification"
              value={formdata.qualification ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <RichTextEditor
              initialContent={formdata.job_description}
              onChange={handleTextFieldChange}
              label="Job Description (Internship Description)"
            />
            <br />
            <TextField
              label="Learning Outcomes (Internship Benefits)"
              required
              type="textarea"
              name="outcomes"
              value={formdata.outcomes ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Number of Openings"
              required
              type="number"
              maxLength={3}
              name="openings"
              value={formdata.openings ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Start Date"
              required
              type="date"
              name="start_date"
              value={formdata.start_date ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="End Date"
              required
              type="date"
              name="end_date"
              value={formdata.end_date ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center  m-5">
        <KiedButton
          onClick={() => handleregister()}
          content={
            <>
              <div className="d-flex p-1">
                <div className="d-flex align-items-center">Submit</div>
                <div className="d-flex align-items-center"></div>
              </div>
            </>
          }
          primary
          rounded
          big
        />
      </div>
    </div>
  );
}

export default SubmitInternshipDetail;
