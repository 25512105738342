export const developmentStage = [
  {
    label: "Ideation",
    name: "Ideation",
  },
  {
    label: "Validation",
    name: "Validation",
  },
  {
    label: "Prototype",
    name: "Prototype",
  },
  {
    label: "Early Traction",
    name: "Early Traction",
  },
  {
    label: "Available in the Market",
    name: "Available in the Market",
  },
];

export const marketResearch = [
  {
    label: "Competing products",
    name: "Competing products",
  },
  {
    label: "Similar products",
    name: "Similar products",
  },
  {
    label: "Similar business models",
    name: "Similar business models",
  },
  {
    label: "Size of market (name)",
    name: "Size of market (name)",
  },
  {
    label: "Demographic",
    name: "Demographic",
  },
];


