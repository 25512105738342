import React from "react";
import KiedVideosDetails from "../../pages/kied_videos/details/KiedVideosDetails";
import SmallBanner from "../../components/small.banner/small.banner";
import { getVideo } from "../../apis/publicApis";
import { useState, useEffect } from "react";
function KiedVideos() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getVideo()
      .then((res) => {
        let prdata = [];
        res?.data?.forEach((item) => {
          console.log(item);

          let prItem = {
            video: item?.url,
            heading: item?.title,
          };
          prdata.push(prItem);
        });
        setData(prdata);
      })
      .catch(() => {});
  }, []);
  return (
    <div>
      <SmallBanner title="Videos" />
      <KiedVideosDetails data={data} />
    </div>
  );
}

export default KiedVideos;
