export const blogsData = [
  {
    id: 1,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "/blogsdetails",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 2,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "",
    viewmorelink: "/blogsdetails",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 3,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 4,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 5,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 6,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 7,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 8,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 9,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 10,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 11,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 12,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 13,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 14,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 15,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 16,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 17,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 18,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 19,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 20,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
  {
    id: 21,
    image: "newscardimage",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "The Dark Side of Overnight Success",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "29 OCTOBER 2022",
  },
];
