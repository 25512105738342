import React from "react";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import "../../schemes.page.styles.css";
import parse from "html-react-parser";
// import ProgDoc from "./innovation-program.pdf";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SchemeDescription(props) {
  let width = useScreenBreakPoint();
  return (
    <div className="auto-container ">
      {/* <div
        className={`schemes_heading  ${
          width === "xs" || width === "sm" ? "sml mt-4 " : "lr mb-5 mt-5 pt-5"
        }`}
      >
        Features of the Scheme
      </div>
      <ul className="ml-1 Schemes_heading_description pt-3 mb-5 px-2 ">
        <li>
          <p>
            Provides subsidy for the investment in land, building, plant &
            machinery, electrification, essential office equipments, pollution
            control devices and other fixed assets.
          </p>
        </li>
        <li>
          <p>
            {" "}
            For general category, assistance is 15% of the capital investment
            limited to Rs 30 lakh.
          </p>
        </li>
        <li>
          <p>
            {" "}
            For young (18 to 45 years), women, SC/ST and non resident keralite
            (NRK) entrepreneurs, the assistance is 25% limited to Rs 40 lakh.
          </p>
        </li>
        <li>
          <p>
            Enterprises in priority sectors are eligible for an additional
            assistance of 10% limited to Rs 10 lakh.
          </p>
        </li>
        <li>
          <p>
            {" "}
            MSMEs started in the districts of Idukki, Wayanad, Kasargode and
            Pathanamthitta are eligible for an additional support of 10% limited
            to Rs 10 lakh.
          </p>
        </li>
        <li>
          <p>
            {" "}
            Enterprises setup after aquiring new technology from approved
            research institutions are eligible for an additional support of 10%
            limited to Rs 10 lakh.
          </p>
        </li>
        <li>
          <p>
            {" "}
            The total eligible assistance for an enterprise is limited to Rs 40
            lakh.
          </p>
        </li>
      </ul> */}

      {/* <div className="schemes_heading mb-5 mt-5">
        Enterprises under Priority Sector
    </div>*/}
      <div className=" Schemes_heading_description my-5">
        {props?.data?.main_description
          ? parse(props?.data?.main_description)
          : "--"}
      </div>
      {/*  <hr />
      <div className="schemes_heading mb-5 mt-5">
        Enterprises under Negative List
      </div>
      <div className=" Schemes_heading_description mb-5">
        Service Enterprises, Photo Studios and Colour Processing Centres,
        Tailoring other than manufacturing of readymade garments, Breweries and
        Distilleries of all types, Saw mills, Soap Grade Sodium Silicate,
        Asbestos processing, Metal Crushers including Granite Manufacturing
        units, All types of Steel Re rolling Mills, Units manufacturing iron
        ingots, Calcium Carbide, Cement manufacturing except units manufacturing
        cement from fly ash, Potassium Chlorate, Power intensive units.
      </div>
      <hr />
      <div className="schemes_heading mb-5 mt-5">
        Stages in Entrepreneur Support Scheme
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">1. Startup Support</div>
      <div className=" Schemes_heading_description mb-5">
        The Startup support is provided for those enterprises which have availed
        at least term loan from a financial institution and wish to avail a part
        of the total eligible support prior to the commencement of commercial
        production. The assistance is limited to 50% of the total eligible
        support limited to Rs 3 lakh on sanctioning the term loan from the bank.
        The unit can apply for the balance of the eligible support once it start
        the commercial production. A unit which do not avail Startup Support,
        can directly apply for the investment support, after the commencement of
        commercial production.
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">2. Investment Support</div>
      <div className=" Schemes_heading_description mb-5">
        Investment Support is provided after the commencement of commercial
        production. To apply for Investment Support, loan from a financial
        institution is not mandatory. The entrepreneurs shall apply within one
        year from the date commencement of production. Enterprises undertaking
        Expansion, Diversification or Modernization are also eligible for invest
        support for the additional investment made.
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">3. Technology Support</div>
      <div className=" Schemes_heading_description mb-5">
        Technology Support is provided after commencement of production, on
        acquiring new technology from authorized institutions. The unit shall
        apply for technology support within 6 months from the date of
        commencement after installing the new technology form authorized
        agencies. Assistance will be provided for the new technology and for the
        plant and machinery installed in connection with the new technology.
      </div>
      <hr />
      <div className="schemes_heading mb-5 mt-5">Application Fee</div>

      <div className=" Schemes_heading_description mb-5">
        An application fee of Rs 1105/- has to be remitted per unit.
      </div> */}

      {/* <div className="d-flex align-items-center mt-5 mb-5">
        <div className="download_description">
          <h6>For more information</h6>
        </div>
        <div className="schemes_download ml-3">
          <a
            href={ProgDoc}
            targt="_blank"
            className="d-flex align-items-center download-btn"
          >
            <span className="ml-1 ">Download</span>
            <span className="d-flex ">
              <KiedIcons icon="Download-btn" className="ml-1" />
            </span>
          </a>
        </div>
      </div> */}
      <div className="d-flex justify-content-center  m-5">
        {/* <Link> */}
        <a
          href={props.data.link}
          // href="https://industry.kerala.gov.in/index.php/schemes-mainmenu/entrepreneur-support-scheme-schemes"
          target="_blank"
          rel="noreferrer"
        >
          <KiedButton
            content={
              <>
                <div className="d-flex p-1">
                  <div className="d-flex align-items-center">Apply</div>
                  <div className="d-flex align-items-center">
                    <KiedIcons
                      icon="arrow-right"
                      className="hero-button-icon arrow_icon"
                    />
                  </div>
                </div>
              </>
            }
            secondary
            rounded
            big
          />
        </a>
        {/* </Link> */}
      </div>
    </div>
  );
}

export default SchemeDescription;
