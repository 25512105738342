import React from "react";
import MentorsCard from "../../../../components/mentors.card/mentors.card";
import { mentors } from "../programsData";

function ProgramsMentor() {
  return (
    <div className="auto-container pb-5 pt-5">
      <div className="d-flex justify-content-center align-items-center">
        <div className="">
          <h2 className="inovation_headings m-1">Mentors</h2>
        </div>
      </div>
      <hr />
      <div>
        <div className="row">
          {mentors.map((card, i) => {
            return <MentorsCard data={card} key={i} index={i} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default ProgramsMentor;
