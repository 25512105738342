export const districts = [
  {
    id: 1,
    name: "Trivandrum",
  },
  {
    id: 2,
    name: "kollam",
  },
  {
    id: 3,
    name: "Pathanamthitta",
  },
  {
    id: 4,
    name: "Alapuzha",
  },
  {
    id: 5,
    name: "Kottayam",
  },
  {
    id: 6,
    name: "idukki",
  },

  {
    id: 7,
    name: "Ernakulam",
  },

  {
    id: 8,
    name: "Thrissur",
  },
  {
    id: 9,
    name: "Palakkad",
  },
  {
    id: 10,
    name: "Malappuram",
  },

  {
    id: 11,
    name: "Kozhikode",
  },
  {
    id: 12,
    name: "Wayanad",
  },
  {
    id: 13,
    name: "Kannur",
  },
  {
    id: 14,
    name: "Kasaragod",
  },
];
export const industry = [{ id: 1, name: "IT" }];
export const city = [
  { id: 1, name: "Thiruvananthapuram" },
  { id: 2, name: "Kozhikode" },
  { id: 3, name: "Kochi" },
  { id: 4, name: "Kollam" },
  { id: 5, name: "Trissur" },
  { id: 6, name: "Kannur" },
  { id: 7, name: "Adoor" },
  { id: 8, name: "Aluva" },
  { id: 9, name: "Alappuzha" },
  { id: 10, name: "Angamaly" },
  { id: 11, name: "Anthoor" },
  { id: 12, name: "Attingal" },
  { id: 13, name: "Chalakudy" },
  { id: 14, name: "Changanassery" },
  { id: 15, name: "Chavakkad" },
  { id: 16, name: "Chengannur" },
  { id: 17, name: "Cherpulassery" },
  { id: 18, name: "Cherthala" },
  { id: 19, name: "Chittur-Thathamangalam" },
  { id: 20, name: "Eloor" },
  { id: 21, name: "Erattupetta" },
  { id: 22, name: "Ettumanoor" },
  { id: 23, name: "Feroke" },
  { id: 24, name: "Guruvayur" },
  { id: 25, name: "Haripad" },
  { id: 26, name: "Irinjalakuda" },
  { id: 27, name: "Iritty" },
  { id: 28, name: "Kalamassery" },
  { id: 29, name: "Kalpetta" },
  { id: 30, name: "Kanhangad" },
  { id: 31, name: "Karunagappalli" },
  { id: 32, name: "Kasaragod" },
  { id: 33, name: "Kattappana" },
  { id: 34, name: "Kayamkulam" },
  { id: 35, name: "Kodungallur" },
  { id: 36, name: "Koduvally" },
  { id: 37, name: "Kondotty" },
  { id: 38, name: "Koothattukulam" },
  { id: 39, name: "Kothamangalam" },
  { id: 40, name: "Kottakkal" },
  { id: 41, name: "Kottarakara" },
  { id: 42, name: "Kottayam" },
  { id: 43, name: "Koyilandy" },
  { id: 44, name: "Kunnamkulam" },
  { id: 45, name: "Kuthuparamba" },
  { id: 46, name: "Malappuram" },
  { id: 47, name: "Mananthavady" },
  { id: 48, name: "Manjeri" },
  { id: 49, name: "Mannarkkad" },
  { id: 50, name: "Maradu" },
  { id: 51, name: "Mattanur" },
  { id: 52, name: "Mavelikkara" },
  { id: 53, name: "Mukkam" },
  { id: 54, name: "Muvattupuzha" },
  { id: 55, name: "Nedumangad" },
  { id: 56, name: "Neyyattinkara" },
  { id: 57, name: "Nilambur" },
  { id: 58, name: "Nileshwaram" },
  { id: 59, name: "North Paravur" },
  { id: 60, name: "Ottappalam" },
  { id: 61, name: "Pala" },
  { id: 62, name: "Palakkad" },
  { id: 63, name: "Pandalam" },
  { id: 64, name: "Panoor" },
  { id: 65, name: "Parappanangadi" },
  { id: 66, name: "Paravur" },
  { id: 67, name: "Pathanamthitta" },
  { id: 68, name: "Pattambi" },
  { id: 69, name: "Payyanur" },
  { id: 70, name: "Payyoli" },
  { id: 71, name: "Perinthalmanna" },
  { id: 72, name: "Perumbavoor" },
  { id: 73, name: "Piravom" },
  { id: 74, name: "Ponnani" },
  { id: 75, name: "Punalur" },
  { id: 76, name: "Ramanattukara" },
  { id: 77, name: "Shornur" },
  { id: 78, name: "Sreekandapuram" },
  { id: 79, name: "Sultan Bathery" },
  { id: 80, name: "Taliparamba" },
  { id: 81, name: "Tanur" },
  { id: 82, name: "Thalassery" },
  { id: 83, name: "Thodupuzha" },
  { id: 84, name: "Thrikkakkara" },
  { id: 85, name: "Thrippunithura" },
  { id: 86, name: "Tirur" },
  { id: 87, name: "Tirurangadi" },
  { id: 88, name: "Tiruvalla" },
  { id: 89, name: "Vaikom" },
  { id: 90, name: "Valanchery" },
  { id: 91, name: "Varkala" },
  { id: 92, name: "Vatakara" },
  { id: 93, name: "Wadakkancherry" },
];
export const sectors = [{ id: 1, name: "Information technology" }];
export const institutions = [
  {
    id: 1,
    name: "School",
  },
  {
    id: 2,
    name: "Polytechnic",
  },
  {
    id: 3,
    name: "ITI",
  },
  {
    id: 4,
    name: "Professional College",
  },
  {
    id: 5,
    name: "Management College",
  },
  {
    id: 6,
    name: "Arts & Science College",
  },
  {
    id: 7,
    name: "Others",
  },
];
export const radiooptions = [
  {
    label: "YES",
    value: "yes",
  },
  {
    label: "NO",
    value: "no",
  },
  {
    label: "UNDISCLOSED",
    value: "no",
  },
];
export const religion = [
  {
    id: 1,
    name: "Hindu",
  },
  {
    id: 2,
    name: "Muslim",
  },
  {
    id: 3,
    name: "Christian",
  },
  {
    id: 4,
    name: "Others",
  },
];
export const qualification = [
  {
    id: 1,
    name: "Less than 10th",
  },
  {
    id: 2,
    name: "10th",
  },
  {
    id: 3,
    name: "12th",
  },
  {
    id: 4,
    name: "Diploma",
  },
  {
    id: 5,
    name: "UG",
  },
  {
    id: 6,
    name: "PG",
  },
  {
    id: 7,
    name: "PhD",
  },
  {
    id: 8,
    name: "Post Doc",
  },
  // {
  //   id: 9,
  //   name: "Professional/Technical",
  // },
  // {
  //   id: 10,
  //   name: "Management",
  // },
];
export const highestqualification = [
  {
    id: 1,
    name: "Less than 10th",
  },
  {
    id: 2,
    name: "10th",
  },
  {
    id: 3,
    name: "12th",
  },
  {
    id: 4,
    name: "Diploma",
  },
  {
    id: 5,
    name: "UG",
  },
  {
    id: 6,
    name: "PG",
  },
  {
    id: 7,
    name: "PhD",
  },
  {
    id: 8,
    name: "Post Doc",
  },
];
export const expertise = [
  {
    id: 1,
    name: "IPR",
  },
  {
    id: 2,
    name: "Legal and Company Secretary",
  },
  {
    id: 3,
    name: "GEM",
  },
  {
    id: 4,
    name: "E-Commerce",
  },
  {
    id: 5,
    name: "Others",
  },
];
export const investmentLevel = [
  {
    id: 1,
    name: "Below 10 Lakhs",
  },
  {
    id: 2,
    name: "Between 10 Lakhs and 25 Lakhs",
  },
  {
    id: 3,
    name: "25 Lakhs to 50 Lakhs",
  },
  {
    id: 4,
    name: "50 Lakhs to 1Cr",
  },
  {
    id: 5,
    name: "1Cr to 5Cr",
  },
  {
    id: 6,
    name: "5Cr to 10Cr",
  },
  {
    id: 7,
    name: "Above 10Cr",
  },
];
