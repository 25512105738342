import React from "react";
import "./newPassword.page.styles.css";
import SmallBanner from "../../components/small.banner/small.banner";
import NewPasswordForm from "../forgot-password/sections/forgotPasswordForm/passwordForm";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { verifyToken } from "../../apis/auth.apis";
import { useState } from "react";

function NewPasswordPage() {
  let tokenParam = useParams();
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    let token = tokenParam.id;
    verifyToken(token).then((res) => {
      console.log(res);
      setUserId(res.result.id);
    });
  });

  return (
    <div>
      <SmallBanner title="Reset Password" />
      <NewPasswordForm userid={userId} token={tokenParam.id} />
    </div>
  );
}

export default NewPasswordPage;
