import React from "react";
import "./company.card.styles.css";
import KiedButton from "../buttons/button/button.component";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function CompanyCard(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handlePopup = () => {
    setShow(true);
  };
  return (
    <>
      <div className="p-2 col-md-4 col-sm-12">
        <div className="card_container  p-4">
          <div className=" d-flex flex-column justify-content-center align-items-center">
            <div className="mt-2 mb-2 company_card_name">{props.data.name}</div>
            <div className="mt-2 mb-2 company_card_description">
              {props.data.designation}
            </div>
            <div className="mt-2 mb-2">
              <KiedButton
                secondary
                square
                onClick={() => {
                  handlePopup();
                }}
                type="submit"
                content={
                  <div className=" d-flex justify-content-center">
                    View Details
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
      {show === true && (
        <div className="w-100">
          <Modal
            className="internship_popup w-100"
            show={show}
            onHide={handleClose}
          >
            <Modal.Header>
              <Modal.Title className="forgot_title w-100 d-flex justify-content-center">
                Internship Details
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className=" col-md-6 col-sm-12 intern-content">
                <div className="d-flex justify-content-start"></div>
                <div className="companyName" style={{ fontWeight: "bold" }}>
                  EMPLOYER PROFILE
                </div>
                <br />
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Company Name</span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.name}</span>
                </div>
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Role</span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.designation}</span>
                </div>
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Company Website </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.website}</span>
                </div>
                <div className="d-flex my-2 ">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Contact Number </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.contact}</span>
                </div>
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Email Id </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.email}</span>
                </div>
              </div>
              <div className="col-12 my-2 intern-content py-2">
                <div className="d-flex flex-column">
                  <span>About Organization</span>
                  <span style={{ color: "red" }}> {props.data.about}</span>
                </div>
              </div>
              <hr />
              <div
                className="companyName  col-6"
                style={{ fontWeight: "bold" }}
              >
                JOB INFO
              </div>
              <br />
              <div className="col-md-6 col-sm-12 intern-content">
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Internship Mode </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.mode}</span>
                </div>
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Nature of Internship </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.nature}</span>
                </div>
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Location </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.location}</span>
                </div>
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Duration </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.duration}</span>
                </div>
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Additional Perks </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.perks}</span>
                </div>
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Stipend Amount </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.stipend}</span>
                </div>
                <div className="d-flex my-2">
                  <span className="col-6 d-flex justify-content-between p-0">
                    <span>Qualification </span>
                    <span>:</span>
                  </span>
                  <span className="pl-1">{props.data.qualification}</span>
                </div>
              </div>
              <div className="col-12 my-2 intern-content py-2">
                <div className="d-flex flex-column">
                  <span className="">Job Description</span>{" "}
                  <span style={{ color: "red" }}>
                    {" "}
                    {props.data.description}
                  </span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button className="forgot_button_secondary" onClick={handleClose}>
                Go Back
              </Button>
              <Button className="forgot_button_primary" type="submit">
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}

export default CompanyCard;
