import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import InternshipDetailBreadcrumb from "./sections/breadcrumb/internship.detail.breadcrumb";
import InternshipDetailDetail from "./sections/details/internship.detail.detail";
import InternshipDetailDescription from "./sections/description/internship.detil.description";
import InternshipDetailCard from "./sections/cards/internship.detail.card";
// import Breadcrumb from "../../components/breadcrumb/breadcrumb";

function InternshipDetailPage() {
  return (
    <div>
      <SmallBanner />

      {/* <Breadcrumb from="InternshipDetails" /> */}
      <InternshipDetailBreadcrumb />
      <InternshipDetailDetail />
      <InternshipDetailDescription />
      {/* <InternshipDetailCard /> */}
    </div>
  );
}

export default InternshipDetailPage;
