import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import InternshipApplicationBreadcrumbs from "./sections/breadcrumbs/internship.application.breadcrumbs";
import InternshipApplicationDetails from "./sections/details/internship.application.details";

function InternshipApplicationPage() {
  return (
    <div>
      <SmallBanner />
      <InternshipApplicationBreadcrumbs />
      <InternshipApplicationDetails />
    </div>
  );
}

export default InternshipApplicationPage;
