import React from "react";
import "./success.card.styles.css";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import { Link } from "react-router-dom";

function SuccessCard() {
  return (
    <div className="auto-container d-flex justify-content-center success ">
      <div className="success_card ">
        <div className="success_title pt-4 pb-5 d-flex justify-content-center">
          <h4>Registration Completed Successfully</h4>
        </div>
        <div className="  d-flex justify-content-center pb-5">
          <KiedIcons className="success_icon" icon="Success-tick" />
        </div>
        <div className="success_desc d-flex justify-content-center pb-5">
          <h6>
            You'll get an email notification once the application is approved
          </h6>
        </div>
        <div className="d-flex justify-content-center pb-5">
          <Link to="/">
            <KiedButton
              secondary
              rounded
              inverted
              content={
                <div className="d-flex justify-content-center">Done</div>
              }
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SuccessCard;
