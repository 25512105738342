import { useEffect, useState } from "react";

const useScreenBreakPoint = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);
	let display_mode = "xs";

	if (width < 576) {
		display_mode = "xs";
	} else {
		if (width >= 1400) {
			display_mode = "xxl";
		} else {
			if (width >= 1200) {
				display_mode = "xl";
			} else {
				if (width >= 992) {
					display_mode = "lg";
				} else {
					if (width >= 768) {
						display_mode = "md";
					} else {
						display_mode = "sm";
					}
				}
			}
		}
	}

	return display_mode;
};

export default useScreenBreakPoint;
