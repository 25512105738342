import React from "react";

import { faqData } from "./faqData";
import FaqCard from "../../../../components/faq.cards/faq.card";
import MultiCarousal from "../../../../components/carousals/multicarousals/multi.carousal";
import "./faq.section.style.css";

function FaqSection(props) {
  return (
    <div className="greyBackground">
    <div className="common-sections container ">
      <div className="faq-section">
        <div className="sec-title text-center">
          <h2 className="sec-heading">{props.heading}</h2>
        </div>
        <MultiCarousal section="faq">
          {faqData.map((faq, i) => {
            return <FaqCard data={faq} key={i} />;
          })}
        </MultiCarousal>
      </div>
    </div>
    </div>
  );
}

export default FaqSection;
