export const ResourcePrograms = [
  {
    id: 1,
    name: "ILO's Start and Improve Your Business (SIYB) a management skill development Training program",
    status: "COMPLETED",
  },
  {
    id: 2,
    name: "Entrepreneurship Establishment Programme",
    status: "COMPLETED",
  },
  {
    id: 3,
    name: "Workshop on Export Import Business",
    status: "COMPLETED",
  },
  {
    id: 4,
    name: "Business Establishment Programme",
    status: "COMPLETED",
  },
  {
    id: 5,
    name: "Entrepreneurship Development Program for SC Unemployed in Aquaculture and Fisheries",
    status: "COMPLETED",
  },
  {
    id: 6,
    name: "Workshop for Women Entrepreneurs",
    status: "COMPLETED",
  },
  {
    id: 7,
    name: "Export Import Training",
    status: "COMPLETED",
  },
  {
    id: 8,
    name: "Entrepreneurship Workshop in Bakery and Confectionery Products",
    status: "COMPLETED",
  },
  {
    id: 9,
    name: "Export Import Training",
    status: "COMPLETED",
  },
  {
    id: 10,
    name: "Entrepreneurship Development Program for SC Unemployed in Aquaculture and Fisheries",
    status: "COMPLETED",
  },
  {
    id: 11,
    name: "Gem Portal training for Resource Persons – 3 Batches",
    status: "COMPLETED",
  },
  {
    id: 12,
    name: "One day workshop on Entrepreneurship Development Programme",
    status: "COMPLETED",
  },
  {
    id: 13,
    name: "Grooming Industry Facilitators on Enterprise Creation Programme Implementation",
    status: "COMPLETED",
  },
  {
    id: 14,
    name: "ARISE Second Phase Immersion Training",
    status: "COMPLETED",
  },
  {
    id: 15,
    name: "Yuva Boot Camp – 14 Districts",
    status: "COMPLETED",
  },
  {
    id: 16,
    name: "Food Safety Training and Certification (FoSTaC) programme.",
    status: "COMPLETED",
  },
  {
    id: 17,
    name: "Workshop on Design Thinking",
    status: "COMPLETED",
  },
  {
    id: 18,
    name: "Two Days Program for Department Officers",
    status: "COMPLETED",
  },
  {
    id: 19,
    name: "PACE Training for Soft Skill Trainers under DDUGKY Scheme",
    status: "COMPLETED",
  },
  {
    id: 20,
    name: "Two days Program on Cooperative Law",
    status: "COMPLETED",
  },
  {
    id: 21,
    name: "Training for Taluk Resource Persons",
    status: "COMPLETED",
  },
  {
    id: 22,
    name: "ARISE Inspiration Training -Trivandrum",
    status: "COMPLETED",
  },
  {
    id: 23,
    name: "ARISE Inspiration Training – Pathanamthitta",
    status: "COMPLETED",
  },
  {
    id: 24,
    name: "ARISE Inspiration Training - Malappuram",
    status: "COMPLETED",
  },
  {
    id: 25,
    name: "ARISE Inspiration Training – Wayanad",
    status: "COMPLETED",
  },
  {
    id: 26,
    name: "ARISE Inspiration Training – Thrissur",
    status: "COMPLETED",
  },
  {
    id: 27,
    name: "ARISE Inspiration Training – Kannur",
    status: "COMPLETED",
  },
  {
    id: 28,
    name: "ARISE Inspiration Training – Palakkad",
    status: "COMPLETED",
  },
  {
    id: 29,
    name: "ARISE Inspiration Training – Kottayam",
    status: "COMPLETED",
  },
  {
    id: 30,
    name: "ARISE Inspiration Training – Kasaragod",
    status: "COMPLETED",
  },
  {
    id: 31,
    name: "ARISE Inspiration Training – Idukki",
    status: "COMPLETED",
  },
  {
    id: 32,
    name: "ARISE Inspiration Training – Kozhikode",
    status: "COMPLETED",
  },
  {
    id: 33,
    name: "ARISE Inspiration Training  at Ernakulam District",
    status: "COMPLETED",
  },
  {
    id: 34,
    name: "ARISE Inspiration Training  at Kollam District",
    status: "COMPLETED",
  },
  {
    id: 35,
    name: "ARISE Inspiration Training  at Alappuzha District",
    status: "COMPLETED",
  },
  {
    id: 36,
    name: "ARISE - Immersion -  Egg",
    status: "COMPLETED",
  },
  {
    id: 37,
    name: "ARISE-Immersion –Meat",
    status: "COMPLETED",
  },
  {
    id: 38,
    name: "ARISE-Immersion –Mushroom",
    status: "COMPLETED",
  },
  {
    id: 39,
    name: "ARISE - Immersion – Spices",
    status: "COMPLETED",
  },
  {
    id: 40,
    name: "ARISE - Immersion – Tuber Crops",
    status: "COMPLETED",
  },
  {
    id: 41,
    name: "ARISE - Immersion – Coconut ",
    status: "COMPLETED",
  },
  {
    id: 42,
    name: "ARISE-Immersion –Milk",
    status: "COMPLETED",
  },
  {
    id: 43,
    name: "ARISE -Immersion Training-Fish",
    status: "COMPLETED",
  },
  {
    id: 44,
    name: "ARISE-Immersion –Fruits and Vegetables",
    status: "COMPLETED",
  },
  {
    id: 45,
    name: "ARISE Second Phase Immersion Offline Training",
    status: "COMPLETED",
  },
  {
    id: 46,
    name: "ARISE -ToT Training",
    status: "COMPLETED",
  },
  {
    id: 47,
    name: "Women Entrepreneurship Programme",
    status: "COMPLETED",
  },
  {
    id: 48,
    name: "Business Acceleration Programme",
    status: "COMPLETED",
  },
  {
    id: 49,
    name: "Yuva Boot Camp Online Mentoring for State Level District Level Winners",
    status: "COMPLETED",
  },
];
