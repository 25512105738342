export const EDCData = [
  {
    id: 1,
    title: "Financial Assistance to ED Club",
    description:
      "Formulated in schools and colleges to inculcate Entrepreneurial Culture amongst youth and equip them with the skills, techniques and confidence.",
    link: "/edc-schemes",
    type: "DIC Scheme",
  },
  // {
  //   id: 2,
  //   title: "StartUp",
  //   description:
  //     "Lorem ipsum is a placeholder text commonly used to demonstrate.",
  //   link: "/schemes",
  // },
  // {
  //   id: 3,
  //   title: "Agro-Farming",
  //   description:
  //     "Lorem ipsum is a placeholder text commonly used to demonstrate.",
  //   link: "/schemes",
  // },
  // {
  //   id: 4,
  //   title: "Scheme Category Four",
  //   description:
  //     "Lorem ipsum is a placeholder text commonly used to demonstrate.",
  //   link: "/schemes",
  // },
];

export const EDCProgramData = [
  {
    id: 1,
    title: "YUVA BOOT CAMP",

    image: "yuva",
    status: "Completed",
    titleLink: "/department/EDC/program/",
    location: "Kochi",
    startDate: "06-September-2022",
    endDate: "To -08-September-2022",
    // startTime: "10.00 AM",
    participants: "For MSME / Small Business Entrepreneurs",
    description:
      "Yuva is a program to create awareness about entreneurship and start up process among college students and youth. A 2-day boot camp att the district level was conducted working on Business model canvas, was provided on day 1 followed by idea pitching on the day.",
    link: "/department/EDC/program/1",
    mainDescription:
      "Yuva is a program to create awareness about entreneurship and start up process among college students and youth. A 2-day boot camp att the district level was conducted working on Business model canvas, was provided on day 1 followed by idea pitching on the day. 30 teams were selected at the district level and were provided online mentorship sessions/master class for 4 days. This will be followed by a State level Programme.",
    reportHeading: "District Level Competition",
    reportHeading2: "null",
    screenShot: "yuvateam11",
    subHeading1: {
      heading: "",
      description: [
        {
          id: 1,
          point:
            "District level competition has been completed, 263 teams consisting of 1318 students from 71 institutions across kerala had participated in the camp.  ",
        },
        {
          id: 2,
          point: "30 teams were selected for the State level Programme.",
        },
        {
          id: 3,
          point:
            "The Yuva Bootcamp district level competition has been inagurated by Sri. Rajeev P, Honarable Minister of industries, law & Coir, Govt of Kerala.  ",
        },

        {
          id: 5,
          point:
            "Two teams from each district were selected for the competition. ",
        },
      ],
    },
    reportHeading2: "null",
    subHeading2: {
      heading: "Offline Mentoring & Trial Pitch",
      status: "Completed",
      gallery: [
        {
          id: 1,
          image: "yuva-oriented1",
        },
        {
          id: 2,
          image: "yuva-oriented2",
        },
        {
          id: 3,
          image: "yuva-oriented3",
        },
        {
          id: 4,
          image: "yuva-oriented4",
        },
        {
          id: 5,
          image: "yuva-oriented6",
        },
      ],
      description: [
        {
          id: 1,
          point:
            "A mentoring session on pitch deck preparation and presentation was held on 30/07/2022 at Kalamassery Innovation Zone for the teams selected from the Yuva Boot Camp District Level Programme. The session was officially inaugurated by Sri. Suman Billa IAS, Principal Secretary, Industries and Commerce, Government of Kerala. The mentoring was provided by investors, founders and professionals. The teams were provided with mentoring session on financial aspects at morning session and it was handled by Seshadri Nathan, Venture Capitalist, Ahaa Ventures. In the afternoon session teams were provided with mentoring on “How to pitch before investors?” and it was handled by Arravind Warrier, HR Director, EY. After that all the teams did a trial pitch and it was evaluated by Zappyhire Founder Jyothis K.S & Co-founder Deepu Xavier.  ",
        },
      ],

      yuvaTeam: [
        {
          id: 1,
          image: "yuvateam1",
          name: "ARUNACHALAM MURUGANTHAM PADMASREE",
          designation: " CEO founder jaya shree industries",
          topic: " Pad man-insparing startup story and his advice",
        },
        {
          id: 2,
          image: "yuvateam2",
          name: "VIKAS BAGARIA",
          designation: "Founder peesafe",
          topic: " Sales",
        },
        {
          id: 3,
          image: "yuvateam3",
          name: "HRITHEKESH ",
          designation: "Founder vakil research",
          topic: " Legal & tax",
        },
        {
          id: 4,
          image: "yuvateam4",
          name: "ZAINAB KHAMIS",
          designation: "Seniorv stratergy and programs manager",
          topic: "How to create a simple business plan",
        },
        {
          id: 7,
          image: "yuvateam6",
          name: "SHOBHANA PRAKASH",
          designation: "Analyst and investment proffessional ",
          topic: " Basics of fundrasing",
        },
        {
          id: 6,
          image: "yuvateam7",
          name: "MURALI KRISHNAN",
          designation: "Cheif branding officer wow momos",
          topic: "Marketing & branding techniques",
        },
        {
          id: 5,
          image: "yuvateam5",
          name: "DRUV NATH",
          designation: "Director lead angles",
          topic: "What investor looks before investing",
        },
        {
          id: 8,
          image: "yuvateam8",
          name: "FARISH",
          designation: "Program manager google for startup",
          topic: "Corporate connect google startup accelarator",
        },
        {
          id: 9,
          image: "yuvateam9",
          name: "SURESH",
          designation: "Founder ceo, pepul",
          topic: "Art of pitching",
        },
        {
          id: 10,
          image: "yuvateam10",
          name: "GENROBOTICS",
          designation: "Gen robotics founding team",
          topic: "Pad man-insparing startup story and his advice",
        },
      ],
    },
    reportHeading3: "null",
    subHeading3: {
      programDetails: "Program Details",
      heading: "Yuva Bootcamp State Level Conclave",
      status: "Completed",
      gallery: [
        {
          id: 1,
          image: "yuva-camp1",
        },
        {
          id: 2,
          image: "yuva-camp2",
        },
        {
          id: 3,
          image: "yuva-camp3",
        },
        {
          id: 4,
          image: "yuva-camp4",
        },
        {
          id: 5,
          image: "yuva-oriented5",
        },

        // {
        //   id: 5,
        //   image: "yuva-oriented5",
        // },
        // {
        //   id: 6,
        //   image: "yuva-oriented6",
        // },
      ],
      description: [
        {
          id: 1,
          point:
            "The inauguration ceremony of the Yuva Boot Camp State Level Conclave was held at the Mascot Hotel, Thiruvananthapuram on 14th & 15th September 2022. Conference was held with a core focus on creating awareness about the importance of entrepreneurship and encouraging the youth to start their own start-ups at the end of their college study or while continuing their study. The conference aimed at sharing experiences to inspire student entrepreneurs. Also the conference served as a platform for student entrepreneurs from various campuses/ ED Clubs to exhibit their products, services and prototypes.  ",
        },
      ],
      listing: [
        {
          id: 1,
          point:
            " The two-day programme comprised of 4 Panel discussions on various topics in the theme of “Students and Youth Entrepreneurship as a Driver of Economic Growth”, one story sharing session where the speakers shared their Entrepreneurial Journey, Business Pitching Contest for 30 student teams selected from district level and Product Expo by Student Entrepreneurs / ED Clubs across Kerala. ",
        },
      ],
    },
    gallery: [
      {
        id: 1,
        image: "edc-yuva1",
      },
      {
        id: 2,
        image: "edc-yuva2",
      },
      {
        id: 3,
        image: "edc-yuva3",
      },
      {
        id: 4,
        image: "edc-yuva4",
      },
      {
        id: 5,
        image: "edc-yuva5",
      },
      {
        id: 6,
        image: "edc-yuva6",
      },
      {
        id: 7,
        image: "edc-yuva7",
      },
      {
        id: 8,
        image: "edc-yuva8",
      },
      {
        id: 9,
        image: "yuva-oriented1",
      },
      {
        id: 10,
        image: "pannel1",
      },
      {
        id: 11,
        image: "pannel2",
      },
      {
        id: 12,
        image: "pannel3",
      },
      {
        id: 13,
        image: "pannel4",
      },
      {
        id: 14,
        image: "pannel5",
      },
    ],
  },
  {
    id: 2,
    title: "ONLINE ENTREPRENEURSHIP PROGRAM",
    image: "edcp",
    status: "Completed",

    titleLink: null,
    location: "Kochi",
    startDate: "06-September-2022",
    endDate: "To -08-September-2022",
    // startTime: "10.00 AM",
    participants: "",
    description:
      "Completed the  Kerala Institute for Entrepreneurship Development (KIED) where a 24 hours program was delivered to students in 24 schools across Kerala.",
    link: "/department/EDC/program/2",
    mainDescription:
      "The Udhyam Learning Foundation Kerala team completed the Online Entrepreneurship Program with Kerala Institute for Entrepreneurship Development (KIED) where a 24 hours program was delivered to students in 24 schools across Kerala.This program was exclusively designed for students who were part of the Entrepreneurship Development (ED) Clubs in schools. Active participation and testimonies from students and teachers suggested a positive impact on their mindsets and an enhancement in their entrepreneurial skills. Several students among them initiated their entrepreneurial journey on a small scale.",
    reportHeading: "Program Details",
    subHeading1: {
      description: [
        {
          id: 1,
          point:
            "Duration: 12 hours training program is conducted from January to March 2022  ",
        },
        {
          id: 2,
          point:
            "28 Schools takes initiative, 24 School has completed, 698 Students started and 326 Students  graduated.",
        },
        {
          id: 3,
          point: "1 live Enterpreneueurship Interations 120  Business ",
        },
      ],
    },
    gallery: [
      {
        id: 1,
        image: "oepimg1",
      },
      {
        id: 2,
        image: "oepimg2",
      },
      {
        id: 3,
        image: "oepimg3",
      },
      {
        id: 4,
        image: "oepimg4",
      },
      {
        id: 5,
        image: "oepimg5",
      },
      {
        id: 6,
        image: "oepimg6",
      },
      {
        id: 7,
        image: "oepimg7",
      },
      {
        id: 8,
        image: "oepimg8",
      },
      {
        id: 9,
        image: "oepimg9",
      },
    ],
  },
];

export const EDCEventData = [
  {
    id: 1,
    title: "Yuva Bootcamp State Level Conclave",
    location: "Mascot Hotel, Thiruvananthapuram ",
    startDate: "14/09/2022",
    endDate: "Sept-15-2022",
    status: "completed",
    // startTime: "10.00 AM",
    NoOfParticipants: "65",
    link: "/edcEvents/",
    duration: "2 days",
    CategoryOfParticipants:
      "Encouraging the youth to start their own start-ups at the end of their college study or while continuing their study. ",
    subTitle1: "Program Format",
    subTitle2: "Panel Discussion",
    programFormat:
      "The two-day programme comprised of 4 Panel discussions on various topics in the theme of “Students and Youth Entrepreneurship as a Driver of Economic Growth”, one story sharing  session where the speakers shared their Entrepreneurial Journey, Business Pitching Contest for 30 student teams selected from district level and Product Expo by Student Entrepreneurs / ED Clubs across Kerala",
    programDiscussion:
      "The event included a fine line up of around 30 speakers in the panel discussion on various topics in the theme of “Students and Youth Entrepreneurship as a Driver of Economic Growth”. The details of the topics for discussion are: 1.“Industry academy collaboration as power house of Innovation”, 2.“Embracing entrepreneurship mindset in students and youth”, 3. “MSME entrepreneurship in era gig economy and industry 4.0”,” 4. Role of media in fostering entrepreneurship.",
    mainDescription:
      "The inauguration ceremony of the Yuva Boot Camp State Level Conclave was held at the Mascot Hotel, Thiruvananthapuram on 14th & 15th September 2022. Conference was held with a core focus on creating awareness about the importance of entrepreneurship and encouraging the youth to start their own start-ups at the end of their college study or while continuing their study. The conference aimed at sharing experiences to inspire student entrepreneurs. Also the conference served as a platform for student entrepreneurs from  various campuses/ ED Clubs to exhibit their products, services and prototypes.",
    images: [
      {
        id: 1,
        image: "edcevent.1.1",
      },
      {
        id: 2,
        image: "edcevent.1.2",
      },
      {
        id: 3,
        image: "edcevent.1.3",
      },
      {
        id: 4,
        image: "edcevent.1.4",
      },
      {
        id: 5,
        image: "edcevent.1.5",
      },
      {
        id: 6,
        image: "edcevent.1.6",
      },
      {
        id: 7,
        image: "edcevent.1.7",
      },
      {
        id: 8,
        image: "edcevent.1.8",
      },
      {
        id: 9,
        image: "edcevent.1.9",
      },
    ],
  },

  {
    id: 2,
    title: "State Level Mentoring & Trial Pitch",
    location: "Kalamassery",
    startDate: " 30/07/2022",
    status: "completed",
    // startTime: "10.00 AM",
    link: "/edcEvents/",
    mainDescription:
      "A mentoring session on pitch deck preparation and presentation was held on 30/07/2022 at Kalamassery Innovation Zone for the teams selected from the Yuva Boot Camp District Level Programme. The session was officially inaugurated by Sri. Suman Billa IAS, Principal Secretary,Industries and Commerce, Government of Kerala. The mentoring was provided by investors, founders and professionals.",
    duration: "1 days",
    images: [
      {
        id: 1,
        image: "edcevent.2.1",
      },
      {
        id: 2,
        image: "edcevent.2.2",
      },
      {
        id: 3,
        image: "edcevent.2.3",
      },
      {
        id: 4,
        image: "edcevent.2.4",
      },
    ],
  },
  {
    id: 3,
    title: "Online Certificate Program",
    location: "Online",
    startDate: "15/04/2021",
    status: "completed",
    // startTime: "10.00 AM",
    link: "/edcEvents/",
    mainDescription:
      "KIED join hands with the Entrepreneurship Development Institute of India (EDII) to address the requirement of entrepreneurs through training and capacity building. The program is designed to expose entrepreneurs to the comprehensive entrepreneurship  development and start-up process, equip them with the requisite skills, knowledge and  competencies for effective initiation of enterprise creation, familiarize the support (financial and non-financial) available for promoting entrepreneurial activities. The program was started on April 15 2021.",
    duration: "5 days",
    CategoryOfParticipants:
      " Entrepreneurial aspirants who are in the initial  stages of start-ups/business",
    NoOfParticipants: "40",
    images: [
      {
        id: 1,
        image: "edcevent.3.1",
      },
      {
        id: 2,
        image: "edcevent.3.2",
      },
      {
        id: 3,
        image: "edcevent.3.3",
      },
      {
        id: 4,
        image: "edcevent.3.4",
      },
    ],
  },
  {
    id: 4,
    title: " Development Program for Students",
    location: "Online",
    startDate: "15/04/2021",
    status: "completed",
    // startTime: "10.00 AM",
    link: "/edcEvents/",
    mainDescription:
      "To sensitize the students about the dynamics of the business environment and  mentor them to experience the process of applying the professional knowledge and skills in identifying attractive business ideas, converting them into sustainable business models and managing them effectively and efficiently.",
    duration: "10 Days",
    CategoryOfParticipants:
      "  Students of Kerala Agricultural University, Thrissur",
    NoOfParticipants: " 41",
    images: [
      {
        id: 1,
        image: "edcevent.4.1",
      },
      {
        id: 2,
        image: "edcevent.4.2",
      },
      {
        id: 3,
        image: "edcevent.4.3",
      },
      {
        id: 4,
        image: "edcevent.4.1",
      },
    ],
  },
  {
    id: 5,
    title: "Online Enterpreneurship Program",
    location: "Online",
    startDate: "10/02/2022",
    status: "completed",
    // startTime: "10.00 AM",
    link: "/edcEvents/",
    mainDescription:
      "Seven days Workshop for Aspiring Entrepreneurs who wish to start a new business was conducted  in online mode using ZOOM platform from 23/03/2022. Objective of the programme is to  formulate business ideas through the Human Centered Approach.",
    duration: "12 days",
    CategoryOfParticipants:
      "ED Club members from High School & Higher Secondary",
    NoOfParticipants: "812",
    programFormat: "",
    programDiscussion: "",
    images: [
      {
        id: 1,
        image: "edcevent.5.1",
      },
      {
        id: 2,
        image: "edcevent.5.2",
      },
      {
        id: 3,
        image: "edcevent.5.3",
      },
      // {
      //   id: 4,
      //   image: "edcevent.5.4",
      // },
      // {
      //   id: 5,
      //   image: "edcevent.5.5",
      // },
      {
        id: 6,
        image: "edcevent.5.5",
      },
    ],
  },
  {
    id: 6,
    title: "Enterpreneurship Mindset Program",
    location: "Online",
    startDate: "09/02/2022",
    status: "completed",
    // startTime: "10.00 AM",
    link: "/edcEvents/",
    duration: "1 days",
    mainDescription:
      " Entrepreneurship mindset and skill set will be the most wanted requirement in.  the coming years both for job market and real market. In collaboration with Udyam foundation, KIED envisaged a program for developing entrepreneurial mind set and skills for our ED Club Coordinators. Online Training of trainers was inaugurated by Director of Industries and Commerce -Kerala Sri. Rajamanickam IAS on 9th February 2021.",
    images: [
      {
        id: 1,
        image: "edcevent.6.1",
      },
      {
        id: 2,
        image: "edcevent.6.2",
      },
      {
        id: 3,
        image: "edcevent.6.3",
      },
    ],
  },
  // {
  //   id: 7,
  //   title: "EVENT NAME",
  //   location: "Kochi", 
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
  // {
  //   id: 8,
  //   title: "EVENT NAME",
  //   location: "Kochi",
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
  // {
  //   id: 9,
  //   title: "EVENT NAME",
  //   location: "Kochi",
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
];
