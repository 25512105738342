import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import InfrastructureBreadcrumb from "./breadcrumb/InfrastructureBreadcrumb";
import InfrastructureHero from "./hero/InfrastructureHero";
import InfrastructureDescription from "./description/InfrastructureDescription";
import Infrastructuresupportingscheme from "./supportingscheme/Infrastructuresupportingscheme";
function MarketInfrastructure() {
  return (
    <div>
      <SmallBanner title={"Local Market Infrastructure for MSMEs"} />
      <InfrastructureBreadcrumb />
      <InfrastructureHero />
      <Infrastructuresupportingscheme />
      <InfrastructureDescription />
    </div>
  );
}

export default MarketInfrastructure;
