import React from "react";

function IndAccProgramBreadcrumb() {
  return (
    <div>
      <div className="faq_banner head-container d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/home"> IAC&nbsp;/</a>
        <a className="current" href="/department/academy-collaboration/program">
          Program Details
        </a>
      </div>
    </div>
  );
}

export default IndAccProgramBreadcrumb;
