import React from "react";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
function SmallBanner({ title }) {
  let width = useScreenBreakPoint();
  return (
    <div>
      <div
        className={`banner_image  ${
          width === "xs" || width === "sm" ? "sml mt-5 " : "lr mb-0 "
        }`}
      >
        <div
          className={`faq_banner d-flex justify-content-center banner_title ${
            width === "xs" || width === "sm"
              ? "sml mt-5 pt-4  text-center "
              : "lr "
          }`}
        >
          <h3 style={{ color: "#fff" }}>{title}</h3>
        </div>
      </div>
    </div>
  );
}

export default SmallBanner;
