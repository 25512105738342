import React from "react";
import "./EdcRegistration.page.styles.css";
import TextField from "../../../components/textfield/TextField.component";
import RadioButton from "../../../components/radiobutton/radiobutton";
import { radiooptions } from "../registrationform.data";
import { districts } from "../registrationform.data";
import { institutions } from "../registrationform.data";
import FileUpload from "../../../components/file.upload/fileupload.component";
import SelectBox from "../../../components/selectbox/SelectBox.component";
import KiedButton from "../../../components/buttons/button/button.component";
import { Link } from "react-router-dom";

function EdcRegistrationPage() {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between auto-container">
        <div className="faq_banner  d-flex">
          <a href="/">Home&nbsp;/</a>
          <a href="/department/EDC/home"> EDC&nbsp;/</a>
          <a className="current" href="department/inovation/edcregistration">
            Edc registration
          </a>
        </div>
        <div className=" d-flex justify-content-end mt-3">
          <p className="">
            <span>
              <span style={{ color: "red" }}>* </span> Mandatory Fields
            </span>
          </p>
        </div>
      </div>
      <div className="auto-container form mt-5">
        <form>
          <RadioButton
            options={radiooptions}
            label="Do you have a Registered ED Club"
            name="YES"
            required
          />
          <br />
          <SelectBox
            label="Select your Institution"
            required
            options={institutions}
            selectionMessage="Select an Institution"
          />
          <br />
          <SelectBox
            label="Select your District where your School/ College/ Institution located"
            required
            options={districts}
            selectionMessage="Select a district"
          />
          <br />
          <TextField label="Name of school/ college/ Institution" required />
          <br />
          <TextField
            label="Address of the school/ college/ Institution"
            required
          />
          <br />
          <TextField label="Name of ED Club Coordinator" required />
          <br />
          <TextField
            label="Whatsapp No. of Coordinator"
            type="number"
            required
          />
          <br />
          <TextField
            label="Email ID of the Coordinator"
            type="email"
            required
          />
          <br />
          <TextField
            label="Total Number of Students in your ED Club"
            type="number"
            required
          />
          <br />
          <TextField
            label="Details of ED Club Activities"
            type="textarea"
            required
          />
          <br />
          <FileUpload label="Last Financial year GST Filing " required />
          <br />
          <RadioButton
            options={radiooptions}
            label="Has your team developed any innovation ideas? "
            name="YES"
            required
          />
          <br />
          <TextField label="Give details " type="textarea" required />
          <br />
          <div className="d-flex justify-content-center pt-5 pb-5">
            <Link to="/success">
              <KiedButton
                primary
                rounded
                content={
                  <div className="registerbtn d-flex justify-content-center">
                    Submit
                  </div>
                }
              />
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EdcRegistrationPage;
