import React from "react";

import { footercontentsub } from "../footer.config";

function FooterDesktopSubComponent() {
  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="copyright">{footercontentsub.label}</div>
        <div className="social-nav">
          {footercontentsub.data.map((footersub, n) => (
            <a href={footersub.link} target="_blank" key={n}>
              <span className={`fa fa-${footersub.icon}`}></span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FooterDesktopSubComponent;
