import React from "react";
import { footerMcontent } from "../footer.config";
import { useLangStore } from "../../../utils/zustand";

function FooterMobileComponent() {
  const {language} = useLangStore()
  return (
    <div className="auto-container ">
      <div className="pt-5">
        <div className="d-flex flex-wrap w-100 justify-content-between">
          {footerMcontent.map((footersection, i) => {
            return (
              <React.Fragment key={i}>
                {footersection.type === "link" && (
                  <div className="footer-column w-50">
                    <div className="footer-widget links-widget">
                      <ul
                        className={`footer-list ${
                          i === 0
                            ? "d-flex flex-column align-items-start "
                            : "d-flex flex-column align-items-start "
                        }`}
                      >
                        {footersection.data.map((footerlinks, n) => {
                          return (
                            <li key={n} className="p-2">
                              <a href="/">
                                {/* {footerlinks.label} */}
                              {language !== "ma" ? footerlinks[language] ?? footerlinks.label  : footerlinks.label}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
                {footersection.type === "address" && (
                  <div className="footer-column w-100 ">
                    <div className="footer-widget links-widget">
                      <ul className="footer-list d-flex flex-wrap align-items-center">
                        <li className="p-1">
                          <a href="/">{footersection.data.line1}</a>
                        </li>{" "}
                        <li className="p-1">
                          <a href="/">{footersection.data.line2}</a>
                        </li>{" "}
                        <li className="p-1">
                          <a href="/">{footersection.data.phone}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>{" "}
      </div>
    </div>
  );
}

export default FooterMobileComponent;
