import React, { useRef, useState, useMemo, useEffect } from "react";
import EventsListingCard from "../../../../components/eventsListingCard/events.listing.card";
import Pagination from "../../../../components/pagination/pagination";

const PageSize = 10;

function EventsListingDetails({ data }) {
  const containerRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  const mainData = useMemo(() => {
    const startIndex = (currentPage - 1) * PageSize;
    const endIndex = startIndex + PageSize;
    return data.slice(startIndex, endIndex);
  }, [data, currentPage]);

  useEffect(() => {
    // Check if the ref is assigned before calling scrollIntoView
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="auto-container" ref={containerRef}>
      <div className="">
        {mainData.map((card, i) => (
          <EventsListingCard key={i} data={card} index={i} type={"SchemeList"} />
        ))}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default EventsListingDetails;
