export const WebinarData = [
  {
    id: 1,
    name: "Webinar on Intellectual property rights",
    status: "COMPLETED",
  },
  {
    id: 2,
    name: "Webinar on E commerce Opportunities – 2 Batches",
    status: "COMPLETED",
  },
  {
    id: 3,
    name: "Webinar on Packing Materials & Packaging Techniques",
    status: "COMPLETED",
  },
  {
    id: 4,
    name: "Webinar on Branding",
    status: "COMPLETED",
  },
  {
    id: 5,
    name: "Webinar on Lead Free Stabilisers for UPVC Pipes and Fittings",
    status: "COMPLETED",
  },
  {
    id: 6,
    name: "Webinar on Legalities of a business -Every entrepreneur should know",
    status: "COMPLETED",
  },
];
