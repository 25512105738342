import React from "react";
// import SmallBanner from "../../components/small.banner/small.banner";
import TextField from "../../components/textfield/TextField.component";
import RadioButton from "../../components/radiobutton/radiobutton";
import SelectBox from "../../components/selectbox/SelectBox.component";
import KiedButton from "../../components/buttons/button/button.component";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FileUpload from "../../components/file.upload/fileupload.component";
import { registeredclub } from "../../actions/api.functions";
import "./edcform.css";

import {
  KiedCarriers,
  gender,
  castCategory,
  radiooptions,
} from "../edc.registration/registrationform.data";
import {
  districts,
  qualification,
} from "../registration/registrationform.data";
import { developmentStage, marketResearch } from "./store";

function EDCThirdForm(props) {
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);
  //   const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();

  const handleTextFieldChange = (value, name) => {
    console.log(value, name);
    setFormdata({ ...formdata, [name]: value });
  };

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
    console.log(e.target.value, typeof e.target.value);
  };

  const handleregister = () => {
    // navigate("/success");
    props.setActiveStep(4);
  };

  const fileUpload = (e) => {
    console.log(formdata);
    console.log(e.target.files);
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    // accept = ".pdf, .png, .jpeg";
    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);

    if (regVal.test(filename)) {
      setFormdata({
        ...formdata,
        [name]: value,
        [name + "_name"]: filename,
      });
    } else {
      setFormdata({
        ...formdata,
        [name]: undefined,
        [name + "_name"]: undefined,
      });
      setError({ [name]: true });
    }
  };

  return (
    <>
      <div className="auto-container form">
        <form>
          <TextField
            label="Describe the problem being solved your startup"
            required
            type="textarea"
            name="solved_problem"
            value={formdata.solved_problem ?? ""}
            onChange={(val, name) => handleTextFieldChange(val, name)}
          />
          <br />
          <TextField
            label="Approach of the startup in solving the problem"
            required
            type="textarea"
            name="solving_approach"
            value={formdata.solving_approach ?? ""}
            onChange={(val, name) => handleTextFieldChange(val, name)}
          />
          <br />
          <SelectBox
            label="Have you carried out any market research?"
            required
            options={marketResearch}
            value={formdata.market_research}
            selectionMessage="Select one"
            name="market_research"
            handleChange={handleChange}
          />
          <br />
          <RadioButton
            options={radiooptions}
            label="Is the technology/ product protected by IP (patent, trademark, design, copyright etc.)?"
            required
            name="product_protection"
            checked={formdata.product_protection ?? ""}
            value={formdata.product_protection ?? ""}
            onChange={handleChange}
          />
          <br />
          {formdata?.product_protection === "Yes" && (
            <>
              <TextField
                label="Provide details of the above"
                type="textarea"
                name="protection_details"
                value={formdata.protection_details ?? ""}
                onChange={handleTextFieldChange}
              />

              <br />
            </>
          )}
          <RadioButton
            options={radiooptions}
            label="Does the start-ups business have licenses or agreements in place with any other body?"
            required
            name="agreements"
            checked={formdata.agreements ?? ""}
            value={formdata.agreements ?? ""}
            onChange={handleChange}
          />
          <br />
          {formdata?.agreements === "Yes" && (
            <>
              <TextField
                label="Provide details of the above"
                type="textarea"
                name="agreement_details"
                value={formdata.agreement_details ?? ""}
                onChange={handleTextFieldChange}
              />

              <br />
            </>
          )}
          <TextField
            label="Briefly explain your business model"
            required
            type="textarea"
            name="business_model"
            value={formdata.business_model ?? ""}
            onChange={(val, name) => handleTextFieldChange(val, name)}
          />
          <br />
          <TextField
            label="How long have you/your team been working on the concept?"
            required
            type="textarea"
            name="concep_timeline"
            value={formdata.concep_timeline ?? ""}
            onChange={(val, name) => handleTextFieldChange(val, name)}
          />
          <br />
          <RadioButton
            options={radiooptions}
            label="Do you have any current clients?"
            required
            name="clients"
            checked={formdata.clients ?? ""}
            value={formdata.clients ?? ""}
            onChange={handleChange}
          />
          <br />
          {formdata?.clients === "Yes" && (
            <>
              <TextField
                label="Provide details of the above"
                type="textarea"
                name="current_clients"
                value={formdata.current_clients ?? ""}
                onChange={handleTextFieldChange}
              />

              <br />
            </>
          )}
          <TextField
            label="Awards & Recognition received till date"
            required
            type="textarea"
            name="awards"
            value={formdata.awards ?? ""}
            onChange={(val, name) => handleTextFieldChange(val, name)}
          />
          <br />
          <FileUpload
            label="Self Declaration (.pdf format files are allowed)"
            required
            accept=".pdf"
            name="declaration"
            type="file"
            // filename={formdata.gst_file_name}
            // value={formdata.gst_file ?? null}
            onChange={fileUpload}
          />
          <br />
        </form>
        <div className="d-flex justify-content-center pt-5 pb-5">
          <KiedButton
            onClick={() => handleregister()}
            primary
            rounded
            type="submit"
            content={
              <div className="registerbtn d-flex justify-content-center">
                Submit
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}

export default EDCThirdForm;
