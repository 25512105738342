export const testimonialData = [
  {
    id: 1,
    title: "Georgin Joseph",
    description:
      "Attended EDP Training at KIED. Amazing training curriculum with visionary trainers. It helped me to plan and validate each and every area of the business. It gave me a total insight starting from design thinking to scale up the business to the next level. The trainers and officials are so helpful, that they are ready to offer support at any given point in our business and regular follow-up about our progress. Thanks to KIED for your wonderful support.",
    date: "21-sep-2022",
    readTime: 10,
    designation: "Founder CEO OAR Agri Tech Pvt Ltd",
    image: "testi1",
    location: "Ernakulam",
  },
  {
    id: 2,
    title: "Ravi Raj. N A",
    description:
      "The Entrepreneurial Development Program offered by KIED Kalamassery Cochin   was an exceptional value and far exceeded skilling program I ever attended.The 10 days classes (19 April - 29 April 2022)were extremely balanced in its offerings and has a practical  business model , that I wish other educational programs would adopt in future. The fusion of practical  training content  , business professional presentations, group exercises and KIED officials  support during the program provided us a unique experience. Its a highly recommend and a  must attended skilling program,  for all those who wish to pursue their entrepreneurial journey.",
    date: "29-April-2022",
    readTime: 10,
    designation: "Business Strategy & Entrepreneurship- Bhava Ideations",
    image: "ravi",
    location: "Thrikkakkara, Ernakulam",
  },
  {
    id: 3,
    title: "Chinchu krishnaraj",
    description:
      "I joined KIED 10 days WEP program and must say it was a roller-coaster experience. From thinking for business To how to manage a successful business all got covered under this. Thank you KIED faculties for giving such a wonderful opportunity . I will strongly suggest young aspirants to connect with Kerala Institute for Entrepreneurship Development dept as they are conducting numerous programs for growth of entrepreneurs.",
    date: "29-April-2022",
    readTime: 10,
    designation: "Proprietor - Ayush Jyothi",
    image: "chinchu",
    location: "Kulayattikkara, Ernakulam",
  },
  {
    id: 4,
    title: "Dr. Jain Joseph",
    description:
      "The efforts taken by KIED and EDC is highly appreciated. The Very much needed makeover of SME sector to cope up with the new digital era is facilitated through the training programs. I have attended the EDP which helped me with a changed outlook in my business.",
    date: "20-Oct-2022",
    readTime: 10,
    designation: "MD & CEO, APPSCOOK Technologies Pvt Ltd. ",
    image: "jain",
    location: "Poothole, Thrissur",
  },
  {
    id: 5,
    title: "Mr. Ashraf.K M",
    description:
      "I am very happy to be able to participate in the training program conducted by KIED. Like other training institutions, KIED has a big role in winning the state award in 2021, ",
    date: "22-Nov-2022",
    readTime: 10,
    designation: "Kerala State Award Winner (2021 Post Harvest)",
    image: "ashraf",
    // location: "Poothole, Thrissur",
  },
  {
    id: 6,
    title: "Mr. Anil Balan",
    description:
      "EDC-KIED has been in the forefront to accelerate the entrepreneurship ecosystem by conducting many workshops and programs for startups, entrepreneurs, women entrepreneurs and youth from ideation to scale across the state. EDC has been instrumental in driving and making a change in the mindset of the people of Kerala to take up entrepreneurship thereby creating jobs and strengthening the financial condition of the society at large. Wishing all the success to KIED, to drive, transform and achieve this objective.",
    date: "20-Oct-2022",
    readTime: 10,
    designation: "Co-Founder- lookODU & President - KSN Globa",
    image: "anil",
    location: "Kozhikode",
  },
  // {
  // 	id: 2,
  // 	title: "2 How to become a best ",
  // 	description:
  // 		"Efficiently unleash cross-media tion without cross-media value. Quickly maximize timely deliverables schemas.",
  // 	date: "21-sep-2002",
  // 	readTime: 10,
  // },
  // {
  //   id: 3,
  //   title: "3 How to become a best ",
  //   description:
  //     "Efficiently unleash cross-media tion without cross-media value. Quickly maximize timely deliverables schemas.",
  //   date: "21-sep-2002",
  //   readTime: 10,
  // },
  // {
  //   id: 4,
  //   title: "4 How to become a best ",
  //   description:
  //     "Efficiently unleash cross-media tion without cross-media value. Quickly maximize timely deliverables schemas.",
  //   date: "21-sep-2002",
  //   readTime: 10,
  // },
  // {
  // 	id: 5,
  // 	title: "5 How to become a best ",
  // 	description:
  // 		"Efficiently unleash cross-media tion without cross-media value. Quickly maximize timely deliverables schemas.",
  // 	date: "21-sep-2002",
  // 	readTime: 10,
  // },
  // {
  // 	id: 6,
  // 	title: "6 How to become a best ",
  // 	description:
  // 		"Efficiently unleash cross-media tion without cross-media value. Quickly maximize timely deliverables schemas.",
  // 	date: "21-sep-2002",
  // 	readTime: 10,
  // },
  // {
  // 	id: 7,
  // 	title: "7 How to become a best ",
  // 	description:
  // 		"Efficiently unleash cross-media tion without cross-media value. Quickly maximize timely deliverables schemas.",
  // 	date: "21-sep-2002",
  // 	readTime: 10,
  // },
];
