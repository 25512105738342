import React from "react";
import "./faq.card.style.css";

function FaqCard(props) {
  return (
    <div className="home_faq_outer">
      <div className="home_faq_inner">
        <div className="home_faq_questions">
          <h5 className="faq_question">{props.data.question}</h5>
        </div>
        <div className="faq_answer">{props.data.answer}</div>
      </div>
    </div>
  );
}

export default FaqCard;
