import React from "react";
import "./testimonial.card.style.css";
import KiedImages from "../../assets/images/kied.images.component";
function TestimonialCard(props) {
  console.log(props.data);
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="h-100 d-flex align-items-start justify-content-center ">
            <div
              className="testiImage"
              style={{ width: "250px", height: "250px", borderRadius: "50%" }}
            >
              <KiedImages
                image={props.data?.image}
                className="w-100 h-100 "
                style={{ borderRadius: "50%" }}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="content inner-column">
            <h2 className="title">{props.data.title}</h2>
            <div className="designation">{props.data.designation}</div>
            <div className="designation" style={{ color: "#d95247" }}>
              {props.data.location}
            </div>
            <div className="quote-icon flaticon-quote-2"></div>
            <div className="text">{props.data.description}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestimonialCard;
