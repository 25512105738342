import React, { useRef } from "react";
import JoditEditor from "jodit-react";

const RichTextEditor = ({
  label = "",
  required = false,
  initialContent = "",
  onEditorContentChange = () => {},
}) => {
  const editor = useRef(null);

  return (
    <>
      <label className="formstyle">
        <span>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </span>
      </label>
      <label className="formstyle">
        <JoditEditor
          ref={editor}
          value={initialContent}
          tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => onEditorContentChange(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent) => onEditorContentChange(newContent)}
        />
      </label>
    </>
  );
};
export default RichTextEditor;
