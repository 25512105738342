import React from "react";

function FileUploadDash(props) {
  return (
    <>
      <div className="d-flex align-items-start ">
        {props.label && (
          <label className="d-flex justify-content-between align-items-start col-5 p-0 ">
            <span
              className={`formstyle p-0 ${
                props.error ? "text-danger" : ""
              } `}
            >
              {props.label}{" "}
              {/* {props.required && <span style={{ color: "red" }}>*</span>} */}
            </span>
            <span className="formstyle pr-1">:</span>
          </label>
        )}
        <label className={`formstyle  pr-2 ${props.labelClass === "full" ? "col-12" : "col-7"}`}>
          <input
            {...props}
            className={`${props.required ? "required " : ""} inputbox 
            }`}
            type={"file"}
            id="upload_plan"
            value={props.value ?? undefined}
            // fileName={props.filename ?? ""}
          />
          {props.errorSize && (
            <span className="" style={{ color: "red", fontSize: "12px" }}>
              {props.errorSize}
            </span>
          )}
        </label>
      </div>
    </>
  );
}

export default FileUploadDash;
