import React from "react";
import {
  FaChevronDown,
  FaExclamationCircle,
  FaCheckCircle,
} from "react-icons/fa";
import { MdArrowRightAlt } from "react-icons/md";
import { BiMenu, BiChevronDown } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { MdOutlineStars } from "react-icons/md";


function KiedIcons(props) {
  const iconGenerator = () => {
    switch (props.icon) {
      case "arrow-down":
        return <FaChevronDown className={props.className ?? ""} />;
      case "arrow-right":
        return <MdArrowRightAlt className={props.className ?? ""} />;
      case "menu-header":
        return <BiMenu className={props.className ?? ""} />;
      case "menu-down-arrow":
        return <BiChevronDown className={props.className ?? ""} />;
      case "Success-tick":
        return <FaCheckCircle className={props.className ?? ""} />;
      case "Download-btn":
        return <AiOutlineDownload className={props.className ?? ""} />;
      case "location-btn":
        return <MdLocationOn className={props.className ?? ""} />;
      case "phone-btn":
        return <BsTelephoneFill className={props.className ?? ""} />;
      case "email-btn":
        return <MdEmail className={props.className ?? ""} />;
        case "list-btn":
        return <MdOutlineStars className={props.className ?? ""} />;
      default:
        return <FaExclamationCircle className={props.className ? props.className : "error-icon"} />;
    }
  };
  return <>{iconGenerator()}</>;
}

export default KiedIcons;
