import React from "react";
import { useState } from "react";

function TextFieldDash(props) {
  const [error, setError] = useState("");
  const handleChange = (e, type = "text") => {
    console.log(e);
    let data = null;

    let value = e.target.value;
    let name = e.target.name;
    var numbersonly = new RegExp("^[0-9]*$");
    var emailsonly = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    );

    if (type === "number") {
      if (numbersonly.test(value)) {
        // console.log(data)
        data = e.target.value;
        
      }
    } else if (type === "email") {
      data = e.target.value;
      if (!emailsonly.test(data)) {
        setError("Please enter a valid email");
      } else {
        setError("")
      }
    } else if (type === "phone"){
      if(numbersonly.test(value)){
        data = e.target.value;
        if (data.length !== 10) {
          setError("Please enter a valid phone number");
        } else {
          setError("")
        }
      }
      
    } else {
      data = e.target.value;
    }
    props.onChange(data, name);
  };
  return (
    <>
      <div className="d-flex align-items-start justify-content-between w-100">
        {props.label && (
          <label
            className={`formstyle mr-1 p-0 col-5 ${
              props.error ? "text-danger" : ""
            }`}
          >
            <span className="d-flex justify-content-between ">
              {props.label}{" "}
              {/* {props.required && <span style={{ color: "red" }}>*</span>} */}
              <span>:</span>
            </span>
          </label>
        )}

        <label
          className={
            props.hidelabelStyle
              ? "w-100 col-7 ml-1 pl-1 mt-1"
              : props.type == "email"
              ? "formstyle ml-1 col-7 w-100  pl-1 mt-1"
              : "formstyle ml-1 col-7 w-100  pl-1 mt-1"
          }
        >
          {props.type === "textarea" ? (
            <textarea
              className={`${props.required ? "required " : ""} ${
                props.className ?? "inputbox"
              }`}
              name={props.name}
              value={props.value ?? ""}
              onChange={(e) => handleChange(e, props.type)}
              id="address"
              maxLength={props?.maxLength ?? ""}
              minLength={props?.maxLength ?? ""}
              placeholder={props.placeholder ?? ""}
              required={props.required}
            />
          ) : (
            <>
              <input
                className={`${props.required ? "required " : ""} ${
                  props.className ?? "inputbox w-100"
                }`}
                minLength={props.minLength ?? undefined}
                maxLength={props.maxLength ?? undefined}
                placeholder={props.placeholder ?? ""}
                name={props.name}
                value={props.value ?? ""}
                required={props.required}
                onChange={(e) => handleChange(e, props.type)}
                type={
                  props.type === "email"
                    ? "email"
                    : props.type === "date"
                    ? "date"
                    : props.type === "number"
                    ? "number"
                    : props.type === "phone"
                    ? "text"
                    : "text"
                }
                id="promoter_name"
              />
              {error && (
                <span className="" style={{ color: "red", fontSize:"12px" }}>
                  {error}
                </span>
              )}
            </>
          )}
        </label>
      </div>
    </>
  );
}

export default TextFieldDash;
