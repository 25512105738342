import React from "react";


function BgpGoogleForm() {
  return (
    <div className="mt-5 pt-5"><iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfcc3ZDKaP9W3JNp-PDmOiO_uu6IH15tKapaRYfxGEtuD2rWg/viewform?embedded=true" width="100%" height="5000" frameborder="0"  marginheight="0" marginwidth="0">Loading…</iframe></div>
    
  );
}

export default BgpGoogleForm;
