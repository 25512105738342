import React from "react";
import { blogsData } from "../blogs.data";
import DedicatedNewsCard from "../../../components/news.card.dedicated/dedicatedNewsCard";
import Pagination from "../../../components/pagination/pagination";
import { useRef, useState, useMemo } from "react";

let PageSize = 6;

function BlogDetail() {
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const myRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  const mainData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return blogsData.slice(firstPageIndex, lastPageIndex);
  }, [blogsData, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    myRef.current.scrollIntoView();
  };
  return (
    <div className="auto-container pb-5 pt-3 mt-1" ref={myRef}>
      <div>
        <div className="row">
          {mainData.map((card, i) => {
            return <DedicatedNewsCard data={card} key={i} index={i} />;
          })}
          <Pagination
            className="pagination-bar d-flex justify-content-center"
            currentPage={currentPage}
            totalCount={blogsData.length}
            pageSize={PageSize}
            onPageChange={(page) => handlePageChange(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default BlogDetail;
