import React from "react";
import "./industry-academy.page.styles.css";
import IndAcDescription from "./sections/description/description.section";
import IndAcEvents from "./sections/events/events.section";
import IndAcSchemes from "./sections/schemes/scheme.section";
import IndAcProgramms from "./sections/programs/program.section";
import Banner from "../../components/banner/banner";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";

function IndustryAccademyPage() {
  let navParam = useParams();
  const academiaPrograms = useRef();
  const academiaSchemes = useRef();
  const academiaEvents = useRef();
  const manageNavigation = (id) => {
    scrollIntoView(id);
  };
  useEffect(() => {
    manageNavigation(navParam.id);
  }, [navParam.id]);
  function scrollIntoView(type) {
    switch (type) {
      case "academiaPrograms":
        academiaPrograms.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "academiaSchemes":
        academiaSchemes.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "academiaEvents":
        academiaEvents.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth", // Optional if you want to skip the scrolling animation
        });
    }
  }
  return (
    <div className="">
      <Banner
        title="Center for Industry Academia Collaboration"
        image="academia-banner"
      />
      <IndAcDescription />
      <div ref={academiaSchemes}></div>
      <IndAcSchemes />
      <div ref={academiaEvents}></div>
      <IndAcEvents />
      <div ref={academiaPrograms}> </div>
      <div className="greyBackground">
        <IndAcProgramms />
      </div>
    </div>
  );
}

export default IndustryAccademyPage;
