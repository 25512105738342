import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ProgramsBanner(props) {
  let isAuthenticated = props.state.isAuthenticated;
  let userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  console.log(userData);
  let width = useScreenBreakPoint();
  const history = useNavigate();
  // const handleregister = () => {
  //   localStorage.setItem("next_path", "/department/inovation/registration");
  //   history("/department/inovation/registration");
  // };
  const handleregister = () => {
    // if (userData?.role === 4) {
    //   if (userData?.is_profile_complete === 1) {
    //     if (userData?.status === 1) {
    //       sessionStorage.setItem(
    //         "next_path",
    //         "/department/inovation/registration"
    //       );
    //       history("/department/inovation/registration");
    //     } else {
    //       Swal.fire({
    //         icon: "warning",
    //         title: "Warning",
    //         text: "Your profile is not yet accepted by the admin. You will receive an email confirmation once the admin accepts your profile.",
    //         confirmButtonColor: "#ffa93c",
    //         confirmButtonText: "Ok"
    //       }).then((result) => {
    //         history("/");
    //       });
    //     }
    //   } else {
    //     Swal.fire({
    //       icon: "warning",
    //       title: "Warning",
    //       text: "You have to complete your profile to access this section",
    //       confirmButtonColor: "#ffa93c",
    //       confirmButtonText: "Ok"
    //     }).then((result) => {
    //       history("/dashboard");
    //     });
    //   }
    // } else {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "No Access",
    //     text: "Sorry! You have to be logged in as Existing entrepreneur to access this section.",
    //     confirmButtonColor: "#ffa93c",
    //     confirmButtonText: "Ok"
    //   }).then((result) => {
    //     history("/");
    //   });
    // }
   history("/department/inovation/edcregistration")
  };
  console.log(props?.data?.status);
  return (
    <div
      id="Banner_outer"
      className="banner_image banner_big d-flex justify-content-center"
    >
      <div
        id="Banner_inner"
        className="d-flex  container bannerContainer  head-container justify-content-between"
      >
        <div
          id="Banner_text"
          className="d-flex flex-column justify-content-end"
          // data-aos="fade-right"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <h2 className="text_banner more_link">{props.data.title}</h2>
          {props?.data?.title === "ARISE" ? (
            <div className="more_link">
              <div className="">
                <span className="prog-banner" style={{ color: "#fff" }}>
                  (Agro Incubation for Sustainable Entrepreneurship)
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="more_link">
            <div className="">
              <span className="prog-banner-2">{props.data.participants}</span>
            </div>
            {props?.data?.title === "BUSINESS GROWTH PROGRAM" ?
            <>
            <div className="mt-2">
            <span className="prog-banner-2">Last date for registration : 20th May 2023</span>
          </div></> : ""
}
          </div>
          {/* <Link to="/department/inovation/registration"> */}

          {props?.data?.status === "Upcoming" && (
            <div className="more_link">
              <KiedButton
                onClick={() => handleregister()}
                content={
                  <>
                    <div className="d-flex">
                      <div className="d-flex align-items-center m-2">
                        <a
                          className="banner-link"
                          // href={props.data.titleLink}
                          target="_blank"
                        >
                          Click Here To Apply
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                rounded
                big
              />
              {/* <span className="prog-banner">
                Registration Will Start on 18th March
              </span> */}

              {/* </Link> */}
            </div>
          )}
        </div>
        <div
          id="Banner_image"
          className="aboutpage_images  d-none d-md-block"
          // data-aos="fade-left"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <KiedImages
            className="program-banner-image d-none d-md-block"
            image="program-banner"
          />
        </div>
      </div>
    </div>
  );
}

export default ProgramsBanner;
