import React from "react";
import "./faq.card.style.css";

function FaqCard(props) {
  return (
    <div>
      <div className="faq_card pb-5">
        <ul>
          <li className="faq_questions"> {props.data.question}</li>
        </ul>
        <p className="faq_answers">{props.data.answer}</p>
      </div>
    </div>
  );
}

export default FaqCard;
