import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import ProgramPartner from "../../../../components/program.partners/program.partners";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function NewProgramSupportingPartners(props) {
  const history = useNavigate();
  let userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  // const handleregister = () => {
  //   localStorage.setItem("next_path", "/department/inovation/registration");
  //   history("/department/inovation/registration");
  // };
  const handleregister = () => {
    // if (userData?.role === 4) {
    //   if (userData?.is_profile_complete === 1) {
    //     if (userData?.status === 1) {
    //       sessionStorage.setItem(
    //         "next_path",
    //         "/department/inovation/registration"
    //       );
    //       history("/department/inovation/registration");
    //     } else {
    //       Swal.fire({
    //         icon: "warning",
    //         title: "Warning",
    //         text: "Your profile is not yet accepted by the admin. You will receive an email confirmation once the admin accepts your profile.",
    //         confirmButtonColor: "#ffa93c",
    //         confirmButtonText: "Ok"
    //       }).then((result) => {
    //         history("/");
    //       });
    //     }
    //   } else {
    //     Swal.fire({
    //       icon: "warning",
    //       title: "Warning",
    //       text: "You have to complete your profile to access this section",
    //       confirmButtonColor: "#ffa93c",
    //       confirmButtonText: "Ok"
    //     }).then((result) => {
    //       history("/dashboard");
    //     });
    //   }
    // } else {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "No Access",
    //     text: "Sorry! You have to be logged in as Existing entrepreneur to access this section.",
    //     confirmButtonColor: "#ffa93c",
    //     confirmButtonText: "Ok"
    //   }).then((result) => {
    //     history("/");
    //   });
    // }
    history("/department/inovation/edcregistration")
  };
  return (
    <div className="">
      <div className="auto-container pb-5 pt-5 ">
        <div className="d-flex justify-content-center align-items-center ">
          <div className="">
            <h2 className="inovation_headings m-1 ">Supporting Partners</h2>
          </div>
        </div>
        <div className="">
          <div className="  d-flex justify-content-center align-items-center align-items-stretch">
            <div className="   d-flex justify-content-center align-items-center flex-wrap p-0 w-100">
              {props?.data?.partners?.map((part, i) => {
                return <ProgramPartner data={part} key={i} index={i} />;
              })}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 mb-3">
          {/* <Link to={props.data.titleLink}>
            <KiedButton
              content={
                <>
                  <div className="d-flex p-1">
                    <div className="d-flex align-items-center">
                      Click Here To Apply
                    </div>
                    <div className="d-flex align-items-center">
                      <KiedIcons
                        icon="arrow-right"
                        className="hero-button-icon"
                      />
                    </div>
                  </div>
                </>
              }
              secondary
              rounded
              big
            />
          </Link> */}
        </div>
        <div>
          <div className="d-flex justify-content-center mt-5 mb-3">
            <KiedButton
              onClick={() => handleregister()}
              content={
                <>
                  <div className="d-flex p-1">
                    <div className="d-flex align-items-center">
                    <a
                        className="banner-link"
                        // href={props.data.titleLink}
                        target="_blank"
                      >
                        Click Here To Apply
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <KiedIcons
                        icon="arrow-right"
                        className="hero-button-icon"
                      />
                    </div>
                  </div>
                </>
              }
              secondary
              rounded
              big
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewProgramSupportingPartners;
