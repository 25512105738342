import React from "react";
import "./team_members.styles.css";
import KiedImages from "../../assets/images/kied.images.component";

function Teammembers(props) {
  return (
    <div className="col-md-4 col-sm-12 p-0 mt-5 ">
      <div className="col-12 mb-4">
        <div className="  d-flex flex-column align-items-center  ">
          <div className="">
            <KiedImages className="team_image" link={props.data.image} />
          </div>
          <div className=" d-flex flex-column justify-content-between align-items-center">
            <div className="team_name mt-2">{props.data.name}</div>
            <div className="team_designation pt-2">
              {props.data.designation}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teammembers;
