import { useLangStore } from "../../utils/zustand";
export const headerMenus = [
  { id: 1, label: "Home",label_ma: "ഹോം", type: "menu", hasMenus: false, path: "/" },
  {
    id: 2,
    label: "About us",
    label_ma:"ഞങ്ങളെപ്പറ്റി",
    type: "menu",
    hasMenus: true,
    menus: [
      { id: 11, label: "KIED EDC", hasSubMenus: false, path: "/about" },
      {
        id: 12,
        label: "Governing Council Members",
        label_ma : "ഭരണസമിതി അംഗങ്ങൾ",
        hasSubMenus: false,
        path: "/our-team",
      },
      // {
      //   id: 13,
      //   label: "Advisory Committee",
      //   hasSubMenus: false,
      //   path: "/advisory-committee",
      // },
      {
        id: 14,
        label: "Campus",
        label_ma: "കാമ്പസ്",
        hasSubMenus: true,
        path: "/campus/home",
        subMenus: [
          {
            id: 143,
            label: "Ernakulam ",
            path: "/campus/campusErnakulam",
            hasSubMenus: true,
            subMenus: [
              { id: 141, label: "Angamaly", path: "/campus/campusPalakkad" },
              {
                id: 142,
                label: "Kalamassery",
                path: "/campus/campusKozhikode",
              },
            ],
          },
        ],
      },
      { id: 55, label: "Contact Us",label_ma: "ബന്ധപെടുക", hasSubMenus: false, path: "/contact_us" },
    ],
  },

  {
    id: 3,
    label_ma: "വകുപ്പുകൾ",
    label : "Departments", 
    type: "menu",
    hasMenus: true,
    menus: [
      {
        id: 31,
        label: "Center for Innovation",
        hasSubMenus: true,
        path: "/department/inovation/home",
        subMenus: [
          {
            id: 311,
            label: "Schemes",
            path: "/department/inovation/inovationSchemes",
          },
          {
            id: 312,
            label: "Events",
            path: "/department/inovation/inovationEvents",
          },
          {
            id: 313,
            label: "Programs",
            path: "/department/inovation/inovationPrograms",
          },
        ],
      },
      {
        id: 32,
        label: "Center for Industry Academia Collaboration",
        hasSubMenus: true,
        path: "/department/academy-collaboration/home",
        subMenus: [
          {
            id: 321,
            label: "Schemes",
            path: "/department/academy-collaboration/academiaSchemes",
          },
          {
            id: 322,
            label: "Events",
            path: "/department/academy-collaboration/academiaEvents",
          },
          {
            id: 323,
            label: "Programs",
            path: "/department/academy-collaboration/academiaPrograms",
          },
        ],
      },
      {
        id: 33,
        label: "Entrepreneurship Development Club",
        hasSubMenus: true,
        path: "/department/EDC/home",
        subMenus: [
          { id: 331, label: "Schemes", path: "/department/EDC/edcSchemes" },
          { id: 332, label: "Events", path: "/department/EDC/edcEvents" },
          { id: 333, label: "Programs", path: "/department/EDC/edcPrograms" },
        ],
      },
      // {
      //   id: 34,
      //   label: "Center For Business Finance",
      //   hasSubMenus: true,
      //   subMenus: [
      //     { id: 341, label: "Schemes", path: "/campus/kozhikode" },
      //     { id: 342, label: "Events", path: "/campus/palakkad" },
      //     { id: 343, label: "Programs", path: "/campus/ernakulam" },
      //   ],
      // },
      // {
      //   id: 35,
      //   label: "Center For Guidance And Facilitation",
      //   hasSubMenus: true,
      //   subMenus: [
      //     { id: 351, label: "Schemes", path: "/campus/kozhikode" },
      //     { id: 352, label: "Events", path: "/campus/palakkad" },
      //     { id: 353, label: "Programs", path: "/campus/ernakulam" },
      //   ],
      // },
      // {
      //   id: 36,
      //   label: "Center For Technology Transfer Assistance",
      //   hasSubMenus: true,
      //   subMenus: [
      //     { id: 361, label: "Schemes", path: "/campus/kozhikode" },
      //     { id: 362, label: "Events", path: "/campus/palakkad" },
      //     { id: 363, label: "Programs", path: "/campus/ernakulam" },
      //   ],
      // },
    ],
  },
  { id: 4, label: "KIED", type: "logo", hasMenus: false, path: "/" },
  {
    id: 5,
    label: "Initiatives",
    label_ma: "സംരംഭങ്ങൾ",
    type: "menu",
    hasMenus: true,
    // path: "/contact_us",
    right: true,
    menus: [
      {
        id: 51,
        label: "Programs",
        label_ma : "പ്രോഗ്രാമുകൾ",
        hasSubMenus: false,
        path: "/programs-listing",
      },
      {
        id: 52,
        label: "Events",
        label_ma: "ഇവന്റുകൾ",
        hasSubMenus: false,
        path: "/department/inovation/events",
      },
      {
        id: 53,
        label: "Schemes",
        label_ma:"സ്കീമുകൾ",
        hasSubMenus: false,
        path: "/schemes-listing",
      },
      //   {
      //     id: 54,
      //     label: "Stackholders",
      //     hasSubMenus: false,
      //     path: "/about-kied",
      //   },
      //   { id: 55, label: "Contact Us", hasSubMenus: false, path: "/about-kied" },
    ],
  },
  {
    id: 6,
    label: "Resources",
    label_ma : "വിഭവങ്ങൾ",
    type: "menu",
    hasMenus: true,
    right: true,
    menus: [
      // { id: 61, label: "Blogs", hasSubMenus: false, path: "/blogs" },
      // { id: 62, label: "Programs", hasSubMenus: false, path: "/programs" },
      // { id: 63, label: "Events", hasSubMenus: false, path: "/events" },
      // {
      //   id: 64,
      //   label: "Webinar REC",
      //   hasSubMenus: false,
      //   path: "/webinar",
      // },
      {
        id: 65,
        label: "Videos",
        label_ma : "വീഡിയോകൾ",
        hasSubMenus: false,
        path: "/kied_videos",
      },
      {
        id: 66,
        label: "Materials",
        label_ma : "മെറ്റീരിയലുകൾ",
        hasSubMenus: false,
        path: "/materials",
      },
      // {
      //   id: 66,
      //   label: "Podcast",
      //   hasSubMenus: false,
      //   path: "/underconstruction",
      // },
      // {
      //   id: 67,
      //   label: "Paper",
      //   hasSubMenus: false,
      //   path: "/underconstruction",
      // },
      // {
      //   id: 68,
      //   label: "News Letter",
      //   hasSubMenus: false,
      //   path: "/underconstruction",
      // },
      // {
      //   id: 69,
      //   label: "Case Study",
      //   hasSubMenus: false,
      //   path: "/underconstruction",
      // },
    ],
  },
  {
    id: 7,
    label: "Information",
    label_ma: "വിവരങ്ങൾ",
    type: "menu",
    hasMenus: true,
    right: true,
    menus: [
      { id: 71, label: "Tenders",label_ma: "ടെൻഡർ", hasSubMenus: false, path: "/tenders" },
      { id: 72, label: "Careers",label_ma: "തൊഴിലവസരങ്ങൾ", hasSubMenus: false, path: "/carrers" },
      // { id: 73, label: "News", hasSubMenus: false, path: "/news" },
    ],
  },
];
