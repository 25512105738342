import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import Teammembers from "../../../../components/team_members/team_members";
import { Team } from "../../team.data";
import "../../team.page.styles.css";

function OurTeamMembers(props) {
  return (
    <div className="auto-container pb-5 pt-5 mt-2">
      <div
        id="InovationEventssHeading"
        className="d-flex justify-content-left align-items-center mb-2"
      >
        {/* <div className="m-1">
          <KiedImages
            className="inovation_headings_logo mr-2 "
            image="inovation-heading-icon"
          />
        </div> */}
        <div className="m-1">
          {/* <h2 className="inovation_headings " style={{ lineHeight: "1em" }}>
            Our Team Members
          </h2> */}
        </div>
      </div>
      <p className="teams-text">
        The operations of EDC are overseen by the Governing Council of KIED,
        consisting of the following members.
      </p>
      <hr />
      <div>
        <div id="inovation_Events_contents" className="row ">
          {props?.data.map((card, i) => {
            return <Teammembers data={card} key={i} index={i} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default OurTeamMembers;
