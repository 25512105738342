import React from "react";
import SchemeBreadcrumbs from "./sections/breadcrumb/schemes.breadcrumb";
import SchemeHerosection from "./sections/hero/schemeHero";
import SchemeDescription from "./sections/description/scheme.description";
import SchemeCanapply from "./sections/canapply/scheme.canapply";
import SchemeEligibility from "./sections/eligibility/scheme.eligibility";
import SupportScheme from "./sections/supportscheme/SupportScheme";
import SmallBanner from "../../components/small.banner/small.banner";
import "./schemes.page.styles.css";

function SchemesPage() {
  return (
    <div>
      <SmallBanner title={"Entrepreneur Support Schemes"} />
      <SchemeBreadcrumbs />
      <SchemeHerosection />
      <SchemeDescription />
      <SchemeCanapply />
      <SupportScheme />
      <SchemeEligibility />
    </div>
  );
}

export default SchemesPage;
