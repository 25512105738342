import React from "react";
import { TenderData } from "../../tenders.data";
import Pagination from "../../../../components/pagination/pagination";
import { useRef, useState, useMemo } from "react";
// import TenderTableComponent from "../../../../components/tender-table/tender.table";
import CareerTableComponent from "../../../../components/career-table/tender.table";
import { useEffect } from "react";
let PageSize = 10;

function TenderTable(props) {
  // const [isActive, setIsActive] = useState(false);
  // const [data, setData] = useState([]);
  const myRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  //   let TenderData = TenderDatas.reverse();

  const mainData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return props?.data.slice(firstPageIndex, lastPageIndex);
  }, [props?.data, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    myRef.current.scrollIntoView();
  };

  return (
    <div
      className="auto-container pb-5 pt-5 "
      ref={myRef}
      
    >
      <div className="" style={{ overflow: "auto" }}>
        <table>
          {mainData.length > 0 ? (
            <tr className="">
              <th className="table-head col-2">Post</th>
              <th className="table-head col-7">Description</th>
              <th className="table-head col-2">Apply</th>
            </tr>
          ) : (
            "No Recent Postings"
          )}

          {mainData.map((card, i) => {
            return <CareerTableComponent data={card} key={i} index={i} />;
          })}

          <Pagination
            className="pagination-bar "
            currentPage={currentPage}
            totalCount={props?.data.length}
            pageSize={PageSize}
            onPageChange={(page) => handlePageChange(page)}
          />
        </table>
      </div>
    </div>
  );
}

export default TenderTable;
