import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import "../../submitSolutions.page.styles.css";
import TextField from "../../../../components/textfield/TextField.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";

function SubmitSolutionsContent() {
  return (
    <div className="auto-container pb-5 pt-5">
      <div id="" className="d-flex justify-content-center align-items-center">
        <div className="">
          <h2 className="report_heading m-1">Submit Your Solutions</h2>
        </div>
      </div>

      <div className=" d-flex">
        <div className="mt-4">
          <KiedImages className="mt-3" image="question-square" />
        </div>
        <div className="d-flex flex-column mt-5 ml-2">
          <div className="submit_sol_heading mb-4">
            This is the heading of a particular problem contain demo text
          </div>
          <div className="Schemes_heading_description">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
            beatae possimus vero quod, aliquam eveniet doloribus rerum harum
            unde voluptates expedita asperiores alias ducimus eius tempora amet
            velit dolorum. Illo Lorem, ipsum dolor sit amet consectetur
            adipisicing elit. Debitis, delectus qui et deleniti reprehenderit
            nemo dignissimos, harum similique, quis dolore voluptas eaque
            numquam voluptatem nisi doloribus rerum quaerat totam unde.
          </div>
          <div className="submit_sol_form mt-4">
            <TextField
              label="Submit your Solution"
              type="text"
              required
              name="solution"
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center  m-5">
        <KiedButton
          content={
            <>
              <div className="d-flex p-1">
                <div className="d-flex align-items-center">Submit</div>
                <div className="d-flex align-items-center">
                  <KiedIcons
                    icon="arrow-right"
                    className="hero-button-icon arrow_icon"
                  />
                </div>
              </div>
            </>
          }
          primary
          rounded
          big
        />
      </div>
    </div>
  );
}

export default SubmitSolutionsContent;
