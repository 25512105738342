import React from "react";
import EventsBreadcrumbs from "./sections/breadcrumbs/events.breadcrumbs";
import EventsHero from "./sections/hero/events.hero";
import EventsDescription from "./sections/description/events.description";

function EventsPage() {
  return (
    <div>
      <EventsBreadcrumbs />
      <EventsHero />
      <EventsDescription />
    </div>
  );
}

export default EventsPage;
