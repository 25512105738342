import React from "react";
import "../../intenship.application.page.styles.css";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
function InternshipApplicationDetails() {
  return (
    <div>
      <div className="auto-container company-heading">
        <h3>COMPANY NAME</h3>
        <h4>Frontend Develepor</h4>
        <div className="company-details">
          <p>Job Type</p>
          <p>Job Duration</p>
        </div>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ratione,
          dolor natus? Temporibus rerum reprehenderit esse expedita, autem
          explicabo consequatur totam dolor optio, ipsa fugit iure quibusdam
          delectus nesciunt dicta natus. Lorem ipsum dolor sit amet consectetur,
          adipisicing elit. Ducimus minima reiciendis vel nam, iste amet ab
          cupiditate corporis vitae hic iure. Officiis consequatur error,
          laudantium adipisci mollitia molestiae similique? Aliquid. Lorem ipsum
          dolor sit amet consectetur adipisicing elit. Aliquid earum dolorem
          voluptatum sit consequatur beatae, inventore, delectus distinctio
          illum minus illo modi assumenda autem. Ducimus et id labore culpa
          distinctio! Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Autem, fugiat? Eos numquam fugit omnis accusantium obcaecati beatae
          iusto? Reiciendis odio maxime vel velit nam ab, necessitatibus
          sapiente ullam illum et?
        </p>
      </div>
      <div className=" auto-container d-flex justify-content-center submit-btn">
      <KiedButton
                content={
                  <>
                    <div className="d-flex ">
                      <div className="d-flex align-items-center mt-1 mb-1">
                        Submit
                      </div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                rounded
                big
              />
      </div>
    </div>
  );
}

export default InternshipApplicationDetails;
