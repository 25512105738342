import React, { forwardRef, useImperativeHandle } from "react";
import Swal from "sweetalert2";
import RadioButtonDash from '../../../../components/radiobutton-dash/radiobutton';
import SelectBoxDash from '../../../../components/selectbox-dash/SelectBox.component';
import TextFieldDash from '../../../../components/textfield-dash/TextField.component';
import { KiedCarriers, castCategory, gender, radiooption, businessStage, businessduration, mode } from '../../../edc.registration/registrationform.data';
import { districts, qualification } from '../../../registration/registrationform.data';
import KiedButton from "../../../../components/buttons/button/button.component";
import { toast } from "react-toastify";

const ExpertRegistrationForm = ({
  handleChange,
  handleTextFieldChange,
  formdata,
  error, setError,
  fielderror,
  setSubmitted,
  handleSubmit,
  setCompleted,
  setFieldError,
  userData,
  userid,
  accountname,
  accountno,
  whatsapp_no,
  userrole,
  setProfiledit,
  fetchprofile,
  createProfile,
  validateField,
  submitted,
  FileUploadDash,
  errorSize,
  fileUpload,
  handleregister,
  expertise
}, ref) => {

  const requiredFields = [
    "gender",
    "highest_educational_qualification",
    "online_profile",
    "area_expertise",

    "qualification_proof",
    "experience_proof",
    ...(formdata.area_expertise === "Others" ? ["expspecify"] : []),
  ];
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      dataSetter();
    },
  }));

  const validate = () => {
    let errorField = {};
    requiredFields.forEach((item) => {
      if (
        formdata[item] === null ||
        formdata[item] === undefined ||
        formdata[item] === ""
      ) {
        errorField[item] = "Please fill in the details";
        setSubmitted(false);
      }
    });
    return errorField;
  };
  const dataSetter = () => {
    setSubmitted(true);


    let payload = new FormData();

    // navigate("/success");
    if (formdata?.gender !== "" && formdata?.gender) {
      payload.append("gender", formdata?.gender);
    }
    if (
      formdata?.highest_educational_qualification !== "" &&
      formdata?.highest_educational_qualification
    ) {
      payload.append(
        "highest_educational_qualification",
        formdata?.highest_educational_qualification
      );
    }
    if (formdata?.online_profile !== "" && formdata?.online_profile) {
      payload.append("online_profile", formdata?.online_profile);
    }

    if (formdata?.area_expertise === "Others") {
      payload.append("area_expertise", formdata?.expspecify ?? "");
    } else {
      if (formdata?.area_expertise !== "" && formdata?.area_expertise) {
        payload.append("area_expertise", formdata?.area_expertise);
      }
    }
    if (formdata?.qualification_proof !== "" && formdata?.qualification_proof) {
      payload.append("qualification_proof", formdata?.qualification_proof);
    }
    if (formdata?.experience_proof !== "" && formdata?.experience_proof) {
      payload.append("experience_proof", formdata?.experience_proof);
    }
    if (formdata?.other_empanelment !== "" && formdata?.other_empanelment) {
      payload.append("other_empanelment", formdata?.other_empanelment);
    }
      
    const errorField = validate();
    if (Object.keys(errorField).length === 0) {
      handleSubmit(payload);
    } else {
      setFieldError(errorField);
      setSubmitted(false);
      setCompleted(true);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required fields (Mandatory fields are marked in red)",
        confirmButtonColor: "#ffa93c",
        confirmButtonText: "Ok",
      });
    }
  };
  console.log(formdata)
  return (
    <div>
                  <div>
                    <div className="">
                      <form>
                        <RadioButtonDash
                          options={gender}
                          label="Gender"
                          required
                          error={fielderror.gender}
                          name="gender"
                          checked={formdata.gender}
                          value={formdata.gender ?? ""}
                          onChange={handleChange}
                        />
                        <br />
                        <SelectBoxDash
                          label="Highest Qualification"
                          required
                          error={fielderror.highest_educational_qualification}
                          options={qualification}
                          value={formdata.highest_educational_qualification}
                          selectionMessage="Select your Qualification"
                          name="highest_educational_qualification"
                          handleChange={handleChange}
                        />
                        <br />
                        <TextFieldDash
                          label="Website/Online Profile of the Applicant e.g., LinkedIn"
                          required
                          error={fielderror.online_profile}
                          name="online_profile"
                          value={formdata.online_profile ?? ""}
                          onChange={handleTextFieldChange}
                        />
                        <br />
                        <SelectBoxDash
                          label="Area of Professional Expertise"
                          required
                          error={fielderror.area_expertise}
                          options={expertise}
                          value={formdata.area_expertise}
                          selectionMessage="Select your Area of Expertise"
                          name="area_expertise"
                          handleChange={handleChange}
                        />
                        <br />
                        {formdata.area_expertise === "Others" && (
                          <>
                            <TextFieldDash
                              label="Specify the Area of Your Expertise"
                              required
                              error={fielderror.expspecify}
                              name="expspecify"
                              value={formdata.expspecify ?? ""}
                              onChange={handleTextFieldChange}
                            />
                            <br />
                          </>
                        )}
                        <FileUploadDash
                          label="Credentials to prove Qualification (.pdf, .docx, .jpg format files are allowed)"
                          required
                          error={fielderror.qualification_proof}
                          errorSize={errorSize?.qualification_proof}
                          accept=".pdf, .docx, .jpg"
                          name="qualification_proof"
                          type="file"
                          // filename={formdata.gst_file_name}
                          // value={formdata.gst_file ?? null}
                          onChange={fileUpload}
                        />

                        <br />
                        {error.proof && (
                          <p id="proof" className="ml-3 error">
                            Unsupported file type! Please select a .pdf, .docx
                            or jpg file
                          </p>
                        )}
                        <br />
                        <FileUploadDash
                          label="Credentials to prove Experience (.pdf, .docx, .jpg format files are allowed)"
                          required
                          error={fielderror.experience_proof}
                          accept=".pdf, .docx, .jpg"
                          name="experience_proof"
                          type="file"
                          // filename={formdata.gst_file_name}
                          // value={formdata.gst_file ?? null}
                          onChange={fileUpload}
                          errorSize={errorSize?.experience_proof}
                        />
                        <br />
                        {error.proof && (
                          <p id="proof" className="ml-3 error">
                            Unsupported file type! Please select a .pdf, .docx
                            or jpg file
                          </p>
                        )}
                        <br />
                        <FileUploadDash
                          label="Details of other Empanelment at other organizations (.pdf, .docx, .jpg format files are allowed)"
                          accept=".pdf, .docx, .jpg"
                          name="other_empanelment"
                          errorSize={errorSize?.other_empanelment}
                          type="file"
                          // filename={formdata.gst_file_name}
                          // value={formdata.gst_file ?? null}
                          onChange={fileUpload}
                        />
                        <br />

                        {error.proof && (
                          <p id="proof" className="ml-3 error">
                            Unsupported file type! Please select a .pdf, .docx
                            or jpg file
                          </p>
                        )}
                        <br />
                      </form>
                    </div>
                  </div>

    </div>
  );
};

export default forwardRef(ExpertRegistrationForm);
