import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SchemeHerosection(props) {
  let width = useScreenBreakPoint();
  return (
    // <div
    //   className={`d-flex justify-content-center ${
    //     width === "xs" || width === "sm"
    //       ? props.index % 2 === 0
    //         ? "flex-column"
    //         : " flex-column"
    //       : props.index % 2 === 0
    //       ? "flex-row"
    //       : "flex-row-reverse "
    //   }`}
    // >
    <div
      className={`d-flex justify-content-center auto-container ${
        width === "xs" || width === "sm" ? "sml p-0 mt-2" : "lr mt-5 pt-5"
      }`}
    >
      <div className="row d-flex justify-content-between w-100">
        <div className="col-lg-4 col-md-4  col-sm-12 d-flex justify-content-start">
          <KiedImages
            className="w-100"
            link={props?.data?.image}
            style={{ height: "100%", objectFit: "cover", borderRadius: "15px" }}
            // image="ess"
          />
        </div>
        <div
          className={`col-lg-8 col-md-8 col-sm-12 d-flex flex-column align-items-start  ${
            width === "xs" || width === "sm" ? "sml  " : "lr p-2 pl-5"
          } `}
        >
          <div className="schemes_heading mb-4">
            <span className="p-1 d-sm-none">
              {/* Who we are{" "} */}
              {/* <KiedImages
              className="pb-1"
              style={{ width: "20px" }}
              image="question-icon"
            /> */}
            </span>
          </div>
          <div className="Schemes_heading_description ">
        
              <h1 className="event-detail-heading">{props?.data?.name}</h1>
           
          </div>
          <div
            className={`Schemes_heading_description Schemes_new_discription w-100 ${
              width === "xs" || width === "sm" ? "sml p-3 mt-5 " : "lr  mt-5 "
            } `}
          >
          {props?.data?.description}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default SchemeHerosection;
