import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import { Link } from "react-router-dom";
import "../../new.programs.styles.css";
import Masonry from "react-responsive-masonry";
import KiedImages from "../../../../assets/images/kied.images.component";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";

function AriseReport(props) {
  let navParam = useParams();
  const inspiration = useRef();
  const immersion = useRef();
  const assesment = useRef();
  const establish = useRef();

  useEffect(() => {
    switch (navParam.id) {
      case "inspiration":
        inspiration.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "immersion":
        immersion.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "assesment":
        assesment.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "establish":
        establish.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth", // Optional if you want to skip the scrolling animation
        });
    }
  }, [navParam.id]);
  const history = useNavigate();
  const handleregister = () => {
    sessionStorage.setItem(
      "next_path",
      "/department/inovation/ARISERegistration"
    );
    history("/department/inovation/ARISERegistration");
  };
  return (
    <div className="greyBackground">
      <div className="auto-container pb-5 pt-5">
        <div
          id="InovationEventssHeading"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="">
            <h2 className="inovation_headings m-1">
              {props?.data?.reportHeading}
            </h2>
          </div>
        </div>
        <div className="mx-2">
          <div ref={assesment}></div>
          {props?.data?.subHeading3 && (
            <>
              <div className="report-border">
                <div className="d-flex align-items-start">
                  <div className="col-md-7 col-sm-12 ">
                    <div className="d-flex align-items-center">
                      <div className="report-heading ">
                        <b> {props?.data?.subHeading3?.heading}</b>
                      </div>
                      <div className="report-status-card mx-3 my-3">
                        <b>{props?.data?.subHeading3?.status}</b>
                      </div>
                    </div>
                    <div className="detail-description">
                      <ul>
                        {props?.data?.subHeading3?.description?.map(
                          (card, i) => {
                            return (
                              <li>
                                <span>{card?.point}</span>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  {props?.data?.subHeading3?.status === "Completed" ||
                    ("Ongoing" && (
                      <>
                        <div className="col-md-5 col-sm-12 d-none d-md-block pt-3 pb-3 mb-4">
                          <Masonry columnsCount={2} gutter="10px">
                            {props?.data?.subHeading3?.gallery?.map(
                              (image, i) => (
                                <KiedImages
                                  image={image.image}
                                  style={{ width: "100%", display: "block" }}
                                />
                              )
                            )}
                          </Masonry>
                        </div>
                      </>
                    ))}
                </div>
                {props?.data?.subHeading3?.status === "Completed" && (
                  <>
                    {" "}
                    <div className="col-12" style={{ overflow: "auto" }}>
                      <table className="w-100">
                        {props?.data?.subHeading3?.mainData.length > 0 ? (
                          <tr className="">
                            <th className="">No</th>
                            <th className="">District</th>
                            <th className="">Date</th>
                            <th className="">Status</th>
                            <th className="">No.of Participants</th>
                            <th className="">Mode</th>
                          </tr>
                        ) : (
                          "No Data Available"
                        )}

                        {props?.data?.subHeading3?.mainData?.map((card, i) => {
                          return (
                            <tr>
                              <td className="m-2">{card.id}</td>
                              <td className="m-2">{card.district}</td>
                              <td className="m-2">{card.date}</td>
                              <td className="m-2">{card.status}</td>
                              <td className="m-2">{card.participantNo}</td>
                              <td className="m-2">{card.mode}</td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </>
                )}
                {props?.data?.subHeading3?.status === "Ongoing" && (
                  <>
                    {" "}
                    <div className="w-100 " style={{ overflow: "auto" }}>
                      <table className="w-100">
                        {props?.data?.subHeading3?.mainData.length > 0 ? (
                          <tr className="">
                            <th className="">No</th>
                            <th className="">Program Title</th>
                            <th className="">Participants</th>
                            <th className="">Batch Size</th>
                            <th className="">Duration ("Days")</th>
                            <th className="">From</th>
                            <th className="">To</th>
                            <th className="">Venue</th>
                            <th className="">Mode</th>
                            <th className="">Fees</th>
                            <th className="">Action</th>
                          </tr>
                        ) : (
                          "No Data Available"
                        )}

                        {props?.data?.subHeading3?.mainData?.map((card, i) => {
                          return (
                            <tr>
                              <td className="m-2">{card.id}</td>
                              <td className="m-2">{card.title}</td>
                              <td className="m-2">{card.participants}</td>
                              <td className="m-2">{card.batchSize}</td>
                              <td className="m-2">{card.duration}</td>
                              <td className="m-2">{card.from}</td>
                              <td className="m-2">{card.to}</td>
                              <td className="m-2">{card.venue}</td>
                              <td className="m-2">{card.mode}</td>
                              <td className="m-2">{card.fees}</td>
                              {card.status === "Upcoming" ? (
                                <td className="m-2 ">
                                  <KiedButton
                                    onClick={handleregister}
                                    secondary
                                    squre
                                    className="formbtn"
                                    // type="submit"
                                    content={
                                      <div className="registerbtn d-flex justify-content-center p-0">
                                        Apply Now
                                      </div>
                                    }
                                  />{" "}
                                </td>
                              ) : (
                                <td>
                                  <div className="report-status-card mx-3 my-3">
                                    <b>{props?.data?.subHeading2?.status}</b>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                    {/* <Link to="/department/inovation/ARISERegistration"> */}
                    {/* <div className="d-flex justify-content-center pt-4 mt-2">
                      <KiedButton
                        onClick={handleregister}
                        secondary
                        rounded
                        // type="submit"
                        content={
                          <div className="registerbtn d-flex justify-content-center">
                            Apply Now
                          </div>
                        }
                      />
                    </div> */}
                    {/* </Link> */}
                  </>
                )}
              </div>
            </>
          )}
          {/* ///////////////////////////////////////////////////////////  */}
          <div ref={establish}></div>
          {props?.data?.subHeading4 && (
            <>
              <div className="report-border">
                <div className="d-flex flex-column align-items-stretch">
                  <div className="col-12">
                    <div className="d-flex align-items-center">
                      <div className="report-heading ">
                        <b> {props?.data?.subHeading4?.heading}</b>
                      </div>
                      <div className="report-status-card m-4">
                        <b>{props?.data?.subHeading4?.status}</b>
                      </div>
                    </div>
                    <div className="detail-description">
                      <ul>
                        {props?.data?.subHeading4?.description?.map(
                          (card, i) => {
                            return (
                              <li>
                                <span>{card?.point}</span>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  {props?.data?.subHeading4?.status === "Completed" && (
                    <>
                      <div className="col-5">
                        <Masonry columnsCount={2} gutter="20px">
                          {props?.data?.subHeading4?.gallery?.map(
                            (image, i) => (
                              <KiedImages
                                image={image.image}
                                style={{ width: "100%", display: "block" }}
                              />
                            )
                          )}
                        </Masonry>
                      </div>
                    </>
                  )}
                </div>
                {/* {props?.data?.subHeading4?.status === "Completed" && (
                  <>
                    {" "}
                    <div className="w-100 ">
                      <table className="w-100">
                        {props?.data?.subHeading4?.mainData.length > 0 ? (
                          <tr className="">
                            <th className="">No</th>
                            <th className="">District</th>
                            <th className="">Date</th>
                            <th className="">Status</th>
                            <th className="">No.of Participants</th>
                            <th className="">Mode</th>
                          </tr>
                        ) : (
                          "No Data Available"
                        )}

                        {props?.data?.subHeading4?.mainData?.map((card, i) => {
                          return (
                            <tr>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.id}
                              </td>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.district}
                              </td>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.date}
                              </td>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.status}
                              </td>
                              <td className="m-2">
                                {
                                  props?.data?.subHeading4?.mainData
                                    ?.participantNo
                                }
                              </td>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.mode}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </>
                )}
                {props?.data?.subHeading4?.status === "Ongoing" && (
                  <>
                    {" "}
                    <div className="w-100 ">
                      <table className="w-100">
                        {props?.data?.subHeading4?.mainData.length > 0 ? (
                          <tr className="">
                            <th className="">No</th>
                            <th className="">Program Title</th>
                            <th className="">Participants</th>
                            <th className="">Batch Size</th>
                            <th className="">Duration ("Days")</th>
                            <th className="">From</th>
                            <th className="">To</th>
                            <th className="">Estimated Amount</th>
                            <th className="">Mode</th>
                            <th className="">Fees</th>
                          </tr>
                        ) : (
                          "No Data Available"
                        )}

                        {props?.data?.subHeading4?.mainData?.map((card, i) => {
                          return (
                            <tr>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.id}
                              </td>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.district}
                              </td>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.date}
                              </td>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.status}
                              </td>
                              <td className="m-2">
                                {
                                  props?.data?.subHeading4?.mainData
                                    ?.participantNo
                                }
                              </td>
                              <td className="m-2">
                                {props?.data?.subHeading4?.mainData?.mode}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </>
                )} */}
              </div>
            </>
          )}
          <div ref={inspiration}></div>
          {props?.data?.subHeading1 && (
            <>
              <div className="report-border">
                <div className="d-flex align-items-start">
                  <div className="col-md-7 col-sm-12 ">
                    <div className="d-flex align-items-center">
                      <div className="report-heading ">
                        <b>{props?.data?.subHeading1?.heading}</b>
                      </div>
                      <div className="report-status-card mx-3 my-3">
                        <b>{props?.data?.subHeading1?.status}</b>
                      </div>
                    </div>
                    <div className="detail-description">
                      <ul>
                        {props?.data?.subHeading1?.description?.map(
                          (card, i) => {
                            return (
                              <li>
                                <span>{card?.point}</span>
                              </li>
                            );
                          }
                        )}
                        <li>Total Participants : 1442</li>
                      </ul>
                    </div>
                  </div>
                  {props?.data?.subHeading1?.status === "Completed" && (
                    <>
                      <div className="col-md-5 col-sm-12 d-none d-md-block pt-3 pb-3 mb-4">
                        <Masonry columnsCount={2} gutter="10px">
                          {props?.data?.subHeading1?.gallery?.map(
                            (image, i) => (
                              <KiedImages
                                image={image.image}
                                style={{ width: "100%", display: "block" }}
                              />
                            )
                          )}
                        </Masonry>
                      </div>
                    </>
                  )}
                </div>
                {props?.data?.subHeading1?.status === "Completed" && (
                  <>
                    {" "}
                    <div className="col-12" style={{ overflow: "auto" }}>
                      <table className="w-100">
                        {props?.data?.subHeading1?.mainData.length > 0 ? (
                          <tr className="">
                            <th className="">No</th>
                            <th className="">District</th>
                            <th className="">Date</th>
                            <th className="">Status</th>
                            <th className="">No.of Participants</th>
                            <th className="">Mode</th>
                          </tr>
                        ) : (
                          "No Data Available"
                        )}

                        {props?.data?.subHeading1?.mainData?.map((card, i) => {
                          return (
                            <tr>
                              <td className="m-2">{card.id}</td>
                              <td className="m-2">{card.district}</td>
                              <td className="m-2">{card.date}</td>
                              <td className="m-2">{card.status}</td>
                              <td className="m-2">{card.participantNo}</td>
                              <td className="m-2">{card.mode}</td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </>
                )}
                {props?.data?.subHeading1?.status === "Ongoing" && (
                  <>
                    {" "}
                    <div className="w-100 ">
                      <table className="w-100">
                        {props?.data?.subHeading1?.mainData.length > 0 ? (
                          <tr className="">
                            <th className="">No</th>
                            <th className="">Program Title</th>
                            <th className="">Participants</th>
                            <th className="">Batch Size</th>
                            <th className="">Duration ("Days")</th>
                            <th className="">From</th>
                            <th className="">To</th>
                            <th className="">Estimated Amount</th>
                            <th className="">Mode</th>
                            <th className="">Fees</th>
                          </tr>
                        ) : (
                          "No Data Available"
                        )}

                        {props?.data?.subHeading1?.mainData?.map((card, i) => {
                          return (
                            <tr>
                              <td className="m-2">{card.id}</td>
                              <td className="m-2">{card.district}</td>
                              <td className="m-2">{card.date}</td>
                              <td className="m-2">{card.status}</td>
                              <td className="m-2">{card.participantNo}</td>
                              <td className="m-2">{card.mode}</td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {/* //////////////////////////////////////////////////////  */}
          <div ref={immersion}></div>
          {props?.data?.subHeading2 && (
            <>
              <div className="report-border">
                <div className="d-flex align-items-start">
                  <div className="col-md-7 col-sm-12 ">
                    <div className="d-flex align-items-center">
                      <div className="report-heading ">
                        <b>{props?.data?.subHeading2?.heading}</b>
                      </div>
                      <div className="report-status-card mx-3 my-3">
                        <b>{props?.data?.subHeading2?.status}</b>
                      </div>
                    </div>
                    <div className="detail-description">
                      <ul>
                        {props?.data?.subHeading2?.description?.map(
                          (card, i) => {
                            return (
                              <li>
                                <span>{card?.point}</span>
                              </li>
                            );
                          }
                        )}
                        <li>Total Participants : 1507</li>
                      </ul>
                    </div>
                  </div>
                  {props?.data?.subHeading2?.status === "Completed" && (
                    <>
                      <div className="col-md-5 col-sm-12 d-none d-md-block pt-3 pb-3 mb-4">
                        <Masonry columnsCount={2} gutter="10px">
                          {props?.data?.subHeading2?.gallery?.map(
                            (image, i) => (
                              <KiedImages
                                image={image.image}
                                style={{ width: "100%", display: "block" }}
                              />
                            )
                          )}
                        </Masonry>
                      </div>
                    </>
                  )}
                </div>
                {props?.data?.subHeading2?.status === "Completed" && (
                  <>
                    {" "}
                    <div className="col-12" style={{ overflow: "auto" }}>
                      <table className="w-100">
                        {props?.data?.subHeading2?.mainData.length > 0 ? (
                          <tr className="">
                            <th className="">No</th>
                            <th className="">District</th>
                            <th className="">Date</th>
                            <th className="">Status</th>
                            <th className="">No.of Participants</th>
                            <th className="">Mode</th>
                          </tr>
                        ) : (
                          "No Data Available"
                        )}

                        {props?.data?.subHeading2?.mainData?.map((card, i) => {
                          return (
                            <tr>
                              <td className="m-2">{card.id}</td>
                              <td className="m-2">{card.district}</td>
                              <td className="m-2">{card.date}</td>
                              <td className="m-2">{card.status}</td>
                              <td className="m-2">{card.participantNo}</td>
                              <td className="m-2">{card.mode}</td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </>
                )}
                {props?.data?.subHeading2?.status === "Ongoing" && (
                  <>
                    {" "}
                    <div className="w-100 ">
                      <table className="w-100">
                        {props?.data?.subHeading2?.mainData.length > 0 ? (
                          <tr className="">
                            <th className="">No</th>
                            <th className="">Program Title</th>
                            <th className="">Participants</th>
                            <th className="">Batch Size</th>
                            <th className="">Duration ("Days")</th>
                            <th className="">From</th>
                            <th className="">To</th>
                            <th className="">Venue</th>
                            <th className="">Mode</th>
                            <th className="">Fees</th>
                            <th className=""></th>
                          </tr>
                        ) : (
                          "No Data Available"
                        )}

                        {props?.data?.subHeading2?.mainData?.map((card, i) => {
                          return (
                            <tr>
                              <td className="m-2">{card.id}</td>
                              <td className="m-2">{card.title}</td>
                              <td className="m-2">{card.participants}</td>
                              <td className="m-2">{card.batchSize}</td>
                              <td className="m-2">{card.duration}</td>
                              <td className="m-2">{card.from}</td>
                              <td className="m-2">{card.to}</td>
                              <td className="m-2">{card.venue}</td>
                              <td className="m-2">{card.mode}</td>
                              <td className="m-2">{card.fees}</td>
                              {card.status === "Upcoming" ? (
                                <td className="m-2">
                                  <KiedButton
                                    onClick={handleregister}
                                    secondary
                                    rounded
                                    // type="submit"
                                    content={
                                      <div className="registerbtn d-flex justify-content-center">
                                        Apply Now
                                      </div>
                                    }
                                  />{" "}
                                </td>
                              ) : (
                                <div className="report-status-card mx-3 my-3">
                                  <b>{props?.data?.subHeading2?.status}</b>
                                </div>
                              )}
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {/* /////////////////////////////////////////////////  */}
        </div>
      </div>
    </div>
  );
}

export default AriseReport;
