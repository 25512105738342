import React from "react";

function AdvisoryDescription() {
  return (
    <div className="auto-container inovation_text about_kied">
      <p className="">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Non placeat
        corporis porro debitis esse harum repellat nulla sint eaque
        voluptatibus. Aperiam ea hic odit doloribus a repudiandae quae mollitia
        eos! Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga sequi
        non eius, beatae, iusto fugit rem nulla mollitia, vero harum aperiam? Et
        explicabo fugiat impedit aperiam doloremque possimus officiis enim.
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae nisi,
        odit voluptates at, dolorum optio dolore quos commodi harum saepe magnam
        dicta numquam maiores adipisci dignissimos? Provident laudantium commodi
        eaque.
      </p>
    </div>
  );
}

export default AdvisoryDescription;
