import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";

function InternshipDetailDetail() {
  return (
    <div className="auto-container  pt-5">
      <div className="d-flex justify-content-center academia_heading">
        <h3> INTERNSHIP CONNECT</h3>
      </div>
      {/* <div className="pt-3 row justify-content-center">
        <div className="col-md-5  col-sm-12 ml-1 mr-1 ">
          <KiedImages className="prog_detail_image" image="seminar" />
        </div>
        <div className="program_details col-md-5 col-sm-12 p-0 ml-2">
          <div className="program_details_inner">
            <div className="program_details_heading">
              <h3>PROGRAM DETAILS</h3>
            </div>
            <div className=" program_date_inner ">
              <div className="d-flex align-items-center justify-content-center">
                <div className="program_date_border program_details_date pb-5  d-flex align-items-center">
                  <div>
                    <KiedImages className="mb-1 mr-2" image="calender-icon" />
                  </div>
                  <div>September 06-2022 AT 10.00 AM</div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="program_details_location d-flex align-items-start">
                  <div className="">
                    <KiedImages className=" mb-1 mr-2" image="location-bicon" />
                  </div>
                  <div>
                    EDC GROUND THRIPUNITHURA, <br />
                    ERNAKULAM -608023
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default InternshipDetailDetail;
