import React from "react";
import EventCard from "../../../../components/event.cards/event.card";
import KiedImages from "../../../../assets/images/kied.images.component";
import { EDCEventData } from "../../edc.page.data";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";

function EDCEvents() {
  let width = useScreenBreakPoint();
  let sortedEventData = EDCEventData.sort(
    (a, b) =>
      new Date(...a.startDate.split("/").reverse()) -
      new Date(...b.startDate.split("/").reverse())
  );
  let mainData = sortedEventData.reverse();
  return (
    <div className="auto-container pb-5 pt-5">
      <div id="" className="d-flex justify-content-center align-items-center">
        <div className="">
          <h2 className="inovation_headings m-1">Events</h2>
        </div>
      </div>
      <div className="ml-2 mr-2 ">
        <div
          id="inovation_Events_contents"
          className={`row d-flex ${
            width === "xs" || width === "sm" ? "flex-sm-column pb-4" : ""
          }`}
        >
          {mainData.map((card, i) => {
            return <EventCard data={card} key={i} index={i} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default EDCEvents;
