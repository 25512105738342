import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import BuildingHero from "./section/hero/BuildingHero";
import BuildingDescription from "./section/description/BuildingDescription";
import BuildingSupportingSchemes from "./section/supportingschemes/BuildingSupportingSchemes";
import BuildingBreadcrumb from "./section/breadcrumbs/BuildingBreadcrumb";
function BuildingProgramSchemes() {
  return (
    <div>
      <SmallBanner title={"Capacity Building Programme"} />
      <BuildingBreadcrumb />
      <BuildingHero />
      <BuildingSupportingSchemes />
      <BuildingDescription />
    </div>
  );
}

export default BuildingProgramSchemes;
