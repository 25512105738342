import React from "react";

function IndAccProgramDescription() {
  return (
    <div className="auto-container inovation_text ">
      <p className="">
        Industries are facing various problems and challenges in their
        operations, and finding effective solutions can be a difficult task.
        However, by utilizing the expertise of academic and research
        institutions, industries can gain access to cutting-edge research and
        innovative solutions. This collaboration can be facilitated through this
        digital platform where industries can post their problems, and academic
        and research institutions can view these and offer their solutions. This
        platform allows for efficient communication and collaboration between
        the two spheres, leading to effective resolution of industry challenges
        and improvement of processes and systems. The combination of industry
        expertise and academic and research knowledge has the potential to drive
        innovation, improve competitiveness, and bring about positive change.
      </p>
      <p className="mb-5 mt-3">
        <b>This program will start on April 2023</b>
      </p>
    </div>
  );
}

export default IndAccProgramDescription;
