import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function NewProgramHero(props) {
  const width = useScreenBreakPoint();
  return (
    <div className="auto-container mt-5 mb-5">
      <div
        className={`d-flex ${
          width === "xs" || width === "sm"
            ? "flex-column align-items-center"
            : "align-items-center justify-content-between"
        }`}
      >
        <div className="col-md-7 col-sm-12 w-100 inovation_text">
          <p>{props.data.mainDescription}</p>
        </div>
        <div className="col-md-5 col-sm-12 w-100">
          <KiedImages
            className=" programs-hero-image w-100"
            image={props?.data?.image}
          />
        </div>
      </div>
    </div>
  );
}

export default NewProgramHero;
