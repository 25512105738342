import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";

function CampusPalakkad() {
  return (
    <div className="auto-container pb-5">
      <div className="pt-3">
        <div>
          <h2
            className="text_about  pl-2 ml-1 pb-4 mb-2"
            style={{ fontWeight: "bolder" }}
          >
            KALAMASSERY CAMPUS
           
          </h2>
        </div>
        <div className=" d-flex flex-row-reverse align-items-center">
          <div className="col-md-6 col-sm-12">
            <div className="">
              <p className="text_about campus-section">
                <p>1. Air Conditioned Training halls.</p>

                <p>2. Air Conditioned Computer Lab.</p>
                <p>3. Resource Centre.</p>
                <p>4. Hostel Facility.</p>
                <p>5. Board Room.</p>
                <p>5. Administration Room.</p>
              </p>
            </div>
            <a
              href="https://www.google.com/maps/place/Kerala+Institute+for+Entrepreneurship+Development/@10.0542199,76.3327828,15z/data=!4m5!3m4!1s0x0:0x4255e36bd9bfad1e!8m2!3d10.0542199!4d76.3327828"
              target="_blank"
            >
              Location: Rockwell Road, HMT Colony, PO, Kalamassery, Kochi,
              Kerala 683503
            </a>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center col-md-6 col-sm-12">
            <KiedImages className="w-100 " image="angamalycampus" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusPalakkad;
