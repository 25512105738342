import React from "react";

import NewProgramHero from "./sections/hero/newPrograms.hero";
import NewProgramOutcome from "./sections/ExpectedOutcome/newProgram.outcome";
import NewProgramDetails from "./sections/details/newprogram.details";
import NewProgramMentors from "./sections/mentors/newProgram.mentors";
import NewProgramSupportingPartners from "./sections/supportingPartners/newProgram.supporting";
import NewProgramFaq from "./sections/faq/newProgram.faq";
import NewProgramsReport from "./sections/reports/newprograms.reports";
import "./new.programs.styles.css";
import { json, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { inovationProgramData } from "../inovation/sections/inovationData";
import ProgramsBanner from "./sections/banner/programs.banner";
import NewProgrmGallery from "./sections/gallery/newProgram.gallery";
import AriseReport from "./sections/ariseReport/arise.report";
import { getProgramById } from "../../apis/publicApis";

function ProgramEnterpriseDetailPage() {
  const param = useParams();
  const [data, setData] = useState({});
  // useEffect(() => {
  // let selected = inovationProgramData.filter((item) => {
  //   console.log("item", typeof item.id);
  //   console.log(typeof param.id);
  //   return item.id == param.id;
  // });
  // setData(selected[0]);
  //   getProgramById(param.id).then((res) => {
  //     let item = res?.data;
  //     let prItem = {
  //       name: item?.name,
  //       location: item?.location,
  //       status: item?.status,
  //       image: item?.image,
  //       description: item?.description,
  //       objectives: item?.objectives,
  //       participants: item?.participants,
  //       eligibility: item?.eligibility,
  //       fees: item?.fees,
  //       faq: item?.faq,
  //       program_structure: item?.program_structure,
  //       date: item?.date,
  //       mentor: item?.mentors,
  //       outcome: item?.outcomes,
  //       partner: item?.partners,
  //       role_id: JSON.parse(item?.role_id)
  //     };
  //     setData(prItem);
  //   });
  // }, [param.id]);
  // console.log(data);
  // console.log(data.status);
  return (
    <div>
      <ProgramsBanner data={data} />
      <NewProgramHero data={data} />
      {/* <NewProgramOutcome data={data} />
      <NewProgramDetails data={data} /> */}
      {/* <NewProgramMentors data={data} /> */}
      <NewProgramSupportingPartners data={data} />
      {/* <NewProgramFaq data={data} /> */}
    </div>
  );
}

export default ProgramEnterpriseDetailPage;
