import React from "react";
import Carousel from "react-multi-carousel";
import "./single.carousal.style.css";
import useScreenBreakPoint from "../../../hooks/useScreenBreakPoint";
function SingleCarousal({ children, ...props }) {
  const width = useScreenBreakPoint();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
  };
  const CustomDot = ({ index, onClick, active, carouselState }) => {
    return (
      <li
        className={
          active
            ? "single-custom-dot single-custom-dot-active"
            : "single-custom-dot"
        }
        onClick={() => {
          onClick();
          props.changeImage && props.changeImage(index);
        }}
      ></li>
    );
  };
  return (
    <div className={width === "xs" ? "px-2" : "carousal-padding-single"}>
      <div style={{ position: "unset" }}>
        <Carousel
          arrows={false}
          additionalTransfrom={0}
          swipeable={false}
          draggable={false}
          responsive={responsive}
          showDots={true}
          infinite={true}
          rtl="false"
          customDot={<CustomDot />}
          autoPlay={true}
          pauseOnHover
          autoPlaySpeed={4000}
          slidesToSlide={1}
          keyBoardControl={true}
          customTransition="transform 500ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container single"
          dotListClass="custom-dot-list-style"
          itemClass="d-flex align-items-start justify-content-center px-1"
          itemAriaLabel={props.section}
        >
          {children}
        </Carousel>
      </div>
    </div>
  );
}

export default SingleCarousal;
