import React from 'react'
import SuccessCard from './sections/success.card/success.card'

function SuccessPage() {
  return (
    <div>
        <SuccessCard />
    </div>
  )
}

export default SuccessPage