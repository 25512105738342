import React from "react";
import Banner from "../../components/banner/banner";
import DirectoryBread from "./sections/breadcrumb/directory.breadcrumb";
import DirectorySearch from "./sections/search/directory.search";
import DirectoryProblems from "./sections/problemdropdown/directory.problems";
import "./directory.page.styles.css";

function DirectoryPage() {
  return (
    <div>
      <Banner title="Directory of Problems" image="academia-banner" />
      <DirectoryBread />
      <DirectorySearch />
      <DirectoryProblems />
    </div>
  );
}

export default DirectoryPage;
