import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import KiedButton from "../buttons/button/button.component";
import "./registration.card.style.css";
import { Link } from "react-router-dom";
import { useLoginState } from "../../utils/zustand";
import { useLangStore } from "../../utils/zustand";

function RegistrationCard(props) {
  let isAuthenticated = props.state.isAuthenticated;
  const {language} = useLangStore()
  const loginState = useLoginState();
  return (
    <div className="col-12 col-md-6 col-lg-6 p-5 w-100">
      <div className="p-1 w-100">
        <div
          className="reg-card-bg position-relative"
          onClick={() => {
            loginState.resetLogin();
            loginState.setNextUserType(props?.data?.slug);
          }}
        >
          <Link
            to={isAuthenticated ? "" : props.data.link}
            className={`${isAuthenticated ? "" : "pointer"}`}
          >
            {/* <div>
              <KiedImages
                className={`w-100 regcard-img ${
                  isAuthenticated ? "" : "pointer"
                }`}
                image={
                  props.chess
                    ? props.index % 2 === 1
                      ? props.data.image
                      : props.data.image
                    : props.data.image
                }
              />
            </div> */}
            {/* <div
            className={` position-absolute top-0  w-100 h-100  ${
              props.chess
                ? props.index % 2 === 1
                  ? "overlay-color"
                  : "overlay-color"
                : "overlay-color"
            }`}
          ></div> */}
            <div
              className={`card-title-head position-absolute py-2 px-3 top-0 w-100 d-flex justify-content-between ${
                isAuthenticated ? "" : "pointer"
              }`}
            >
              <div
                className={`reg-card-datas title ${
                  props.chess
                    ? props.index % 2 === 1
                      ? ""
                      : ""
                    : ""
                }`}
              >
                {language !== "ma" ? props.data[language] ?? props.data.label  : props.data.label}
                {/* {props.data.label} */}
              </div>
              <div>
              <KiedImages
                className={`w-100 regcard-img ${
                  isAuthenticated ? "" : "pointer"
                }`}
                image={
                  props.chess
                    ? props.index % 2 === 1
                      ? props.data.image
                      : props.data.image
                    : props.data.image
                }
              />
            </div>
              {/* <div
							className={`reg-card-datas desc ${
								props.chess ? (props.index % 2 === 1 ? "" : "text-white") : "text-white"
							}`}
						>
							{props.data.description}
						</div> */}
              {/* <div className="d-flex">
              <div className="pe-2 mt-2">
                <KiedButton orange big inverted content={"Explore"} />
              </div>
              <div className="pe-2 mt-2">
                <KiedButton secondary big content={"Register"} />
              </div>
            </div> */}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RegistrationCard;
