import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";

import { useNavigate } from "react-router-dom";
function ProgramsBanner(props) {
  const history = useNavigate();
  // const handleregister = () => {
  //   sessionStorage.setItem("next_path", "/department/inovation/registration");
  //   history("/department/inovation/registration");
  // };
console.log (props?.data?.status)
  return (
    <div
      id="Banner_outer"
      className="banner_image banner_big d-flex justify-content-center"
    >
      <div
        id="Banner_inner"
        className="d-flex  container bannerContainer  head-container justify-content-between"
      >
        <div
          id="Banner_text"
          className="d-flex flex-column justify-content-end"
          // data-aos="fade-right"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <h2 className="text_banner more_link">{props.data.title}</h2>

          <div className="more_link">
            <div className="">
              <span className="prog-banner">{props.data.participants}</span>
            </div>
          </div>
          {/* <Link to="/department/inovation/registration"> */}
          {props?.data?.status === "Upcoming" && (
            <div className="more_link">
              <KiedButton
                // onClick={() => handleregister()}
                content={
                  <>
                    <div className="d-flex">
                      <div className="d-flex align-items-center m-2">
                        <a
                          className="banner-link"
                          href={props.data.titleLink}
                          target="_blank"
                        >
                          Click Here To Apply
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                rounded
                big
              />

              {/* </Link> */}
            </div>
          )}
        </div>
        <div
          id="Banner_image"
          className="aboutpage_images  d-none d-md-block"
          // data-aos="fade-left"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <KiedImages
            className="program-banner-image d-none d-md-block"
            image="program-banner"
          />
        </div>
      </div>
    </div>
  );
}

export default ProgramsBanner;
