export const IndAcSchemesData = [
  {
    id: 1,
    title: "Capacity Building Programme",
    description:
      "Government has accorded high priority for promoting technology infusion in industry and start-ups. As universities become more involved in promoting  economic development.",
    link: "/BuildingProgramSchemes",
    type: "Completed",
  },
  {
    id: 2,
    title: "Assistance for Export Promotion of Existing MSMEs",
    description:
      "The schemes aims at providing incentive and hand holding to increase export volumes of MSMEs which exports their goods and also to attract existing MSMEs to start exporting.",
    link: "/AssistancePromotion",
    type: "Completed",
  },
  {
    id: 3,
    title: "Local Market Infrastructure for MSMEs",
    description:
      "This schemes has been formulated to support the market for the commodities which arev sold within the local geographical limits.",
    link: "/MarketInfrastructure",
    type: "Completed",
  },
  // {
  //   id: 2,
  //   title: "Schemes two",
  //   description:
  //     "Lorem ipsum is a placeholder text commonly used to demonstrate.",
  //   link: "/schemes",
  // },
  // {
  //   id: 3,
  //   title: "Schemes three",
  //   description:
  //     "Lorem ipsum is a placeholder text commonly used to demonstrate.",
  //   link: "/schemes",
  // },
  // {
  //   id: 4,
  //   title: "Schemes Four",
  //   description:
  //     "Lorem ipsum is a placeholder text commonly used to demonstrate.",
  //   link: "/schemes",
  // },
];

export const IndAcProgramData = [
  {
    id: 1,
    image: "matchmaking",
    
    status: "Upcoming",
    title: "INDUSTRY PROBLEM - SOLUTION LINKING",
    titleLink: "/department/academy-collaboration/program",
    location: "Online",
    startDate: "April 2023",
    // endDate: "",
    // startTime: "10.00 AM",
    participants:
      "Academic institutions, Industry Associations & Entrepreneurs ",
    description:
      "A digital solution connecting Industries and Academic and Research Institutions to address challenges faced by the former. The industries can post their problems and receive answers from the Academic and Research Institutions, facilitating their resolution of challenges.",
    link: "/department/academy-collaboration/program",
  },
  // {
  //   id: 2,
  //   title: "JOINT RESEARCH SERVICES",
  //   titleLink: "#",
  //   status: "Completed",
  //   location: "Offline",
  //   startDate: "August-09-2022",
  //   endDate: "September-02-2022",
  //   startTime: "10.00 AM",
  //   participants: "College Students",
  //   description:
  //     "Enterprise Development Center (EDC) is a place which empowers innovative entrepreneurs to Plan, Start, Sustain, Revive and Grow their business.",
  //   link: "#",
  //   image: "internship",
  // },
  {
    id: 3,
    title: " INTERNSHIP CONNECT",
    titleLink: "/internship-details",
    
    status: "Upcoming",
    location: "Online",
    startDate: "April 2023",
    // endDate: "",
    // startTime: "10.00 AM",
    participants: "College Students",
    image: "internship",
    description:
      "Entrepreneurial Culture amongst youth and equip them with the skills, technique and confidence to act as tourch-bearers of enterprise for the new generation.",
    link: "/internship-details",
  },
  // {
  //   id: 4,
  //   title: "PROVIDING TESTING FACILITIES",
  //   titleLink: "#",
  //   location: "EDC Ground",
  //   startDate: "September-06-2022",
  //   endDate: "September-08-2022",
  //   startTime: "10.00 AM",
  //   participants: "College Students",
  //   description:
  //     "Entrepreneurial Culture amongst youth and equip them with thec skills, technique and confidence to act as tourch-bearers of enterprise for the new generation.",
  //   link: "",
  // },
];

export const IndAcEventsData = [
  {
    id: 1,
    title: "Skill Gap Assessment",
    location: "Online",
    startDate: "29-05-2021",
    // startTime: "10.00 AM",
    link: "/InternshipEventDetails/",
    status : "Completed",
    mainDescription:
      "As part of the employment generation and skill development in Kalamassery constituency a meeting was convened on 29-05-2021 by Hon’ble Minister for Industries, Commerce and Law. Based on the decision of the meeting it was decided to convene a meeting with Industry and Association representatives to discuss the skill gap assessment in the sector and the meeting was conducted on program had attended the meeting",
    cardDescription:
      "Sri. M. A. Ali, Secretary, KSSIA, Sri. Anise N.A Secretary, Rubber Park, KINFRA, Sri. Nisam O.A, President, Kalamassery Development Plot Association, Sri. Ahammad Unni, EDMIECS, Jayan Kerala Clay, Traditional Pottery and ceramics Association,Sri. Abdul Jabbar M M, All Kerala PVC Manufacturers Association, Sri.Ramakrishnan Potti, Paint Manufacturers Association, Smt. Lekha Balachandran, Ressitech Electricals, IDA, Erumathala, Aluva, Sri. Tom Thomas, Vice President,All India Food Processors Association, Sri. Thomas, Secretary, Edayar Small Scale  Industrial Association, Sri. Varghese J Alukkal, Secretary, All Kerala Cement Bricks Association, Sri. Suresh N, Shakthiman Pipes, All Kerala PVC     Manufacturers Association were attended the meeting as industry and association representatives. Sri. Biju P. Abraham, General Manager, DIC, Ernakulam, Sri. Sarath V. Raj, CEO, KIED, Sri V. A. Shamsudeen, NITTTR, (ECK) Coordinator and Sri. Jaikumar C. Pillai, the officials of various Departments associated with the program also attended the meeting. ",
    duration: "",
    image: "internshipevent1",
    CategoryOfParticipants: "",
    NoOfParticipants: "",
    listheading: "",
    listitem: [],
    listingImage: "",
    images: [
      {
        id: 1,
        image: "internshipevent1",
      },
    ],
  },
  {
    id: 2,
    title: "Webinar on Lead Free Stabilizers ",
    location: "Kochi",
    startDate: " 09/07/2021",
    // startTime: "10.00 AM",
    status : "Completed",
    link: "/InternshipEventDetails/",
    mainDescription:
      "The webinar on Lead free stabilizers for manufacturing of UPVC Pipes & Fittings was conducted in online mode using ZOOM platform for UPVC distributer & manufactures in association with CIPET, Kochi on 09/07/2021.",
    cardDescription: "",
    duration: "1 day",
    CategoryOfParticipants: "PVC Manufactures & distributes",
    NoOfParticipants: "92",
    listheading: "Program Agenda",
    listitem: [
      "Welcome Speech – Sri. Sarath V. Raj, CEO & Executive Director, KIED",
      "Inaugural Address – Sri. Shabeer Mohamed, Deputy Director, Directorate of Industries and Commerce, Trivandrum",
      "Vote of Thanks – Sri. Rahul Shah, Staff, KIED",
      "Session on Lead free stabilizers - Sri.Nilesh Nandve, Sr.Manager - Technical Service, Goldstab Organics Pvt Ltd",
      "Testing of UPVC Pipes & Fittings - Sri.Rajesh K A, Manager & Centre in Charge, CIPET, Kochi",
      "Revision of IS Standards & Licensing - Sri.Dhinesh Rajagopalan L, Scientist C,BIS, Kochi",
      "Felicitation - Sri.N Suresh, PVC Manufacturers association",
      "Vote of Thanks - Dr.Neetha John, CIPET, Kochi",
    ],
    listingImage: "",
    images: [
      {
        id: 1,
        image: "internshipevent2",
      },
      {
        id: 2,
        image: "internshipevent3",
      },
      {
        id: 3,
        image: "internshipevent4",
      },
      {
        id: 4,
        image: "internshipevent5",
      },
    ],
  },
  // {
  //   id: 3,
  //   title: "EVENT NAME",
  //   location: "EDC Ground",
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
  // {
  //   id: 4,
  //   title: "EVENT NAME",
  //   location: "EDC Ground",
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
  // {
  //   id: 5,
  //   title: "EVENT NAME",
  //   location: "EDC Ground",
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
  // {
  //   id: 6,
  //   title: "EVENT NAME",
  //   location: "EDC Ground",
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
  // {
  //   id: 7,
  //   title: "EVENT NAME",
  //   location: "EDC Ground",
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
  // {
  //   id: 8,
  //   title: "EVENT NAME",
  //   location: "EDC Ground",
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
  // {
  //   id: 9,
  //   title: "EVENT NAME",
  //   location: "EDC Ground",
  //   startDate: "Sept-06-2022",
  //   startTime: "10.00 AM",
  //   link: "/events",
  // },
];
