import React from "react";
import ActivitiesSection from "./sections/activities/activities.section";
import FaqSection from "./sections/faq/faq.section";

import ServiceSection from "./sections/services/service.section";
import { useOutletContext } from "react-router-dom";
import HeroSection from "./sections/hero/hero.section";
import NewsSection from "./sections/news/news.section";
import RegistrationSection from "./sections/registration/registration.section";
import TestimonialSection from "./sections/testimonial/testimonial.section";
import LoaderComponent from "../../components/loader/loader.component";
import { useState } from "react";
import { useEffect } from "react";
import DummyFlipcard from "./sections/flipcard/dummyflipcard";
import NewTestimonial from "./sections/testimonial/newtestimonial";
import EventsCarousel from "../../components/eventscarousel/eventscarousel";
import { getNews } from "../../apis/publicApis";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useLoginState } from "../../utils/zustand";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLangStore } from "../../utils/zustand";
import EventsRibbon from "../../components/eventsRibbon/eventscarousel";


function HomePage() {
  const {language} = useLangStore()
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  let props = useOutletContext();
  const needLogin = sessionStorage.getItem("loginRequired") || null;
  const loginState = useLoginState();
  console.log(loginState, needLogin);
  const loginRef = useRef(null);

  const [data, setData] = useState([]);
  let navParam = useParams();
  const login = useRef();
  useEffect(() => {
    getNews()
      .then((res) => {
        let prdata = [];
        res?.data?.forEach((item) => {
          console.log(item);
          let prItem = {
            date: item?.date,
            photo: item?.photo,
            designation : item?.designation,
            title : item?.title,
            id: item?.id
          };
          prdata.push(prItem);
        });
        setData(prdata);
        setIsActive(false);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    if (loginState?.loginCount) {
      login.current.scrollIntoView({ behavior: "smooth" });
    }
    console.log(needLogin);
  }, [loginState?.loginCount]);

  useEffect(() => {
    let n = localStorage.getItem("visited");
    console.log(n);
    if (!n) {
      Swal.fire({
        title: "Center for Innovation",
        text: "Registrations for Business Growth Program is open now. Click 'Continue' to register",

        showCancelButton: true,
        confirmButtonColor: "#ffa93c",
        cancelButtonColor: "#a1513b",
        cancelButtonText: "Go Home",
        confirmButtonText: "Continue",
        backdrop: `
    rgba(247,159,115,.4)
    no-repeat
  `,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/newprograms/1");
          localStorage.setItem("visited", 1);
        } else {
          localStorage.setItem("visited", 1);
        }
      });
    }
  }, []);
  return (
    <div>
      <LoaderComponent open={isActive} {...props} />
      <HeroSection {...props} />
     
      {/* <div className="home_events_carousel">
        <EventsRibbon data={data} />
      </div> */}
      <div className="py-1  home_events_carousel">
        <EventsCarousel data={data} />
      </div>
      {/* <ServiceSection /> */}
      {/* <DummyFlipcard heading={"Departments"} {...props} /> */}
      <div ref={login}>
        {/* <ActivitiesSection /> */}
        <RegistrationSection
        language = {language}
          heading={language !== "label" ? "നിങ്ങളുടെ വിഭാഗം തിരഞ്ഞെടുക്കുക":"Select your Category "}
          nextPath={"login"}
          {...props}
          ref={loginRef}
        />
      </div>

      <NewsSection heading={language !== "label" ? "വാർത്തകൾ":"Latest News"} {...props} data={data} language = {language === "label" ? "ma" : "en"}/>
      {/* <NewTestimonial /> */}
      <TestimonialSection {...props} language = {language === "label" ? "ma" : "en"}/>
      <FaqSection heading={language !== "label" ? "ചോദ്യങ്ങൾ":"Frequently Asked Questions"} language = {language === "label" ? "ma" : "en"} {...props} />
    </div>
  );
}

export default HomePage;
