import React from "react";

import KiedIcons from "../../../../assets/icons/kied.icons.component";

import "../../new.programs.styles.css";

function NewProgramsReport(props) {
  return (
    <>
      <div className="greyBackground">
        <div className="auto-container pb-5 pt-5">
          <div
            id="InovationEventssHeading"
            className="d-flex justify-content-center align-items-center"
          >
            <div className="">
              <h2 className="inovation_headings m-1">
                {props?.data?.reportHeading}
              </h2>
            </div>
          </div>
          <div className="mx-2">
            <div className="report-border">
              <div className="report-heading ">
                <b>{props?.data?.subHeading1?.heading}</b>
              </div>
              {/* <div className="report-description ">
              {props?.data?.subHeading1?.subheading}
            </div> */}
              <div className="" style={{ overflow: "auto" }}>
                <table className="w-100">
                  {props?.data?.subHeading1?.mainData.length > 0 ? (
                    <tr className="">
                      <th className="">No</th>
                      <th className="">Date</th>
                      <th className="">Days</th>
                      <th className="">Hrs</th>
                      <th className="">Timing</th>
                      <th className="">Session Name</th>
                    </tr>
                  ) : (
                    "No Data Available"
                  )}

                  {props?.data?.subHeading1?.mainData?.map((card, i) => {
                    return (
                      <tr>
                        <td className="m-2">{card.id}</td>
                        <td className="m-2">{card.date}</td>
                        <td className="m-2">{card.days}</td>
                        <td className="m-2">{card.hrs}</td>
                        <td className="m-2">{card.timing}</td>
                        <td className="m-2">{card.name}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>

            {props?.data?.subHeading3 && (
              <>
                <div className="report-border">
                  <div className="report-heading ">
                    <b>{props?.data?.subHeading3?.heading}</b>
                  </div>
                  <div className="detail-description">
                    <ul>
                      {props?.data?.subHeading3?.description?.map((card, i) => {
                        return (
                          <li>
                            <span>{card?.point}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <hr />
              </>
            )}

            {props?.data?.subHeading4 && (
              <>
                <div className="report-border">
                  <div className="report-heading ">
                    <b>{props?.data?.subHeading4?.heading}</b>
                  </div>
                  <div className="detail-description">
                    <ul>
                      {props?.data?.subHeading4?.description?.map((card, i) => {
                        return (
                          <li>
                            <span>{card?.point}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* <div className="d-flex align-items-center mt-4 ml-3">
          <div className="d-flex align-items-center">
            <a
              href={`${process.env.PUBLIC_URL}/files/${props?.data?.fileLink}`}
              target="_blank"
              download
              rel="noreferrer"
              className="d-flex align-items-center download-btn"
            >
              <span className="d-flex ">
                <KiedIcons icon="Download-btn" className="mx-2" />
              </span>
              <span className=" ">Download</span>
            </a>
            <div>
              <span>current stages report </span>
            </div>
          </div>
        </div> */}
        </div>
      </div>
      {/* //////////////////////////////////////////// */}
      <div className="">
        <div className="auto-container pb-5 pt-5">
          <div
            id="InovationEventssHeading"
            className="d-flex justify-content-center align-items-center"
          >
            <div className="">
              <h2 className="inovation_headings m-1">
                {props?.data?.reportHeading2}
              </h2>
            </div>
          </div>
          <div className="mx-2">
            <div className="greyBorder">
              <div className="report-heading ">
                <b>{props?.data?.subHeading2?.heading}</b>
              </div>
              {/* <div className="report-description ">
              {props?.data?.subHeading1?.subheading}
            </div> */}
              <div className="" style={{ overflow: "auto" }}>
                <table className="w-100">
                  {props?.data?.subHeading2?.mainData.length > 0 ? (
                    <tr className="">
                      <th className="">No</th>
                      <th className="">Name</th>
                      <th className="">Designation</th>
                    </tr>
                  ) : (
                    "No Data Available"
                  )}

                  {props?.data?.subHeading2?.mainData?.map((card, i) => {
                    return (
                      <tr>
                        <td className="m-2">{card.id}</td>
                        <td className="m-2">{card.name}</td>
                        <td className="m-2">{card.designation}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>

            {props?.data?.subHeading3 && (
              <>
                <div className="report-border">
                  <div className="report-heading ">
                    <b>{props?.data?.subHeading3?.heading}</b>
                  </div>
                  <div className="detail-description">
                    <ul>
                      {props?.data?.subHeading3?.description?.map((card, i) => {
                        return (
                          <li>
                            <span>{card?.point}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <hr />
              </>
            )}

            {props?.data?.subHeading4 && (
              <>
                <div className="report-border">
                  <div className="report-heading ">
                    <b>{props?.data?.subHeading4?.heading}</b>
                  </div>
                  <div className="detail-description">
                    <ul>
                      {props?.data?.subHeading4?.description?.map((card, i) => {
                        return (
                          <li>
                            <span>{card?.point}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* <div className="d-flex align-items-center mt-4 ml-3">
          <div className="d-flex align-items-center">
            <a
              href={`${process.env.PUBLIC_URL}/files/${props?.data?.fileLink}`}
              target="_blank"
              download
              rel="noreferrer"
              className="d-flex align-items-center download-btn"
            >
              <span className="d-flex ">
                <KiedIcons icon="Download-btn" className="mx-2" />
              </span>
              <span className=" ">Download</span>
            </a>
            <div>
              <span>current stages report </span>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default NewProgramsReport;
