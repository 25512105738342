import React from "react";
import "./news.page.styles.css";
import NewsCardSection from "./sections/newsCard/news.card.section";
import Banner from "../../components/banner/banner";

function NewsPage() {
  return (
    <div>
      <Banner
        image="academia-banner"
        title="Latest News"
        subtitle="More trending up-to-date news. Hope you'll enjoy."
      />
      <NewsCardSection />
    </div>
  );
}

export default NewsPage;
