import React from "react";
import DashInfo from "../../../../components/dashinfo/dashInfo";
import KiedButton from "../../../../components/buttons/button/button.component";

function UniversityDataForm({ handleEdit, handlepopupEdit, ...props }) {
  return (
    <>
      <div>
        <div className="">
          <DashInfo title="Gender" value={props.formdata.gender ?? ""} />
          <DashInfo title="Date of Birth " value={props.formdata.dob ?? ""} />
          {/* <DashInfo title="Age" value={props.formdata.age ?? ""} /> */}
          <DashInfo title="Address" value={props.formdata.address ?? ""} />
          <DashInfo title="District" value={props.formdata.district} />
          <DashInfo
            title="Highest Qualification"
            value={props.formdata.highest_educational_qualification}
          />
          <DashInfo
            title="Do you Own a Business"
            value={props.formdata.do_you_own_business == "1" ? "Yes" : "No"}
          />
          <DashInfo
            title="Name of your Company/ Business"
            value={props.formdata.company_name ?? ""}
          />
          <DashInfo
            title="Stage of your business"
            value={props.formdata.stage_of_business}
          />
          <DashInfo
            title="How long have you been in this business"
            value={props.formdata.how_long_business}
          />
          <DashInfo
            title="Specify the Product/ Service"
            value={props.formdata.product_type ?? ""}
          />
          <DashInfo
            title="Mode of business"
            value={
              props.formdata.mode_of_business == "Other"
                ? props.formdata.other_mode_business
                : props.formdata?.mode_of_business
            }
          />
          {/* <DashInfo title="Mode of business" value={props.formdata.others} /> */}
          {/* {props.formdata.mode_of_business === "Other" && (
            <DashInfo
              title="Please specify the mode of business"
              value={props.formdata.other_mode_business}
            />
          )} */}

          {/* {error.proof && (
            <p id="proof" className="ml-3 error">
              Unsupported file type! Please select a .pdf or .docx file
            </p>
          )} */}
          <br />

          <div className="d-flex justify-content-center pt-5 pb-5">
            <KiedButton
              onClick={() => handleEdit()}
              primary
              rounded
              className="mx-3"
              type="submit"
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Edit
                </div>
              }
            />
            <KiedButton
              onClick={() => handlepopupEdit()}
              primary
              className="mx-3"
              rounded
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Home
                </div>
              }
            />
          </div>
        </div>
      </div>{" "}
    </>
  );
}

export default UniversityDataForm;
