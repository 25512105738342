// import React from 'react'

// function AssistanceCanapply() {
//   return (
//     <div>AssistanceCanapply</div>
//   )
// }

// export default AssistanceCanapply

import React from "react";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function AssistanceCanapply(props) {
  let width = useScreenBreakPoint();
  return (
    // <div className="auto-container pt-5 pb-3">
    //   <div className="d-flex flex-column align-items-start justify-content-center">
    //     <div className="schemes_heading mb-5">How to apply</div>
    //     <div className="Schemes_heading_description ">
    //       The application can be submitted online along with the documents as
    //       per the checklist using the link given at the bottom. The application
    //       will be processed by the Sanctioning Authority and the assistance will
    //       be sanctioned based on merit.
    //     </div>
    //   </div>
    //   <hr />
    //   <div className="schemes_heading mb-3 mt-5">Sanctioning Authority</div>
    //   <ul className="ml-4 Schemes_heading_description pt-3 mb-5">
    //     <li>
    //       For Startup Support - General Manager, District Industries Centre
    //     </li>
    //     <li>
    //       {" "}
    //       For Investment Support - District Level Committee consisting of the
    //       following Members;.
    //       <ul className="sub_list">
    //         <li>District Collector (Chairman)</li>
    //         <li>Lead District Manager</li>
    //         <li>Representative of Finance Department in Government</li>
    //         <li>District Manager, KFC</li>
    //         <li>Representative of KSSIA District Committee</li>
    //         <li>
    //           General Manager, District Industries Centre (Member Secretary)
    //         </li>
    //       </ul>
    //     </li>
    //   </ul>
    //
    //   <hr />
    // </div>

    <div style={{ backgroundColor: "#f2f3f4 " }} className="pb-5">
      <div
        className={` row head-container  d-flex justify-content-between ${
          width === "xs" || width === "sm" ? "sml pt-5 " : "lr mt-5 pt-5"
        } `}
      >
        <div className="col-lg-6 col-md-6 col-sm-12  ">
          <div
            className={`schemes-listing-card  ${
              width === "xs" || width === "sm" ? "sml p-3" : "lr"
            }`}
          >
            <div
              className={`schemes_heading text-dark  ${
                width === "xs" || width === "sm"
                  ? "sml pt-2 "
                  : "lr mb-3 mt-5 pl-3"
              } `}
            >
              {" "}
              Obligations of the applicant
            </div>
            <div className="px-3">
              <ul
                className={`Schemes_heading_description  ${
                  width === "xs" || width === "sm"
                    ? "sml pt-2 p-0"
                    : "pt-3 mb-5 ml-4"
                } `}
              >
                <li>
                  <p>
                    The applicant shell provide all required details and declare
                    such information as to be true.
                  </p>
                </li>
                <li>
                  <p>
                    The applicant shall provide clarifications or further
                    details sought by the recommending/sanctioning authority.
                  </p>
                </li>
                <li>
                  <p>
                    The applicant shall allow inspection or verification
                    regarding any details mentioned in the application including
                    plant and machinery and all other assets if so required boy
                    the recommending/ sanctioning authority.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    The applicant shall produce originals of any important
                    documents if so required by the recommending/sanctioning
                    authority for verification.
                  </p>{" "}
                </li>
                <li>
                  <p>
                    The applicant shall execute requisite legal agreement online
                    and if required on paper as and when the proposal is
                    approved for implementation and furnish the signed hard copy
                    subsequently via post or otherwise.
                  </p>
                </li>
                <li>
                  <p>
                    The applicant shall utilize the amount received only in the
                    matter agreed upon.
                  </p>
                </li>
                <li>
                  <p>
                    The applicant shall operate the unit as stipulated in the
                    agreement in which the quantum of support received failing
                    which the assistance shall be resumed by restoring the
                    provisions of the Kerala Revenue Recovery Act.
                  </p>
                </li>
                <li>
                  <p>
                    1$ The appl’icant unit after availing the assistance shall
                    furnish copies of balance sheets, valid license from Local
                    Self Government, electricity bills, performance particulars
                    in the prescribed proforma etc every year till the
                    stipulated period before 31' t December of the next
                    financial year, as proof of their functioning to the
                    notified authority.
                  </p>
                </li>
                <li>
                  <p>
                    The applicant shall submit Annual export returns to the
                    General Manager, DIC concerned for 5 years from the date of
                    availing assistance.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12  ">
          <div
            className={`schemes-listing-card  ${
              width === "xs" || width === "sm" ? "sml p-3 mt-3" : "lr"
            }`}
          >
            <div
              className={`schemes_heading text-dark  ${
                width === "xs" || width === "sm"
                  ? "sml pt-2 "
                  : "lr mb-3 mt-5 pl-3"
              } `}
            >
              {" "}
              General provisions
            </div>
            <div className="px-3">
              <ul
                className={`Schemes_heading_description schemes-list  ${
                  width === "xs" || width === "sm"
                    ? "sml pt-2 p-0 text-left "
                    : "pt-3 mb-5 ml-4"
                } `}
              >
                <li>
                  <p>
                    The assistance under the scheme shall be released to the
                    eligible entrepreneur by bank on a pro-rata basis.
                  </p>
                </li>
                <li>
                  <p>
                    All applications for assistance under the scheme shall be
                    submitted by the applicant and produce the originals along
                    with details before the Recommending Authority.
                  </p>
                </li>
                <li>
                  <p>
                    The Officer while accepting the application form should
                    examine whether all necessary details have been fiimished.
                    If any information is found inadequate, the same should be
                    intimated to the applicant init both electronically and
                    otherwise and grant 10 days time to rectify the defects.
                  </p>
                </li>
                <li>
                  <p>
                    The assistance shall be disbursed by sanctioning authority
                    through the financing institution which has financed the
                    unit on their executing an agreement with the sanctifying
                    authority in the prescribed format.
                  </p>{" "}
                </li>
                <li>
                  <p>
                    Industrial units which receive the assistance will be under
                    obligation to remain working continuously for five years
                    from the date of receipt of grant. However, it is necessary
                    that the unit shall be a working one as on date of release
                    of the assistance.
                  </p>
                </li>
                <li>
                  <p>
                    The unit shall not have availed any grant assistance earlier
                    under any scheme of Government of India or Government of
                    Kerala or Loal Self Goveimnent Department, for the same
                    purpose.
                  </p>
                </li>
                <li>
                  <p>
                    The Director of Industries and Commerce shall have the full
                    authority to prepare application forms, form of agreement
                    and any other formats required for the proper implementation
                    of the scheme and to make sub- rules, check list, if any,
                    required.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssistanceCanapply;
