// import React from 'react'

// function YuvaTeamCard() {
//   return (
//     <div>

//     </div>
//   )
// }

// export default YuvaTeamCard
import React from "react";
import "./YuvaTeamCard.css";
import KiedImages from "../../assets/images/kied.images.component";

function YuvaTeamCard(props) {
  return (
    <div className="col-md-3 col-sm-12 p-0 mt-5 ">
      <div className="mentor-card">
        <div className="col-sm-12 mb-4">
          <div className="mentor-card-inner">
            <div className="  d-flex flex-column align-items-center  ">
              <div className="yuva-team-name">
                <KiedImages
                  className="team_image"
                  image={props.data.image}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className=" d-flex flex-column justify-content-between  align-items-center mt-3">
                <div>{props.data.screenShot}</div>
                <div className=" mentor-names mt-2">{props.data.name}</div>
                <div className=" mentor-designation pt-2">
                  {props.data.designation}
                </div>
                <div className="mentor-topics pt-2">
                  Topics: {props.data.topic}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YuvaTeamCard;
