
import YuvaTeamCard from "../../../../components/yuva-team/YuvaTeamCard";
import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import { Link } from "react-router-dom";
import Masonry from "react-responsive-masonry";
import { useState } from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { EDCProgramData } from "../../../Entrepreneurship_Development_Club/edc.page.data";
import "../../EdcNewProgram.css";
function EdcNewProgramDone(props) {
  let width = useScreenBreakPoint();
  let navParam = useParams();
  const inspiration = useRef();
  const immersion = useRef();
  const assesment = useRef();
  const establish = useRef();

  const [active, setActive] = useState("Mentoring");

  // useEffect(() => {
  //   switch (navParam.id) {
  //     case "inspiration":
  //       inspiration.current.scrollIntoView({ behavior: "smooth" });
  //       break;
  //     case "immersion":
  //       immersion.current.scrollIntoView({ behavior: "smooth" });
  //       break;
  //     case "assesment":
  //       assesment.current.scrollIntoView({ behavior: "smooth" });
  //       break;
  //     case "establish":
  //       establish.current.scrollIntoView({ behavior: "smooth" });
  //       break;
  //     default:
  //       window.scrollTo({
  //         top: 0,
  //         left: 0,
  //         behavior: "smooth", // Optional if you want to skip the scrolling animation
  //       });
  //   }
  // }, [navParam.id]);
  // const history = useNavigate();
  // const handleregister = () => {
  //   sessionStorage.setItem(
  //     "next_path",
  //     "/department/inovation/ARISERegistration"
  //   );
  //   history("/department/inovation/ARISERegistration");
  // };
  function handleClicka() {
    inspiration.current.scrollIntoView({ behavior: "smooth" });
    setActive("Mentoring");
  }
  function handleClickb() {
    immersion.current.scrollIntoView({ behavior: "smooth" });
    setActive("offline");
  }
  function handleClickc() {
    assesment.current.scrollIntoView({ behavior: "smooth" });
    setActive("yuva");
  }
  function handleClickd() {
    establish.current.scrollIntoView({ behavior: "smooth" });
    setActive("program");
  }
  return (
    <>
      <div className="">
        <div>
          <div>
            <div className="row auto-container">
              <div className="w-100">
                <div
                  className={`d-flex justify-content-between button-section ${
                    width === "xs" || width === "sm" ? "flex-wrap" : ""
                  } `}
                >
                  <button
                    className={
                      active === "Mentoring" ? "activeh " : "Scrolling-button "
                    }
                    onClick={handleClicka}
                  >
                    Online Mentoring
                  </button>
                  <button
                    className={
                      active === "offline" ? "activeh" : "Scrolling-button"
                    }
                    onClick={handleClickb}
                  >
                    Offline Mentoring & Trail Pitch
                  </button>
                  <button
                    className={
                      active === "yuva" ? "activeh" : "Scrolling-button"
                    }
                    onClick={handleClickc}
                  >
                    Yuva Bootcamp State Level Conclave
                  </button>
                  <button
                    className={
                      active === "program" ? "activeh" : "Scrolling-button"
                    }
                    onClick={handleClickd}
                  >
                    Yuva Bootcamp Program Details
                  </button>
                </div>
                <div className="">
                  <div>
                    <div className="auto-container pb-5 ">
                      <div
                        id="InovationEventssHeading"
                        className="d-flex justify-content-left align-items-center mb-2"
                      >
                        <div ref={inspiration}></div>
                        <div
                          id="InovationEventssHeading"
                          className="d-flex justify-content-center align-items-center"
                        >
                          <div className="report-heading  mb-4">
                            <b> Online Mentoring</b>
                          </div>
                        </div>
                      </div>
                      <p className="teams-text mb-3">
                        Our experts mentors who took the online mentoring
                        section. Online mentoring sessions were imparted to 30
                        selected teams. Further 27 teams (164 students)
                        submitted their pitch deck for the final event.
                      </p>
                      <div>
                        <div id="inovation_Events_contents" className="row ">
                          {props?.data?.subHeading2?.yuvaTeam.map((card, i) => {
                            return (
                              <YuvaTeamCard data={card} key={i} index={i} />
                            );
                          })}
                        </div>
                      </div>
                      <div className="screenshot">
                        <KiedImages
                          image={props.data.screenShot}
                          // style={{
                          //   width: "50%",
                          //   height: "50%",
                          //   display: "block",
                          // }}
                          className="screenshot-img"
                        />
                        <p className="image-paragraph">
                          Kerala Angle Network (KAN) took the session and 24
                          teams attented the event on 8 july 2022
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---------------------------------------- */}
          <div className="greyBackground pt-5">
            <div ref={immersion}></div>

            <div className="auto-container ">
              <div
                id="InovationEventssHeading"
                className="d-flex justify-content-center align-items-center"
              >
                <div className="">
                  <h2 className="inovation_headings m-1">
                    {props?.data?.reportHeadin}
                  </h2>
                </div>
              </div>
              <div className="mx-2">
                {props?.data?.subHeading2 && (
                  <>
                    <div className="report-border">
                      <div className="d-flex align-items-start">
                        <div className="col-md-7 col-sm-12  ">
                          <div className="d-flex align-items-center">
                            <div className="report-heading  mb-5  pt-5">
                              <b> {props?.data?.subHeading2?.heading}</b>
                            </div>
                            {/* <div className="report-status-card mx-3 my-3">
                        <b>{props?.data?.subHeading2?.status}</b>
                      </div> */}
                          </div>
                          <div className=" yuva-details ">
                            <ul>
                              {props?.data?.subHeading2?.description?.map(
                                (card, i) => {
                                  return (
                                    <li>
                                      <span>{card?.point}</span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                        {/* /////////////////////////////////// */}
                        {props?.data?.subHeading2?.status === "Completed" && (
                          <>
                            <div className="col-md-5 col-sm-12 d-none d-md-block pt-3 pb-3 mt-5 pt-4">
                              <Masonry columnsCount={2} gutter="10px">
                                {props?.data?.subHeading2?.gallery?.map(
                                  (image, i) => (
                                    <KiedImages
                                      image={image.image}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "block",
                                      }}
                                    />
                                  )
                                )}
                              </Masonry>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* --------------------------------------------------------------------------- */}
            <div ref={assesment}></div>
            <div className="auto-container pb-5 pt-5">
              <div
                id="InovationEventssHeading"
                className="d-flex justify-content-center align-items-center"
              >
                <div className="">
                  <h2 className="inovation_headings m-1">
                    {props?.data?.reportHeadin}
                  </h2>
                </div>
              </div>
              <div className="mx-2">
                {props?.data?.subHeading3 && (
                  <>
                    <div className="report-border">
                      <div className="d-flex align-items-start py-5">
                        <div className="col-md-7 col-sm-12  ">
                          <div className="d-flex align-items-center">
                            <div className="report-heading  mb-5">
                              <b> {props?.data?.subHeading3?.heading}</b>
                            </div>
                            {/* <div className="report-status-card mx-3 my-3">
                        <b>{props?.data?.subHeading2?.status}</b>
                      </div> */}
                          </div>
                          <div ref={establish}></div>
                          <div className=" yuva-details ">
                            <ul>
                              {props?.data?.subHeading3?.description?.map(
                                (card, i) => {
                                  return (
                                    <li>
                                      <span>{card?.point}</span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>

                          <div className="yuva-program-details mb-3">
                            {props?.data?.subHeading3?.programDetails}
                          </div>
                          <div className=" yuva-details ">
                            <ul>
                              {props?.data?.subHeading3?.listing?.map(
                                (card, i) => {
                                  return (
                                    <li>
                                      <span>{card?.point}</span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                        {props?.data?.subHeading3?.status === "Completed" && (
                          <>
                            <div className="col-md-5 col-sm-12 d-none d-md-block  pb-3">
                              <Masonry columnsCount={2} gutter="10px">
                                {props?.data?.subHeading3?.gallery?.map(
                                  (image, i) => (
                                    <KiedImages
                                      image={image.image}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "block",
                                      }}
                                    />
                                  )
                                )}
                              </Masonry>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EdcNewProgramDone;
