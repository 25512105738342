// import React from 'react'

// function SupportsSchemes() {
//   return (
//     <div>SupportsSchemes</div>
//   )
// }

// export default SupportsSchemes

import React from "react";
import "../../schemes.page.styles.css";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SupportsSchemes() {
  let width = useScreenBreakPoint();
  return (
    <div>
      <div className="auto-container">
        <div
          className={`schemes_heading  ${
            width === "xs" || width === "sm"
              ? "sml mt-5 mb-2"
              : "lr  mb-3 mt-5 pt-5"
          }`}
        >
          Scope of the scheme
        </div>
        <div className=" Schemes_heading_description mb-5">
          Any College or Higher Secondary School may set up an EDC with at least
          25 interested students with a teacher/faculty preferably from Commerce
          or Economics as Coordinator. In schools the members will be preferably
          from Classes XI and XII. The scheme will be implemented in selected
          Engineering colleges, Polytechnics, Arts and Science colleges and
          Higher Secondary schools. Government aided and unaided institutions
          can set up EDCs. Students willing to join the EDC will be enrolled as
          members.
        </div>
        <div
          className={`schemes_heading  ${
            width === "xs" || width === "sm" ? "sml mt-5 mb-2" : "lr  mb-3"
          }`}
        >
          Implementing agency
        </div>
        <div className=" Schemes_heading_description ">
          District Industries Centre (DIC) concerned will be the implementing
          agency. General Manager, DIC shall advise the activities and have
          close liaison in conducting the activities
        </div>
        <div
          className={`schemes_heading  ${
            width === "xs" || width === "sm" ? "sml mt-5 mb-2" : "lr  mb-3 mt-5"
          }`}
        >
          Sanction of assistance
        </div>
        <div className=" Schemes_heading_description mb-5 pb-5">
          Any institution, which forms the club and opens a Bank Account (joint
          account of Coordinator and the Head of the Institution) may apply to
          the General Manager, District Industries Centre for sanction of
          assistance under the scheme.The application for assistance shall be
          accompanied by the list of members, Bio data of the coordinator,
          details of the Bank account and the proposed activities of the club
          during the year. General Managers shall forward the applications with
          the details and specific recommendations to the Director of Industries
          & Commerce for obtaining the sanction.50% of the assistance sanctioned
          will be released along with the sanction. On satisfactory utilization,
          balance will be released on the recommendation of the General Manager,
          District Industries Centre. While recommending for the second
          instalment a clear report on the activities performed shall also to be
          enclosed.
        </div>
      </div>
    </div>
  );
}

export default SupportsSchemes;
