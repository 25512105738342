import React, { useState } from "react";
import InovationText from "./sections/text/inovation.text";
import InovationPrograms from "./sections/Programs/inovation.programs";
import InovationBanner from "./sections/banner/inovations.banner";
import "./sections/inovation.css";

function EnterpreneurshipDevelopmentCenter() {
  const department = {
    name: "Enterprise Development Center",
    image: "inovation-banner",
  };

  const programs = [
    {
      id: 999,
      status: "Ongoing",
      thumb: "inovation-banner",
      name: "Enterprise Development Center",
      location: "Thiruvananthapuram",
      date: "February 01 2024",
      participants: "all",
      description: "Description",
      static: true,
    },
  ];

  return (
    <div className="">
      <InovationBanner data={department} />
      <InovationText data={department} />
      <div className="greyBackground">
        <InovationPrograms data={programs} />
      </div>
    </div>
  );
}

export default EnterpreneurshipDevelopmentCenter;
