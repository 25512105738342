import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import TextField from "../../components/textfield/TextField.component";
import RadioButton from "../../components/radiobutton/radiobutton";
import SelectBox from "../../components/selectbox/SelectBox.component";
import KiedButton from "../../components/buttons/button/button.component";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  KiedCarriers,
  gender,
  castCategory,
  radiooptions,
} from "../edc.registration/registrationform.data";
import {
  districts,
  qualification,
} from "../registration/registrationform.data";

function AspirantForm() {
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const handleTextFieldChange = (value, name) => {
    setFormdata({ ...formdata, [name]: value });
  };

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
    console.log(e.target);
  };

  const handleregister = () => {
    navigate("/success");
  };

  // const fileUpload = (e) => {
  //   console.log(formdata);
  //   console.log(e.target.files);
  //   let name = e.target.name;
  //   let value = e.target.files[0];
  //   let filename = e.target.files[0].name;
  //   // accept = ".pdf, .png, .jpeg";
  //   let validationFormats = e.target.accept
  //     .split(",")
  //     .join("|")
  //     .split(".")
  //     .join("")
  //     .split(" ")
  //     .join("");

  //   var regVal = new RegExp(`^.*.(${validationFormats})$`);

  //   if (regVal.test(filename)) {
  //     setFormdata({
  //       ...formdata,
  //       [name]: value,
  //       [name + "_name"]: filename,
  //     });
  //   } else {
  //     setFormdata({
  //       ...formdata,
  //       [name]: undefined,
  //       [name + "_name"]: undefined,
  //     });
  //     setError({ [name]: true });
  //   }
  // };

  return (
    <>
      <SmallBanner title="Aspirant Entrepreneur Data Form" />
      <div>
        <div className="d-flex align-items-center justify-content-between head-container">
          <div className="faq_banner  d-flex">
            <a href="/">Home&nbsp;/</a>

            <a
              className="current"
              href="/department/inovation/ARISERegistration"
            >
              Registration
            </a>
          </div>
          <div className=" d-flex justify-content-end mt-3">
            <p className="">
              <span>
                <span style={{ color: "red" }}>* </span> Mandatory Fields
              </span>
            </p>
          </div>
        </div>

        <div className="auto-container form">
          <form>
            <TextField
              label="Date of Birth "
              required
              type="date"
              name="dob"
              value={formdata.dob ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />

            <RadioButton
              options={gender}
              label="Gender"
              required
              name="gender"
              value={formdata.gender ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Religion"
              required
              name="religion"
              value={formdata.religion ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <RadioButton
              options={castCategory}
              label="Cast"
              required
              name="cast"
              value={formdata.cast ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <SelectBox
              label="Highest Qualification"
              required
              options={qualification}
              value={formdata.qualification}
              selectionMessage="Select your Qualification"
              name="qualification"
              handleChange={handleChange}
            />
            <br />

            <TextField
              label="Address"
              required
              type="textarea"
              name="address"
              value={formdata.address ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <SelectBox
              label="District"
              required
              options={districts}
              value={formdata.district}
              selectionMessage="Select your district"
              name="district"
              handleChange={handleChange}
            />
            <br />

            <TextField
              label="Business Idea"
              required
              type="text"
              name="phone"
              value={formdata.phone ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />
            <TextField
              label="Investment Capability of the Applicant (in Lakhs)"
              required
              type="number"
              name="aadhaar"
              value={formdata.aadhaar ?? ""}
              onChange={handleTextFieldChange}
            />
            <br />

            <RadioButton
              options={radiooptions}
              label="Ever Attended a KIED Program"
              required
              name="owner"
              value={formdata.owner ?? ""}
              onChange={handleChange}
            />
            <br />
            {formdata.owner === "yes" && (
              <>
                <TextField
                  label="Specify the Program Name"
                  required
                  type="text"
                  name="typeSpecification"
                  value={formdata.typeSpecification ?? ""}
                  onChange={handleChange}
                />
                <br />
              </>
            )}
            <SelectBox
              label="How did you come to know about KIED"
              required
              options={KiedCarriers}
              value={formdata.KiedCarriers}
              selectionMessage=""
              name="KiedCarriers"
              handleChange={handleChange}
            />
            <br />

            {error.proof && (
              <p id="proof" className="ml-3 error">
                Unsupported file type! Please select a .pdf or .docx file
              </p>
            )}
            <br />
          </form>
          <div className="d-flex justify-content-center pt-5 pb-5">
            <KiedButton
              onClick={() => handleregister()}
              primary
              rounded
              type="submit"
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Submit
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AspirantForm;
