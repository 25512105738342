import React from 'react'
import KiedButton from '../../../../components/buttons/button/button.component'
import DashInfo from '../../../../components/dashinfo/dashInfo'


function SchoolDataForm({handleEdit, handlepopupEdit, ...props}) {
  return (
    <>
    <div>
      <div className="">
        <DashInfo
          title="School/College Name"
          value={props.formdata.school_name}
        />
        <DashInfo
          title="Address"
          value={props.formdata.address ?? ""}
        />
        <hr />
        <br />
        <DashInfo
          title="Name of Head of the Institution"
          value={props.formdata.head_name}
        />
        <DashInfo
          title="Designation of Head of the Institution"
          value={props.formdata.head_designation}
        />
        <DashInfo
          title="E-mail of Head of the Institution"
          value={props.formdata.head_email ?? ""}
        />
        <DashInfo
          title="Contact Number of Head of the Institution"
          value={props.formdata.head_contact_no ?? ""}
        />

        <hr />
        <br />
        <DashInfo
          title="Name of Single Point of Contact at the Institution"
          value={props.formdata.name_spc ?? ""}
        />
        <DashInfo
          title="Designation of Single Point of Contact"
          value={props.formdata.designation_spc ?? ""}
        />
        <DashInfo
          title="E-mail of Single Point of Contact"
          value={props.formdata.email_spc ?? ""}
        />

        <DashInfo
          title="Contact Number of Single Point of Contact"
          value={props.formdata.contact_no_spc ?? ""}
        />
        <hr />
        <br />
        <DashInfo
          title="Type of Institution"
          value={props.formdata.institution_type ?? ""}
        />
        <DashInfo
          title="Area of Expertise of the organization"
          value={props.formdata.area_expertise ?? ""}
        />
        <DashInfo
          title="Authorization Letter"
          value={props.formdata.auth_letter ?? ""}
          file
        />

        {/* {error.proof && (
          <p id="proof" className="ml-3 error">
            Unsupported file type! Please select a .pdf or .docx
            file
          </p>
        )} */}
        <br />

        <div className="d-flex justify-content-center pt-5 pb-5">
          <KiedButton
            onClick={() => handleEdit()}
            primary
            rounded
            className="mx-3"
            type="submit"
            content={
              <div className="registerbtn d-flex justify-content-center">
                Edit
              </div>
            }
          />
          <KiedButton
            onClick={() => handlepopupEdit()}
            primary
            className="mx-3"
            rounded
            content={
              <div className="registerbtn d-flex justify-content-center">
                Home
              </div>
            }
          />
        </div>
      </div>
    </div>
  </>
  )
}

export default SchoolDataForm