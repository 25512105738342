export const mode = [
  {
    id: 1,
    name: "In Office",
  },
  {
    id: 2,
    name: "Virtual",
  },
  {
    id: 3,
    name: "Hybrid",
  },
];

export const nature = [
  {
    id: 1,
    name: "Part Time",
  },
  {
    id: 2,
    name: "Full Time",
  },
];

export const payment = [
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Unpaid",
    value: "unpaid",
  },
];
