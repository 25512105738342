import React from "react";
import { companyDetails } from "../../internship.summary.data";
import CompanyCard from "../../../../components/company-card/company.card";

function InternshipSummaryDetails() {
  return (
    <div className="auto-container">
      <div className="d-flex flex-wrap  pb-5 mb-4">
        {companyDetails.map((card, i) => {
          return <CompanyCard data={card} key={i} index={i} />;
        })}
      </div>
    </div>
  );
}

export default InternshipSummaryDetails;
