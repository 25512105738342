import React from "react";
import { AdvisoryCommittee } from "../../advisory.committee.data";
import Teammembers from "../../../../components/team_members/team_members";
import KiedImages from "../../../../assets/images/kied.images.component";

function AdvisoryMembers() {
  return (
    <div className="auto-container  pt-5" style={{ paddingBottom: "68px" }}>
      <div
        id="InovationEventssHeading"
        className="d-flex justify-content-left align-items-center mb-2"
      >
        <div className="">
          <h2 className="inovation_headings m-1">Advisory Committee Members</h2>
        </div>
      </div>
      <p className="teams-text">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit asperiores
        quibusdam iste laboriosam <br />
        ipsum quaerat voluptates cum laborum rerum odit dolorem?
      </p>

      <div>
        <div id="inovation_Events_contents" className="row ">
          {AdvisoryCommittee.map((card, i) => {
            return <Teammembers data={card} key={i} index={i} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default AdvisoryMembers;
