import React from "react";

function EventsDescription() {
  return (
    <div className="auto-container mb-5 mt-5 pb-5">
      <div className="Schemes_heading_description">
        Entrepreneurial Culture amongst youth and equip them with the skills,
        techniques and confidence to act as torch-bearers of Enterprise for the
        new generation. There are around 600 plus ED Clubs registered across
        Kerala of which around 400 are actively engaged in entrepreneurship
        activities Entrepreneurial Culture amongst youth.Entrepreneurial Culture
        amongst youth and equip them with the skills, techniques and confidence
        to act as torch-bearers of Enterprise for the new generation. There are
        around 600 plus ED Clubs registered across Kerala of which around 400
        are actively engaged in entrepreneurship activities Entrepreneurial
        Culture amongst youth.Entrepreneurial Culture amongst youth and equip
        them with the skills, techniques and confidence to act as torch-bearers
        of Enterprise for the new generation. There are around 600 plus ED Clubs
        registered across Kerala of which around 400 are actively engaged in
        entrepreneurship activities Entrepreneurial Culture amongst.
      </div>
    </div>
  );
}

export default EventsDescription;
