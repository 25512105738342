import React from "react";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import { Link } from "react-router-dom";

function NewProgramFaq() {
  return (
    <div className="greyBackground">
      <div className="auto-container pb-5 pt-5">
        <div
          id="InovationEventssHeading"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="">
            <h2 className="inovation_headings m-1">
              Frequently Asked Questions
            </h2>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 mb-3">
          <Link to="/department/inovation/faq">
            <KiedButton
              content={
                <>
                  <div className="d-flex p-1">
                    <div className="d-flex align-items-center">FAQ</div>
                    <div className="d-flex align-items-center">
                      <KiedIcons
                        icon="arrow-right"
                        className="hero-button-icon"
                      />
                    </div>
                  </div>
                </>
              }
              secondary
              rounded
              big
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NewProgramFaq;
