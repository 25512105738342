import React from "react";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import Inov from "./innovation-program.pdf";
import "../../programs.page.styles.css";

function ProgramsTextcontent() {
  return (
    <div className="auto-container inovation_text about_kied pt-4 pb-5">
      <div className="">
        <p className="pb-5">
          The Micro, Small and Medium Enterprises (MSME) sector has emerged as a
          highly vibrant and dynamic sector of the Indian economy over the last
          five decades. It contributes significantly in the economic and social
          development of the country by fostering entrepreneurship and
          generating large employment opportunities at comparatively lower
          capital cost, next only to agriculture. MSMEs are complementary to
          large industries as ancillary units and it contributes significantly
          in the inclusive industrial development of the country. The 6 crore
          MSMEs are widening their domain across sectors of the economy,
          producing diverse range of products and services to meet demands of
          the domestic as well as the global markets Globally, technology and
          its rapid expansion and adoption is becoming a critical factor in
          impacting almost all sectors. Technology is also deciding the way
          Micro, Small and Medium Enterprises (MSMEs) expand in the global
          economy while playing an important role in the national economic
          development. In this rapidly changing world, competitiveness of MSMEs
          needs to be maintained through continuous innovation and to build
          adequate support infrastructure to nurture such innovation. We need to
          have Business Incubators (BI) as a support infrastructure for enabling
          the MSMEs to develop innovative ideas that are not only useful for
          MSMEs but also for the complete industrial ecosystem. Bls are also
          required for assisting in commercialization of such ideas and helping
          them to scale up their business. Business incubators focus in
          providing extensive mentorship support, market and support network
          access that enables the MSME units to accelerate their growth and
          remain competitive.
        </p>
      </div>
      {/* <div className="d-flex align-items-center mt-5 ml-3">
        <div>
          <h6>Business Plan</h6>
        </div>
        <div className="">
          <a
            href={Inov}
            targt="_blank"
            className="d-flex align-items-center download-btn"
          >
            <span className=" ml-4">Download</span>
            <span className="d-flex ">
              <KiedIcons icon="Download-btn" className="ml-1" />
            </span>
          </a>
        </div>
      </div> */}
    </div>
  );
}

export default ProgramsTextcontent;
