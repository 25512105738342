import React from "react";

import Banner from "../../components/banner/banner";
import CampusDescription from "./sections/description/campus.description";
import CampusKozhikode from "./sections/kozhikode/campus.kozhikode";
import CampusPalakkad from "./sections/palakkad/campus.palakkad";
import CampusErnakulam from "./sections/ernakulam/campus.ernakulam";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";

function CampusPage() {
  let navParam = useParams();
  const campusKozhikode = useRef();
  const campusPalakkad = useRef();
  const campusErnakulam = useRef();
  useEffect(() => {
    switch (navParam.id) {
      case "campusKozhikode":
        campusKozhikode.current.scrollIntoView({ behavior: "smooth" });
        break;
      // case "campusPalakkad":
      //   campusPalakkad.current.scrollIntoView({ behavior: "smooth" });
      //   break;
      case "campusErnakulam":
        campusErnakulam.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth", // Optional if you want to skip the scrolling animation
        });
    }
  }, [navParam.id]);
  return (
    <div>
      <div className="" ref={campusErnakulam}>
        <Banner title="Campus" image="academia-banner" />
        {/* <CampusDescription /> */}
        {/* <CampusErnakulam /> */}
        <div className="" ref={campusKozhikode}></div>

        <div className="" ref={campusPalakkad}></div>
        <div className="greyBackground">
          <CampusKozhikode />
        </div>

        <CampusPalakkad />
      </div>
    </div>
  );
}

export default CampusPage;
