import React, { forwardRef } from "react";
import DashInfo from "../../components/dashinfo/dashInfo";

const PrintDashBoard = forwardRef(
  (
    { formdata, accountname, accountno, whatsapp_no, userData, yearlyData },
    ref
  ) => {
    console.log(formdata, yearlyData);
    return (
      <div ref={ref} className="dashprintContainer">
        <div className="auto-container dashform mt-4 ml-1 mr-1 col-12">
          <DashInfo title="Name" value={accountname} />
          <DashInfo title="Phone No" value={accountno} />
          <DashInfo title="Whatsapp No" value={whatsapp_no} />

          {userData?.role === 2 && (
            <>
              <DashInfo title="Date of Birth" value={formdata.dob ?? ""} />
              <DashInfo title="Gender" value={formdata.gender ?? ""} />
              <DashInfo title="Religion" value={formdata.religion} />

              <DashInfo
                title="Specify Religion"
                value={formdata.specify_religion ?? ""}
              />

              <DashInfo title="Caste" value={formdata.caste ?? ""} />
              <DashInfo
                title="Highest Qualification"
                value={formdata.qualification}
              />
              <DashInfo title="Address" value={formdata.address ?? ""} />
              <DashInfo title="District" value={formdata.district} />
              <DashInfo
                title="Business Idea"
                value={formdata.business_idea ?? ""}
              />
              <DashInfo
                title="Investment Capability of the Applicant (in Lakhs)"
                value={formdata.investment_capability ?? ""}
              />
              <DashInfo
                title="Ever Attended a KIED Programme"
                value={formdata.is_attended_kied_program == "1" ? "Yes" : "No"}
              />
              {formdata.is_attended_kied_program == "1" && (
                <DashInfo
                  title="Specify the Program Name"
                  value={formdata.program_details_attended ?? ""}
                />
              )}

              <DashInfo
                title="How did you come to know about KIED"
                value={formdata.how_you_know_kied}
              />
            </>
          )}
          {userData?.role === 3 && (
            <>
              <DashInfo
                title="School/College Name"
                value={formdata.school_name}
              />
              <DashInfo title="Address" value={formdata.address ?? ""} />
              <hr />
              <br />
              <DashInfo
                title="Name of Head of the Institution"
                value={formdata.head_name}
              />
              <DashInfo
                title="Designation of Head of the Institution"
                value={formdata.head_designation}
              />
              <DashInfo
                title="E-mail of Head of the Institution"
                value={formdata.head_email ?? ""}
              />
              <DashInfo
                title="Contact Number of Head of the Institution"
                value={formdata.head_contact_no ?? ""}
              />

              <hr />
              <br />
              <DashInfo
                title="Name of Single Point of Contact at the Institution"
                value={formdata.name_spc ?? ""}
              />
              <DashInfo
                title="Designation of Single Point of Contact"
                value={formdata.designation_spc ?? ""}
              />
              <DashInfo
                title="E-mail of Single Point of Contact"
                value={formdata.email_spc ?? ""}
              />

              <DashInfo
                title="Contact Number of Single Point of Contact"
                value={formdata.contact_no_spc ?? ""}
              />
              <hr />
              <br />
              <DashInfo
                title="Type of Institution"
                value={formdata.institution_type ?? ""}
              />
              <DashInfo
                title="Area of Expertise of the organization"
                value={formdata.area_expertise ?? ""}
              />
            </>
          )}
          {userData?.role === 4 && (
            <>
              <DashInfo title="Gender" value={formdata.gender ?? ""} />
              <DashInfo title="Date of Birth " value={formdata.dob ?? ""} />
              {/* <DashInfo title="Age" value={formdata.age ?? ""} /> */}
              <DashInfo title="Address" value={formdata.address ?? ""} />
              <DashInfo title="District" value={formdata.district} />
              <DashInfo
                title="Highest Qualification"
                value={formdata.highest_educational_qualification}
              />
              <DashInfo
                title="Do you Own a Business"
                value={formdata.do_you_own_business == "1" ? "Yes" : "No"}
              />
              <DashInfo
                title="Name of your Company/ Business"
                value={formdata.company_name ?? ""}
              />
              <DashInfo
                title="Stage of your business"
                value={formdata.stage_of_business}
              />
              <DashInfo
                title="How long have you been in this business"
                value={formdata.how_long_business}
              />
              <DashInfo
                title="Specify the Product/ Service"
                value={formdata.product_type ?? ""}
              />
              <DashInfo
                title="Mode of business"
                value={
                  formdata.mode_of_business == "Other"
                    ? formdata.other_mode_business
                    : formdata?.mode_of_business
                }
              />
              {/* <DashInfo title="Mode of business" value={formdata.others} /> */}
              {/* {formdata.mode_of_business === "Other" && (
                <DashInfo
                  title="Please specify the mode of business"
                  value={formdata.others}
                />
              )} */}
            </>
          )}
          {userData?.role === 5 && (
            <>
              <DashInfo
                title="Institute/ Organization Name"
                value={formdata.organization_name ?? ""}
              />
              <DashInfo title="Address" value={formdata.address ?? ""} />
              <DashInfo
                title="Name of Head of the Institution"
                value={formdata.head_name ?? ""}
              />
              <DashInfo
                title="Designation of Head of the Institution"
                value={formdata.head_designation ?? ""}
              />

              <DashInfo
                title="E-Mail of Head of the Institution"
                value={formdata.head_email ?? ""}
              />
              <DashInfo
                title="Contact Number of Head of the Institution"
                value={formdata.head_contact ?? ""}
              />
              <hr />
              <br />
              <DashInfo
                title="Name of Single Point of Contact at the Institution"
                value={formdata.name_single_poc ?? ""}
              />
              <DashInfo
                title="Designation of Single Point of Contact"
                value={formdata.designation_single_poc ?? ""}
              />
              <DashInfo
                title="E-Mail of Single Point of Contact"
                value={formdata.email_single_poc ?? ""}
              />
              <DashInfo
                title="Contact Number of Single Point of Contact"
                value={formdata.contact_single_poc ?? ""}
              />

              <DashInfo
                title="Type of Institution"
                value={formdata.institution_type}
              />
              {formdata.institution_type === "Others" && (
                <DashInfo
                  title="Give Details on the Institution Type"
                  value={formdata.details ?? ""}
                />
              )}
              <DashInfo
                title="Area of Expertise of the organization"
                value={formdata.area_of_expertise ?? ""}
              />
              <DashInfo
                title="Authorization Letter from Head of the Institution"
                value={formdata.authorization_letter ?? ""}
              />
            </>
          )}
          {userData?.role === 6 && (
            <>
              <DashInfo
                title="Name of the Association/industry body"
                value={formdata.organization_name ?? ""}
              />
              <DashInfo title="Address" value={formdata.address ?? ""} />
              <DashInfo
                title="Name of Head of the Institution"
                value={formdata.head_name ?? ""}
              />
              <DashInfo
                title="Designation of Head of the Institution"
                value={formdata.head_designation ?? ""}
              />

              <DashInfo
                title="E-Mail of Head of the Institution"
                value={formdata.head_email ?? ""}
              />
              <DashInfo
                title="Contact Number of Head of the Institution"
                value={formdata.head_contact_no ?? ""}
              />
              <hr />
              <br />
              <DashInfo
                title="Name of Single Point of Contact at the Institution"
                value={formdata.name_single_poc ?? ""}
              />
              <DashInfo
                title="Designation of Single Point of Contact"
                value={formdata.designation_single_poc ?? ""}
              />
              <DashInfo
                title="E-Mail of Single Point of Contact"
                value={formdata.email_single_poc ?? ""}
              />
              <DashInfo
                title="Contact Number of Single Point of Contact"
                value={formdata.contact_single_poc ?? ""}
              />

              <DashInfo
                title="Type of Institution"
                value={formdata.institution_type}
              />
              {/* <DashInfo
                title="Give Details on the Institution Type"
                value="Software Development"
              /> */}
              <DashInfo
                title="Area of Expertise of the organization"
                value={formdata.area_of_expertise ?? ""}
              />
              <DashInfo
                title="Authorization Letter from Head of the Institution"
                value={formdata.authorization_letter ?? ""}
              />
            </>
          )}
          {userData?.role === 7 && (
            <>
              <DashInfo title="Gender" value={formdata.gender ?? ""} />
              <DashInfo
                title="Highest Qualification"
                value={formdata.highest_educational_qualification}
              />
              <DashInfo
                title="Website/Online Profile of the Applicant e.g., LinkedIn"
                value={formdata.online_profile ?? ""}
              />
              <DashInfo
                title="Area of Professional Expertise"
                value={formdata.area_expertise}
              />
              {formdata.area_expertise === "Others" && (
                <DashInfo
                  title="Specify the Area of Your Expertise"
                  value={formdata.expspecify ?? ""}
                />
              )}
              <DashInfo
                title="Credentials to prove Qualification (.pdf, .docx, .jpg format files are allowed)"
                value={formdata.qualification_proof ?? ""}
              />
              <DashInfo
                title="Credentials to prove Experience (.pdf, .docx, .jpg format files are allowed)"
                value={formdata.experience_proof ?? ""}
              />
              <DashInfo
                title="Details of other Empanelment at other organizations (.pdf, .docx, .jpg format files are allowed)"
                value={formdata.other_empanelment ?? ""}
              />
            </>
          )}
          {userData?.role === 8 && (
            <>
              <DashInfo
                title="Do you have a Registered ED Club"
                value={formdata?.is_member === 1 ? "Yes" : "No"}
              />
              {formdata?.is_member === 1 && (
                <DashInfo
                  title="Supporting Document"
                  value={formdata?.supporting_document ?? ""}
                  file
                />
              )}
              <DashInfo
                title="Institution Type"
                value={formdata?.institution_type ?? ""}
              />

              <DashInfo
                title="Select the District where your School/ College/ Institution is Located"
                value={formdata?.district_institution}
              />

              <DashInfo
                title="Address of the School/ College/ Institution"
                value={formdata?.address_institution ?? ""}
              />
              <DashInfo
                title="Account Number"
                value={formdata?.account_no ?? ""}
              />
              <DashInfo
                title="Account Name"
                value={formdata?.account_name ?? ""}
              />
              <DashInfo title="Bank Name" value={formdata?.bank_name ?? ""} />
              <DashInfo title="IFSC Code" value={formdata?.ifsc ?? ""} />

              <>
                <hr />
                {yearlyData.length !== 0 && (
                  <>
                    {yearlyData.map((item, key) => (
                      <>
                        <div>
                          <h1>
                            <b>{item.year}</b>
                          </h1>
                        </div>
                        <div class>
                          <h2 className="my-4">Faculty Coordinator Details</h2>
                          <div class="d-flex">
                            <div class="d-flex justify-content-between col-6 pr-1">
                              <span class="formstyle p-0">
                                Name of ED Club Faculity Coordinator
                              </span>
                              <span class="formstyle p-0">:</span>
                            </div>
                            <div class="col-6">
                              <span class="formstyle p-0">
                                {item.faculty_name}
                              </span>
                            </div>
                          </div>
                          <br></br>
                          <div class="d-flex">
                            <div class="d-flex justify-content-between col-6 pr-1">
                              <span class="formstyle p-0">
                                Whatsapp No of Faculity Coordinator
                              </span>
                              <span class="formstyle p-0">:</span>
                            </div>
                            <div class="col-6">
                              <span class="formstyle p-0">
                                {item.faculty_number}
                              </span>
                            </div>
                          </div>
                          <br></br>
                          <div class="d-flex">
                            <div class="d-flex justify-content-between col-6 pr-1">
                              <span class="formstyle p-0">
                                Email ID of Faculity Coordinator
                              </span>
                              <span class="formstyle p-0">:</span>
                            </div>
                            <div class="col-6">
                              <span class="formstyle p-0">
                                {item.faculty_email}
                              </span>
                            </div>
                          </div>
                          <br></br>

                          <br></br>
                        </div>
                        <hr></hr>
                        <div class>
                          <h2 className="my-4">Student Coordinator Details</h2>
                          <div class="d-flex">
                            <div class="d-flex justify-content-between col-6 pr-1">
                              <span class="formstyle p-0">
                                Name of ED Club Student Coordinator
                              </span>
                              <span class="formstyle p-0">:</span>
                            </div>
                            <div class="col-6">
                              <span class="formstyle p-0">
                                {item.student_name}
                              </span>
                            </div>
                          </div>
                          <br></br>
                          <div class="d-flex">
                            <div class="d-flex justify-content-between col-6 pr-1">
                              <span class="formstyle p-0">
                                Whatsapp No of Student Coordinator
                              </span>
                              <span class="formstyle p-0">:</span>
                            </div>
                            <div class="col-6">
                              <span class="formstyle p-0">
                                {item.student_no}
                              </span>
                            </div>
                          </div>
                          <br></br>
                          <div class="d-flex">
                            <div class="d-flex justify-content-between col-6 pr-1">
                              <span class="formstyle p-0">
                                Email ID of Student Coordinator
                              </span>
                              <span class="formstyle p-0">:</span>
                            </div>
                            <div class="col-6">
                              <span class="formstyle p-0">
                                {item.student_email}
                              </span>
                            </div>
                          </div>
                          <br></br>

                          <br></br>
                        </div>
                        <hr></hr>
                        <div class>
                          <h2 className="my-4">Student Details</h2>
                          <div class="d-flex">
                            <div class="d-flex justify-content-between col-6 pr-1">
                              <span class="formstyle p-0">
                                Total Student Count
                              </span>
                              <span class="formstyle p-0">:</span>
                            </div>
                            <div class="col-6">
                              <span class="formstyle p-0">
                                {item.total_students}
                              </span>
                            </div>
                          </div>
                          <br></br>
                          <div class="d-flex">
                            <div class="d-flex justify-content-between col-6 pr-1">
                              <span class="formstyle p-0">Number of Boys</span>
                              <span class="formstyle p-0">:</span>
                            </div>
                            <div class="col-6">
                              <span class="formstyle p-0">{item.boys}</span>
                            </div>
                          </div>
                          <br></br>
                          <div class="d-flex">
                            <div class="d-flex justify-content-between col-6 pr-1">
                              <span class="formstyle p-0">Number of Girls</span>
                              <span class="formstyle p-0">:</span>
                            </div>
                            <div class="col-6">
                              <span class="formstyle p-0">{item.girls}</span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <hr />
                      </>
                    ))}
                  </>
                )}
              </>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default PrintDashBoard;
