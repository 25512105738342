import React from "react";
import EDCBanner from "./sections/banner/edc.banner";
import EDCDescription from "./sections/description/edc.description";
// import EDCGraph from "./sections/graph/edc.graph";
import EDCSchemes from "./sections/schemes/edc.schemes";
import EDCEvents from "./sections/events/edc.events";
import EDCPrograms from "./sections/programs/edc.programs";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";

function EDCPage() {
  let props = useOutletContext();
  let navParam = useParams();
  const edcPrograms = useRef();
  const edcSchemes = useRef();
  const edcEvents = useRef();
  useEffect(() => {
    switch (navParam.id) {
      case "edcPrograms":
        edcPrograms.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "edcSchemes":
        edcSchemes.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "edcEvents":
        edcEvents.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth", // Optional if you want to skip the scrolling animation
        });
    }
  }, [navParam.id]);
  return (
    <div>
      <EDCBanner {...props} />
      <EDCDescription />
      {/* <EDCGraph /> */}
      <div ref={edcSchemes}></div>
      <EDCSchemes />
      <div ref={edcEvents}></div>
      <EDCEvents />
      <div ref={edcPrograms}> </div>
      <div className="greyBackground">
        <EDCPrograms />
      </div>
    </div>
  );
}

export default EDCPage;
