import React from "react";
import KiedImages from "../../assets/images/kied.images.component";
import "./program.partner.styles.css";

function ProgramPartner(props) {
  console.log("supporting partner",props.data.logo)
  return (
    <div className="col-md-4 col-sm-12  d-flex align-items-center justify-content-center mt-5">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <KiedImages className="col-10 " src={props.data.logo} />
        {/* <div className={`mt-4 partner-name ${props.data.title ? "" : "pb-4"}`}>
          {props.data.title}
        </div> */}
      </div>
    </div>
  );
}

export default ProgramPartner;
