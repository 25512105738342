import React from "react";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import "../../schemes.page.styles.css";
import ProgDoc from "./innovation-program.pdf";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SchemeDescription() {
  let width = useScreenBreakPoint();
  return (
    <div className="auto-container ">
      <div
        className={`schemes_heading  ${
          width === "xs" || width === "sm" ? "sml mt-4 " : "lr  mt-5 pt-5"
        }`}
      >
        Objectives
      </div>
      <ul className="ml-1 Schemes_heading_description pt-3 mb-5 px-2 ">
        <li>
          <p>
            To inculcate social virtues i.e., trustworthiness, integrity, hard
            work, discipline, honesty etc as constituents of entrepreneurship.
          </p>
        </li>
        <li>
          <p>
            {" "}
            To sensitise students on the real economic and industrial
            development scenario of the state.
          </p>
        </li>
        <li>
          <p> To inculcate entrepreneurial culture in students.</p>
        </li>
        <li>
          <p>To enable them to take informed decisions.</p>
        </li>
        <li>
          <p>
            {" "}
            To change the mind set on the negative attitude prevailing in the
            state for taking up entrepreneurship as a career.
          </p>
        </li>
        <li>
          <p> To induct entrepreneurial spirit in the institutions.</p>
        </li>
        <li>
          <p>
            {" "}
            To bring in successful entrepreneur and students on a common
            platform.
          </p>
        </li>
        <li>
          <p>
            {" "}
            To motivate the members to venture into the industrial activity.
          </p>
        </li>
        <li>
          <p> To educate members on various dimensions of industry.</p>
        </li>
        <li>
          <p> To nurture the latent entrepreneurial talent.</p>
        </li>
      </ul>

      {/* <div className="schemes_heading mb-5 mt-5">Contact Details </div>
      <div className=" Schemes_heading_description mb-5">
        Mr. Nithesh B Manager(Project Finance) KSIDC.Ltd,
        <br /> 2nd Floor.Choice Towers, <br />
        Manorama Junction, Kochi-682016 <br /> Email Id:nithesh@ksidcmail.org
        <br />
        Ph: 0484-2323010/2323101
      </div>
      <br />
      <div className=" Schemes_heading_description mb-5">
        Mr Vinu K.R. Project Officer(IF &WE Mission)KSIDC.Ltd,
        <br /> 2nd Floor.Choice Towers, <br />
        Manorama Junction, Kochi-682016 <br /> Email Id:vinu@ksidcmail.org
        <br />
        Ph: 0484-2323010/2323101
      </div>
      <hr /> */}

      {/* <div className="schemes_heading mb-5 mt-5">
        Stages in Entrepreneur Support Scheme
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">1. Startup Support</div>
      <div className=" Schemes_heading_description mb-5">
        The Startup support is provided for those enterprises which have availed
        at least term loan from a financial institution and wish to avail a part
        of the total eligible support prior to the commencement of commercial
        production. The assistance is limited to 50% of the total eligible
        support limited to Rs 3 lakh on sanctioning the term loan from the bank.
        The unit can apply for the balance of the eligible support once it start
        the commercial production. A unit which do not avail Startup Support,
        can directly apply for the investment support, after the commencement of
        commercial production.
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">2. Investment Support</div>
      <div className=" Schemes_heading_description mb-5">
        Investment Support is provided after the commencement of commercial
        production. To apply for Investment Support, loan from a financial
        institution is not mandatory. The entrepreneurs shall apply within one
        year from the date commencement of production. Enterprises undertaking
        Expansion, Diversification or Modernization are also eligible for invest
        support for the additional investment made.
      </div>
      <div className="schemes_sub_heading mb-5 mt-5">3. Technology Support</div>
      <div className=" Schemes_heading_description mb-5">
        Technology Support is provided after commencement of production, on
        acquiring new technology from authorized institutions. The unit shall
        apply for technology support within 6 months from the date of
        commencement after installing the new technology form authorized
        agencies. Assistance will be provided for the new technology and for the
        plant and machinery installed in connection with the new technology.
      </div>
      <hr />
      <div className="schemes_heading mb-5 mt-5">Application Fee</div>

      <div className=" Schemes_heading_description mb-5">
        An application fee of Rs 1105/- has to be remitted per unit.
      </div> */}

      {/* <div className="d-flex align-items-center mt-5 mb-5">
        <div className="download_description">
          <h6>For more information</h6>
        </div>
        <div className="schemes_download ml-3">
          <a
            href={ProgDoc}
            targt="_blank"
            className="d-flex align-items-center download-btn"
          >
            <span className="ml-1 ">Download</span>
            <span className="d-flex ">
              <KiedIcons icon="Download-btn" className="ml-1" />
            </span>
          </a>
        </div>
      </div> */}
      {/* <hr /> */}
    </div>
  );
}

export default SchemeDescription;
