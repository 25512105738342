import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import "../../programs.page.styles.css";

function ProgramsStructure() {
  return (
    <div className="auto-container pb-5 pt-5">
      <div className="d-flex justify-content-center align-items-center">
        <div className="">
          <h2 className="inovation_headings m-1">Program Structure</h2>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-around">
        <div className="d-flex flex-column justify-content-center criteria structure_points">
          <ul>
            <li>1. Training Programs </li>
            <li>2. One to Many Mentorship </li>
            <li>3. One to One Mentorship </li>
          </ul>
        </div>
        <KiedImages
          className="program-banner-image d-none d-md-block"
          image="program-structure"
        />
      </div>
      <div className="d-flex justify-content-around align-items-center flex-row-reverse">
        <div className="d-flex flex-column justify-content-center  criteria structure_points">
          <ul className="">
            <li>4. Service Network Connect </li>
            <li>5. Funding Support </li>
            {/* <li>Coustomer Validation Excercise</li> */}
          </ul>
        </div>
        <KiedImages
          className="program-banner-image d-none d-md-block"
          image="program-structure"
        />
      </div>
      {/* /////////////////////////////// */}
      {/* <div className="d-flex justify-content-left align-items-center">
        <div className="">
          <h2 className="inovation_sub_headings mt-3 ml-1">
            <b>Training Program</b>
          </h2>
        </div>
      </div>
      <div className="inovation_text mt-2 pb-3">
        <div className="">
          <p className="">
            In this incubation program the training support will be given to
            entrepreneurs to enhance the operational efficiency, capacity
            utilization, improving the Sales & Distribution, Marketing
            strategies, Finance and Team Management. Weightage of the programs
            will be as follows
            <ul className="pt-4 ml-5">
              <li>Operation: 30%</li>
              <li>Sales and Distribution: 20% </li>
              <li>Marketing: 20% </li>
              <li>Finance: 20% </li>
              <li>Leadership, Team and Organizational Culture: 10%</li>
            </ul>
          </p>
          <div className="ml-5">
            <i>
              *Training program/modules and its sessions may slightly
              change/vary based on the requirements and feedback received from
              the participants in the cohort.{" "}
            </i>
          </div>
        </div>
      </div> */}
      {/* /////////////////////////////// */}
      {/* /////////////////////////////// */}
      {/* <div className="d-flex justify-content-left align-items-center">
        <div className="">
          <h2 className="inovation_sub_headings mt-3 ml-1">
            <b>One to Many Mentorship Sessions</b>
          </h2>
        </div>
      </div>
      <div className="inovation_text mt-2 pb-3">
        <div className="">
          <p className="">
            In this session, subject matter experts in Operation, Sales, and
            Finance will interact with entrepreneurs, and common inputs will be
            given on each area. The entrepreneurs can interact with them, the
            problems which they are facing can be discussed with the mentor.
            Such discussions will act as learning for the entire group.
          </p>
        </div>
      </div> */}
      {/* /////////////////////////////// */}
      {/* /////////////////////////////// */}
      {/*   */}
      {/* /////////////////////////////// */}
      {/* /////////////////////////////// */}
      {/* <div className="d-flex justify-content-left align-items-center">
        <div className="">
          <h2 className="inovation_sub_headings mt-3 ml-1">
            <b>Service Network Connect</b>
          </h2>
        </div>
      </div>
      <div className="inovation_text pb-3 mt-2">
        <div className="">
          <p className="">
            Many entrepreneurs require the support of agencies like digital
            marketing agency, branding, packaging, chartered accountant firms
            etc. So the incubator will build this service network and connect
            the appropriate agencies as per the requirement of the entrepreneur.
            The objective here is to support the entrepreneurs with right
            agencies that too at a reasonable rate so that entrepreneur need not
            waste their time by trying to find such agencies. The service
            network should not be restricted to few support agencies alone, the
            incubator should focus on building and engaging actively with
            educational/technical institutions/ NGO’s/ Government agencies,
            Industry bodies and other institutions and entities that can support
            in fulfilling such requirements
          </p>
        </div>
      </div> */}
      {/* /////////////////////////////// */}
      {/* <div className="d-flex justify-content-left align-items-center">
        <div className="">
          <h2 className="inovation_sub_headings mt-3 ml-1">
            <b>Funding Connect</b>
          </h2>
        </div>
      </div>
      <div className="inovation_text pb-3 mt-2">
        <div className="">
          <p className="">
            Normally in any incubation program, the center doesn’t promises
            funding to entrepreneurs. With regard to funding, the objective of
            the center is to get them prepared for raising funds from lending
            institutions and also connecting them to appropriate agencies.
          </p>
        </div>
      </div> */}
      {/* /////////////////////////////// */}
      <div className="d-flex justify-content-center align-items-center mt-5">
        {/* <div className="">
          <KiedImages
            className="inovation_headings_logo m-1"
            image="inovation-heading-icon"
          />
        </div> */}

        <div className="">
          <h2 className="inovation_headings m-1">Fees</h2>
        </div>
      </div>
      <hr />

      <div className="mt-5 pt-5 mb-5 criteria">
        <ol className="">
          <li>Fees for this program will be Rs.10000+GST per participant.</li>
          <li>
            Participants should make payment on or before the program day.
          </li>
          <li>
            Participants opting for Co-working space(3 Members cubicle) should
            pay Rs.2500+GST per participant per month
          </li>
          <li>
            For non incubate entrepreneurs should pay the normal co-working
            charges of Rs.5000+GST per month.
          </li>
        </ol>
      </div>
      {/* /////////////////////////////// */}
    </div>
  );
}

export default ProgramsStructure;
