import React from "react";
import SmallBanner from "../../components/small.banner/small.banner";
import SubmitSolutionsBreadcrumb from "./sections/breadcrumbs/submitSolutions.breadcrumb";
import SubmitSolutionsContent from "./sections/content/content.submitsolutions";
import "./submitSolutions.page.styles.css";

function SubmitSolutionsPage() {
  return (
    <div>
      <SmallBanner />
      <SubmitSolutionsBreadcrumb />
      <SubmitSolutionsContent />
    </div>
  );
}

export default SubmitSolutionsPage;
