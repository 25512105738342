import React from 'react'
import ProgramsBanner from './sections/banner/programs.banner'
import ProgramsEligibility from './sections/eligibility/programs.eligibility'
import ProgramsFaq from './sections/faq/programs.faq'
import ProgramsMentor from './sections/mentors/Programs.Mentor'
import ProgramsOfferings from './sections/offerings/programs.offering'
import ProgramsStructure from './sections/program structure/programs.structure'
import ProgramPartners from './sections/supporting partners/programs.partners'
import ProgramsTextcontent from './sections/textcontent/porgram.textcontent'
import "./programs.page.styles.css"


function ProgramsPage() {
  return (
    <div>
        <ProgramsBanner />
        <ProgramsTextcontent />
        <ProgramsOfferings />
        <ProgramsEligibility />
        <ProgramsStructure />
        <ProgramsMentor />
        <ProgramPartners />
        <ProgramsFaq />
    </div>
  )
}

export default ProgramsPage