import React from "react";
// import SmallBanner from "../../components/small.banner/small.banner";
import TextField from "../../components/textfield/TextField.component";
import RadioButton from "../../components/radiobutton/radiobutton";
import SelectBox from "../../components/selectbox/SelectBox.component";
import KiedButton from "../../components/buttons/button/button.component";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FileUpload from "../../components/file.upload/fileupload.component";
import { registeredclub } from "../../actions/api.functions";
import "./edcform.css";

import {
  KiedCarriers,
  gender,
  castCategory,
  radiooptions,
} from "../edc.registration/registrationform.data";
import {
  districts,
  qualification,
} from "../registration/registrationform.data";
import { developmentStage } from "./store";

function EDCSecondForm(props) {
  const [formdata, setFormdata] = useState({
    
  });
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);
  //   const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();



  const handleregister = () => {
    // navigate("/success");
    props.setActiveStep(3);
  };

  const fileUpload = (e) => {
    console.log(formdata);
    console.log(e.target.files);
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    // accept = ".pdf, .png, .jpeg";
    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);

    if (regVal.test(filename)) {
      setFormdata({
        ...formdata,
        [name]: value,
        [name + "_name"]: filename,
      });
    } else {
      setFormdata({
        ...formdata,
        [name]: undefined,
        [name + "_name"]: undefined,
      });
      setError({ [name]: true });
    }
  };
 


 
 
  return (
    <>
      <div className="auto-container form">
        <form>
         
          <FileUpload
            label="Company Incorporation Certificate  (.pdf format files are allowed)"
            required
            accept=".pdf"
            name="incorporation_Certificate"
            type="file"
            // filename={formdata.gst_file_name}
            // value={formdata.gst_file ?? null}
            onChange={fileUpload}
          />
          <br />
          <FileUpload
            label="DPIIT Recognition Certificate  (.pdf format files are allowed)"
            required
            accept=".pdf"
            name="recognition_certificate"
            type="file"
            // filename={formdata.gst_file_name}
            // value={formdata.gst_file ?? null}
            onChange={fileUpload}
          />
          <br />
          <FileUpload
            label="Pitch Deck   (.pdf format files are allowed)"
            required
            accept=".pdf"
            name="pitch_deck"
            type="file"
            // filename={formdata.gst_file_name}
            // value={formdata.gst_file ?? null}
            onChange={fileUpload}
          />
          <br />
          <FileUpload
            label="Financial Statements (Balance sheets, P&L statements etc.)   (.pdf format files are allowed)"
            required
            accept=".pdf"
            name="financial_statement"
            type="file"
            // filename={formdata.gst_file_name}
            // value={formdata.gst_file ?? null}
            onChange={fileUpload}
          />
          <br />
          <FileUpload
            label="Any other relevant Document   (.pdf format files are allowed)"
            required
            accept=".pdf"
            name="relevant_document"
            type="file"
            // filename={formdata.gst_file_name}
            // value={formdata.gst_file ?? null}
            onChange={fileUpload}
          />
          <br />
         
        </form>
        <div className="d-flex justify-content-center pt-5 pb-5">
          <KiedButton
            onClick={() => handleregister()}
            primary
            rounded
            type="submit"
            content={
              <div className="registerbtn d-flex justify-content-center">
                Submit
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}

export default EDCSecondForm;
