import React from "react";

function DirectoryBread() {
  return (
    <div>
      <div className="faq_banner head-container d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/home">IAC&nbsp;/</a>
        <a href="/department/academy-collaboration/program">
          {" "}
          Program Detail&nbsp;/
        </a>
        <a className="current" href="/directory">
          Directory of Problems
        </a>
      </div>
    </div>
  );
}

export default DirectoryBread;
