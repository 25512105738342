import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";

function CampusKozhikode() {
  return (
    <div className="auto-container pb-4">
      <div className="pt-3">
        <div>
          <h2
            className="text_about  pl-2 ml-1 pb-5"
            style={{ fontWeight: "bolder" }}
          >
            ANGAMALY CAMPUS
          </h2>
        </div>
        <div className=" d-flex flex-row-reverse align-items-center">
          <div className="col-md-6 col-sm-12">
            <div className="">
              <p className="text_about campus-section ">
                <p>
                  1. Incubation-Co Working Space for 30 number (one open cubicle
                  for a team of 3 members) of business owners{" "}
                </p>

                <p>2. Closed Office Space.</p>
                <p>3. Conference/Board Room for 20 pax.</p>
                <p>4. Lecture/Seminar Hall for 75 pax.</p>
                <p>5. Meeting Room.</p>
              </p>
            </div>
            <a
              href="https://www.google.com/maps/place/10%C2%B010'52.3%22N+76%C2%B022'15.7%22E/@10.1811727,76.3709124,21z/data=!4m5!3m4!1s0x0:0x383b48b4d9c5e754!8m2!3d10.1812055!4d76.3710168?hl=en"
              target="_blank"
            >
              Location: Angamaly South, Angamaly, Kerala 683573
            </a>
          </div>
          <div className=" col-md-6 col-sm-12 d-none d-md-block">
            <div className="d-flex align-items-center">
              <KiedImages className="w-100 " image="cochincampus" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusKozhikode;
