import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import LayoutComponent from "../../layout/layout.component";

function AuthRoute(props) {
	let isAuthenticated = props.state.isAuthenticated;
	return (
		<LayoutComponent {...props}>
			{isAuthenticated ? <Navigate to="/" replace /> : <Outlet context={props} />}
		</LayoutComponent>
	);
}

export default AuthRoute;
