export const faqData = [
  {
    id: 1,
    question:
      "What's the relationship between business growth and a business plan?",
    answer:
      "A strategic business plan can help structure your company's vision for growth and development. ",
    readTime: 10,
  },
  {
    id: 2,
    question: "Are business ethics part of developing a business?",
    answer:
      "It's important for those working in this aspect of business growth to make decisions in line with their professional ethics and support increased revenue and profits. ",
    readTime: 10,
  },
  {
    id: 3,
    question: "How do I figure out who my target audience is?",
    answer:
      "You need to develop buyer personas. Remember, you can’t be all things to all people so you need to figure out who would be interested in buying what you are selling.",
    readTime: 10,
  },
  {
    id: 4,
    question:
      "What’s the difference between business development and marketing?",
    answer:
      "Marketing is another field that is often confused with business development.",
    readTime: 10,
  },
  {
    id: 5,
    question: "Why is business development important?",
    answer:
      "Business development acts as the thread that ties together all of a company's functions or departments",
    readTime: 10,
  },
  //   {
  //     id: 6,
  //     question: "How do i enroll in EDC Kerala?",
  //     answer: "Enroll in EDC Kerala, Enroll in EDC Kerala",
  //     readTime: 10,
  //   },
];
