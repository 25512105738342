import React, { useState } from "react";
import "./paymentListing.css";
import KiedButton from "../buttons/button/button.component";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { getPayment, getEventPayment } from "../../apis/publicApis";
import { useParams } from "react-router-dom";
import { useLoginStore } from "../../utils/zustand";
import Swal from "sweetalert2";
function PaymentListing(props) {
  const [data, setData] = useState({});
  const params = useParams();
  const loggedUser = localStorage.getItem("user");
  const profileData = loggedUser ? JSON.parse(loggedUser) : null;
  const user_id = profileData?.id;
  const event_id = params.id;
  const { setIsLogin } = useLoginStore();
  console.log(props);
  // const router = useNavigate()
  const PaymentDetails = (amount) => {
    if (props.event === true) {
      getEventPayment({ user_id, event_id, fees: amount })
        .then((res) => {
          const bookingId = res?.booking_id;
          getPayment({ amount: amount, booking_id: bookingId })
            .then((res) => {
              console.log(res);
              setData(res);
              sessionStorage.setItem("tid", res.tid);
              sessionStorage.setItem("order_id", res.orderId);
              // setTimeout(()=>{
              //   console.log(data)
              // },100)
              let payload = {
                encRequest: res.encRequest,
                access_code: res.access_code,
                tid: res.tid,
                order_id: res.orderId,
              };

              // axios.post("https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction", payload)
              const form = document.createElement("form");
              form.method = "POST";
              form.action =
                "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction";

              const encRequestInput = document.createElement("input");
              encRequestInput.type = "hidden";
              encRequestInput.name = "encRequest";
              encRequestInput.value = payload.encRequest;
              form.appendChild(encRequestInput);

              const accessCodeInput = document.createElement("input");
              accessCodeInput.type = "hidden";
              accessCodeInput.name = "access_code";
              accessCodeInput.value = payload.access_code;
              form.appendChild(accessCodeInput);

              document.body.appendChild(form);
              form.submit();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {});
    } else {
      getPayment({ amount: amount })
        .then((res) => {
          console.log(res);
          setData(res);
          sessionStorage.setItem("tid", res.tid);
          sessionStorage.setItem("order_id", res.orderId);
          // setTimeout(()=>{
          //   console.log(data)
          // },100)
          let payload = {
            encRequest: res.encRequest,
            access_code: res.access_code,
            tid: res.tid,
            order_id: res.orderId,
          };

          // axios.post("https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction", payload)
          const form = document.createElement("form");
          form.method = "POST";
          form.action =
            "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction";

          const encRequestInput = document.createElement("input");
          encRequestInput.type = "hidden";
          encRequestInput.name = "encRequest";
          encRequestInput.value = payload.encRequest;
          form.appendChild(encRequestInput);

          const accessCodeInput = document.createElement("input");
          accessCodeInput.type = "hidden";
          accessCodeInput.name = "access_code";
          accessCodeInput.value = payload.access_code;
          form.appendChild(accessCodeInput);

          document.body.appendChild(form);
          form.submit();
        })
        .catch((err) => {
          const error = err?.response?.data?.message;
          Swal.fire({
            icon: "error",
            title: "error",
            text: { error },
          });
        });
    }
  };
  return (
    <>
      <div className="p-3">
        <div className="payment-mainContainer d-flex flex-column justify-content-center align-items-center">
          <h1 className="payment_title_color">{props.title}</h1>
          <p className="mb-0 text-center">{props.description}</p>
          <h1 className="payment_title_color">₹ {props.amount}</h1>
          <KiedButton
            onClick={
              () => PaymentDetails(props.amount)
              // (e) =>
              // router("/payment")
            }
            secondary
            small
            className="mt-2"
            type="submit"
            content={<div className=" d-flex justify-content-center">Pay</div>}
          />
        </div>
      </div>
    </>
  );
}

export default PaymentListing;
