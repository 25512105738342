import React from "react";
import KsidcSchemeBreadcrumbs from "./sections/breadcrumb/schemes.breadcrumb";
import SchemeHerosection from "./sections/hero/schemeHero";
import SchemeDescription from "./sections/description/scheme.description";
import SchemeCanapply from "./sections/canapply/scheme.canapply";
import SchemeEligibility from "./sections/eligibility/scheme.eligibility";
import SupportsSchemes from "./sections/supportingschemes/SupportsSchemes"
import "./schemes.page.styles.css";

function EDCSchemesPage() {
  return (
    <div>
      <KsidcSchemeBreadcrumbs />
      <SchemeHerosection />
      <SchemeDescription />
      <SchemeCanapply />
      <SupportsSchemes/>
      <SchemeEligibility />
    </div>
  );
}

export default EDCSchemesPage;
