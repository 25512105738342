import React from "react";

import KiedIcons from "../../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import { Table } from "reactstrap";
import "../../EdcNewProgram.css";
function ProgramDetails(props) {
  let width = useScreenBreakPoint();
  return (
    <div>
      <div className="greyBackground sectional-spaces ">
        <div className="auto-container">
          <div className="white-bg">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex justify-content-center">
                  <h2 className="inovation_headings listing-details">
                    {props?.data?.reportHeading}
                  </h2>
                </div>
                <div>
                  <div className="detail-description ">
                    <ul>
                      {props?.data?.subHeading1?.description?.map((card, i) => {
                        return (
                          <li>
                            <span>{card?.point}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramDetails;
