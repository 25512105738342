import React from "react";
import TextField from "../../../../components/textfield/TextField.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import SelectBox from "../../../../components/selectbox/SelectBox.component";
import FileUpload from "../../../../components/file.upload/fileupload.component";
import RadioButton from "../../../../components/radiobutton/radiobutton";
import { districts } from "../../../registration/registrationform.data";
import { institutions } from "../../../registration/registrationform.data";
import { problemtype } from "../../../edc.registration/registrationform.data";

function ReportProblemContent() {
  return (
    <div className="auto-container pb-5 ">
      <div id="" className="d-flex justify-content-center align-items-center">
        <div className="">
          <h2 className="report_heading m-1">Company Details</h2>
        </div>
      </div>
      <div className="">
        <div className="auto-container form mt-5">
          <div className=" mt-4">
            <TextField label="Name of the Firm" type="text" required name="" />
            <br />
            <SelectBox
              label="Select District"
              required
              options={districts}
              selectionMessage="Select your district"
            />
            <br />
            <TextField
              label="Type of Business Activity"
              type="text"
              required
              name="solution"
            />
            <br />
            <TextField
              label="Registered Address"
              type="textarea"
              required
              name="solution"
            />
            <br />
          </div>
        </div>
      </div>
      <div
        id=""
        className="d-flex justify-content-center align-items-center mt-5"
      >
        <div className="">
          <h2 className="report_heading m-1">Problem Statement Details</h2>
        </div>
      </div>
      <div className="">
        <div className="auto-container form mt-5">
          <div className=" mt-4">
            <RadioButton
              options={problemtype}
              label="Type of Problem "
              name="YES"
              required
            />
            <br />
            <TextField label="Sector" type="text" required name="solution" />
            <br />
            <TextField
              label="Title of Problem Statement"
              type="text"
              required
              name="solution"
            />
            <br />
            <TextField
              label="Abstract of Problem Statement"
              type="textarea"
              required
              name="solution"
            />
            <br />
            <TextField
              label="Solution Prerequisites"
              type="textarea"
              required
              name="solution"
            />
            <br />
            <FileUpload
              label="Attach any Additional Files Related to the Problem Statement "
              required
            />
            <br />
            <TextField
              label="Urgency of Solution"
              type="text"
              required
              name="solution"
            />
            <br />
            <TextField
              label="Budgeted Amount for Workable Solution"
              type="text"
              required
              name="solution"
            />
            <br />
          </div>
        </div>
      </div>
      <div
        id=""
        className="d-flex justify-content-center align-items-center mt-5"
      >
        <div className="">
          <h2 className="report_heading m-1">
            Point of Contact at the Company
          </h2>
        </div>
      </div>
      <div className="">
        <div className="auto-container form mt-5">
          <div className=" mt-4">
            <TextField label="PoC Name" type="text" required name="" />
            <br />
            <TextField
              label="Designation of PoC"
              type="text"
              required
              name="solution"
            />
            <br />
            <TextField label="Mail Id" type="text" required name="solution" />
            <br />
            <TextField
              label="Mobile Number"
              type="text"
              required
              name="solution"
            />
            <br />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center  m-5">
        <KiedButton
          content={
            <>
              <div className="d-flex p-1">
                <div className="d-flex align-items-center">Submit</div>
                <div className="d-flex align-items-center"></div>
              </div>
            </>
          }
          primary
          rounded
          big
        />
      </div>
    </div>
  );
}

export default ReportProblemContent;
