// import React from 'react'

// function InfrastructureHero() {
//   return (
//     <div>InfrastructureHero</div>
//   )
// }

// export default InfrastructureHero

import React from "react";
import KiedImages from "../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../hooks/useScreenBreakPoint";
function InfrastructureHero(props) {
  let width = useScreenBreakPoint();
  return (
    // <div
    //   className={`d-flex justify-content-center ${
    //     width === "xs" || width === "sm"
    //       ? props.index % 2 === 0
    //         ? "flex-column"
    //         : " flex-column"
    //       : props.index % 2 === 0
    //       ? "flex-row"
    //       : "flex-row-reverse "
    //   }`}
    // >
    <div
      className={`d-flex justify-content-center auto-container ${
        width === "xs" || width === "sm" ? "sml p-0 mt-2" : "lr mt-5 pt-5"
      }`}
    >
      <div className="row d-flex justify-content-between w-100">
        <div className="col-lg-4 col-md-4  col-sm-12 d-flex justify-content-start">
          <KiedImages
            className="w-100"
            style={{ height: "100%", objectFit: "cover", borderRadius: "15px" }}
            image="ess"
          />
        </div>
        <div
          className={`col-lg-8 col-md-8 col-sm-12 d-flex flex-column align-items-start  ${
            width === "xs" || width === "sm" ? "sml mt-3 " : "lr p-2 pl-5"
          } `}
        >
          <div className="schemes_heading">
            {" "}
            Establishment of Local Market Infrastructure for MSMEs
          </div>
          <div
            className={`Schemes_heading_description Schemes_new_discription  ${
              width === "xs" || width === "sm" ? "sml p-3 mt-3 " : "lr  mt-5 "
            } `}
          >
            Government of Kerala has declared the year 2022-23 as year of
            enterprises. As part of the celebration of 'Year of Enterprises' it
            has been decided to facilitate the. opening of 1 Lakh Enterprises in
            the state, under the aegis of the Department of Industries and
            Commerce during the Financial Year 2022-23. Various activities in
            this regard have been decided to be carried out with cognizance and
            convergence of the various Departments of the Government, the
            coordination of which shall be undertaken by the Department of
            Industries. To support marketing activity and empower MSMEs in the
            state a new scheme is formulated.
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default InfrastructureHero;
