import React from "react";
import DashInfo from "../../../../components/dashinfo/dashInfo";
import KiedButton from "../../../../components/buttons/button/button.component";

function IndustryDataForm({ handleEdit, handlepopupEdit, ...props }) {
  return (
    <>
      <div>
        <div className="">
          <DashInfo
            title="Name of the Association/ industry body"
            value={props.formdata.organization_name ?? ""}
          />
          <DashInfo title="Address" value={props.formdata.address ?? ""} />
          <DashInfo
            title="Name of Head of the Institution"
            value={props.formdata.head_name ?? ""}
          />
          <DashInfo
            title="Designation of Head of the Institution"
            value={props.formdata.head_designation ?? ""}
          />

          <DashInfo
            title="E-Mail of Head of the Institution"
            value={props.formdata.head_email ?? ""}
          />
          <DashInfo
            title="Contact Number of Head of the Institution"
            value={props.formdata.head_contact_no ?? ""}
          />
          <hr />
          <br />
          <DashInfo
            title="Name of Single Point of Contact at the Institution"
            value={props.formdata.name_single_poc ?? ""}
          />
          <DashInfo
            title="Designation of Single Point of Contact"
            value={props.formdata.designation_single_poc ?? ""}
          />
          <DashInfo
            title="E-Mail of Single Point of Contact"
            value={props.formdata.email_single_poc ?? ""}
          />
          <DashInfo
            title="Contact Number of Single Point of Contact"
            value={props.formdata.contact_single_poc ?? ""}
          />

          <DashInfo
            title="Type of Institution"
            value={props.formdata.institution_type}
          />
          {/* <DashInfo
          title="Give Details on the Institution Type"
          value="Software Development"
        /> */}
          <DashInfo
            title="Area of Expertise of the organization"
            value={props.formdata.area_of_expertise ?? ""}
          />
          <DashInfo
            title="Authorization Letter from Head of the Institution"
            value={props.formdata.authorization_letter ?? ""}
            file
          />

          {/* {error.proof && (
          <p id="proof" className="ml-3 error">
            Unsupported file type! Please select a .pdf or .docx
            file
          </p>
        )} */}
          <br />

          <div className="d-flex justify-content-center pt-5 pb-5">
            <KiedButton
              onClick={() => handleEdit()}
              primary
              rounded
              className="mx-3"
              type="submit"
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Edit
                </div>
              }
            />
            <KiedButton
              onClick={() => handlepopupEdit()}
              primary
              className="mx-3"
              rounded
              content={
                <div className="registerbtn d-flex justify-content-center">
                  Home
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default IndustryDataForm;
