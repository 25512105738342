import React from "react";
import "../../schemes.page.styles.css";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SupportScheme() {
  let width = useScreenBreakPoint();
  return (
    <div>
      <div className="auto-container">
        <div
          className={`schemes_heading  ${
            width === "xs" || width === "sm" ? "sml mt-5" : "lr  mb-5 mt-5 pt-5"
          }`}
        >
          Stages in Entrepreneur Support Scheme
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          1. Startup Support
        </div>
        <div className=" Schemes_heading_description mb-5">
          The Startup support is provided for those enterprises which have
          availed at least term loan from a financial institution and wish to
          avail a part of the total eligible support prior to the commencement
          of commercial production. The assistance is limited to 50% of the
          total eligible support limited to Rs 3 lakh on sanctioning the term
          loan from the bank. The unit can apply for the balance of the eligible
          support once it start the commercial production. A unit which do not
          avail Startup Support, can directly apply for the investment support,
          after the commencement of commercial production.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          2. Investment Support
        </div>
        <div className=" Schemes_heading_description mb-5">
          Investment Support is provided after the commencement of commercial
          production. To apply for Investment Support, loan from a financial
          institution is not mandatory. The entrepreneurs shall apply within one
          year from the date commencement of production. Enterprises undertaking
          Expansion, Diversification or Modernization are also eligible for
          invest support for the additional investment made.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          3. Technology Support
        </div>
        <div className=" Schemes_heading_description mb-5">
          Technology Support is provided after commencement of production, on
          acquiring new technology from authorized institutions. The unit shall
          apply for technology support within 6 months from the date of
          commencement after installing the new technology form authorized
          agencies. Assistance will be provided for the new technology and for
          the plant and machinery installed in connection with the new
          technology.
        </div>
      </div>
      <div style={{ backgroundColor: "#f2f3f4 " }} className="pb-4">
        <div className="auto-container">
          <div
            className={`schemes_heading ${
              width === "xs" || width === "sm"
                ? "sm pt-3 mb-3"
                : "lr mt-5 py-5 "
            }`}
          >
            Application Procedure and Free Details
          </div>
          <div
            className={`schemes-fee ${
              width === "xs" || width === "sm" ? "sm w-100 mt-0" : "lr  "
            }`}
          >
            An application fee of Rs 1105/- has to be remitted per unit.
          </div>
          <div className="text-dark fess-discription mt-5">
            The application can be submitted online along with the documents as
            per the checklist using the link given at the bottom. The
            application will be processing by the Sanctioning Authority and the
            assistance will be sancitioned based on merit.
          </div>
          <div className="d-flex justify-content-center  m-4">
            {/* <Link> */}
            <a
              // href="https://industry.kerala.gov.in/index.php/pages/capacity-building/ed-club"
              href="https://ess.kerala.gov.in/login"
              // href="https://industry.kerala.gov.in/index.php/schemes-mainmenu/entrepreneur-support-scheme-schemes"
              target="_blank"
              rel="noreferrer"
            >
              <KiedButton
                content={
                  <>
                    <div className="d-flex p-1">
                      <div className="d-flex align-items-center">Apply</div>
                      <div className="d-flex align-items-center">
                        <KiedIcons
                          icon="arrow-right"
                          className="hero-button-icon arrow_icon"
                        />
                      </div>
                    </div>
                  </>
                }
                secondary
                rounded
                big
              />
            </a>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportScheme;
