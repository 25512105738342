import React from "react";

function InternshipDetailDescription() {
  return (
    <div className="auto-container inovation_text about_kied">
      <p className="">
        The platform aims to bridge the gap between Industries offering
        internships and students seeking them, by connecting them directly. This
        will help create a pipeline of industry-ready professionals upon
        graduation, reducing the time and effort spent in finding relevant
        internships. The platform is designed to streamline the internship
        process and make it more accessible for both parties, allowing students
        to gain valuable real-world experience and industries to identify and
        nurture new talent.
      </p>
      <p className="mt-3 mb-5">
        <b>This program will start from April 2023</b>
      </p>
    </div>
  );
}

export default InternshipDetailDescription;
