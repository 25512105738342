import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import KiedButton from "../../../../components/buttons/button/button.component";
import KiedIcons from "../../../../assets/icons/kied.icons.component";
import { useNavigate } from "react-router-dom";
function ProgramsBanner() {
  const history = useNavigate();
  const handleregister = () => {
    localStorage.setItem("next_path", "/department/inovation/registration");
    history("/department/inovation/registration");
  };
  return (
    <div
      id="Banner_outer"
      className="banner_image banner_big d-flex justify-content-center"
    >
      <div
        id="Banner_inner"
        className="d-flex  container bannerContainer  head-container justify-content-between"
      >
        <div
          id="Banner_text"
          className="d-flex flex-column justify-content-end"
          // data-aos="fade-right"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <h2 className="text_banner more_link">Business Growth Program</h2>

          <div className="more_link">
            <div className="">
              <span className="prog-banner">
                For MSME / Small Business Entrepreneurs 
              </span>
            </div>
          </div>
          {/* <Link to="/department/inovation/registration"> */}
          <div className="more_link">
            <KiedButton
              onClick={() => handleregister()}
              content={
                <>
                  <div className="d-flex">
                    <div className="d-flex align-items-center m-2">
                      Click Here To Apply
                    </div>
                    <div className="d-flex align-items-center">
                      <KiedIcons
                        icon="arrow-right"
                        className="hero-button-icon"
                      />
                    </div>
                  </div>
                </>
              }
              secondary
              rounded
              big
            />
            {/* Registration will start on 18th March */}
            {/* </Link> */}
          </div>
        </div>
        <div
          id="Banner_image"
          className="aboutpage_images  d-none d-md-block"
          // data-aos="fade-left"
          // data-aos-duration="1000"
          // data-aos-easing="ease-in-out"
        >
          <KiedImages
            className="program-banner-image d-none d-md-block"
            image="program-banner"
          />
        </div>
      </div>
    </div>
  );
}

export default ProgramsBanner;
