import React from "react";
import KiedImages from "../../../../assets/images/kied.images.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
function SchemeHerosection() {
  let width = useScreenBreakPoint();
  return (
    // <div className="auto-container mt-3  mb-5">
    //   <div className="row d-flex justify-content-center">
    //     <div className="col-md-4 col-sm-12">
    //       <KiedImages className="w-100" image="scheme" />
    //     </div>
    //     <div className="col-md-7 col-sm-12 d-flex flex-column align-items-start justify-content-center">
    //       <div className="schemes_heading mb-4">ED Club Scheme</div>
    //       <div className="Schemes_heading_description">
    //         Formulated in schools and colleges to inculcate Entrepreneurial
    //         Culture amongst youth and equip them with the skills, techniques and
    //         confidence.
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div
      className={`d-flex justify-content-center auto-container ${
        width === "xs" || width === "sm" ? "sml p-0 mt-2" : "lr mt-5 pt-5"
      }`}
    >
      <div className="row d-flex justify-content-between w-100">
        <div className="col-lg-4 col-md-4  col-sm-12 d-flex justify-content-start">
          <KiedImages
            className="w-100"
            style={{ height: "100%", objectFit: "cover", borderRadius: "15px" }}
            image="edcschemenew"
          />
        </div>
        <div
          className={`col-lg-8 col-md-8 col-sm-12 d-flex flex-column align-items-start  ${
            width === "xs" || width === "sm" ? "sml  " : "lr p-2 pl-5"
          } `}
        >
          <div className="schemes_heading mb-4">Financial Assistance to ED Club</div>
          <div className="Schemes_heading_description">
            To create wealth and employment commensurate with unlimited natural
            and immense human resource potential of Kerala by developing
            entrepreneurship in the youth.
          </div>
          <div
            className={`Schemes_heading_description Schemes_new_discription  ${
              width === "xs" || width === "sm" ? "sml p-3 mt-5 " : "lr  mt-5 "
            } `}
          >
            Department of Industries & Commerce, Government of Kerala formulated
            a scheme to set up ”Entrepreneurship Development Clubs” in schools
            and colleges of the State to inculcate “Entrepreneurial Culture”
            amongst youth and equip them with the skills, techniques and
            confidence to act as torch- bearers of “Enterprise” for the new
            generation.
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchemeHerosection;
