import React from "react";
import { useRef } from "react";

import SingleCarousal from "../../../../components/carousals/singlecarousals/single.carousal";
import TestimonialCard from "../../../../components/testimonial.cards/testimonial.card";
import useOnScreen from "../../../../hooks/useOnScreen";
import "./testimonial.section.style.css";
import { testimonialData } from "./testimonialData";
import Auth1 from "../../../../assets/images/resourses/author-19.jpg";
import Auth2 from "../../../../assets/images/resourses/author-20.jpg";
import Auth3 from "../../../../assets/images/resourses/author-21.jpg";
import Auth4 from "../../../../assets/images/resourses/author-22.jpg";
import Auth5 from "../../../../assets/images/resourses/author-23.jpg";
import { useState } from "react";
import KiedImages from "../../../../assets/images/kied.images.component";

function TestimonialSection() {
  const [image, setImage] = useState(0);
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  return (
    <div className="clear-fix">
      <div
        className="common-sections container testimonial-section-two"
        ref={ref}
      >
        <div className="container">
          <div className="row clearfix">
            {/* <div className="image-column  d-none d-sm-block">
            <div className="inner-column"></div>
          </div> */}
            <div className=" col-sm-12">
              <SingleCarousal changeImage={(data) => setImage(data)}>
                {testimonialData.map((testi, i) => (
                  <TestimonialCard
                    key={i}
                    data={testi}
                    section={"testimonial"}
                  />
                ))}
              </SingleCarousal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialSection;
