export const newsData = [
  {
    id: 3,
    image: "news-4",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "YUVA BOOT CAMP PRODUCT EXPO",
    description:
      "As part of the Yuva Boot camp, a State Level Conclave was also conducted on the same day.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "15 NOVEMBER 2022",
    maindescription:
      "The Product Expo- exhibition displaying innovative products and services by student. The Product expo, which was inaugurated by Finance Minister Shri.K.N Balagopal and also part of President KIED Shri. Suman Billa IAS, will mainly display products and services by young and student entrepreneurs. Student entrepreneurs from various schools and colleges across Kerala participated and showcased their products and demos. From the 40 stalls, the top 5 stalls were awarded mementos & certificates. The exhibitors got the chance to meet and interact with industry experts and mentors from various organizations",
    // authorimage: "newsauthor",
    // authorname: "Lissy",
    // date: "15 SEPTEMBER 2022",
    venue: "Mascot Hotel, Thiruvananthapuram",
    // maindescriptionheading: "Programme Format",
    // maindescription2:
    //   "The event included a fine line up of around 30 speakers in the panel discussion on various topics in the theme of “Students and Youth Entrepreneurship as a Driver of Economic Growth”. The details of the topics for discussion are: 1.“Industry academy collaboration as power house of Innovation”, 2.“Embracing entrepreneurship mindset in students and youth”, 3. “MSME entrepreneurship in era gig economy and industry 4.0”,” 4. Role of media in fostering entrepreneurship.",
    // maindescription2heading: "Panel Discussion",
    // duration: "2 days",
    // category:
    //   " Aspiring Entrepreneurs who wish to start Business in Bakery Products",
    // noofparticipants: " 34",
    listheading: "",
    listitem: [],
    readTime: "5",
    images: [
      {
        id: 1,
        image: "pro1",
      },
      {
        id: 2,
        image: "pro2",
      },
      {
        id: 3,
        image: "pro3",
      },
      {
        id: 4,
        image: "pro4",
      },
      {
        id: 5,
        image: "pro5",
      },
      {
        id: 6,
        image: "pro6",
      },
      {
        id: 7,
        image: "pro7",
      },
      {
        id: 8,
        image: "pro8",
      },
      {
        id: 9,
        image: "pro9",
      },
      {
        id: 10,
        image: "pro10",
      },
    ],
  },
  {
    id: 4,
    image: "news-5",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: " YUVA BOOT CAMP (valedictory function)",
    description:
      "The valedictory function of the Yuva boot camp was addressed by Hon'ble  Finance minister Shri. K. N. Balagopal.",
    maindescription:
      "The valedictory function of the Yuva boot camp was addressed by Honourable Finance minister Shri. K. N. Balagopal.He was the Chief Guest of that session and also distributed the prize money and certificate to the winners",
    // authorimage: "newsauthor",
    // authorname: "Lissy",
    date: "15 SEPTEMBER 2022",
    // maindescriptionheading: "Programme Format",
    // maindescription2:
    //   "The event included a fine line up of around 30 speakers in the panel discussion on various topics in the theme of “Students and Youth Entrepreneurship as a Driver of Economic Growth”. The details of the topics for discussion are: 1.“Industry academy collaboration as power house of Innovation”, 2.“Embracing entrepreneurship mindset in students and youth”, 3. “MSME entrepreneurship in era gig economy and industry 4.0”,” 4. Role of media in fostering entrepreneurship.",
    // maindescription2heading: "Panel Discussion",
    // duration: "2 days",
    // category:
    //   " Aspiring Entrepreneurs who wish to start Business in Bakery Products",
    // noofparticipants: " 34",
    listheading: "",
    listitem: [],
    readTime: "5",
    images: [
      {
        id: 1,
        image: "val1",
      },
      {
        id: 2,
        image: "val2",
      },
      {
        id: 3,
        image: "val3",
      },
      {
        id: 4,
        image: "val4",
      },
      {
        id: 5,
        image: "val5",
      },
      {
        id: 6,
        image: "val6",
      },
      {
        id: 7,
        image: "val7",
      },
      {
        id: 8,
        image: "val8",
      },
    ],
  },
  {
    id: 5,
    image: "homenews1",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "YUVA BOOT CAMP STUDENT EXPO",
    description:
      "The inauguration ceremony of the Yuva Boot Camp State Level Conclave was held at the Mascot Hotel, Thiruvananthapuram.",
    maindescription:
      "The two-day programme comprised of 4 Panel discussions on various topics in the theme of “Students and Youth Entrepreneurship as a Driver of Economic Growth”, one story sharing session where the speakers shared their Entrepreneurial Journey, Business Pitching Contest for 30 student teams selected from district level and Product Expo by Student Entrepreneurs/ ED Clubs across Kerala.",
    maindescriptionheading: "Programme Format",
    maindescription2:
      "The event included a fine line up of around 30 speakers in the panel discussion on various topics in the theme of “Students and Youth Entrepreneurship as a Driver of Economic Growth”. The details of the topics for discussion are: 1.“Industry academy collaboration as power house of Innovation”, 2.“Embracing entrepreneurship mindset in students and youth”, 3. “MSME entrepreneurship in era gig economy and industry 4.0”,” 4. Role of media in fostering entrepreneurship.",
    maindescription2heading: "Panel Discussion",
    duration: "2 days",
    category:
      " Aspiring Entrepreneurs who wish to start Business in Bakery Products",
    noofparticipants: " 34",
    listheading: "",
    listitem: [],
    readTime: "5",
    images: [
      {
        id: 1,
        image: "yuvamain",
      },
      {
        id: 2,
        image: "yuva1",
      },
      {
        id: 3,
        image: "yuva2",
      },
      {
        id: 4,
        image: "yuva3",
      },
      {
        id: 5,
        image: "yuva4",
      },
      {
        id: 6,
        image: "yuva5",
      },
      {
        id: 7,
        image: "yuva6",
      },
      {
        id: 8,
        image: "yuva7",
      },
      {
        id: 9,
        image: "yuva8",
      },
    ],
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "14 SEPTEMBER 2022",
  },
  {
    id: 6,
    image: "immersion",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "newprograms/2",
    title: "ARISE-Immersion inaugurated by Shri. P. Rajiv",
    description:
      " Hon'ble Minister of Industrial Law, Department of Agriculture, Shri. P. Rajiv inaugurated ARISE-Immersion Offline training. program based on Exposure to the technical and commercial aspects of Agro based value-added products was conducted at KIED Campus",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "30 JULY 2021",
  },
  {
    id: 7,
    image: "news-3",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "ENTREPRENEURSHIP WORKSHOP IN BAKERY",
    description:
      "Five days Residential Entrepreneurship Workshop in Bakery & Confectionery Products for Aspiring Entrepreneurs.",
    maindescription: "",
    maindescriptionheading: "",
    maindescription2: "",
    maindescription2heading: "",
    duration: "5 days",
    category:
      " Aspiring Entrepreneurs who wish to start Business in Bakery Products",
    noofparticipants: " 34",
    listheading: "",
    listitem: [],
    readTime: "5",
    images: [
      {
        id: 1,
        image: "bakery1",
      },
      {
        id: 2,
        image: "bakery",
      },
    ],
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "30 AUGUST 2022",
  },
  {
    id: 1,
    image: "news-2",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "",
    title: "ENTERPRISE CREATION PROGRAMME ",
    description:
      "Training programme for Grooming Resource Persons of DIC, Govt of Kerala was conducted by KIED in association with EDII.",
    maindescription: "",
    maindescriptionheading: "",
    maindescription2: "",
    maindescription2heading: "",
    duration: "5 days",
    readTime: "5",
    category:
      " Aspiring Entrepreneurs who wish to start Business in Bakery Products",
    noofparticipants: " 34",
    listheading: "Topics Covered",
    listitem: [
      "Understanding the Business variants and business cycle.",
      "How to register a business entity",
      "Statutory Requirements / compliances for a business",
      "How to obtain a License from LSG",
      "Opportunities and Challenges for Small-scale Agro-Entrepreneurs in farm exports.",
      "Digital Marketing",
      "Process of applying for GST",
      "General norms and compliances- Intellectual Property Rights",
    ],
    images: [
      {
        id: 1,
        image: "inaugral",
      },
      {
        id: 2,
        image: "inaugral2",
      },
    ],
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "25 OCTOBER 2022",
  },
  {
    id: 2,
    image: "newsmentors",
    tag1: "BRANDING",
    tag2: "BUSINESS",
    viewmorelink: "edcEvents/2",
    title: "YUVA BOOT CAMP MENTORING",
    description:
      "Yuva boot camp Mentoring Session -2022 Inaugural address by:- shri. Suman Billa, IAS President Of KIED.",
    authorimage: "newsauthor",
    authorname: "Lissy",
    date: "30 JULY 2021",
  },
  // {
  //   id: 2,
  //   image: "newsmentors",
  //   tag1: "BRANDING",
  //   tag2: "",
  //   title: "YUVA BOOT CAMP MENTORING",
  //   viewmorelink: "edcEvents/2",
  //   description:
  //     "Yuva boot camp Mentoring Session -2022 Inaugural address by:- shri. Suman Billa, IAS President Of KIED.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "30 JULY 2022",
  // },

  // {
  //   id: 8,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 9,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 10,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 11,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 12,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 13,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 14,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 15,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 16,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 17,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 18,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 19,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 20,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
  // {
  //   id: 21,
  //   image: "newscardimage",
  //   tag1: "BRANDING",
  //   tag2: "BUSINESS",
  //   viewmorelink: "",
  //   title: "The Dark Side of Overnight Success",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, beatae repellendus cum a sit numquam dignissimos sint quam sapiente aliquam sequi maxime dicta quo aspernatur consectetur dolorem explicabo iste quibusdam.",
  //   authorimage: "newsauthor",
  //   authorname: "Lissy",
  //   date: "29 OCTOBER 2022",
  // },
];
