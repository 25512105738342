

import React from "react";

import KiedIcons from "../../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import { Table } from "reactstrap";
import "../../EdcNewProgram.css";
function EdcNewProgramDescription(props) {
  let width = useScreenBreakPoint();
  return (
    <div>
      <div className="greyBackground sectional-spaces ">
        <div className="auto-container">
          <div className="white-bg">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="d-flex justify-content-center">
                  <h2 className="inovation_headings listing-details">
                    {props?.data?.reportHeading}
                  </h2>
                </div>
                <div>
                  <div className="detail-description ">
                    <ul>
                      {props?.data?.subHeading1?.description?.map((card, i) => {
                        return (
                          <li>
                            <span>{card?.point}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ overflow: "auto" }}
              >
                <div className="w-100">
                  <Table bordered className="table-inner">
                    <thead>
                      <tr>
                        <th>District</th>
                        <th>Total No.of students participated</th>
                        <th>No.of team selected</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ernakulam</td>
                        <td>102</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Thrissur</td>
                        <td>109</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Palakkad</td>
                        <td>112</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Malappuram</td>
                        <td>103</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Calicut</td>
                        <td>77</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Kasargod</td>
                        <td>97</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Kannur</td>
                        <td>103</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Wayanad</td>
                        <td>102</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Alappuzha</td>
                        <td>127</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Pathanamthitta</td>
                        <td>91</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Idukki</td>
                        <td>100</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Kottayam</td>
                        <td>70</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Kollam</td>
                        <td>65</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Trivandrum</td>
                        <td>75</td>
                        <td>2</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EdcNewProgramDescription;
