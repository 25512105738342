// import React from 'react'

// function Infrastructuresupportingscheme() {
//   return (
//     <div>Infrastructuresupportingscheme</div>
//   )
// }

// export default Infrastructuresupportingscheme
import React from "react";
// import "../../schemes.page.styles.css";
import KiedButton from "../../../components/buttons/button/button.component";
import KiedIcons from "../../../assets/icons/kied.icons.component";
import useScreenBreakPoint from "../../../hooks/useScreenBreakPoint";
function Infrastructuresupportingscheme() {
  let width = useScreenBreakPoint();
  return (
    <div>
      <div className="auto-container">
        {/* <div
          className={`schemes_heading  ${
            width === "xs" || width === "sm" ? "sml mt-5" : "lr  mb-5 mt-5 pt-5"
          }`}
        >
          Stages in Entrepreneur Support Scheme
        </div> */}
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          1. Introduction
        </div>
        <div className=" Schemes_heading_description mb-5">
          This scheme has been formulated to support the market for the
          comodities which are sold within the local geographical limits.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          2. 
          About the scheme
        </div>
        <div className=" Schemes_heading_description mb-5">
          The scheme intends to provide marketing support for MSMEs which
          are having fixed capital investment up to Rs 25 lakhs. The component
          of FCI is does not comes the investments on land and building of the
          unit. This support is provided for the first two years from the date
          of commencement of production. Priority shall be given to
          handicrafts, GI indicated products. Preference may be given to spaces
          in prominent spotsjtourists places for displaying the product. As
          a pilot project, this scheme is meant for food products and other agro
          based products for the first year.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          3. Objectives
        </div>
        <div className=" Schemes_heading_description mb-5">
          The objective of the scheme is to support and promote marketing
          facilities and activities of MSMEs within local geographical limits of
          a district.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          4. General Provisions
        </div>
        <div className=" Schemes_heading_description mb-5">
          All applications for assistance under the scheme shall be submitted by
          the applicant directly to ADIO, TIO and produce the originals along
          with documents relating to investment, Udhyam and proof of identity.
          The officer while accepting the application form should examine
          whether all necessary details have been furnished. If any information
          is found inadequate the same should be intimated to the applicant unit
          both electronically and otherwise and grant 10 days time to rectify
          the defects.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          5. Appeals
        </div>
        <div className=" Schemes_heading_description mb-5">
          Appeals shall be filed before the Director of Industries & Commerce
          within 30 days from the date of issue of orders of the General
          Manager, District Industries. Centres concerned, no appeal after this
          period will be entertained. The appeal shall be disposed of as far as
          possible within 3 months from the date of receipt of appeal after
          giving the appellant an opportunity of being heard whenever necessary.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          6. Recoveries and Penalties
        </div>
        <div className=" Schemes_heading_description mb-5">
          Any assistance under the scheme to an industrial unit is liable to be
          refunded by the unit with interest at the rate of 14% per annum from
          the date of receipt of the same, on issue of registered demand notice
          to the unit by the Recommending/Sanctioning Authority on ground of
          obtaining assistance by misrepresentation, forgery or deception or not
          found working. All amounts due to 'Government under this provision
          shall, in case of default, be recoverable as if they are arrears of
          land revenue under the provisions of the Kerala Revenue Recovery Act,
          1968 or in such other manner as Government may deem fit sufficient 
          opportunity to show cause in writing shall however be granted to the
          units before a demand raised against it.
        </div>
        <div
          className={`schemes_sub_heading  ${
            width === "xs" || width === "sm" ? "sm my-4" : "lr  mb-5 mt-5"
          } `}
        >
          7. Funds
        </div>
        <div className=" Schemes_heading_description mb-5">
          Fund for the scheme for an amount of Rs 100 lakhs shall be met from
          the head of account 2851-00-102-07 (05) wherein an amount of Rs.700
          lakhs is earmarked for Establishment of local market infrastructure
          for MSMEs scheme during 2022- 2023. The Director of Industries &
          Commerce have the full powers to prepare applications forms, form of
          agreement and any other formats required for the proper implementation
          of the scheme and to make, sub-rules, check list if any, required.
        </div>
      </div>
    </div>
  );
}

export default Infrastructuresupportingscheme;
