// import React from 'react'

// function InfrastructureBreadcrumb() {
//   return (
//     <div>InfrastructureBreadcrumb</div>
//   )
// }

// export default InfrastructureBreadcrumb
import React from "react";

function InfrastructureBreadcrumb() {
  return (
    <div>
      <div className="faq_banner auto-container d-flex">
        <a href="/">Home&nbsp;/</a>
        <a href="/department/academy-collaboration/academiaSchemes">
          {" "}
          Academia&nbsp;/
        </a>
        <a className="current" href="/BuildingProgramSchemes">
          Academia Scheme Details
        </a>
      </div>
    </div>
  );
}

export default InfrastructureBreadcrumb;
