import React from "react";

import MultiCarousal from "../carousals/multicarousals/multi.carousal";
import "./eventscrousel.styles.css";
import { useNavigate } from "react-router-dom";
import KiedImages from "../../assets/images/kied.images.component";

function EventsCarousel(props) {
  let navigate = useNavigate();

  return (
    <>
      <MultiCarousal section="faq">
        {props.data.map((faq, i) => {
          return (
            <div
              style={{ border: "1px solid grey", borderRadius: "5px" }}
              className="col-12 my-2 h-100"
            >
              <div className="d-flex justify-content-center align-items-center h-100 ">
                <div>
                  <div style={{ color: "black", fontSize: "18px" }}>
                    {faq.title}
                  </div>
                  {/* <div>{faq.status}</div> */}
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => navigate(`/homeNews-detail/${faq.id}`)}
                  >
                    Learn More
                  </div>
                </div>
                <KiedImages
                  className="mr-3"
                  style={{
                    height: "80px",
                    width: "auto",
                    boxShadow: "2px 2px grey",
                  }}
                  link={faq.photo}
                />
              </div>
            </div>
          );
        })}
      </MultiCarousal>
    </>
  );
}

export default EventsCarousel;
