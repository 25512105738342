export const companyDetails = [
  {
    id: 1,
    name: "Inovace",
    designation: "Front End DEveloper",
    website: "google.com",
    contact: "9876543210",
    email: "email@mail.com",
    about:
      "Maharashtra assembly on Tuesday unanimously passed a resolution over the border dispute with neighbouring Karnataka. Chief minister Eknath Shinde moved the resolution. According to the resolution, Maharashtra govt will make all necessary legal pursuits in the Supreme Court to include Belgaum, Karvar, Nipani, Bhalki, Bidar cities & 865 Marathi-speaking villages of Karnataka in Maharashtra under any circumstances.",
    mode: "Offline",
    nature: "paid",
    location: "Kollam",
    duration: "6 Months",
    perks: "Laptop",
    stipend: "10k",
    qualification: "MCA",
    description:
      "Maharashtra assembly on Tuesday unanimously passed a resolution over the border dispute with neighbouring Karnataka. Chief minister Eknath Shinde moved the resolution. According to the resolution, Maharashtra govt will make all necessary legal pursuits in the Supreme Court to include Belgaum, Karvar, Nipani, Bhalki, Bidar cities & 865 Marathi-speaking villages of Karnataka in Maharashtra under any circumstances.",
  },
  {
    id: 2,
    name: "QSOL India",
    designation: "Front End DEveloper",
  },
  {
    id: 3,
    name: "Dell",
    designation: "Front End DEveloper",
  },
  {
    id: 4,
    name: "Provogue",
    designation: "Front End DEveloper",
  },
  {
    id: 5,
    name: "HP",
    designation: "Front End DEveloper",
  },
  {
    id: 6,
    name: "Asus",
    designation: "Front End DEveloper",
  },
  {
    id: 7,
    name: "MI India",
    designation: "Front End DEveloper",
  },
  {
    id: 8,
    name: "Evol",
    designation: "Front End DEveloper",
  },
  {
    id: 9,
    name: "Acer",
    designation: "Front End DEveloper",
  },
];
